/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import _find from 'lodash/find';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AddCredentialButton from './AddCredentialButton';

function ReAuthenticateCredentialDialog() {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const { items: credentials2 } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };

  function onClose() {
    searchParams.delete('credentialId');
    navigate({
      pathname: `/workspace/${workspaceId}/data-sources`,
      search: searchParams.toString(),
    });
  }

  function closeDialog() {
    onClose();
  }

  const foundCredential = _find(credentials2, {
    id: searchParams.get('credentialId'),
  });
  const selectedDataProvider = foundCredential
    ? foundCredential.dataProvider[0]
    : '';

  const dpConf =
    dataProviderSettings.config[selectedDataProvider.toUpperCase()];

  return (
    <Dialog maxWidth="md" onClose={closeDialog} open>
      <DialogContent>
        {foundCredential?.status !== 'INVALID' && (
          <>
            <Typography component="div" variant="h5" sx={{ mb: 1.5 }}>
              Successful re-authenticated the credential
            </Typography>

            <Typography variant="body2">
              Success! You can close this tab.
            </Typography>
          </>
        )}
        {foundCredential?.status === 'INVALID' && (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            {dpConf && (
              <AddCredentialButton
                reAuthenticate={true}
                selectedDataProvider={selectedDataProvider}
                variant="large"
              />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReAuthenticateCredentialDialog;
