/* eslint-disable no-nested-ternary */

import {
  AddCircle,
  Construction,
  Google,
  OpenInNew,
  Visibility,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as types from '../../constants/ActionTypes';
import dataStudioLogo from '../../data_studio_logo.svg';
import * as queries from '../../graphql/queries';
import TemplateImage from '../templates/TemplateImage';

dayjs.extend(relativeTime);

const selectTemplates = createSelector(
  (state) => state,
  (_, settings) => settings,
  (state, settings) => {
    const { selectedUserTeam, dataProvider } = settings;
    const {
      isFetching,
      items: templates,
      nextToken: initialNextToken,
    } = state.templatesByTeam[selectedUserTeam] || {
      isFetching: true,
      items: [],
      nextToken: undefined,
    };

    let filteredTemplates = templates;

    if (dataProvider) {
      filteredTemplates = _filter(filteredTemplates, (o) =>
        o.dataProviders.includes(dataProvider)
      );
    }

    return {
      isFetching,
      templates: filteredTemplates,
      initialNextToken,
    };
  }
);

function TemplateCard({
  hasEditRights,
  selectedUserTeam,
  dataSourceId,
  templateId,
  templateName,
  reportTemplateId,
  connectorId,
  image,
}) {
  const reportTemplateParam = reportTemplateId
    ? `&reportTemplateId=${reportTemplateId}`
    : '';

  const link = `https://lookerstudio.google.com/datasources/create?connectorId=${connectorId}${reportTemplateParam}&connectorConfig=${encodeURIComponent(
    JSON.stringify({
      workspaceId: selectedUserTeam,
      dataSourceId,
      use_existing: 'true',
    })
  )}`;

  return (
    <ImageListItem
      disabled={!hasEditRights}
      key={templateId}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.action.disabled}`,
        padding: '0px !important',
        margin: '2px',
        maxHeight: '200px',
      })}
    >
      <a
        disabled={!hasEditRights}
        href={hasEditRights ? link : '#'}
        rel="noopener noreferrer"
        target="_blank"
      >
        {!image ? (
          <img
            alt={templateName}
            src={'https://cdn.gladior.com/ds-blank.png'}
            style={{ width: '100%', maxHeight: '200px' }}
          />
        ) : (
          <TemplateImage image={image} />
        )}
      </a>
      <ImageListItemBar
        actionIcon={
          <IconButton
            disabled={!hasEditRights}
            href={link}
            rel="noopener"
            size="large"
            target="_blank"
          >
            <AddCircle
              sx={{
                color: '#fff',
              }}
            />
          </IconButton>
        }
        title={templateName}
      />
    </ImageListItem>
  );
}

function GoogleDataStudioDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const [dsUsage, setDsUsage] = useState(null);
  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const dialogs = useSelector((state) => state.dialogs);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const userObject = useSelector((state) => state.userObject);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const open = dialogs.showGoogleDataStudioDialog;

  // eslint-disable-next-line no-unused-vars
  const { isFetching, templates, initialNextToken } = useSelector((state) =>
    selectTemplates(state, {
      selectedUserTeam,
      dataProvider: selectedDataSource?.dataProvider,
    })
  );

  const dpConf =
    dataProviderSettings.config[selectedDataSource?.dataProvider.toUpperCase()];

  async function getDataStudioUsage() {
    const usages = await API.graphql(
      graphqlOperation(queries.getDataStudioUsage, {
        dataSourceId: selectedDataSource.id,
        teamId: selectedUserTeam,
      })
    );
    setDsUsage(usages.data.getDataStudioUsage);
  }

  useEffect(() => {
    setDsUsage(null);

    if (selectedDataSource && selectedUserTeam) {
      getDataStudioUsage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectedDataSource && selectedUserTeam) {
      getDataStudioUsage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSource, selectedUserTeam]);

  function closeDialog() {
    setDsUsage(null);
    dispatch({
      type: types.SHOW_GOOGLE_DATA_STUDIO_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  if (!selectedDataSource) {
    return <></>;
  }

  let hasEditRights =
    selectedDataSource.owner === userObject.id ||
    (selectedDataSource.editors &&
      selectedDataSource.editors.includes(userObject.id));

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  if (groups) {
    if (groups.includes(selectedDataSource.groups)) {
      hasEditRights = true;
    }
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={open}>
        <DialogTitle sx={{ textAlign: 'center' }}>Looker Studio</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                color="info"
                href="https://lookerstudio.google.com/u/0/"
                startIcon={<OpenInNew />}
                target="_blank"
                variant="text"
              >
                Open Looker Studio
              </Button>
            </Box>
          </Box>
          {dsUsage && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Stack
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                      spacing={0}
                    >
                      <Visibility
                        color="info"
                        sx={{ width: 20, height: 20, mb: 1 }}
                      />
                      <Typography align="center" variant="subtitle2">
                        Last Viewed
                      </Typography>
                      <Typography align="center" variant="body1">
                        {dsUsage.lastViewedTimestamp
                          ? dayjs().to(dayjs(dsUsage.lastViewedTimestamp))
                          : '-'}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Stack
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                      spacing={0}
                    >
                      <Construction
                        color="info"
                        sx={{ width: 20, height: 20, mb: 1 }}
                      />
                      <Typography align="center" variant="subtitle2">
                        Last Configured
                      </Typography>
                      <Typography align="center" variant="body1">
                        {dsUsage.lastConfiguredTimestamp
                          ? dayjs().to(dayjs(dsUsage.lastConfiguredTimestamp))
                          : '-'}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Stack
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                      spacing={0}
                    >
                      <Avatar
                        src={dataStudioLogo}
                        sx={{ width: 20, height: 20, mb: 1 }}
                        variant="square"
                      />
                      <Typography align="center" variant="subtitle2">
                        Active Accounts
                      </Typography>
                      <Typography align="center" variant="body1">
                        {dsUsage.accountCount}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          )}
          {(dsUsage && Number(dsUsage.accountCount)) > 0 &&
            dsUsage.accounts.filter((account) => account && account.length > 0)
              .length > 0 && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', mb: 2, mt: 2 }}
              >
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="subtitle2"
                >
                  Active accounts:
                </Typography>
                <Box>
                  <Stack direction="row" spacing={2}>
                    {dsUsage.accounts
                      .filter((account) => account && account.length > 0)
                      .map((account) => (
                        <Chip
                          avatar={
                            <Avatar>
                              <Google />
                            </Avatar>
                          }
                          key={account}
                          label={account}
                        />
                      ))}
                  </Stack>
                </Box>
              </Box>
            )}

          <Box sx={{ display: 'flex', mt: 4, flexDirection: 'column' }}>
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              Connect to Looker Studio
            </Typography>

            <Paper variant="outlined">
              <Carousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5,
                  },
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                  },
                }}
              >
                <TemplateCard
                  connectorId={dpConf?.connectorId}
                  dataSourceId={selectedDataSource.id}
                  hasEditRights={hasEditRights}
                  reportTemplateId={''}
                  selectedUserTeam={selectedUserTeam}
                  templateId={dpConf?.connectorId}
                  templateName={'New Report'}
                />

                {templates.map((template) => (
                  <TemplateCard
                    connectorId={dpConf?.connectorId}
                    dataSourceId={selectedDataSource.id}
                    hasEditRights={hasEditRights}
                    key={template.id}
                    reportTemplateId={template?.view?.lookerStudioId}
                    selectedUserTeam={selectedUserTeam}
                    templateId={template.id}
                    templateName={template.name}
                    image={template.image}
                    // templateThumbnail={template.thumbnail}
                  />
                ))}

                {/* {dataStudioTemplates.map((template) => (
                  <TemplateCard
                    connectorId={template.connectorId}
                    dataSourceId={selectedDataSource.id}
                    hasEditRights={hasEditRights}
                    key={template.id}
                    reportTemplateId={template.reportTemplateId}
                    selectedUserTeam={selectedUserTeam}
                    templateId={template.id}
                    templateName={template.name}
                    templateThumbnail={template.thumbnail}
                  />
                ))} */}
              </Carousel>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GoogleDataStudioDialog;
