export const SET_USER_OBJECT = 'SET_USER_OBJECT';
export const SET_CURRENT_AUTHENTICATED_USER = 'SET_CURRENT_AUTHENTICATED_USER';

export const SELECT_USER_TEAM = 'SELECT_USER_TEAM';

export const SET_USER_TEAMS = 'SET_USER_TEAMS';
export const ADD_USER_TEAM = 'ADD_USER_TEAM';
export const DELETE_USER_TEAM = 'DELETE_USER_TEAM';
export const UPDATE_USER_TEAM = 'UPDATE_USER_TEAM';

export const SET_TEAM_REPORTS = 'SET_TEAM_REPORTS';
export const ADD_MORE_TEAM_REPORTS = 'ADD_MORE_TEAM_REPORTS';

export const ADD_TEAM_REPORT = 'ADD_TEAM_REPORT';
export const DELETE_TEAM_REPORT = 'DELETE_TEAM_REPORT';
export const UPDATE_TEAM_REPORT = 'UPDATE_TEAM_REPORT';

export const REQUEST_TEAM_REPORTS = 'REQUEST_TEAM_REPORTS';

export const ADD_TEAM_THEME = 'ADD_TEAM_THEME';
export const UPDATE_TEAM_THEME = 'UPDATE_TEAM_THEME';

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';

export const SET_TEAM_CREDENTIALS = 'SET_TEAM_CREDENTIALS';
export const ADD_TEAM_CREDENTIAL = 'ADD_TEAM_CREDENTIAL';
export const UPDATE_TEAM_CREDENTIAL = 'UPDATE_TEAM_CREDENTIAL';
export const DELETE_TEAM_CREDENTIAL = 'DELETE_TEAM_CREDENTIAL';
export const ADD_MORE_TEAM_CREDENTIALS = 'ADD_MORE_TEAM_CREDENTIALS';
export const REQUEST_TEAM_CREDENTIALS = 'REQUEST_TEAM_CREDENTIALS';

export const SET_TEAM_INVITES = 'SET_TEAM_INVITES';
export const REQUEST_TEAM_INVITES = 'REQUEST_TEAM_INVITES';

export const SET_TEAM_DATA_SOURCES = 'SET_TEAM_DATA_SOURCES';
export const ADD_TEAM_DATA_SOURCE = 'ADD_TEAM_DATA_SOURCE';
export const UPDATE_TEAM_DATA_SOURCE = 'UPDATE_TEAM_DATA_SOURCE';
export const DELETE_TEAM_DATA_SOURCE = 'DELETE_TEAM_DATA_SOURCE';
export const ADD_MORE_TEAM_DATA_SOURCES = 'ADD_MORE_TEAM_DATA_SOURCES';
export const REQUEST_TEAM_DATA_SOURCES = 'REQUEST_TEAM_DATA_SOURCES';

export const SET_TEAM_WIDGETS = 'SET_TEAM_WIDGETS';
export const ADD_TEAM_WIDGET = 'ADD_TEAM_WIDGET';
export const UPDATE_TEAM_WIDGET = 'UPDATE_TEAM_WIDGET';
export const DELETE_TEAM_WIDGET = 'DELETE_TEAM_WIDGET';
export const ADD_MORE_TEAM_WIDGETS = 'ADD_MORE_TEAM_WIDGETS';
export const REQUEST_TEAM_WIDGETS = 'REQUEST_TEAM_WIDGETS';

export const SET_TEAM_TEMPLATES = 'SET_TEAM_TEMPLATES';
export const ADD_TEAM_TEMPLATE = 'ADD_TEAM_TEMPLATE';
export const UPDATE_TEAM_TEMPLATE = 'UPDATE_TEAM_TEMPLATE';
export const DELETE_TEAM_TEMPLATE = 'DELETE_TEAM_TEMPLATE';
export const ADD_MORE_TEAM_TEMPLATES = 'ADD_MORE_TEAM_TEMPLATES';
export const REQUEST_TEAM_TEMPLATES = 'REQUEST_TEAM_TEMPLATES';

export const SET_TEMPLATE_SETTINGS = 'SET_TEMPLATE_SETTINGS';
export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';

export const SET_SELECTED_CHAPTER = 'SET_SELECTED_CHAPTER';

export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';

export const SET_SELECTED_DATA_SOURCE = 'SET_SELECTED_DATA_SOURCE';
export const SET_SELECTED_DATA_PROVIDER = 'SET_SELECTED_DATA_PROVIDER';

export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

export const SET_CONNECTED_DATA_SOURCES = 'SET_CONNECTED_DATA_SOURCES';
export const SET_CONNECTED_REPORTS = 'SET_CONNECTED_REPORTS';

export const SHOW_DELETE_REPORT_DIALOG = 'SHOW_DELETE_REPORT_DIALOG';
export const SHOW_UPDATE_REPORT_DIALOG = 'SHOW_UPDATE_REPORT_DIALOG';
export const SHOW_EDITING_RIGHTS_REPORT_DIALOG =
  'SHOW_EDITING_RIGHTS_REPORT_DIALOG';
export const SHOW_EDIT_REPORT_DIALOG = 'SHOW_EDIT_REPORT_DIALOG';

export const SHOW_TEMPLATE_SETTINGS_DIALOG = 'SHOW_TEMPLATE_SETTINGS_DIALOG';

export const SHOW_CHANGE_CREDENTIAL_FOR_DATA_SOURCE_DIALOG =
  'SHOW_CHANGE_CREDENTIAL_FOR_DATA_SOURCE_DIALOG';

export const SHOW_DELETE_DATA_SOURCE_DIALOG = 'SHOW_DELETE_DATA_SOURCE_DIALOG';

export const SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG =
  'SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG';

export const SHOW_GOOGLE_DATA_STUDIO_DIALOG = 'SHOW_GOOGLE_DATA_STUDIO_DIALOG';
export const SHOW_POWER_BI_DIALOG = 'SHOW_POWER_BI_DIALOG';
export const SHOW_DATA_API_DIALOG = 'SHOW_DATA_API_DIALOG';
export const SHOW_QUERY_COMPOSER_DIALOG = 'SHOW_QUERY_COMPOSER_DIALOG';
export const SHOW_PRE_CACHE_DIALOG = 'SHOW_PRE_CACHE_DIALOG';
export const SHOW_DATA_EXPLORER_DIALOG = 'SHOW_DATA_EXPLORER_DIALOG';
export const SHOW_ASK_DATA_HUB_DIALOG = 'SHOW_ASK_DATA_HUB_DIALOG';

export const SHOW_MICROSOFT_TEAMS_DIALOG = 'SHOW_MICROSOFT_TEAMS_DIALOG';

export const SHOW_RE_CONNECT_DATA_SOURCE_DIALOG =
  'SHOW_RE_CONNECT_DATA_SOURCE_DIALOG';

export const SHOW_EDIT_DATA_SOURCE_DIALOG = 'SHOW_EDIT_DATA_SOURCE_DIALOG';

export const SHOW_CREATE_DATA_SOURCE_CONNECTION_DIALOG =
  'SHOW_CREATE_DATA_SOURCE_CONNECTION_DIALOG';

export const SHOW_CREATE_REPORT_DATA_SOURCE_CONNECTION_DIALOG =
  'SHOW_CREATE_REPORT_DATA_SOURCE_CONNECTION_DIALOG';

export const SHOW_FILTER_DIALOG = 'SHOW_FILTER_DIALOG';

export const SHOW_CREATE_TEAM_DIALOG = 'SHOW_CREATE_TEAM_DIALOG';
export const SHOW_DELETE_TEAM_DIALOG = 'SHOW_DELETE_TEAM_DIALOG';
export const SHOW_UPDATE_TEAM_DIALOG = 'SHOW_UPDATE_TEAM_DIALOG';
export const SHOW_UPDATE_COLOR_THEME_DIALOG = 'SHOW_UPDATE_COLOR_THEME_DIALOG';

export const SHOW_MANAGE_TEAM_MEMBERS_DIALOG =
  'SHOW_MANAGE_TEAM_MEMBERS_DIALOG';

export const SHOW_DELETE_TEMPLATE_DIALOG = 'SHOW_DELETE_TEMPLATE_DIALOG';

export const SHOW_EDIT_TEMPLATE_DIALOG = 'SHOW_EDIT_TEMPLATE_DIALOG';

export const SHOW_USER_TOKEN_DIALOG = 'SHOW_USER_TOKEN_DIALOG';

export const USER_LOGOUT = 'USER_LOGOUT';

export const SHOW_UPDATE_DATA_SOURCE_DISPLAY_NAME =
  'SHOW_UPDATE_DATA_SOURCE_DISPLAY_NAME';
export const SHOW_UPDATE_MAX_REQUEST = 'SHOW_UPDATE_MAX_REQUEST';

export const SHOW_USAGE = 'SHOW_USAGE';

export const SET_DATA_PROVIDER_CONFIG = 'SET_DATA_PROVIDER_CONFIG';
export const REQUEST_DATA_PROVIDER_CONFIG = 'REQUEST_DATA_PROVIDER_CONFIG';

export const SET_DATA_PROVIDER_SETTINGS = 'SET_DATA_PROVIDER_SETTINGS';

export const SHOW_WEBSITE_INTEGRATION_DIALOG =
  'SHOW_WEBSITE_INTEGRATION_DIALOG';
