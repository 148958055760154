import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

async function deleteFeedTemplate(feedId) {
  await API.graphql(
    graphqlOperation(mutations.removeFeedTemplate, {
      feedId,
    })
  );

  return feedId;
}

export default deleteFeedTemplate;
