import { Business, Close, Label, Language } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import * as lTypes from '../../constants/LabelTypes';
import DataProviderChip from '../data/DataProviderChip';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  outerDiv: {
    '&:hover': {
      '& $addIcon': {
        display: 'block',
      },
    },
  },
  addIcon: {
    display: 'none',
    color: theme.palette.text.primary,
  },
  whiteBackground: {
    background: '#fff',
    border: `1px solid ${theme.palette.action.disabled}`,
    color: blue[500],
    fontWeight: 500,
  },
}));

function DataSourceLabel({
  type,
  name,
  dataProvider,
  deletable = false,
  deleteAction = async () => {},
  alwaysShowDeleteButton = false,
  variant = '',
  confirmDeletion = true,
}) {
  const classes = useStyles();

  const containerRef = useRef(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  let icon = <></>;

  switch (type) {
    case lTypes.WEBSITE:
      icon = <Language color="secondary" fontSize="small" />;
      break;
    case lTypes.COMPANY:
      icon = <Business color="secondary" fontSize="small" />;
      break;
    case lTypes.CUSTOM:
      icon = <Label color="secondary" fontSize="small" />;
      break;
    case lTypes.ACCOUNT_ID:
      icon = (
        <DataProviderChip dataProvider={dataProvider} variant="small_avatar" />
      );
      break;
    default:
      break;
  }

  let onDelelete = null;

  if (deletable === true) {
    onDelelete = async () => {
      if (confirmDeletion === true) {
        setOpenDeleteDialog(true);
      } else {
        await deleteAction();
      }
    };
  }

  return (
    <>
      <Dialog
        maxWidth="sm"
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        open={openDeleteDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Label will be removed from the Data Source
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              await deleteAction();
              setOpenDeleteDialog(false);
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Chip
        className={clsx({
          [classes.outerDiv]: true,
          [classes.whiteBackground]: variant === 'SEARCH_BAR',
        })}
        deleteIcon={
          <Close
            className={clsx({
              [classes.addIcon]: alwaysShowDeleteButton === false,
            })}
            fontSize="small"
            ref={containerRef}
          />
        }
        icon={<Avatar className={classes.small}>{icon}</Avatar>}
        label={name}
        onDelete={onDelelete}
        variant="outlined"
      />
    </>
  );
}

export default DataSourceLabel;
