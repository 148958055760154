import {
  amber,
  blue,
  green,
  orange,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

const avatarCredentialColors = [
  green[300],
  blue[300],
  red[300],
  orange[300],
  yellow[300],
  teal[300],
  amber[300],
];

export default avatarCredentialColors;
