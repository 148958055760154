import { Check, Search } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import TreeItem from '@mui/lab/TreeItem';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  InputBase,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import querystring from 'querystring';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import getAllLabels from '../api/getAllLabels';

import useQuery from '../hooks/useQuery';
import useDebounce from '../utilities/useDebounce';
import CredentialLabel from './credentials/CredentialLabel';
import NewLabelDialog from './labels/NewLabelDialog';
import ListItemLink from './ListItemLink';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },

  treeItemGroup: {
    marginLeft: '0px',
  },
  treeItemContent: {
    marginTop: theme.spacing(1),

    marginBottom: theme.spacing(1),
  },

  tableWrapper: {
    maxHeight: 200,
    minHeight: 200,
    overflow: 'auto',
  },

  searchBar: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  labelToolbar: {
    height: '35px',
    minHeight: '35px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  treeLabel: {
    marginLeft: theme.spacing(1),
  },
}));

const loader = (
  <ListItemLink key={0}>
    <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress size={20} />
    </Box>
  </ListItemLink>
);

function RecentSwitcher() {
  const classes = useStyles();

  const navigate = useNavigate();

  const location = useLocation();

  const scrollParentRef = useRef();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const [nextToken, setNextToken] = useState(null);
  const [labels, setLabels] = useState([]);
  const [query, setQuery] = useState('');
  const [waitForQuery, setWaitForQuery] = useState(false);
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [selectedDataProviders, setSelectedDataProviders] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState(null);

  const debouncedSearchTerm = useDebounce(query, 1000);

  const useQ = useQuery();

  const labelIds = useQ.get('labels') ? [useQ.get('labels')] : [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const connectorIds = useQ.get('connectors') ? [useQ.get('connectors')] : [];
  const credentialsIds = useQ.get('credentials')
    ? [useQ.get('credentials')]
    : [];

  const { items: credentialsItems } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };

  let dataProviders = Object.keys(dataProviderSettings.config).map((key) => {
    const prov = dataProviderSettings.config[key];
    return { id: key, name: prov.name, image: prov.image };
  });

  dataProviders = dataProviders.filter((el) => el !== null);

  useEffect(() => {
    if (connectorIds.length > 0) {
      const selectedDP = _find(dataProviders, {
        id: connectorIds[0],
      });
      setSelectedDataProviders(selectedDP);
    } else {
      setSelectedDataProviders(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorIds]);

  useEffect(() => {
    if (credentialsIds.length > 0) {
      const selectedC = _find(credentialsItems, {
        id: credentialsIds[0],
      });
      setSelectedCredential(selectedC);
    } else {
      setSelectedCredential(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialsIds]);

  async function fetchLabels() {
    let filter;
    if (debouncedSearchTerm) {
      filter = {
        or: [
          {
            nameLowercase: {
              contains: debouncedSearchTerm.trim().toLowerCase(),
            },
          },
        ],
      };
    }

    let labelsToAdd = [];
    const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
      await getAllLabels(selectedUserTeam, undefined, 10, filter);
    labelsToAdd = [...labelsToAdd, ...newDataSourceLabels];

    if (debouncedSearchTerm === '') {
      const { dataSourceLabels: suggestedDataSourceLabels } =
        await getAllLabels(selectedUserTeam, undefined, 10, undefined);

      const suggestedDataSourceLabelsToAddIds = suggestedDataSourceLabels.map(
        (o) => o.id
      );

      const suggestedDataSourceLabelsToAdd = suggestedDataSourceLabels.map(
        (o) => ({ ...o, isSuggestion: true })
      );

      const filteredDataSourceLabelsToAdd = labelsToAdd.filter(
        (o) => !suggestedDataSourceLabelsToAddIds.includes(o.id)
      );

      labelsToAdd = [
        ...suggestedDataSourceLabelsToAdd,
        ...filteredDataSourceLabelsToAdd,
      ];
    }

    labelsToAdd = labelsToAdd.map((lta) => ({
      ...lta,
      isConnected: labelIds.includes(lta.id),
    }));

    labelsToAdd = _orderBy(
      labelsToAdd,
      ['isConnected', 'name'],
      ['desc', 'asc']
    );

    setLabels(labelsToAdd);
    setNextToken(newNextToken);
    setWaitForQuery(false);
  }

  useEffect(() => {
    fetchLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  async function loadItems() {
    let filter;
    if (debouncedSearchTerm) {
      filter = {
        or: [
          {
            nameLowercase: {
              contains: debouncedSearchTerm.trim().toLowerCase(),
            },
          },
        ],
      };
    }
    const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
      await getAllLabels(selectedUserTeam, nextToken, 10, filter);

    let labelsToAdd = [...labels, ...newDataSourceLabels];

    labelsToAdd = labelsToAdd.map((lta) => ({
      ...lta,
      isConnected: labelIds.includes(lta.id),
    }));

    labelsToAdd = _orderBy(
      labelsToAdd,
      ['isConnected', 'name'],
      ['desc', 'asc']
    );

    setLabels(labelsToAdd);
    setNextToken(newNextToken);
  }

  return (
    <>
      <NewLabelDialog
        selectedUserTeam={selectedUserTeam}
        setShowNewDialog={setShowNewDialog}
        showNewDialog={showNewDialog}
      />
      <Box pt={1} width="300px">
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['3']}
        >
          <TreeItem
            classes={{
              group: classes.treeItemGroup,
              content: classes.treeItemContent,
              iconContainer: classes.treeLabel,
            }}
            label="Filter by Credentials"
            nodeId="1"
          >
            <Box mx={2}>
              <Autocomplete
                getOptionLabel={(option) => option?.name}
                groupBy={(option) =>
                  option.dataProvider
                    .map((dp) => dataProviderSettings.config[dp]?.name)
                    .join()
                }
                onChange={(event, value) => {
                  const queryObj = querystring.parse(location.search.slice(1));
                  if (value) {
                    queryObj.credentials = value.id;
                  } else {
                    delete queryObj.credentials;
                  }
                  navigate({
                    ...location,
                    search: querystring.stringify(queryObj),
                  });
                }}
                options={credentialsItems}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Credentials"
                    margin="dense"
                    placeholder="Credential"
                    variant="outlined"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <Box {...props}>
                    <CredentialLabel
                      dataProvider={option.dataProvider[0]}
                      deletable={false}
                      email={option.email}
                      key={option.id}
                      name={option.name}
                      picture={option.picture}
                      props={props}
                      selected={selected}
                      type={option.dataProvider[0]}
                      variant="SEARCH_BAR"
                    />
                    {option.title}
                  </Box>
                )}
                value={selectedCredential}
              />
            </Box>
          </TreeItem>
          <TreeItem
            classes={{
              group: classes.treeItemGroup,
              content: classes.treeItemContent,
              iconContainer: classes.treeLabel,
            }}
            label="Filter by Connectors"
            nodeId="2"
          >
            <Box mx={2}>
              <Autocomplete
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  const queryObj = querystring.parse(location.search.slice(1));
                  if (value) {
                    queryObj.connectors = value.id;
                  } else {
                    delete queryObj.connectors;
                  }

                  navigate({
                    ...location,
                    search: querystring.stringify(queryObj),
                  });
                }}
                options={dataProviders}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Data Providers"
                    margin="dense"
                    placeholder="Data Provider"
                    variant="outlined"
                  />
                )}
                value={selectedDataProviders}
              />
            </Box>
          </TreeItem>

          <TreeItem
            classes={{
              group: classes.treeItemGroup,
              content: classes.treeItemContent,
              iconContainer: classes.treeLabel,
            }}
            label="Filter by Label"
            nodeId="3"
          >
            <Box mt={1}>
              <AppBar
                className={classes.searchBar}
                color="default"
                elevation={0}
                position="static"
              >
                <Toolbar className={classes.labelToolbar} variant="dense">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                  >
                    <InputBase
                      autoFocus
                      className={classes.input}
                      margin="dense"
                      onChange={(e) => {
                        if (waitForQuery === false) {
                          setWaitForQuery(true);
                        }
                        setQuery(e.target.value);
                      }}
                      placeholder="Filter labels"
                      type="search"
                      value={query}
                    />
                    <IconButton
                      aria-label="search"
                      className={classes.iconButton}
                      size="large"
                    >
                      {waitForQuery === true ? (
                        <CircularProgress size={15} />
                      ) : (
                        <Search />
                      )}
                    </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
            </Box>

            <Box>
              <div className={classes.tableWrapper} ref={scrollParentRef}>
                <InfiniteScroll
                  hasMore={waitForQuery === false ? nextToken !== null : false}
                  loadMore={loadItems}
                  loader={loader}
                  pageStart={0}
                  useWindow={false}
                >
                  <List dense>
                    {labels.map((item) => (
                      <ListItem
                        button
                        key={item.id}
                        onClick={async () => {
                          const queryObj = querystring.parse(
                            location.search.slice(1)
                          );

                          if (queryObj.labels === item.id) {
                            delete queryObj.labels;
                          } else {
                            queryObj.labels = item.id;
                          }

                          navigate({
                            ...location,
                            search: querystring.stringify(queryObj),
                          });
                        }}
                      >
                        <Box width="14px">
                          {labelIds.includes(item.id) && (
                            <Check fontSize="inherit" />
                          )}
                        </Box>

                        <ListItemText
                          disableTypography
                          primary={
                            <Typography fontSize="" noWrap type="body2">
                              <Box
                                component="span"
                                display="flex"
                                flexDirection="row"
                                fontSize={14}
                                m={1}
                              >
                                <Box component="span" mr={1}>
                                  <Icon color="secondary" fontSize="inherit">
                                    language
                                  </Icon>
                                </Box>

                                {item.name}
                              </Box>
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </InfiniteScroll>
              </div>

              <Divider />
              <Box p={1}>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={async () => {
                    setShowNewDialog(true);
                  }}
                >
                  Create new{' '}
                </Button>
              </Box>
            </Box>
          </TreeItem>
        </TreeView>
      </Box>
    </>
  );
}

export default RecentSwitcher;
