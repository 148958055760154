/* eslint-disable consistent-return */
import { API, graphqlOperation } from 'aws-amplify';
import parse2 from 'url-parse';
import * as mutations from '../graphql/mutations';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function updateDataSourceObject({
  id,
  credentialId,
  dataProvider,
  name,
  view,
  dataSourceTeamId,
  editors,
  tags,
  type,
  url,
  filter,
  displayName,
  rateLimit,
}) {
  const input = { id };

  if (credentialId) {
    input.dataSourceCredentialId = credentialId;
  }

  if (dataProvider) {
    input.dataProvider = dataProvider;
  }

  if (name) {
    input.name = name;
    input.nameLowercase = name.toLowerCase();
  }

  if (displayName || displayName === '') {
    input.displayName = displayName;
    input.displayNameLowercase = displayName.toLowerCase();
  }

  if (rateLimit?.max) {
    input.rateLimit = rateLimit;
  }

  if (view) {
    input.view = view;
  }

  if (dataSourceTeamId) {
    input.dataSourceTeamId = dataSourceTeamId;
  }

  if (editors) {
    input.editors = editors;
  }

  if (tags) {
    input.tags = tags;
    input.tagsLowercase = tags.map((tag) => tag.toLowerCase());
    input.tagsLowercaseToString = tags
      .map((tag) => tag.toLowerCase())
      .join(' ');
  }

  if (type) {
    input.type = type;
  }

  if (url) {
    const parsed = parse2(url);

    let hostName = parsed.hostname;

    if (hostName.startsWith('www.')) {
      hostName = hostName.replace('www.', '').trim();
    }

    let domain = hostName;

    if (hostName != null) {
      const parts = hostName.split('.').reverse();

      if (parts != null && parts.length > 1) {
        domain = `${parts[1]}.${parts[0]}`;
        if (
          hostName.toLowerCase().indexOf('.co.uk') !== -1 &&
          parts.length > 2
        ) {
          domain = `${parts[2]}.${domain}`;
        }
      }
    }
    input.url = url;
    input.hostname = hostName;
    input.domain = domain;
  }

  if (filter) {
    input.filter = filter;
  }

  const excludeArray = [
    'credential.status',
    'credential.owners',
    'credential.editors',
    'credential.createdAt',
    'credential.updatedAt',
    'credential.authType',
    'credential.team',
    'credential.dataSources',
    'credential.dataProvider',
    'credential.authDataId',
    'credential.clientId',
    'credential.authData',
    'credential.label',
    'sets',
    'labels.items.dataSource',
    'team.avatar',
    'team.name',
    'team.owner',
    'team.members',
    'team.dataSources',
    'team.teamMembers',
    'team.credentials',
    'team.dataSourceLabels',
    'team.themes',
    'dataUrls.items.dataSource',
    'notificationActions.items.dataSource',
    'queries.items.dataSource',
    'queries.items.integrations',
    'integrations.items',
    // 'view.viewName',
    // 'view.propertyId',
    // 'view.loginCustomerId',
    // 'view.customerId',
    // 'view.site',
    // 'view.locationId',
    // 'view.channelId',
    // 'view.spreadsheetId',
    // 'view.accountId',
    // 'view.dc',
    // 'view.connectorId',
  ];

  const updateDataSourceFilter = (path) => {
    const re = new RegExp(`updateDataSource.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };

  const updateDataSourceObjectObj = await API.graphql(
    graphqlOperation(
      filterGraphQLFields(mutations.updateDataSource, updateDataSourceFilter),
      {
        input,
      }
    )
  );
  return updateDataSourceObjectObj.data.updateDataSource;
}

export default updateDataSourceObject;
