import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql/mutations';

function DeleteTeamDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // redux selectors
  const selectedTeam = useSelector((state) => state.selectedTeam);

  const dialogs = useSelector((state) => state.dialogs);

  const open = dialogs.showDeleteTeamDialog;

  function closeDialog() {
    dispatch({
      type: types.SHOW_DELETE_TEAM_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function deleteSelectedTeam() {
    try {
      await API.graphql(
        graphqlOperation(mutations.removeWorkspace, {
          teamId: selectedTeam.id,
        })
      );
      navigate(0);
    } catch (e) {
      if (e.errors) {
        if (e.errors[0].errorType === 'Lambda:Unhandled') {
          const errorMessage = e.errors[0].message;
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
        }
      }
    }
  }

  if (!selectedTeam) {
    return <></>;
  }

  return (
    <Dialog
      aria-describedby="delete-team-dialog-description"
      aria-labelledby="delete-team-dialog-title"
      maxWidth="sm"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle id="delete-team-dialog-title">
        Remove {selectedTeam.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-team-dialog-description">
          Are you sure you want to delete <b>{selectedTeam.name}</b>? This
          cannot be undone
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={deleteSelectedTeam}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTeamDialog;
