/* eslint-disable no-nested-ternary */

import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import PreCacheQueryViewer from './PreCacheQueryViewer';

function ViewQueryDialog({ open, setOpen, initialState }) {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer((oldState, action) => {
    switch (action.type) {
      case 'SET_DATE_RANGE':
        return { ...oldState, dateRange: action.value };

      case 'SET_START_DATE':
        return { ...oldState, startDate: action.value };
      case 'SET_END_DATE':
        return { ...oldState, endDate: action.value };
      case 'SET_SELECTED_METRICS':
        return { ...oldState, selectedMetrics: action.value };
      case 'SET_SORT_FIELD':
        return { ...oldState, sortField: action.value };
      case 'SET_LIMIT':
        return { ...oldState, limit: action.value };
      case 'SET_SELECTED_DIMENSIONS':
        return { ...oldState, selectedDimensions: action.value };
      case 'SET_DATE_RANGE_ERROR':
        return { ...oldState, dateRangeError: action.value };
      case 'SET_DATA_URL_NAME':
        return { ...oldState, name: action.value };
      case 'SET_CREDENTIAL_ID':
        return { ...oldState, notificationActionCredentialId: action.value };
      case 'SET_SCHEDULE':
        return { ...oldState, schedule: action.value };
      case 'SET_TRIGGER':
        return { ...oldState, trigger: action.value };
      case 'SET_DIMENSION_FILTER_PROP':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters = [...oldState.dimensionsFilters];
        newDimensionFilters[action.andFilterIndex][action.orFilterIndex][
          action.property
        ] = action.value;
        return { ...oldState, dimensionsFilters: newDimensionFilters };
      case 'REMOVE_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters2 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF, orIndex) => {
            if (
              action.andFilterIndex !== andIndex ||
              action.orFilterIndex !== orIndex
            ) {
              dIFs.push(dIF);
            }
          });
          if (dIFs.length > 0) {
            newDimensionFilters2.push(dIFs);
          }
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters2 };
      case 'ADD_OR_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters3 = [];
        oldState.dimensionsFilters.forEach((dFilter, andIndex) => {
          const dIFs = [];
          dFilter.forEach((dIF) => {
            dIFs.push(dIF);
          });

          if (action.andFilterIndex === andIndex) {
            dIFs.push({
              field: null,
              values: [],
              type: 'INCLUDE',
              operator: 'none',
            });
          }

          newDimensionFilters3.push(dIFs);
        });
        return { ...oldState, dimensionsFilters: newDimensionFilters3 };
      case 'ADD_AND_DIMENSION_FILTER':
        // eslint-disable-next-line no-case-declarations
        const newDimensionFilters4 = [...oldState.dimensionsFilters];

        newDimensionFilters4.push([
          {
            field: null,
            values: [],
            type: 'INCLUDE',
            operator: 'none',
          },
        ]);

        return { ...oldState, dimensionsFilters: newDimensionFilters4 };
      case 'RESET_STATE':
        return initialState;

      case 'INITIALIZE_FIELDS':
        // eslint-disable-next-line no-case-declarations
        const { fieldIds, sort, dimensionsFiltersObjects, ...otherStateProps } =
          oldState;
        return {
          ...otherStateProps,
          selectedMetrics: action.selectedMetrics,
          selectedDimensions: action.selectedDimensions,
          dimensionsFilters: action.dimensionsFilters,
          sortField: action.sortField,
          startDate: action.startDate,
          endDate: action.endDate,
        };

      default:
        throw new Error();
    }
  }, initialState);

  const [runError] = React.useState('');

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource,
  );

  if (!selectedDataSource) {
    return <></>;
  }

  function closeExplorer() {
    dispatch({
      type: 'RESET_STATE',
      value: '',
    });
    setOpen(false);
  }

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={() => {
        closeExplorer();
      }}
      open={open}
    >
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Typography align="center" color="inherit" variant="h6">
              View Pre Cache Request
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ minWidth: '600px' }}>
        <TextField
          fullWidth
          label={'Title of Request'}
          margin="dense"
          onChange={(event) => {
            dispatch({
              type: 'SET_DATA_URL_NAME',
              value: event.target.value,
            });
          }}
          placeholder="Web URL 1"
          size="small"
          value={state.name}
          variant="outlined"
        />
        <Box sx={{ my: 2 }}>
          <Divider flexItem orientation="horizontal" />
        </Box>
        <PreCacheQueryViewer
          dataProvider={selectedDataSource?.dataProvider}
          dataSourceId={selectedDataSource?.id}
          dispatch={dispatch}
          state={state}
        />
        <FormHelperText error>{state.dateRangeError}</FormHelperText>

        {runError && <Alert severity="error">{runError}</Alert>}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          py: 2,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              closeExplorer();
            }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ViewQueryDialog;
