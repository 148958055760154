import { FormControl, MenuItem, Select } from '@mui/material';

export default function IntegrationDropdown({ step, value, setValue }) {
  return (
    <FormControl
      fullWidth
      sx={{
        my: 1,
      }}
      variant="outlined"
    >
      <Select
        onChange={(event) => {
          setValue(event.target.value);
        }}
        value={value ?? ''}
      >
        {step.config.options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
