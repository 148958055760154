/* eslint-disable no-nested-ternary */

import { AutoAwesome } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import * as types from '../../constants/ActionTypes';
import ConversationView from './ConversationView';

dayjs.extend(relativeTime);

async function sendRequest(url, { arg }) {
  // eslint-disable-next-line prefer-const
  let queryStringParameters = {};

  const tokens = await Auth.currentSession();
  const token = tokens.getIdToken().getJwtToken();

  const { dataSourceId, prompt } = arg;

  return API.post('DataProviderApi', url, {
    headers: {
      Authorization: token,
    },
    body: {
      dataSourceId,
      prompt,
    },
    response: true,
    queryStringParameters,
  }).then((res) => res.data);
}

const initialState = {
  conversations: [
    {
      id: 'intro',
      side: 'left',
      messages: [
        "Hi, I'm Data Hub, your data assistant",
        'Ask questions like:',
        'How many likes between 1 jan 2023 and 1 april 2023',
      ],
    },
  ],
};

function AskDataHubDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  const [prompt, setPrompt] = React.useState('');

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const {
    trigger,
    isMutating,
    data: gptData,
  } = useSWRMutation(`/data-providers/ask-data-hub`, sendRequest);

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer((oldState, action) => {
    switch (action.type) {
      case 'SET_CONVERSATIONS':
        return { ...oldState, conversations: action.value };

      case 'REMOVE_IS_TYPING':
        return {
          ...oldState,
          conversations: oldState.conversations.filter(
            (conv) => !conv.messages.includes('IS_TYPING')
          ),
        };

      case 'ADD_CONVERSATIONS':
        return {
          ...oldState,
          conversations: [...oldState.conversations, ...action.value],
        };
      default:
        throw new Error("USE REDUCER: Action type doesn't exist");
    }
  }, initialState);

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  React.useEffect(() => {
    if (gptData) {
      dispatch({
        type: 'ADD_CONVERSATIONS',
        value: [
          {
            id: 'anser',
            side: 'left',
            messages: [gptData.answer],
          },
        ],
      });
    }
  }, [gptData]);

  React.useEffect(() => {
    if (isMutating === true) {
      dispatch({
        type: 'ADD_CONVERSATIONS',
        value: [
          {
            id: 'anser',
            side: 'left',
            messages: ['IS_TYPING'],
          },
        ],
      });
    } else {
      dispatch({
        type: 'REMOVE_IS_TYPING',
      });
    }
  }, [isMutating]);

  const openDialog = dialogs.showAskDataHubDialog;

  function closeDialog() {
    dispatch({
      type: 'SET_CONVERSATIONS',
      value: initialState.conversations,
    });

    reduxDispatch({
      type: types.SHOW_ASK_DATA_HUB_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function sendPrompt() {
    dispatch({
      type: 'SET_CONVERSATIONS',
      value: [
        {
          id: 'test',
          side: 'right',
          messages: [prompt],
        },
      ],
    });

    trigger({ dataSourceId: selectedDataSource.id, prompt });

    setPrompt('');
  }

  if (!groups.includes('Ask-Data-Hub')) {
    return null;
  }

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      <Dialog maxWidth={'lg'} onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg height={0} width={0}>
              <linearGradient
                id="linearColors"
                x1="0%"
                x2="100%"
                y1="0%"
                y2="0"
              >
                <stop offset="0%" stopColor="#8983ba" />
                <stop offset="20%" stopColor="#9e98cc" />
                <stop offset="38%" stopColor="#c29abe" />
                <stop offset="56%" stopColor="#e197b0" />
                <stop offset="74%" stopColor="#ff9a7d" />
                <stop offset="84%" stopColor="#ffb467" />
                <stop offset="100%" stopColor="#ffda8e" />
              </linearGradient>
            </svg>
            <AutoAwesome sx={{ fill: 'url(#linearColors)', mr: 1 }} />
            <Box
              component={'span'}
              sx={{
                background:
                  'linear-gradient(to right, #f06844 0%, #ee4c54 25%, #d45e95 50%, #9c6ca6 75%, #6583c1 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Ask Data Hub
            </Box>
          </Box>
        </DialogTitle>
        <Stack
          alignItems="stretch"
          direction="column"
          justifyContent="space-between"
          spacing={2}
          sx={{ minWidth: '600px', maxWidth: '700px', minHeight: '600px' }}
        >
          <Box
            sx={() => ({
              p: 2,
            })}
          >
            <ConversationView
              conversations={state.conversations}
              isTyping={isMutating}
            />
          </Box>

          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.grey[100],
              mt: 4,
              p: 2,
              borderTop: `1px solid ${theme.palette.action.disabled}`,
            })}
          >
            <Stack direction="column" spacing={2}>
              <Box
                sx={(theme) => ({
                  bgcolor: theme.palette.background.paper,
                })}
              >
                <TextField
                  fullWidth
                  multiline
                  onChange={(event) => {
                    setPrompt(event.target.value);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      sendPrompt();
                      ev.preventDefault();
                    }
                  }}
                  placeholder="How many likes between 1 jan 2023 and 1 april 2023"
                  rows={4}
                  value={prompt}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color="info"
                  disabled={!prompt}
                  onClick={() => {
                    sendPrompt();
                  }}
                  variant="contained"
                >
                  Ask Data Hub
                </Button>
              </Box>
            </Stack>
          </Box>
        </Stack>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AskDataHubDialog;
