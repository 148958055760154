import isValidDomain from 'is-valid-domain';
import isValidUrl from './isValidUrl';

function checkIfMeetsMandatoryParts(requiredParts, configParams) {
  let meetsMandatoryPart = true;
  if (requiredParts) {
    requiredParts.forEach((dependsOnCheck) => {
      if (configParams[dependsOnCheck.input_id]) {
        if (dependsOnCheck.condition === 'EQUAL_TO') {
          if (
            configParams[dependsOnCheck.input_id].id !== dependsOnCheck.value
          ) {
            meetsMandatoryPart = false;
          }
        } else if (dependsOnCheck.condition === 'NOT_EMPTY') {
          if (!configParams[dependsOnCheck.input_id].id) {
            meetsMandatoryPart = false;
          }
        } else if (dependsOnCheck.condition === 'VALID_URL') {
          if (!isValidUrl(configParams[dependsOnCheck.input_id].id)) {
            meetsMandatoryPart = false;
          }
        } else if (dependsOnCheck.condition === 'VALID_DOMAIN') {
          if (!isValidDomain(configParams[dependsOnCheck.input_id].id)) {
            meetsMandatoryPart = false;
          }
        }
      } else {
        meetsMandatoryPart = false;
      }
    });
  }
  return meetsMandatoryPart;
}

export default checkIfMeetsMandatoryParts;
