import { Avatar, Box, Container, Typography } from '@mui/material';
import React from 'react';

import logoDev from '../data-hub-icon-dev.svg';

import logo from '../logo-icon.svg';

function Success() {
  return (
    <>
      <Container maxWidth="sm">
        <Box sx={{ mt: 2 }}>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box mb={2}>
              <Avatar
                src={
                  process.env.REACT_APP_USER_BRANCH === 'prod' ? logo : logoDev
                }
                variant="rounded"
              />
            </Box>
            <Typography align="center" variant="h5">
              Success!
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              component="span"
              gutterBottom
              variant="subtitle1"
            >
              You can close this tab.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Success;
