import isValidHttpUrl from 'is-valid-http-url';

function isValidUrl(input) {
  const validUrl = isValidHttpUrl(input);

  // technical https://wwww.com is a valid domain
  // but not in oure use case
  if (validUrl) {
    const wwwCheckUrl = new URL(input);
    if (wwwCheckUrl.hostname.startsWith('www.')) {
      if (wwwCheckUrl.hostname.split('.').length === 3) {
        return true;
      }
      return false;
    }
    return validUrl;
  }
  return false;
}

export default isValidUrl;
