import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import useDataProviderData from '../../hooks/useDataProviderData';
import { connectorId } from '../dashboards/AddNewDashboardDialog';

function CreateReport() {
  const dispatch = useDispatch();
  const { data: configData } = useDataProviderData(
    `/data-providers/config/`,
    {}
  );

  const urlParams = new URLSearchParams(window.location.search);
  const reportId = urlParams.get('reportId');
  const reportName = urlParams.get('reportName');
  const workspaceId = urlParams.get('workspaceId');
  const credentialId = urlParams.get('credentialId');
  const dataProviderId = urlParams.get('dataProviderId');
  const configParams = JSON.parse(urlParams.get('configParams'));
  const alias = urlParams.get('alias') ?? 'ds0';
  const [isCreating, setCreate] = useState(false);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const { route } = dataProviderSettings?.config?.[dataProviderId] || {
    route: '',
  };
  useEffect(() => {
    if (configData && !dataProviderSettings?.config?.[dataProviderId]) {
      dispatch({
        type: types.SET_DATA_PROVIDER_SETTINGS,
        settings: configData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, route]);

  async function sendRequest(url, { arg }) {
    const tokens = await Auth.currentSession();
    return API.post('DataProviderApi', url, {
      body: {
        configParams: arg.configParams,
      },
      headers: {
        Authorization: `${tokens.getIdToken().getJwtToken()}`,
      },
      response: true,
      queryStringParameters: {
        credentialId: arg.credentialId,
      },
    });
  }
  async function createDatasource() {
    if (
      !reportId ||
      !reportName ||
      !connectorId ||
      !workspaceId ||
      !credentialId ||
      !dataProviderId ||
      isCreating ||
      !configParams ||
      !dataProviderSettings?.config?.[dataProviderId]
    ) {
      return;
    }
    setCreate(true);

    const dataSourceResponse = await sendRequest(
      route ? `/${route}/data-source/` : null,
      {
        arg: {
          configParams,
          credentialId,
        },
      }
    );
    const dataSourceId = dataSourceResponse.data.id;
    // ds0 -> alias -> get from linking api
    window.location.replace(
      `https://lookerstudio.google.com/reporting/create?c.reportId=${reportId}&c.explain=true&r.reportName=${reportName}&c.mode=edit&ds.${alias}.connector=community&ds.${alias}.connectorId=${connectorId}&ds.${alias}.refreshFields=true&ds.${alias}.workspaceId=${workspaceId}&ds.${alias}.credentialId=${credentialId}&ds.${alias}.use_existing=true&ds.${alias}.dataProvider=${dataProviderId}&ds.${alias}.dataSourceId=${dataSourceId}`
    );
  }

  createDatasource();
  return (
    <Backdrop
      open
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      })}
    >
      <CircularProgress color="inherit" />{' '}
    </Backdrop>
  );
}

export default CreateReport;
