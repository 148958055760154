import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import createTeamObject from '../../api/createTeamObject';

import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql/mutations';

const defaultTheme = {
  primaryColor: '#455A64',
  secondaryColor: '#F57C00',
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function CreateTeamForm({
  closeDialog,
  showCancelButton,
  makeUserDefaultTeam,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  // local state
  const [success, setSuccess] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const newTeam = await createTeamObject(
          values.name,
          makeUserDefaultTeam
        );

        const themeGraphObject = await API.graphql(
          graphqlOperation(mutations.createTheme, {
            input: {
              teamThemesId: newTeam.id,
              primaryColor: defaultTheme.primaryColor,
              secondaryColor: defaultTheme.secondaryColor,
            },
          })
        );

        dispatch({
          type: types.ADD_USER_TEAM,
          team: {
            ...newTeam,
            themes: { items: [themeGraphObject.data.createTheme] },
          },
        });

        if (makeUserDefaultTeam === true) {
          dispatch({
            type: types.SELECT_USER_TEAM,
            teamId: newTeam.id,
          });
        }

        setSubmitting(false);
        setSuccess(true);
        setTimeout(() => {
          closeDialog();
        }, 500);
      }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Verplicht veld';
        }
        return errors;
      }}
    >
      {(props) => (
        <Form>
          <DialogContent className={classes.container}>
            <TextField
              error={props.errors.name !== undefined}
              helperText={props.errors.name}
              label="Team name"
              margin="dense"
              name="name"
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              type="name"
              value={props.values.name}
              variant="filled"
            />
            <FormHelperText />
            {props.isSubmitting && <LinearProgress />}
          </DialogContent>
          <DialogActions className={classes.actionsContainer}>
            {showCancelButton === true && (
              <Button
                onClick={() => {
                  closeDialog();
                }}
              >
                Annuleren
              </Button>
            )}
            <Button
              className={buttonClassname}
              color="primary"
              disabled={props.isSubmitting}
              onClick={props.submitForm}
              type="submit"
              variant="contained"
            >
              {success && <Icon>check</Icon>}
              {props.isSubmitting === true ? (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                />
              ) : (
                'Create a Team'
              )}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default CreateTeamForm;
