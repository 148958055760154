import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import CreateTeamForm from './CreateTeamForm';

function CreateTeamDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();

  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showCreateTeamDialog;

  function closeDialog() {
    dispatch({
      type: types.SHOW_CREATE_TEAM_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  return (
    <Dialog
      aria-describedby="create-team-dialog-description"
      aria-labelledby="create-team-dialog-title"
      maxWidth="sm"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle id="create-team-dialog-title">Maak een team aan</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-team-dialog-description">
          Maak een Team aan
        </DialogContentText>
      </DialogContent>
      <CreateTeamForm
        closeDialog={closeDialog}
        makeUserDefaultTeam={false}
        showCancelButton
      />
    </Dialog>
  );
}

export default CreateTeamDialog;
