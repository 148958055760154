import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { API, graphqlOperation, Logger } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql/mutations';

function DeleteTemplateDialog({ onClose = () => {} }) {
  const logger = new Logger('DeleteTemplateDialog');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const selectedTemplate = useSelector((state) => state.selectedTemplate);
  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showDeleteTemplateDialog;

  const userObject = useSelector((state) => state.userObject);

  function closeDialog() {
    dispatch({
      type: types.SHOW_DELETE_TEMPLATE_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function deleteSelectedDataSource() {
    // check if the user is the owner
    if (userObject.id === selectedTemplate.owner) {
      logger.info('user is owner');
    }

    const deleteTemplateReponse = await API.graphql(
      graphqlOperation(mutations.deleteTemplate, {
        input: { id: selectedTemplate.id },
      })
    );

    batch(() => {
      dispatch({
        type: types.DELETE_TEAM_TEMPLATE,
        template: deleteTemplateReponse.data.deleteTemplate,
        teamId: selectedUserTeam,
      });
      dispatch({
        type: types.SET_SELECTED_TEMPLATE,
        template: null,
      });
      dispatch({
        type: types.SHOW_EDIT_TEMPLATE_DIALOG,
        isVisible: false,
      });
    });

    enqueueSnackbar(`Template deleted successfully`, {
      variant: 'info',
    });

    closeDialog(true);
  }

  if (!selectedTemplate) {
    return <></>;
  }

  return (
    <Dialog
      aria-describedby="delete-template-dialog-description"
      aria-labelledby="delete-template-dialog-title"
      maxWidth="sm"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle id="delete-template-dialog-title">
        Delete template
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-template-dialog-description">
          Are you sure you want to delete <b>{selectedTemplate.name}</b>? This
          cannot be undone
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={deleteSelectedDataSource}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTemplateDialog;
