import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../constants/ActionTypes';

import { useSearchParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import CredentialPicker from '../CredentialPicker';
import DataProviderSelector from '../data-provider/DataProviderSelector';
import { ColorlibStepIcon } from '../data-provider/SelectorStep';
import IntegrationSettings from './IntegrationSettings';
import IntegrationTile from './IntegrationTile';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('BackendApi', url, {
    body: {
      configParams: arg.configParams,
      queryId: arg.queryId,
      dataProviderId: arg.dataProviderId,
      credentialId: arg.credentialId,
      additionalSettings: arg.additionalSettings,
      id: arg.integrationId,
    },
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
  });
}

export default function CreateIntegrationDialog({
  isOpen,
  onClose,
  queryObj,
  initialState,
  setOpen,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [configParamIds, setConfigParamIds] = useState(null);
  const [createStatus, setCreateStatus] = useState(false);
  const [configObj, setConfig] = useState({});
  const dispatch = useDispatch();
  const { trigger, isMutating } = useSWRMutation('/integration', sendRequest);
  const [isValid, setIsValid] = useState(false);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings.config
  );
  const integrations = Object.entries(dataProviderSettings)
    .filter(([, value]) => value.supportIntegrations === true)
    .map(([, value]) => ({ ...value, id: value.dataProvider }));

  useEffect(() => {
    if (initialState) {
      setActiveStep(initialState.redirect ? 1 : 3);
      setSelectedIntegration(initialState.dataProvider);
      setSelectedCredential(initialState.credential);
      setConfig(
        initialState.additionalSettings.reduce((acc, curr) => {
          acc[curr.name] = curr.value;
          return acc;
        }, {})
      );
    } else {
      setActiveStep(0);
      setSelectedIntegration(null);
      setSelectedCredential(null);
      setConfigParamIds(null);
      setConfig({});
    }
  }, [initialState]);
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (activeStep === 1 && selectedIntegration) {
      setSearchParams((sp) => {
        sp.set('dp', selectedIntegration);
        return sp;
      });
    } else {
      setSearchParams((sp) => {
        sp.delete('dp');
        return sp;
      });
    }
  }, [activeStep, selectedIntegration]);

  return (
    <Dialog maxWidth={'lg'} onClose={onClose} open={isOpen}>
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Typography align="center" color="inherit" variant="h6">
              Create Integration
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ minWidth: '600px' }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              optional={
                selectedIntegration
                  ? integrations.find((ig) => ig.id === selectedIntegration)
                      ?.name
                  : null
              }
            >
              Select Integration
            </StepLabel>
            <StepContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'flex-start',
                  marginBottom: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  height: '150px',
                }}
              >
                {integrations.map((integration) => (
                  <IntegrationTile
                    integration={integration}
                    key={integration.id}
                    select={() => setSelectedIntegration(integration.id)}
                    selected={selectedIntegration === integration.id}
                  ></IntegrationTile>
                ))}
              </Box>
              <Box>
                <Button
                  color="primary"
                  disabled={!selectedIntegration}
                  onClick={() => {
                    setActiveStep(activeStep + 1);
                  }}
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                >
                  Next
                </Button>
                <Button
                  color="primary"
                  disabled={true}
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  variant="text"
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              optional={selectedCredential?.name}
            >
              Select Credential
            </StepLabel>
            <StepContent>
              <Box
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.action.disabled}`,
                  mb: 2,
                })}
              >
                <CredentialPicker
                  credentialSelected={() => {
                    setActiveStep(activeStep + 1);
                  }}
                  integrationInfo={{
                    queryId: queryObj.id,
                    integrationId: initialState?.id,
                    dataProvider: selectedIntegration,
                    page: 'settings',
                  }}
                  selectedDataProvider={
                    integrations.find((ig) => ig.id === selectedIntegration)
                      ?.dataProvider
                  }
                  setSelectedCredential={setSelectedCredential}
                ></CredentialPicker>
              </Box>
              <Box>
                <Button
                  color="primary"
                  disabled={selectedCredential === null}
                  onClick={() => {
                    setActiveStep(activeStep + 1);
                  }}
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                >
                  Next
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  variant="text"
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              Further Information
            </StepLabel>
            <StepContent>
              <Box
                sx={{
                  mb: 2,
                }}
              >
                {initialState?.dataSource ? (
                  <>
                    dataSource connected{' '}
                    <Button
                      onClick={() => {
                        dispatch({
                          type: types.SHOW_RE_CONNECT_DATA_SOURCE_DIALOG,
                          isVisible: true,
                          dataSource: initialState.dataSource,
                        });
                      }}
                      variant="outlined"
                    >
                      Reconnect
                    </Button>
                  </>
                ) : (
                  <DataProviderSelector
                    dataProvider={
                      integrations.find((ig) => ig.id === selectedIntegration)
                        ?.dataProvider
                    }
                    selectedCredential={selectedCredential}
                    setCreateStatus={(
                      hasValidRequiredPartsCombination,
                      configParamsIds
                    ) => {
                      setCreateStatus(hasValidRequiredPartsCombination);
                      setConfigParamIds(configParamsIds);
                    }}
                  ></DataProviderSelector>
                )}
              </Box>
              <Box>
                <Button
                  color="primary"
                  disabled={
                    !createStatus && initialState?.dataSource === undefined
                  }
                  onClick={async () => {
                    setActiveStep(activeStep + 1);
                  }}
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                >
                  Next
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  variant="text"
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              Integration Settings
            </StepLabel>
            <StepContent>
              <IntegrationSettings
                configObj={configObj}
                credentialId={selectedCredential?.id}
                dataProvider={
                  integrations.find((ig) => ig.id === selectedIntegration)
                    ?.dataProvider
                }
                dataSourceConfig={configParamIds}
                dataSourceId={initialState?.dataSource?.id}
                queryObj={queryObj}
                setConfig={setConfig}
                setValid={setIsValid}
              ></IntegrationSettings>
              <Box>
                <LoadingButton
                  color="primary"
                  disabled={!isValid}
                  loading={isMutating}
                  onClick={async () => {
                    await trigger({
                      configParams: configParamIds,
                      additionalSettings: Object.entries(configObj).map(
                        ([key, value]) => {
                          if (Array.isArray(value)) {
                            return {
                              name: key,
                              value: JSON.stringify(value),
                            };
                          }
                          return {
                            name: key,
                            value,
                          };
                        }
                      ),
                      queryId: queryObj.id,
                      dataProviderId: integrations.find(
                        (ig) => ig.id === selectedIntegration
                      )?.dataProvider,
                      credentialId: selectedCredential?.id,
                      integrationId: initialState?.id,
                    });

                    setOpen(false);
                    onClose();
                  }}
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                >
                  {initialState?.dataSource ? 'Update' : 'Create'}
                </LoadingButton>
                <Button
                  color="primary"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  sx={{
                    mr: 1,
                  }}
                  variant="text"
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}
