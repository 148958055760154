import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { API, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import DraggableDrawer from '../../../feedTemplates/DraggableDrawer';
import MessageEditor from '../textEditor/messageEditor';
import FilterBox from './FilterBox';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('BackendApi', url, {
    body: {
      ...arg,
    },
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
  });
}

export default function FeedEditor({ open, close, queryObj, initialState }) {
  const [conditions, setConditions] = useState([]);
  const [root, setRoot] = useState(null);
  const [def, setDefault] = useState(null);
  const [dbValue, setDbValue] = useState(null);
  const [initialConditions, setInitialConditions] = useState([]);
  const [name, setName] = useState([]);
  const { trigger, isMutating } = useSWRMutation('/feed-template', sendRequest);
  function addCondition() {
    setConditions([
      ...conditions,
      {
        id: Math.random().toString(36).substring(7),
      },
    ]);
  }

  useEffect(() => {
    const val = {
      root: JSON.stringify(root),
      conditions: JSON.stringify(
        conditions.map((c) => {
          const newC = { ...c };
          delete newC.id;
          return newC;
        })
      ),
      default: JSON.stringify(def),
      queryId: queryObj.id,
      name,
      id: initialState?.id,
    };
    setDbValue(val);
  }, [conditions, root, def, name]);

  useEffect(() => {
    if (initialState) {
      const ic = JSON.parse(initialState.conditions).map((c) => ({
        ...c,
        id: Math.random().toString(36).substring(7),
      }));
      setConditions(JSON.parse(JSON.stringify(ic)));
      setInitialConditions(ic);

      setRoot(JSON.parse(initialState.root));
      setDefault(JSON.parse(initialState.default));
      setName(initialState.name);
    } else {
      setConditions([]);
      setInitialConditions([]);
      setRoot(null);
      setDefault(null);
      setName('');
    }
  }, [initialState]);

  async function save() {
    await trigger(dbValue);
    close();
  }

  return (
    <DraggableDrawer close={close} open={open}>
      <Box
        sx={{
          maxWidth: '1000px',
          minWidth: 'fit-content',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">Feed editor</Typography>
          <TextField
            placeholder="Feed name"
            label="Feed name"
            onChange={(e) => setName(e.target.value)}
            sx={{
              my: 2,
            }}
            value={name}
          ></TextField>
          <Typography
            sx={{
              mb: -1,
            }}
            variant="subtitle1"
          >
            Root
          </Typography>
          <MessageEditor
            fields={[
              {
                id: 'text',
                name: 'Text',
                conceptType: 'DIMENSION',
                type: 'TEXT',
                group: 'default',
                description: 'Text',
              },
            ]}
            initialMessage={JSON.stringify(root)}
            lines={3}
            onChange={(value) => {
              setRoot(value);
            }}
            small={true}
          ></MessageEditor>

          <Typography
            sx={{
              mb: -1,
            }}
            variant="subtitle1"
          >
            Nodes
          </Typography>
          <Box
            sx={{
              ml: 1,
            }}
          >
            {conditions.map((condition, i) => (
              <Box key={condition.id}>
                <br></br>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <FilterBox
                    fields={[
                      ...queryObj.selectedDimensions,
                      ...queryObj.selectedMetrics,
                    ]}
                    initialValue={initialConditions[i].filter}
                    onChange={(value) => {
                      setConditions((cond) => {
                        const newCond = [...cond];
                        if (!newCond[i]) {
                          return cond;
                        }
                        newCond[i].filter = value;
                        return newCond;
                      });
                    }}
                  ></FilterBox>
                  <IconButton
                    onClick={() => {
                      setConditions(
                        conditions.filter((__, index) => index !== i)
                      );
                    }}
                    sx={{
                      mt: '4px',
                      ml: '4px',
                    }}
                  >
                    <Delete color="error"></Delete>
                  </IconButton>
                </Box>

                <MessageEditor
                  fields={[
                    ...queryObj.selectedDimensions,
                    ...queryObj.selectedMetrics,
                  ]}
                  initialMessage={condition.value}
                  key={i}
                  lines={5}
                  onChange={(value) => {
                    setConditions((cond) => {
                      const newCond = [...cond];
                      newCond[i].value = value;
                      return newCond;
                    });
                  }}
                  small={true}
                ></MessageEditor>
              </Box>
            ))}
            <Button
              color="secondary"
              onClick={addCondition}
              startIcon={<Add></Add>}
              sx={{
                width: 'fit-content',
                my: 2,
              }}
              variant="outlined"
            >
              Add condition
            </Button>
            <Typography
              sx={{
                mb: -1,
              }}
              variant="subtitle2"
            >
              Default
            </Typography>

            <MessageEditor
              fields={[
                ...queryObj.selectedDimensions,
                ...queryObj.selectedMetrics,
              ]}
              initialMessage={JSON.stringify(def)}
              lines={5}
              onChange={(value) => {
                setDefault(value);
              }}
              small={true}
            ></MessageEditor>
          </Box>
        </Box>
        <Box
          sx={{
            pb: 2,
          }}
        >
          <LoadingButton
            color="info"
            loading={isMutating}
            onClick={save}
            sx={{
              mr: 1,
            }}
            variant="contained"
          >
            {initialState ? 'Update' : 'Create'}
          </LoadingButton>
          <Button onClick={close} variant="text">
            Cancel
          </Button>
        </Box>
      </Box>
    </DraggableDrawer>
  );
}
