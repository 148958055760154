/* eslint-disable consistent-return */
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function getQueryObject(queryId) {
  const excludeArray = ['dataSource', 'integrations'];

  const getFilter = (path) => {
    const re = new RegExp(`getQuery.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };
  const query = filterGraphQLFields(queries.getQuery, getFilter);
  const QO = await API.graphql({
    ...graphqlOperation(query, {
      id: queryId,
      limit: 1000,
    }),
  });
  return QO.data.getQuery;
}

export default getQueryObject;
