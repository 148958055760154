import { useAuthenticator } from '@aws-amplify/ui-react';
import _find from 'lodash/find';
import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import getUserObject from '../api/getUserObject';
import * as types from '../constants/ActionTypes';
import useBackendData from '../hooks/useBackendData';
import useDataProviderData from '../hooks/useDataProviderData';

function ValidateWorkspace({ children }) {
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const { data: workspaces = [], isLoading } = useBackendData(
    user && `/workspaces`,
    {}
  );
  const { data: configData } = useDataProviderData(
    user && `/data-providers/config/`,
    {}
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const { workspaceId } = useParams();

  useEffect(() => {
    if (route === 'authenticated' && configData) {
      dispatch({
        type: types.SET_DATA_PROVIDER_SETTINGS,
        settings: configData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, route]);

  useEffect(() => {
    if (route === 'authenticated' && isLoading === false) {
      // check if current authenticated user has any teams
      // if user has no teams, show the create mandatory team dialog

      if (workspaces.length === 0) {
        navigate('/workspace');
      } else if (workspaces.length > 0) {
        const foundWorkSpace = _find(workspaces, (o) =>
          o.id.startsWith(workspaceId)
        );

        if (foundWorkSpace) {
          batch(() => {
            dispatch({
              type: types.SELECT_USER_TEAM,
              teamId: foundWorkSpace.id,
            });
            dispatch({
              type: types.SET_USER_TEAMS,
              userTeams: workspaces,
            });
          });
        } else {
          navigate('/workspace');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, workspaces, isLoading]);

  useEffect(() => {
    async function fetchGraphQL() {
      const {
        userObject: userObject2,
        currentAuthenticatedUser: currentAuthUser,
      } = await getUserObject();

      if (userObject2) {
        batch(() => {
          dispatch({
            type: types.SET_CURRENT_AUTHENTICATED_USER,
            currentAuthenticatedUser: currentAuthUser,
          });
          dispatch({
            type: types.SET_USER_OBJECT,
            userObject: userObject2,
          });
        });
      }
    }

    if (route === 'authenticated') {
      fetchGraphQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  if (!selectedUserTeam || isLoading === true) {
    return null;
  }

  return children;
}

export default ValidateWorkspace;
