import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

function RemoveAsAdminDialog({
  open,
  closeDialog,
  selectedUsername,
  switchRole,
}) {
  async function removeAsAdmin() {
    switchRole('TEAM_MEMBER', selectedUsername, true);
    closeDialog(false);
  }

  return (
    <Dialog
      aria-describedby="remove-as-admin-dialog-description"
      aria-labelledby="remove-as-admin-dialog-title"
      maxWidth="sm"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle id="remove-as-admin-dialog-title">
        Remove yourself as Admin
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="remove-as-admin-dialog-description">
          Are you sure you want to remove yourself as admin?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button color="primary" onClick={removeAsAdmin} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveAsAdminDialog;
