const React = require('react');

module.exports = function ReactJoin(props) {
  const separator = props.separator || ', ';
  const children = props.children || null;
  const len = children && children.length - 1;
  const isString = typeof separator === 'string';
  const isReact = React.isValidElement(separator);
  const key = new Date();

  return len > 0
    ? children.reduce((links, link, index) => {
        links.push(link);

        if (index < len) {
          links.push(
            isString
              ? separator
              : React.cloneElement(isReact ? separator : separator(index), {
                  // eslint-disable-next-line react/no-array-index-key
                  key: key + (index + 1),
                })
          );
        }

        return links;
      }, [])
    : children;
};
