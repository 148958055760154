import getAllDataSources from '../api/getAllDataSources';

async function fetchAllDataSources(
  selectedUserTeam,
  initialNextToken,
  searchQuery,
  dataProvider,
  limit = 10000
) {
  let filter;

  if (searchQuery) {
    filter = {
      or: [
        {
          nameLowercase: {
            contains: searchQuery.trim().toLowerCase(),
          },
        },
        { url: { contains: searchQuery.trim().toLowerCase() } },
        {
          tagsLowercaseToString: {
            contains: searchQuery.trim().toLowerCase(),
          },
        },
      ],
    };
  }

  if (dataProvider) {
    if (!filter) {
      filter = {};
    }

    filter = {
      ...filter,
      dataProvider: { eq: dataProvider },
    };
  }

  const { dataSources: newDataSources, nextToken: newNextToken } =
    await getAllDataSources(selectedUserTeam, initialNextToken, limit, filter);

  return { newDataSources, newNextToken };
}

export default fetchAllDataSources;
