/* eslint-disable no-nested-ternary */

import {
  Cached,
  CheckCircleOutline,
  NotInterested,
  OpenInNew,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import ellipsis from 'text-ellipsis';
import * as types from '../../constants/ActionTypes';
import ViewQueryDialog from './ViewQueryDialog';

const orState = {
  id: '',
  name: '',
  status: 'ACTIVE',
  dateRange: '',
  selectedMetrics: [],
  selectedDimensions: [],
  startDate: '',
  endDate: '',
  dateRangeError: '',
  dimensionsFilters: [],
  sortField: null,
  limit: '',
};

function PreCacheDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [initialState, setInitialState] = React.useState(orState);

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource,
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  const openDialog = dialogs.showPreCacheDialog;

  function closeDialog() {
    reduxDispatch({
      type: types.SHOW_PRE_CACHE_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  function createInitialState(preCacheRequest) {
    const inState = {
      id: preCacheRequest.id,
      name: preCacheRequest.name,
      status: preCacheRequest.status,
      dateRange: preCacheRequest.dateRangeType,
      fieldIds: preCacheRequest.fields,
      dimensionsFiltersObjects: preCacheRequest.dimensionsFilters,
      selectedMetrics: [],
      selectedDimensions: [],
      startDate: preCacheRequest.dateRange.startDate,
      endDate: preCacheRequest.dateRange.endDate,
      dateRangeError: '',
      dimensionsFilters: [],
      query: preCacheRequest.query,
    };

    setInitialState(inState);
    setOpen(true);
  }

  React.useEffect(() => {
    setInitialState(orState);
  }, [selectedDataSource]);

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      {open && (
        <ViewQueryDialog
          initialState={initialState}
          open={open}
          setOpen={setOpen}
        />
      )}

      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Pre-Cache</DialogTitle>
        <DialogContent>
          {selectedDataSource?.preCacheRequests?.items?.filter(
            (item) => item.requestCount > 2 && item.TTL > new Date().valueOf(),
          )?.length > 0 && (
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Cache</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedDataSource?.preCacheRequests?.items
                    .filter(
                      (item) =>
                        item.requestCount > 2 &&
                        item.TTL > new Date().valueOf(),
                    )
                    .map((preCacheRequest) => (
                      <TableRow key={preCacheRequest.id}>
                        <TableCell>
                          <Box display="flex" flexDirection="row">
                            <Box sx={{ mr: 1 }}>
                              <Avatar
                                sx={{ bgcolor: 'white' }}
                                color="info"
                                variant="circular"
                              >
                                <Cached color="info" />
                              </Avatar>
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                color="textPrimary"
                                component="span"
                                variant="body1"
                              >
                                {preCacheRequest.name}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                component="span"
                                variant="body2"
                              >
                                {ellipsis(
                                  preCacheRequest.fields.join(', '),
                                  40,
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            color="info"
                            onClick={async () => {
                              createInitialState(preCacheRequest);
                            }}
                            startIcon={<OpenInNew />}
                            sx={(theme) => ({
                              textTransform: 'none',
                              background: theme.palette.background.default,
                              width: '100%',
                            })}
                            target="_blank"
                            variant="outlined"
                          >
                            <Typography variant="inherit">View</Typography>
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          {new Date(preCacheRequest.updatedAt).valueOf() >
                          new Date().valueOf() - 86400000 ? (
                            <CheckCircleOutline color="success"></CheckCircleOutline>
                          ) : (
                            <NotInterested color="error"></NotInterested>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {selectedDataSource?.preCacheRequests?.items.filter(
            (item) => item.requestCount > 2 && item.TTL > new Date().valueOf(),
          ).length === 0 && (
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="center">
                <Cached color="disabled" fontSize="large" />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
              >
                No pre-cached requests found
              </Typography>
              <Typography
                align="center"
                color="textSecondary"
                display="block"
                gutterBottom
                variant="caption"
              >
                Make a request in Looker Studio to pre-cache it
              </Typography>
            </Box>
          )}
        </DialogContent>

        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            mt: 2,
            borderTop: `1px solid ${theme.palette.action.disabled}`,
          })}
        ></Box>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PreCacheDialog;
