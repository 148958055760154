import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import addExtrasGraphQL from '../utilities/addExtrasGraphQL';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function getAllCredentials(
  teamId,
  nextToken = undefined,
  limit = 10000,
  filter
) {
  const inputQuery = {
    id: teamId,
    limit,
    nextToken,
  };

  if (filter) {
    inputQuery.filter = filter;
  }

  const excludeArray = [
    'avatar',
    'id',
    'name',
    'owner',
    'members',
    'admins',
    'editors',
    'emailDomains',
    'autoInviteDomains',
    'teamMembers',
    'credentials.items.dataSources',
    'credentials.items.authData',
    'credentials.items.authDataId',
    'credentials.items.clientId',
    'credentials.items.team.avatar',
    'credentials.items.team.name',
    'credentials.items.team.owner',
    'credentials.items.team.members',
    'credentials.items.team.dataSources',
    'credentials.items.team.teamMembers',
    'credentials.items.team.credentials',
    'credentials.items.team.dataSourceLabels',
    'credentials.items.team.themes',
    'credentials.items.team.admins',
    'credentials.items.team.editors',
    'credentials.items.team.emailDomains',
    'credentials.items.team.autoInviteDomains',
    'credentials.items.team.defaultTemplateId',
    'credentials.items.team.createdAt',
    'credentials.items.team.updatedAt',
    'credentials.items.label',
    'dataSourceLabels',
    'defaultTemplateId',
    'themes',
    'createdAt',
    'updatedAt',
    'dataSources',
  ];

  const getTeamFilter = (path) => {
    const re = new RegExp(`getTeam.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };

  let query = filterGraphQLFields(queries.getTeam, getTeamFilter);

  const variableDefinition = (name, type) => ({
    kind: 'VariableDefinition',
    variable: { kind: 'Variable', name: { kind: 'Name', value: name } },
    type: { kind: 'NamedType', name: { kind: 'Name', value: type } },
  });

  const enumArgument = (name, enumValue) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'EnumValue', value: enumValue },
  });
  const variableArgument = (name, variableName) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'Variable', name: { kind: 'Name', value: variableName } },
  });

  const extraVariables = {
    GetTeam: [
      variableDefinition('limit', 'Int'),
      variableDefinition('filter', 'ModelCredentialFilterInput'),
      variableDefinition('nextToken', 'String'),
    ],
  };
  const extraArguments = {
    credentials: [
      enumArgument('sortDirection', 'DESC'),
      variableArgument('limit', 'limit'),
      variableArgument('nextToken', 'nextToken'),
      variableArgument('filter', 'filter'),
    ],
  };

  query = addExtrasGraphQL(query, extraVariables, extraArguments);

  const credentialsQueryObject = await API.graphql(
    graphqlOperation(query, inputQuery)
  );

  const credentials = credentialsQueryObject.data.getTeam.credentials.items;
  const nextTokenBack =
    credentialsQueryObject.data.getTeam.credentials.nextToken;

  return { credentials, nextToken: nextTokenBack };
}

export default getAllCredentials;
