/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import {
  Add,
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  Check,
  Edit,
} from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _uniqBy from 'lodash/unionBy';
import useSWRMutation from 'swr/mutation';

import React from 'react';
import Carousel from 'react-multi-carousel';

import { LoadingButton } from '@mui/lab';
import { green } from '@mui/material/colors';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import useDataProviderData from '../../hooks/useDataProviderData';
import usePrefetchDataProviderData from '../../hooks/usePrefetchDataProviderData';
import AddCredentialButton from '../data/AddCredentialButton';
import CredentialPicker from '../data/CredentialPicker';
import DataProviderSelectors from '../data/DataProviderSelectors';
import TemplateImage from '../templates/TemplateImage';

// react-dom/server is used to render the HTML on the server

function utf8ToB64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64ToUtf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export const connectorId =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'AKfycbyJicj6n-7mWPY3rO82lCrP3ofLLzecggFgKgDeNd8t2MJ3utGI5mcMl6mitYDCjcyt'
    : 'AKfycbxu17kMCqGEoo7Sxi2tNO1OvKV-e8EctEZ7JmSY_jA';

const CustomRightArrow = ({ onClick }) => (
  <IconButton
    color="info"
    onClick={() => onClick()}
    size="small"
    sx={(theme) => ({
      position: 'absolute',
      right: 'calc(4% + 1px)',
      textTransform: 'none',
      border: `1px solid ${
        theme.palette.mode === 'dark'
          ? theme.palette.grey[600]
          : theme.palette.grey[300]
      }`,

      color:
        theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
      background:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#FFF',
    })}
  >
    <ArrowForwardIos fontSize="small" />
  </IconButton>
);

const CustomLeftArrow = ({ onClick }) => (
  <IconButton
    color="info"
    onClick={() => onClick()}
    size="small"
    sx={(theme) => ({
      position: 'absolute',
      left: 'calc(4% + 1px)',
      textTransform: 'none',
      border: `1px solid ${
        theme.palette.mode === 'dark'
          ? theme.palette.grey[600]
          : theme.palette.grey[300]
      }`,

      color:
        theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
      background:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#FFF',
    })}
  >
    <ArrowBackIos fontSize="small" />
  </IconButton>
);

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();

  return API.post('DataProviderApi', url, {
    body: {
      configParams: arg.configParams,
    },
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {
      credentialId: arg.credentialId,
    },
  });
}

function AddNewDashboardDialog() {
  const dispatch = useDispatch();

  const { workspaceId } = useParams();

  const carousel = React.useRef(null);

  const { prefetch } = usePrefetchDataProviderData();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDataProvider = searchParams.get('dataProvider');
  const credentialId = searchParams.get('credentialId');
  const templateId = searchParams.get('templateId');
  const connectedAl = searchParams.get('connected');
  const stAlias = searchParams.get('alias');

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedDP, setSelectedDP] = React.useState(selectedDataProvider);
  const [selectedCredential, setSelectedCredential] = React.useState(null);
  const [highlightedPages, setHighlightedPages] = React.useState({});
  const [selectedPage, setSelectedPage] = React.useState('ALL_PAGES');
  const [currentSlidePage, setCurrentSlidePage] = React.useState(0);
  const [datasourceAliases, setDatasourceAliases] = React.useState([]);

  const [connectedDatasourceAliases, setConnectedDatasourceAliases] =
    React.useState({});

  const [configParams, setConfigParams] = React.useState({});
  const [selectedAlias, setSelectedAlias] = React.useState(null);
  const [createStatus, setCreateStatus] = React.useState(false);
  const [linkingApiLink, setLinkingApiLink] = React.useState('');

  const navigate = useNavigate();

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const templatesByTeam = useSelector((state) => state.templatesByTeam);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const selectedTemplate = useSelector((state) => state.selectedTemplate);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const { items: credentials2 } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };
  const { items: templates } = templatesByTeam[selectedUserTeam] || {
    items: [],
  };
  const { data = {} } = useDataProviderData(
    `/data-providers/looker-studio/template-info`,
    {
      templateId,
    }
  );

  const { route } = dataProviderSettings?.config?.[selectedDP] || { route: '' };
  const { trigger, isMutating } = useSWRMutation(
    route ? `/${route}/data-source/` : null,
    sendRequest
  );

  React.useEffect(() => {
    if (connectedAl) {
      setConnectedDatasourceAliases(JSON.parse(b64ToUtf8(connectedAl)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedAl]);

  React.useEffect(() => {
    if (stAlias && datasourceAliases && datasourceAliases.length > 0) {
      const foundAl = _find(datasourceAliases, {
        alias: stAlias,
      });

      setSelectedAlias(foundAl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stAlias, datasourceAliases]);

  React.useEffect(() => {
    if (activeStep === 0 && !selectedDataProvider) {
      setConfigParams({});
      setCreateStatus(false);
      setSelectedAlias(null);
      setSelectedDP('');
    }

    if (activeStep === 1) {
      searchParams.delete('credentialId');
      setSearchParams(searchParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  React.useEffect(() => {
    // {[aliasesObj.alias] ? (
    if (Object.keys(connectedDatasourceAliases).length > 0) {
      searchParams.set(
        'connected',
        utf8ToB64(JSON.stringify(connectedDatasourceAliases))
      );
    } else {
      searchParams.delete('connected');
    }
    setSearchParams(searchParams);
  }, [connectedDatasourceAliases]);

  React.useEffect(() => {
    if (datasourceAliases && selectedDataProvider && !credentialId) {
      const foundAl = _find(datasourceAliases, {
        dataProvider: selectedDataProvider,
      });

      if (foundAl) {
        setActiveStep(1);

        setSelectedAlias(foundAl);
      }
    }

    if (credentialId) {
      const foundCredential = _find(credentials2, { id: credentialId });
      if (
        foundCredential &&
        !foundCredential.dataProvider.includes(selectedDP)
      ) {
        searchParams.delete('credentialId');
        setSearchParams(searchParams);
      } else if (credentials2.length > 0 && foundCredential) {
        setSelectedCredential(foundCredential);
        setActiveStep(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialId, credentials2, datasourceAliases]);

  React.useEffect(() => {
    const configSettings = dataProviderSettings.config[selectedDP];

    if (configSettings) {
      prefetch(`/${configSettings.route}/config/`, {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDP, dataProviderSettings]);

  React.useEffect(() => {
    if (templateId && templates.length > 0) {
      const updatedTemplate = _find(templates, (o) => o.id === templateId);

      if (updatedTemplate) {
        batch(() => {
          dispatch({
            type: types.SET_SELECTED_TEMPLATE,
            template: updatedTemplate,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templates]);

  React.useEffect(() => {
    if (selectedTemplate?.pages) {
      let aliases = [];

      selectedTemplate.pages.forEach((pageObj) => {
        pageObj.datasourceAliases.forEach((aliasObj) => {
          aliases.push(aliasObj);
        });
      });

      aliases = _uniqBy(aliases, 'alias');

      setDatasourceAliases(aliases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  React.useEffect(() => {
    if (selectedTemplate) {
      const queryObj = {};

      let reportName = data?.reportTitle;
      if (!reportName && Object.keys(connectedDatasourceAliases).length > 0) {
        reportName = Object.values(connectedDatasourceAliases)[0]?.name;
      }

      queryObj['c.reportId'] = selectedTemplate?.view?.lookerStudioId;
      queryObj['c.explain'] = true;
      queryObj['r.reportName'] = reportName;
      queryObj['c.mode'] = 'edit';

      Object.keys(connectedDatasourceAliases).forEach((alias) => {
        const { dataSourceId, dataProvider, name } =
          connectedDatasourceAliases[alias];
        queryObj[`ds.${alias}.datasourceName`] = name;
        queryObj[`ds.${alias}.connector`] = 'community';
        queryObj[`ds.${alias}.connectorId`] = connectorId;
        queryObj[`ds.${alias}.workspaceId`] = selectedUserTeam;
        queryObj[`ds.${alias}.use_existing`] = true;
        queryObj[`ds.${alias}.dataProvider`] = dataProvider;
        queryObj[`ds.${alias}.refreshFields`] = true;
        queryObj[`ds.${alias}.dataSourceId`] = dataSourceId;
      });

      const params = new URLSearchParams(queryObj);

      setLinkingApiLink(
        `https://lookerstudio.google.com/reporting/create?${params.toString()}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedDatasourceAliases, selectedTemplate]);

  function closeDialog() {
    searchParams.delete('credentialId');
    searchParams.delete('connected');
    searchParams.delete('dataProvider');
    searchParams.delete('templateId');
    searchParams.delete('alias');
    setSearchParams(searchParams);

    navigate({
      pathname: `/workspace/${workspaceId}/templates`,
      search: searchParams.toString(),
    });
  }

  if (!selectedTemplate) {
    return null;
  }

  async function close() {
    closeDialog();
  }

  async function highLightDataSource(alias) {
    if (selectedTemplate?.pages) {
      const filteredPages = _filter(selectedTemplate.pages, {
        datasourceAliases: [{ alias }],
      });

      const foundAliasObj = _find(datasourceAliases, {
        alias,
      });

      const hPageObj = {};

      filteredPages.forEach((pageObj) => {
        hPageObj[pageObj.id] = foundAliasObj;
      });

      setHighlightedPages(hPageObj);
    }
  }

  function setDynamicStatus(status, configParamsIds) {
    if (configParamsIds) {
      setConfigParams(configParamsIds);
    }
    setCreateStatus(status);
  }

  return (
    <>
      <Dialog fullWidth maxWidth={'md'} onClose={close} open>
        <AppBar
          color="default"
          elevation={0}
          position="static"
          sx={(theme) => ({
            bgcolor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Toolbar variant="regular">
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Box></Box>
              <Typography align="center" color="inherit" variant="h6">
                Create new Dashboard
              </Typography>
              <Box>
                <Button
                  color="info"
                  onClick={() => {
                    navigate({
                      pathname: `/workspace/${workspaceId}/templates/t/${selectedTemplate.id}`,
                      search: '',
                    });
                  }}
                  startIcon={<Edit />}
                  variant="outlined"
                >
                  Edit Template
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Grid container spacing={0}>
          <Grid item xs={7}>
            <Box
              sx={(theme) => ({
                bgcolor: theme.palette.grey[100],
                borderBottom: `1px solid ${theme.palette.action.disabled}`,
              })}
            >
              <Box sx={{ p: 1 }}>
                {selectedTemplate?.pages?.length > 0 && (
                  <Carousel
                    afterChange={(previousSlide, { currentSlide }) => {
                      setCurrentSlidePage(currentSlide);
                    }}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    ref={carousel}
                    responsive={{
                      superLargeDesktop: {
                        breakpoint: { max: 4000, min: 3000 },
                        items: 1,
                      },
                      desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 1,
                      },
                      tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                      },
                    }}
                  >
                    {selectedTemplate.pages.map((pageObj) => {
                      let components = [];

                      let height = '';
                      let width = '';
                      let reportId = '';
                      let pageId = '';

                      if (highlightedPages[pageObj.id] && data) {
                        height = data.height;
                        width = data.width;
                        reportId = data.reportId;

                        pageId = pageObj.id;
                        components =
                          data?.dataSources?.[
                            highlightedPages?.[pageObj.id]?.dataSourceId
                          ]?.pages[pageObj.id].components;
                      }

                      return (
                        <Box
                          key={pageObj.id}
                          sx={{
                            maxHeight: '350px',
                            opacity:
                              !highlightedPages[pageObj.id] &&
                              Object.keys(highlightedPages).length !== 0
                                ? 0.3
                                : 1,
                          }}
                        >
                          <TemplateImage
                            components={components}
                            height={height}
                            image={pageObj?.image}
                            pageId={pageId}
                            reportId={reportId}
                            width={width}
                          />
                        </Box>
                      );
                    })}
                  </Carousel>
                )}

                {selectedTemplate?.pages?.length > 0 && (
                  <Box
                    sx={(theme) => ({
                      bgcolor: theme.palette.background.default,
                      p: 1,
                      border: `1px solid ${theme.palette.action.disabled}`,
                    })}
                  >
                    <Carousel
                      responsive={{
                        superLargeDesktop: {
                          breakpoint: { max: 4000, min: 3000 },
                          items: 4,
                        },
                        desktop: {
                          breakpoint: { max: 3000, min: 1024 },
                          items: 4,
                        },
                        tablet: {
                          breakpoint: { max: 1024, min: 464 },
                          items: 4,
                        },
                        mobile: {
                          breakpoint: { max: 464, min: 0 },
                          items: 4,
                        },
                      }}
                    >
                      {selectedTemplate?.pages.map((pageObj, index) => (
                        <Box
                          key={pageObj.id}
                          onClick={() => {
                            setCurrentSlidePage(index);
                            carousel.current.goToSlide(index, true);
                          }}
                          sx={(theme) => ({
                            mr: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            bgcolor: theme.palette.background.paper,
                            opacity:
                              !highlightedPages[pageObj.id] &&
                              Object.keys(highlightedPages).length !== 0
                                ? 0.3
                                : 1,
                            cursor: 'pointer',
                            border:
                              currentSlidePage === index
                                ? `2px solid ${theme.palette.secondary.main}`
                                : `1px solid ${theme.palette.action.disabled}`,
                          })}
                        >
                          {highlightedPages[pageObj.id] ? (
                            <Box
                              sx={() => ({
                                position: 'absolute',

                                left: '0px',
                                right: '8px',
                                bottom: '0px',
                                top: '0px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              })}
                            >
                              <Box>
                                <Avatar
                                  src={
                                    dataProviderSettings.config[
                                      highlightedPages[pageObj.id].dataProvider
                                    ]?.image
                                  }
                                  sx={{ width: 24, height: 24 }}
                                  variant="rounded"
                                />
                              </Box>
                            </Box>
                          ) : null}
                          <TemplateImage image={pageObj?.image} />
                        </Box>
                      ))}
                    </Carousel>
                  </Box>
                )}

                {!selectedTemplate.pages && (
                  <ImageListItem>
                    <TemplateImage image={selectedTemplate?.image} />
                    <ImageListItemBar
                      sx={{
                        background:
                          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                      }}
                    />
                  </ImageListItem>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sx={(theme) => ({
              borderLeft: `1px solid ${theme.palette.action.disabled}`,
            })}
            xs={5}
          >
            {activeStep === 0 && (
              <>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {selectedTemplate?.pages?.length > 0 && (
                    <Box sx={{ flex: 0 }}>
                      <Box sx={{ m: 1 }}>
                        <FormControl fullWidth>
                          <Select
                            onChange={(event) => {
                              const pageId = event.target.value;
                              if (pageId !== 'ALL_PAGES') {
                                const index = selectedTemplate.pages.findIndex(
                                  (x) => x.id === pageId
                                );
                                setCurrentSlidePage(index);
                                carousel.current.goToSlide(index, true);
                              } else {
                                setCurrentSlidePage(0);
                                carousel.current.goToSlide(0, true);
                              }
                              setSelectedPage(pageId);
                            }}
                            sx={(theme) => ({
                              bgcolor: theme.palette.background.paper,
                            })}
                            value={selectedPage}
                            variant="outlined"
                          >
                            <MenuItem value={'ALL_PAGES'}>All pages</MenuItem>
                            {selectedTemplate.pages.map((pageObj) => (
                              <MenuItem key={pageObj.id} value={pageObj.id}>
                                {pageObj?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ flex: 2 }}>
                    <List>
                      {datasourceAliases.map((aliasesObj) => (
                        <ListItemButton
                          key={aliasesObj.alias}
                          onClick={() => {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep + 1
                            );
                            setSelectedAlias(aliasesObj);
                            setSelectedDP(aliasesObj.dataProvider);
                            searchParams.set(
                              'dataProvider',
                              aliasesObj.dataProvider
                            );
                            searchParams.set('alias', aliasesObj.alias);
                            setSearchParams(searchParams);
                          }}
                          onMouseEnter={() => {
                            highLightDataSource(aliasesObj.alias);
                          }}
                          onMouseLeave={() => {
                            setHighlightedPages({});
                          }}
                        >
                          <ListItemAvatar>
                            {connectedDatasourceAliases[aliasesObj.alias] ? (
                              <Badge
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                badgeContent={
                                  <Avatar
                                    sx={(theme) => ({
                                      width: 22,
                                      height: 22,
                                      bgcolor: green[600],
                                      border: `2px solid ${theme.palette.background.paper}`,
                                    })}
                                  >
                                    <Check fontSize="22" />
                                  </Avatar>
                                }
                                overlap="circular"
                              >
                                <Avatar
                                  src={
                                    dataProviderSettings.config[
                                      aliasesObj.dataProvider
                                    ]?.image
                                  }
                                  variant="rounded"
                                />
                              </Badge>
                            ) : (
                              <Avatar
                                src={
                                  dataProviderSettings.config[
                                    aliasesObj.dataProvider
                                  ]?.image
                                }
                                variant="rounded"
                              />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="inherit">
                                {
                                  dataProviderSettings.config[
                                    aliasesObj.dataProvider
                                  ]?.name
                                }{' '}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                {`${aliasesObj.alias}`}
                              </Typography>
                            }
                          />

                          <Box sx={{ ml: 2 }}>
                            <ArrowForwardIos fontSize="small" />
                          </Box>
                        </ListItemButton>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ flex: 0 }}>
                    <Box
                      sx={(theme) => ({
                        pt: 1,
                        flex: 1,
                        m: 1,
                        borderTop: `1px solid ${theme.palette.action.disabled}`,
                      })}
                    >
                      <Button
                        color="info"
                        disabled={
                          Object.keys(connectedDatasourceAliases).length === 0
                        }
                        fullWidth
                        href={linkingApiLink}
                        rel="noopener noreferrer"
                        size="large"
                        sx={{ textTransform: 'none' }}
                        target="_blank"
                        variant="contained"
                      >
                        Create Dashboard
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {(activeStep === 1 || activeStep === 2) && (
              <>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ flex: 0 }}>
                    <AppBar
                      color="default"
                      elevation={0}
                      position="static"
                      sx={(theme) => ({
                        borderBottom: `1px solid ${theme.palette.action.disabled}`,
                      })}
                    >
                      <Toolbar variant="regular">
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box>
                            {activeStep > 0 && (
                              <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                  if (activeStep === 1) {
                                    setSelectedDP('');
                                    searchParams.delete('dataProvider');
                                    searchParams.delete('alias');
                                    setSearchParams(searchParams);
                                  }
                                  setActiveStep(
                                    (prevActiveStep) => prevActiveStep - 1
                                  );
                                }}
                                size="large"
                              >
                                <ArrowBack />
                              </IconButton>
                            )}
                          </Box>

                          <Typography
                            align="center"
                            color="inherit"
                            noWrap
                            variant="subtitle1"
                          >
                            {(activeStep === 1 || activeStep === 2) && (
                              <Box
                                component={'span'}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar
                                  src={
                                    dataProviderSettings.config[selectedDP]
                                      ?.image
                                  }
                                  sx={{ width: 25, height: 25, mr: 1 }}
                                  title={
                                    dataProviderSettings.config[selectedDP]
                                      ?.name
                                  }
                                  variant="rounded"
                                />
                                {dataProviderSettings.config[selectedDP]?.name}{' '}
                              </Box>
                            )}
                          </Typography>

                          <Box></Box>
                        </Box>
                      </Toolbar>
                    </AppBar>
                  </Box>
                  {activeStep === 1 && (
                    <>
                      <Box sx={{ flex: 2 }}>
                        <CredentialPicker
                          allowCreateCredential={false}
                          credentialSelected={() => {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep + 1
                            );
                          }}
                          selectedDataProvider={selectedDP}
                          setSelectedCredential={(selecCrede) => {
                            searchParams.set('credentialId', selecCrede.id);
                            setSearchParams(searchParams);
                            setSelectedCredential(selecCrede);
                          }}
                        />
                      </Box>

                      <Box
                        sx={(theme) => ({
                          borderTop: `1px solid ${theme.palette.action.disabled}`,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignContent: 'center',
                          p: 1,
                        })}
                      >
                        <AddCredentialButton
                          selectedDataProvider={selectedDP}
                          variant="credentialPicker"
                        />
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Box sx={{ flex: 2 }}>
                        <DataProviderSelectors
                          dataProvider={selectedDP}
                          selectedCredential={selectedCredential}
                          setDynamicStatus={setDynamicStatus}
                        />
                      </Box>
                      <Box sx={{ flex: 0 }}>
                        <Box
                          sx={(theme) => ({
                            pt: 1,
                            flex: 1,
                            m: 1,
                            borderTop: `1px solid ${theme.palette.action.disabled}`,
                          })}
                        >
                          <LoadingButton
                            color="info"
                            disabled={!createStatus || activeStep !== 2}
                            fullWidth
                            loading={isMutating}
                            onClick={async () => {
                              const dataSourceResponse = await trigger({
                                configParams,
                                credentialId: selectedCredential.id,
                              });

                              const dataSourceId = dataSourceResponse.data.id;
                              // get the latest version with also the correct label
                              const updatedDataSource =
                                await updateDataSourceObject({
                                  id: dataSourceId,
                                });

                              setConnectedDatasourceAliases({
                                ...connectedDatasourceAliases,
                                [selectedAlias.alias]: {
                                  dataSourceId,
                                  name: updatedDataSource.name,
                                  dataProvider: selectedAlias.dataProvider,
                                },
                              });

                              setActiveStep(0);
                              searchParams.delete('dataProvider');
                              searchParams.delete('credentialId');
                              searchParams.delete('alias');
                              setSearchParams(searchParams);
                            }}
                            startIcon={<Add />}
                            sx={{ textTransform: 'none' }}
                            variant="contained"
                          >
                            Add to dashboard
                          </LoadingButton>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddNewDashboardDialog;
