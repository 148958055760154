function shouldFetchCredentials(credentialsByTeam, selectedUserTeam) {
  if (!credentialsByTeam) {
    return true;
  }

  if (!selectedUserTeam) {
    return false;
  }

  if (!credentialsByTeam[selectedUserTeam]) {
    return true;
  }

  if (credentialsByTeam[selectedUserTeam].isFetching === true) {
    return false;
  }

  if (credentialsByTeam[selectedUserTeam].movedCount > 0) {
    if (
      credentialsByTeam[selectedUserTeam].items.length -
        credentialsByTeam[selectedUserTeam].movedCount <
      1
    ) {
      return true;
    }
  }

  if (credentialsByTeam[selectedUserTeam].items.length > 0) {
    return false;
  }

  return true;
}

export default shouldFetchCredentials;
