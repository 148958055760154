import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  LinearProgress,
  Slider,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Auth, Storage } from 'aws-amplify';
import clsx from 'clsx';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import createAvatarObject from '../../api/createAvatarObject';
import awsconfig from '../../aws-exports-static';
import * as types from '../../constants/ActionTypes';

const visibility = 'protected';

// Storage.configure({ level: visibility });

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },

  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  buttonProgress: {
    color: green[500],
  },
}));

function UserUploadAvatarDialog({ handleClose, open, closeDialog }) {
  const dispatch = useDispatch();

  const userObject = useSelector((state) => state.userObject);
  const setEditorRef = useRef();

  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [image, setImage] = useState('');
  const [scale, setScale] = useState(1);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {
    setImage('');
    setSuccess(false);
    setScale(1);
  }, [open]);

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      // eslint-disable-next-line no-unused-vars
      const binaryStr = reader.result;
    };

    acceptedFiles.forEach((file) => reader.readAsBinaryString(file));

    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  // eslint-disable-next-line no-unused-vars
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  function scaleChange(event, value) {
    setScale(value);
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
      <DialogTitle>Public avatar</DialogTitle>
      <Formik
        initialValues={{
          imgInfo: undefined,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const avatarImage = setEditorRef.current.getImage();
          const canvas = avatarImage.toDataURL();
          const res = await fetch(canvas);
          const blob = await res.blob();

          const fileName = 'avatar.png';

          const key = `avatar/${uuid()}/${fileName}`;

          await Storage.put(key, blob, { level: visibility });

          const creds = await Auth.currentCredentials();

          const updatedUser = await createAvatarObject(userObject.id, {
            visibility,
            identityId: creds.identityId,
            file: {
              bucket: awsconfig.aws_user_files_s3_bucket,
              key,
              region: awsconfig.aws_user_files_s3_bucket_region,
              fileName,
            },
          });

          dispatch({
            type: types.SET_USER_OBJECT,
            userObject: updatedUser,
          });

          setSubmitting(false);
          setSuccess(true);

          setTimeout(() => {
            closeDialog();
          }, 500);
        }}
        render={({ submitForm, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogContent className={classes.container}>
              {!image && (
                <section className="container" style={{ marginBottom: '10px' }}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag image here or Select Image</p>
                  </div>
                </section>
              )}

              <ErrorMessage name="imgInfo" />
              {image && (
                <>
                  <AvatarEditor
                    height={250}
                    image={image}
                    onLoadSuccess={(imgInfo) => {
                      setFieldValue('imgInfo', imgInfo);
                    }}
                    ref={setEditorRef}
                    scale={scale}
                    width={250}
                  />
                  <Box marginTop="10px">
                    <Slider
                      defaultValue={1}
                      max={2}
                      min={0.1}
                      name="scale"
                      onChange={scaleChange}
                      step={0.01}
                    />
                  </Box>
                </>
              )}
              {isSubmitting && <LinearProgress />}
            </DialogContent>
            <DialogActions className={classes.actionsContainer}>
              <Button
                onClick={() => {
                  closeDialog();
                }}
              >
                Cancel
              </Button>

              <Button
                className={buttonClassname}
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                type="submit"
                variant="contained"
              >
                {success && <Icon>check</Icon>}
                {isSubmitting === true ? (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                ) : (
                  'Update avatar'
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
        validate={(values) => {
          const errors = {};
          if (!values.imgInfo) {
            errors.imgInfo = 'Verplicht veld';
          }
          return errors;
        }}
      />
    </Dialog>
  );
}

export default UserUploadAvatarDialog;
