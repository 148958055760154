const dataSourceLabel = /* GraphQL */ `
id
name
nameLowercase
value
type
createdAt
dataProviders
updatedAt
`;

const teamCredential = /* GraphQL */ `
id
owners
editors
name
email
picture
status
authType
dataProvider
team {
  id
}
`;

const teamDataSource = /* GraphQL */ `
id
owner
editors
groups
createdAt
updatedAt
dataProvider
name
nameLowercase
view {
  viewId
              viewName
              propertyId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              dc
              connectorId
}
url
hostname
domain
tags
labels {
          items {
            label {
              ${dataSourceLabel}
            }
          }
        }

filter {
  isActive
  dimensionsFilters {
    fieldName
    values
    type
    operator
  }
  segment {
    id
    name
  }
}
credential {
  ${teamCredential}
}

team {
  id
}
`;

export const createDataSource = /* GraphQL */ `mutation CreateDataSource($input: CreateDataSourceInput!) {
  createDataSource(input: $input) {
    ${teamDataSource}
  }
}
  `;

export const updateDataSource = /* GraphQL */ `mutation UpdateDataSource($input: UpdateDataSourceInput!) {
    updateDataSource(input: $input) {
    ${teamDataSource}
    }
  }
  `;

export const storeOAuthCode = /* GraphQL */ `mutation StoreOAuthCode(
    $service: Service
    $clientId: String
    $dataProviders: [DataProvider]
    $code: String
    $teamId: String
  ) {
    storeOAuthCode(
      service: $service
      clientId: $clientId
      dataProviders: $dataProviders
      code: $code
      teamId: $teamId
    ) {
      ${teamCredential}

    }
  }
  `;

export const storeUserCredentials = /* GraphQL */ `
  mutation StoreUserCredentials(
    $service: Service
    $credentials: [UserCredentialField]
    $dataProviders: [DataProvider]
    $teamId: String
    $labelId: String
  ) {
    storeUserCredentials(
      service: $service
      credentials: $credentials
      dataProviders: $dataProviders
      teamId: $teamId
      labelId: $labelId
    ) {
      ${teamCredential}
    }
  }
`;

export const createLabelSource = /* GraphQL */ `
  mutation CreateLabelSource(
    $input: CreateLabelSourceInput!
    $condition: ModelLabelSourceConditionInput
  ) {
    createLabelSource(input: $input, condition: $condition) {
      id
      dataSourceID
      labelID
      dataSource {
        id
      }
      label {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
        }
      }
      defaultTeamId
      createdAt
      updatedAt
    }
  }
`;

export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      owner
      members
      admins
      editors
      emailDomains
      autoInviteDomains
      avatar {
        visibility
        file {
          bucket
          key
          region
          fileName
        }
      }
      defaultTemplateId

      createdAt
      updatedAt
    }
  }
`;

export const createDataSourceObject = /* GraphQL */ `
  mutation CreateDataSourceObject(
    $dataProvider: DataProvider
    $credentialId: String
    $createLabel: Boolean
    $workspaceId: String
    $viewToSubmit: String
    $credentialLabelValue: String
  ) {
    createDataSourceObject(
      dataProvider: $dataProvider
      credentialId: $credentialId
      createLabel: $createLabel
      workspaceId: $workspaceId
      viewToSubmit: $viewToSubmit
      credentialLabelValue: $credentialLabelValue
    ) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      dataProvider
      displayName
      displayNameLowercase
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      url
      hostname
      domain
      type
      view {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        merchantId
      }
      dataSourceCredentialId
      dataSourceTeamId
      groups
    }
  }
`;
