/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import getAllCredentials from '../../api/getAllCredentials';

import * as types from '../../constants/ActionTypes';
import shouldFetchCredentials from '../../utilities/shouldFetchCredentials';
import useDebounce from '../../utilities/useDebounce';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

function CreateNewTemplateDialog() {
  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/templates',
    sendRequest
  );

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const { workspaceId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);

  const [query, setQuery] = React.useState('');
  const [error, setError] = React.useState('');

  const [validInput, setValidInput] = React.useState(false);
  const [checked, setChecked] = React.useState([]);

  const debouncedSearchTerm = useDebounce(query, 1000);

  useEffect(() => {
    const pattern =
      /^https:\/\/(datastudio|lookerstudio)\.google\.com\/u\/0\/reporting\/[a-zA-Z0-9-]+\/?(page\/[a-zA-Z0-9-|_]+\/?)?$/;

    const result = pattern.test(debouncedSearchTerm);

    if (!result) {
      setError('Looker Studio report link is not valid');
      setValidInput(false);
    } else if (checked.length <= 2) {
      setError('Not all checkboxes are checked');
      setValidInput(false);
    } else {
      setError('');
      setValidInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, checked]);

  function closeDialog() {
    searchParams.delete('dataProvider');
    searchParams.delete('dataSourceToConnect');
    searchParams.delete('credentialId');

    navigate({
      pathname: `/workspace/${workspaceId}/templates`,
      search: searchParams.toString(),
    });
  }

  // eslint-disable-next-line no-unused-vars
  async function uploadNewLookerStudioTemplate() {
    const regex = /reporting\/([^/]*)/;

    const id1 = debouncedSearchTerm.match(regex)[1];

    const bal = await trigger({
      lookerStudioId: id1,
      workspaceId: selectedUserTeam,
    });

    batch(() => {
      dispatch({
        type: types.ADD_TEAM_TEMPLATE,
        template: { ...bal.data, status: 'new' },
        teamId: selectedUserTeam,
      });
    });

    closeDialog();
  }

  useEffect(() => {
    async function fetchALlCredentials() {
      if (shouldFetchCredentials(credentialsByTeam, selectedUserTeam)) {
        dispatch({
          type: types.REQUEST_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
        });
        const { credentials } = await getAllCredentials(
          selectedUserTeam,
          undefined
        );
        dispatch({
          type: types.SET_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
          credentials,
          nextToken: undefined,
        });
      }
    }

    fetchALlCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  function getLabel(value) {
    switch (value) {
      case 0:
        return `Set link sharing to unlisted and make sure the checkboxes are unchecked under the "Report access settings".`;
      case 1:
        return `Looker Studio template must be available in incognito. This is important, the sources need to have owner credentials instead of viewer credentials.`;
      case 2:
        return `The Template link needs to have /u/0/ in it. Without it, you can't upload the template.`;
      default:
        return '';
    }
  }

  return (
    <Dialog fullWidth maxWidth={'sm'} onClose={closeDialog} open>
      <DialogContent>
        <Typography variant="h6">Add new Looker Studio Template</Typography>
        <Typography color="textSecondary" variant="body1">
          Copy the reporting link you want to reuse when linking a new data
          source
        </Typography>
        <Box my={2}>
          <List
            sx={{
              width: '100%',
            }}
          >
            {[0, 1, 2].map((value) => {
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem disablePadding key={value}>
                  <ListItemButton
                    dense
                    onClick={handleToggle(value)}
                    role={undefined}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        disableRipple
                        edge="start"
                        inputProps={{ 'aria-labelledby': labelId }}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={getLabel(value)} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            error={error}
            fullWidth
            helperText={error}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            placeholder="https://lookerstudio.google.com/u/0/reporting/xxxxxxxx"
            value={query}
            variant="filled"
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          py: 2,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color="info"
            disabled={!validInput}
            onClick={() => {
              uploadNewLookerStudioTemplate();
            }}
            variant="contained"
          >
            {isMutating ? 'Creating...' : 'Add'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewTemplateDialog;
