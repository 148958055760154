/* eslint-disable camelcase */
import { Divider } from '@aws-amplify/ui-react';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Popper,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import React from 'react';
import useDataProviderData from '../../../hooks/useDataProviderData';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';
import AddCredentialButton from '../AddCredentialButton';

function generateMultipleObj(options, config, configParams, credential) {
  const optionsId = options
    .map((v) => v[config?.multiple_config?.option_config?.value || 'id'])
    .sort()
    .join(',');
  const multipleObj = {
    id: optionsId,
    [config.option_config.value]: optionsId,
    options,
  };

  config?.multiple_config?.fields.forEach((fieldObj) => {
    const nameTemplate = fieldObj.value;
    const name = nameTemplate.replace(
      /\{\{(([#]{1}?)\w+([.]{1}?)(\w+?))\}\}/g,
      (all, one) => {
        let idT = one.split('.')[0];
        if (idT.startsWith('#')) {
          idT = idT.substring(1);
        }
        let key;
        if (one.split('.').length === 2) {
          // eslint-disable-next-line prefer-destructuring
          key = one.split('.')[1];
        }

        if (idT === 'CREDENTIAL') {
          return credential[key];
        }
        if (configParams?.[idT]?.[key]) {
          return configParams?.[idT]?.[key];
        }
        return all;
      }
    );

    if (options.length > 0) {
      multipleObj[fieldObj.key] = name;
    }
  });

  return multipleObj;
}

function DynamicDropdown({
  id,
  dependsOn,
  credential,
  config,
  configParams,
  stepIndex,
  updateConfig,
  dataProvider,
}) {
  const [checkAll, setCheckAll] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(false);

  const path = React.useMemo(() => {
    const { endpoint, input } = config;
    let endpointWithValues = endpoint;
    input.forEach((inputObj) => {
      if (configParams[inputObj?.id]) {
        if (inputObj.type === 'PATH_PARAM') {
          const pathParam = configParams[inputObj?.id]?.id;
          endpointWithValues = endpointWithValues.replace(
            `{{#${inputObj?.id}}}`,
            pathParam
          );
        }
      }
    });
    return endpointWithValues;
  }, [config, configParams]);

  const queryStringObj = React.useMemo(() => {
    const { input } = config;

    const queryStringParameters = {};

    input.forEach((inputObj) => {
      if (inputObj.id === 'CREDENTIAL_ID') {
        queryStringParameters.credentialId = credential?.id;
      } else if (configParams[inputObj?.id]) {
        if (inputObj.type === 'QUERY_PARAM') {
          if (inputObj.value) {
            queryStringParameters[inputObj.key] =
              configParams[inputObj?.id][inputObj.value];
          } else {
            queryStringParameters[inputObj.key] = configParams[inputObj?.id];
          }
        }
      }
    });

    return queryStringParameters;
  }, [config, configParams, credential?.id]);

  const {
    data = [],
    isLoading,
    errorMessage,
    errorType,
  } = useDataProviderData(path, queryStringObj);

  React.useEffect(() => {
    if (config.defaultValue) {
      updateConfig(stepIndex, id, config.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { option_config, label, helper_text } = config;

  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  let helperText = '';
  if (helper_text) {
    helperText = helper_text;
  }

  if (errorMessage) {
    helperText = errorMessage;
  }

  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      if (config?.multiple === true) {
        const multipleObj = generateMultipleObj(
          data,
          config,
          configParams,
          credential
        );
        updateConfig(stepIndex, id, multipleObj);
      } else {
        updateConfig(stepIndex, id, data);
      }
    } else {
      updateConfig(stepIndex, id, {
        id: '',
        options: [],
      });
    }
  };

  let configParamsValue;

  if (configParams[id]) {
    if (configParams[id].options) {
      configParamsValue = configParams[id].options;
    } else if (config?.multiple === true) {
      configParamsValue = [configParams[id]];
    } else {
      configParamsValue = configParams[id];
    }
  }

  if (!configParamsValue) {
    if (config?.multiple === true) {
      configParamsValue = [];
    }
  }

  return (
    <>
      <Autocomplete
        PopperComponent={(param) => {
          if (config?.multiple === true) {
            return (
              <Popper {...param}>
                <Paper>
                  <ListItem dense={+true}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checkAll}
                        dense={+true}
                        id="check-all"
                        onChange={checkAllChange}
                        onMouseDown={(e) => e.preventDefault()}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Select all'} />
                  </ListItem>

                  <Divider light="true" />
                  <Box>{param.children}</Box>
                </Paper>
              </Popper>
            );
          }

          return <Popper {...param} />;
        }}
        disableCloseOnSelect={config?.multiple === true}
        // defaultValue={config?.defaultValue || null}
        getOptionLabel={(option) => (option ? option[option_config.title] : '')}
        isOptionEqualToValue={(option, value) =>
          option[config.option_config.value] ===
          value[config.option_config.value]
        }
        loading={isLoading}
        multiple={config?.multiple || false}
        onChange={(event, value, reason) => {
          if (reason === 'removeOption') {
            setCheckAll(false);
          } else if (reason === 'clear') {
            setCheckAll(false);
          }

          if (config?.multiple === true) {
            if (value.length === 1) {
              updateConfig(stepIndex, id, value[0]);
            } else {
              const multipleObj = generateMultipleObj(
                value,
                config,
                configParams,
                credential
              );
              updateConfig(stepIndex, id, multipleObj);
            }
          } else {
            updateConfig(stepIndex, id, value);
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
        // onInputChange={(_, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={Boolean(errorMessage)}
            fullWidth
            helperText={helperText}
            label={label}
            variant="filled"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const labelId = `checkbox-list-label-${option[option_config.title]}`;

          if (config?.multiple === true) {
            return (
              <ListItemButton {...props} dense={+true}>
                <ListItemIcon>
                  <Checkbox checked={selected} dense={+true} />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={option[option_config.title]}
                  secondary={option[option_config.secondary]}
                />
              </ListItemButton>
            );
          }
          return (
            <ListItem button selected={selected} {...props}>
              <ListItemText
                primary={option[option_config.title]}
                secondary={option[option_config.secondary]}
              />
            </ListItem>
          );
        }}
        value={configParamsValue}
      />

      {isLoading === false &&
        data.length === 0 &&
        errorType !== 'InvalidCredentialError' && (
          <Alert severity="info" sx={{ mt: 1 }}>
            {`No options were provided for "${label}". Check if you have the correct account/rights.`}
          </Alert>
        )}
      {errorType === 'InvalidCredentialError' && (
        <Alert severity="error" sx={{ mt: 1 }}>
          <AlertTitle>User credentials are not valid</AlertTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            Click on the button to re-authenticate the credential.
            <Box mt={1}>
              <AddCredentialButton
                email={credential.email}
                reAuthenticate={true}
                selectedDataProvider={dataProvider}
                variant="default"
              />
            </Box>
          </Box>
        </Alert>
      )}
    </>
  );
}

export default DynamicDropdown;
