/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */

import {
  Add,
  DataObjectTwoTone,
  MoreVert,
  OpenInNew,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React from 'react';

import 'react-multi-carousel/lib/styles.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import ellipsis from 'text-ellipsis';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import schedule from '../../config/schedule';
import * as types from '../../constants/ActionTypes';
import microsoftTeamsLogo from '../../microsoft-teams.svg';
import CreateNotificationActionDialog from './CreateNotificationActionDialog';

dayjs.extend(relativeTime);

const dataApiBase =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data.dh.gladior.com'
    : 'https://data-dev.dh.gladior.com';

async function postRequestToDataProviderApi(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  }).then((response) => response.data);
}

const orState = {
  id: '',
  name: '',
  status: 'ACTIVE',
  notificationService: 'MICROSOFT_TEAMS',
  template: 'TABLE',
  dateRange: '',

  selectedMetrics: [],
  selectedDimensions: [],
  startDate: '',
  endDate: '',
  dateRangeError: '',
  notificationActionCredentialId: '',
  schedule: 'EVERY_DAY',
  dimensionsFilters: [],
  sortField: null,
  trigger: 'SCHEDULE',
  limit: '10',
};

function MicrosoftTeamsDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [initialState, setInitialState] = React.useState(orState);

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const { enqueueSnackbar } = useSnackbar();
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  const openDialog = dialogs.showMicrosoftTeamsDialog;

  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/notification-action/delete',
    postRequestToDataProviderApi
  );

  const {
    trigger: triggerTest,
    isMutating: isMutatingTest,
    data: dataTest,
    reset: resetTest,
  } = useSWRMutation(
    '/data-providers/adaptive-cards/trigger',
    postRequestToDataProviderApi
  );

  function closeDialog() {
    reduxDispatch({
      type: types.SHOW_MICROSOFT_TEAMS_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  function createInitialState(notificationAction) {
    const inState = {
      id: notificationAction.id,
      name: notificationAction.name,
      status: notificationAction.status,
      notificationService: notificationAction.notificationService,
      template: notificationAction.template,
      dateRange: notificationAction.dateRangeType,

      fieldIds: notificationAction.fields,
      sort: notificationAction.sort,
      dimensionsFiltersObjects: notificationAction.dimensionsFilters,
      selectedMetrics: [],
      selectedDimensions: [],
      startDate: notificationAction.dateRange.startDate,
      endDate: notificationAction.dateRange.endDate,
      dateRangeError: '',
      notificationActionCredentialId:
        notificationAction.notificationActionCredentialId,
      schedule: notificationAction.schedule,
      dimensionsFilters: [],
      sortField: null,
      trigger: notificationAction.trigger,
      limit: notificationAction.limit,
    };

    setInitialState(inState);

    setOpen(true);
  }

  React.useEffect(() => {
    setInitialState(orState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSource]);

  React.useEffect(() => {
    if (!dataTest && isMutatingTest) {
      enqueueSnackbar(`Testing Microsoft Teams integration... `, {
        variant: 'info',
      });
    }

    if (dataTest && isMutatingTest === false) {
      enqueueSnackbar(
        `Success! View your Teams channel for the successful test`,
        {
          variant: 'success',
        }
      );
    }

    if (dataTest) {
      resetTest();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTest, isMutatingTest]);

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      {open && (
        <CreateNotificationActionDialog
          initialState={initialState}
          open={open}
          setOpen={setOpen}
        />
      )}
      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Microsoft Teams</DialogTitle>
        <DialogContent>
          {selectedDataSource?.notificationActions?.items?.length > 0 && (
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Microsoft Teams integration</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedDataSource?.notificationActions?.items.map(
                    (notificationAction) => (
                      <TableRow key={notificationAction.id}>
                        <TableCell>
                          <Tooltip
                            title={ellipsis(
                              notificationAction.fields.join(','),
                              50
                            )}
                          >
                            <Box display="flex" flexDirection="row">
                              <Box sx={{ mr: 1 }}>
                                <Avatar
                                  alt=""
                                  src={microsoftTeamsLogo}
                                  variant="circular"
                                />
                              </Box>

                              <Box display="flex" flexDirection="column">
                                <Typography
                                  color="textPrimary"
                                  component="span"
                                  variant="body1"
                                >
                                  {notificationAction.name}
                                </Typography>

                                {notificationAction.trigger === 'SCHEDULE' && (
                                  <Typography
                                    color="textSecondary"
                                    component="span"
                                    variant="body2"
                                  >
                                    {notificationAction.dateRangeType ===
                                    'CUSTOM' ? (
                                      <>{`${notificationAction.dateRange.startDate} - ${notificationAction.dateRange.endDate}`}</>
                                    ) : (
                                      <>
                                        {schedule[notificationAction.schedule]}
                                      </>
                                    )}
                                  </Typography>
                                )}

                                {notificationAction.trigger === 'WEBHOOK' && (
                                  <Typography
                                    color="textSecondary"
                                    component="span"
                                    variant="body2"
                                  >
                                    Webhook
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="left">
                          {notificationAction.trigger === 'WEBHOOK' && (
                            <Button
                              color="info"
                              href={`${dataApiBase}/wh/${notificationAction.id}`}
                              startIcon={<OpenInNew />}
                              sx={(theme) => ({
                                textTransform: 'none',
                                background: theme.palette.background.default,
                                width: '100%',
                              })}
                              target="_blank"
                              variant="outlined"
                            >
                              <Typography variant="inherit">
                                Webhook URL
                              </Typography>
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="right" padding="none">
                          <PopupState variant="popover">
                            {(popupState) => (
                              <>
                                <IconButton
                                  color="primary"
                                  variant="contained"
                                  {...bindTrigger(popupState)}
                                  size="large"
                                >
                                  <MoreVert />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={async () => {
                                      popupState.close();
                                      createInitialState(notificationAction);
                                    }}
                                  >
                                    Edit
                                  </MenuItem>

                                  <MenuItem
                                    disabled={
                                      notificationAction.trigger === 'WEBHOOK'
                                    }
                                    onClick={async () => {
                                      // popupState.close();
                                      triggerTest({
                                        notificationActionId:
                                          notificationAction.id,
                                      });
                                    }}
                                  >
                                    Test
                                  </MenuItem>

                                  <MenuItem
                                    onClick={async () => {
                                      trigger({
                                        notificationActionId:
                                          notificationAction.id,
                                      });

                                      popupState.close();

                                      setTimeout(async () => {
                                        const updatedDataSource =
                                          await updateDataSourceObject({
                                            id: selectedDataSource.id,
                                          });

                                        batch(() => {
                                          reduxDispatch({
                                            type: types.UPDATE_TEAM_DATA_SOURCE,
                                            dataSource: updatedDataSource,
                                            teamId: updatedDataSource.team.id,
                                          });
                                        });
                                      }, 500);
                                    }}
                                  >
                                    {isMutating ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <>Delete</>
                                    )}
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </PopupState>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          )}

          {selectedDataSource?.notificationActions?.items.length === 0 && (
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="center">
                <DataObjectTwoTone color="disabled" fontSize="large" />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
              >
                No Microsoft Teams integrations found
              </Typography>
              <Typography
                align="center"
                color="textSecondary"
                display="block"
                gutterBottom
                variant="caption"
              >
                Add a integrations below
              </Typography>
            </Box>
          )}
        </DialogContent>

        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            mt: 2,
            borderTop: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              pt: 2,
            }}
          >
            <Button
              color="info"
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<Add />}
              variant="outlined"
            >
              Create Microsoft Teams integration
            </Button>
          </Box>
        </Box>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MicrosoftTeamsDialog;
