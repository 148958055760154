import { Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import _find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactJoin from '../../utilities/react-join';
import DataProviderChip from './DataProviderChip';

function DataSourceListItem({ dataSourceId }) {
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);

  const { items: dataSources } = dataSourcesByTeam[selectedUserTeam] || {
    items: [],
  };

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    if (dataSourceId && dataSources.length > 0) {
      const foundDataSource = _find(dataSources, (o) => o.id === dataSourceId);
      setDataSource(foundDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceId, dataSources]);

  if (!dataSource) {
    return null;
  }
  return (
    <ListItem>
      <ListItemIcon>
        <DataProviderChip
          dataProvider={dataSource.dataProvider}
          variant="small_avatar"
        />
      </ListItemIcon>
      <ListItemText
        primary={
          dataSource.displayName ? dataSource.displayName : dataSource.name
        }
        secondary={
          <>
            <ReactJoin
              separator={
                <Icon
                  style={{
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                  }}
                >
                  navigate_next
                </Icon>
              }
            >
              {dataSource.tags}
            </ReactJoin>
          </>
        }
      />
    </ListItem>
  );
}

export default DataSourceListItem;
