const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:bce583d3-5b17-41c3-ae8e-c9c267ee8d66',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_qxCavShWt',
  aws_user_pools_web_client_id: '7r2dv342ob8p61hv5d2tkiavnb',
  aws_appsync_graphqlEndpoint:
    'https://igeajlz2arbmzegz4y6lle6t2y.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-z5snizjdk5b6zg4c4mb5p4nd7q',
  aws_user_files_s3_bucket: 'datahubcontent192652-dev',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_cognito_social_providers: ['GOOGLE'],
  federationTarget: 'COGNITO_USER_POOLS',
  oauth: {
    domain: 'data-hub-staging-auth.gladior.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      'http://localhost:3000,https://data-hub-staging.gladior.com/',
    redirectSignOut:
      'http://localhost:3000/,https://data-hub-staging.gladior.com/',
    responseType: 'code',
  },
};

export default awsmobile;
