/* eslint-disable no-nested-ternary */

import { CalendarMonth } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import PopupState, {
  anchorRef,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';

const options = ['today', 'yesterday', 'xdaysAgo', 'custom'];

function AutocompleteDatePicker({ label, inputValue, setInputValue }) {
  const [date, setDate] = React.useState(dayjs());

  const [error, setError] = React.useState('');
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    const testRegex =
      /[0-9]{4}-[0-9]{2}-[0-9]{2}|today|yesterday|[0-9]+(daysAgo)/;

    const isValid = testRegex.test(inputValue);

    if (!inputValue) {
      setError('');
    } else if (!isValid) {
      if (inputValue === 'xdaysAgo') {
        setError(
          'Replace the "x" with the number of days, for example 30daysAgo'
        );
      } else {
        setError('Not valid');
      }
    } else {
      setError('');
    }
  }, [inputValue]);

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <Autocomplete
            freeSolo
            inputValue={inputValue}
            onChange={(event, newValue) => {
              if (newValue === 'custom') {
                popupState.open();
                setInputValue('');
                setValue(null);
              } else {
                setValue(newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (newInputValue !== 'custom') {
                setInputValue(newInputValue);
              }
            }}
            options={options}
            ref={anchorRef(popupState)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ position: 'absolute', right: '20px' }}
                    >
                      <IconButton {...bindTrigger(popupState)} edge="end">
                        <CalendarMonth />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!error}
                helperText={error}
                label={label}
              />
            )}
            size="small"
            sx={{ width: '100%' }}
            value={value}
          />

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <CalendarPicker
              date={date}
              onChange={(newDate) => {
                setInputValue(newDate.format('YYYY-MM-DD'));
                setDate(newDate);
                popupState.close();
              }}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
}

export default AutocompleteDatePicker;
