import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql-custom/mutations';

async function createAvatarObject(userId, avatar) {
  const userUpdatedResponse = await API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        id: userId,
        avatar,
      },
    })
  );

  return userUpdatedResponse.data.updateUser;
}

export default createAvatarObject;
