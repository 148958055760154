import { Close } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { blue, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    background: theme.palette.background.paper,
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  normalAvatar: {
    background: theme.palette.background.paper,
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.action.disabled}`,
    marginRight: '8px',
  },
  small: {
    marginLeft: '4px',
    marginRight: '-5px',
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: grey[200],
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  whiteBackground: {
    background: '#fff',
    border: `1px solid ${theme.palette.action.disabled}`,
    color: blue[500],
    fontWeight: 500,
  },

  searchBarAvatar: {
    width: 20,
    height: 20,
    backgroundColor: '#fff',
  },
}));

function DataProviderChip({
  dataProvider,
  variant = 'chip',

  deletable = false,
  deleteAction = async () => {},
}) {
  const classes = useStyles();
  const containerRef = useRef(null);

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  let dataProviderImage = '';
  let dataProviderLabel = '';

  dataProviderImage =
    dataProviderSettings.allDataProviders[dataProvider]?.image;
  dataProviderLabel = dataProviderSettings.allDataProviders[dataProvider]?.name;

  let onDelelete = null;

  if (deletable === true) {
    onDelelete = async () => {
      await deleteAction();
    };
  }

  if (variant === 'SEARCH_BAR') {
    return (
      <Chip
        className={clsx({
          [classes.whiteBackground]: variant === 'SEARCH_BAR',
        })}
        deleteIcon={
          <Close
            className={clsx({
              [classes.addIcon]: true,
            })}
            fontSize="small"
            ref={containerRef}
          />
        }
        icon={
          <Avatar className={classes.searchBarAvatar} src={dataProviderImage} />
        }
        label={dataProviderLabel}
        onDelete={onDelelete}
        variant="outlined"
      />
    );
  }

  if (variant === 'chip') {
    return (
      <Chip
        avatar={<Avatar src={dataProviderImage} />}
        label={dataProviderLabel}
        size="small"
        variant="outlined"
      />
    );
  }

  if (variant === 'sub_header') {
    return (
      <>
        <Box
          alignContent="center"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Avatar className={classes.smallAvatar} src={dataProviderImage} />

          <Box marginLeft="8px">{dataProviderLabel}</Box>
        </Box>
      </>
    );
  }

  if (variant === 'avatar') {
    return (
      <>
        <Tooltip title={dataProviderLabel}>
          <Box
            alignContent="center"
            alignItems="center"
            display="flex"
            flexDirection="row"
          >
            <Avatar className={classes.normalAvatar} src={dataProviderImage} />
          </Box>
        </Tooltip>
      </>
    );
  }

  if (variant === 'large_avatar') {
    return <Avatar src={dataProviderImage} />;
  }

  if (variant === 'small_avatar') {
    return (
      <Avatar className={classes.small} src={dataProviderImage} variant="" />
    );
  }
}

export default DataProviderChip;
