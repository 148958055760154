/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { TextField } from '@mui/material';
import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import useDebounce from '../../../hooks/useDebounce';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';

function TextTextField({
  id,
  dependsOn,
  credentialId,
  config,
  configParams,
  stepIndex,
  updateConfig,
}) {
  const [textValue, setTextValue] = useState('');
  const [textValueIsValid, setTextValueIsValid] = useState(true);
  const debouncedTextValue = useDebounce(textValue, 1000);

  useEffect(() => {
    async function validateInput() {
      let isValid = false;

      if (!config.validate) {
        isValid = true;
      } else {
        const { endpoint, input } = config.validate;

        const queryStringParameters = {};
        input.forEach((inputObj) => {
          if (inputObj.id === 'CREDENTIAL_ID') {
            queryStringParameters.credentialId = credentialId;
          } else if (inputObj.id === id) {
            queryStringParameters[inputObj.key] = debouncedTextValue;
          } else if (configParams[inputObj.id]) {
            if (inputObj.type === 'QUERY_PARAM') {
              queryStringParameters[inputObj.key] = configParams[inputObj.id];
            }
          }
        });

        const tokens = await Auth.currentSession();
        const token = tokens.getIdToken().getJwtToken();
        const dpResponse = await API.get('DataProviderApi', endpoint, {
          headers: {
            Authorization: token,
          },
          response: true,
          queryStringParameters,
        });

        const accessData = dpResponse.data;

        if (accessData.hasAccess === true) {
          isValid = true;
        }
      }

  

      if (isValid) {
        setTextValueIsValid(true);
        updateConfig(stepIndex, id, { id: debouncedTextValue });
      } else {
        setTextValueIsValid(false);
      }
    }

    validateInput();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTextValue]);

  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  return (
    <>
      <TextField
        error={textValueIsValid === false}
        fullWidth
        label={config.label}
        onChange={(event) => {
          updateConfig(stepIndex, id, false);
          setTextValueIsValid(true);
          setTextValue(event.target.value);
        }}
        value={textValue}
        variant="filled"
      />
    </>
  );
}

export default TextTextField;
