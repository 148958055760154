import { API, Auth } from 'aws-amplify';
import useSWR from 'swr';

const backendFetcher = async (input) => {
  const [path, queryStringParameters] = input;

  const tokens = await Auth.currentSession();
  const token = tokens.getIdToken().getJwtToken();
  return API.get('BackendApi', path, {
    headers: {
      Authorization: token,
    },
    response: true,
    queryStringParameters,
  }).then((res) => res.data);
};

function useBackendData(path, queryStringObj = {}) {
  // eslint-disable-next-line no-unused-vars
  const { data, error } = useSWR(
    path ? [path, queryStringObj] : null,
    backendFetcher,
    {
      errorRetryCount: 3,
    }
  );

  let errorMessage;
  let errorType = '';
  let statusCode = 200;

  if (error) {
    errorMessage =
      'Unknown Error - There was an error communicating with the data provider.';

    if (error.response) {
      const errorData = error.response.data;

      errorType = 'UnexpectedError';

      if (errorData?.type) {
        errorType = errorData.type;
      }

      statusCode = errorData.statusCode;
    }
  }

  return {
    data,
    isLoading: !error && !data,
    errorMessage,
    statusCode,
    errorType,
  };
}

export default useBackendData;
