import { Check, Search } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _orderBy from 'lodash/orderBy';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import getAllLabels from '../../api/getAllLabels';
import isValidUrl from '../../utilities/isValidUrl';
import useDebounce from '../../utilities/useDebounce';
import ListItemLink from '../ListItemLink';
import NewLabelDialog from './NewLabelDialog';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  labelToolbar: {
    height: '35px',
    minHeight: '35px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableWrapper: {
    maxHeight: 150,
    minHeight: 150,
    overflow: 'auto',
  },
}));

const loader = (
  <ListItemLink key={0}>
    <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress size={20} />
    </Box>
  </ListItemLink>
);

function AddLabelDialog({ open, closeDialog, setLabel, label }) {
  const labelIds = [];

  const classes = useStyles();

  const scrollParentRef = useRef();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const [nextToken, setNextToken] = useState(null);
  const [labels, setLabels] = useState([]);
  const [query, setQuery] = useState('');
  const [showNewDialog, setShowNewDialog] = useState(false);

  const debouncedSearchTerm = useDebounce(query, 1000);
  const [waitForQuery, setWaitForQuery] = useState(false);

  // const [showNewDialog, setShowNewDialog] = useState(false);
  const [urlLabel, setUrlLabel] = useState('');

  const debouncedUrlLabel = useDebounce(urlLabel, 1000);

  async function fetchLabels() {
    let filter;
    if (debouncedSearchTerm) {
      filter = {
        or: [
          {
            nameLowercase: {
              contains: debouncedSearchTerm.trim().toLowerCase(),
            },
          },
        ],
      };
    }

    let labelsToAdd = [];
    const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
      await getAllLabels(selectedUserTeam, undefined, 10, filter);

    labelsToAdd = [...labelsToAdd, ...newDataSourceLabels];

    if (debouncedSearchTerm === '') {
      const { dataSourceLabels: suggestedDataSourceLabels } =
        await getAllLabels(selectedUserTeam, undefined, 10, undefined);

      const suggestedDataSourceLabelsToAddIds = suggestedDataSourceLabels.map(
        (o) => o.id
      );

      const suggestedDataSourceLabelsToAdd = suggestedDataSourceLabels.map(
        (o) => ({ ...o, isSuggestion: true })
      );

      const filteredDataSourceLabelsToAdd = labelsToAdd.filter(
        (o) => !suggestedDataSourceLabelsToAddIds.includes(o.id)
      );

      labelsToAdd = [
        ...suggestedDataSourceLabelsToAdd,
        ...filteredDataSourceLabelsToAdd,
      ];
    }

    labelsToAdd = labelsToAdd.map((lta) => ({
      ...lta,
      isConnected: labelIds.includes(lta.id),
    }));

    labelsToAdd = _orderBy(
      labelsToAdd,
      ['isConnected', 'name'],
      ['desc', 'asc']
    );

    setLabels(labelsToAdd);

    setNextToken(newNextToken);
    setWaitForQuery(false);
  }

  useEffect(() => {
    fetchLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      !debouncedUrlLabel.startsWith('http://') &&
      !debouncedUrlLabel.startsWith('https://')
    ) {
      if (isValidUrl(`https://${debouncedUrlLabel}`)) {
        setUrlLabel(`https://${debouncedUrlLabel}`);
      }
    }
  }, [debouncedUrlLabel]);

  async function loadItems() {
    let filter;
    if (debouncedSearchTerm) {
      filter = {
        or: [
          {
            nameLowercase: {
              contains: debouncedSearchTerm.trim().toLowerCase(),
            },
          },
        ],
      };
    }
    const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
      await getAllLabels(selectedUserTeam, nextToken, 10, filter);

    let labelsToAdd = [...labels, ...newDataSourceLabels];

    labelsToAdd = labelsToAdd.map((lta) => ({
      ...lta,
      isConnected: labelIds.includes(lta.id),
    }));

    labelsToAdd = _orderBy(
      labelsToAdd,
      ['isConnected', 'name'],
      ['desc', 'asc']
    );

    setLabels(labelsToAdd);
    setNextToken(newNextToken);
  }

  return (
    <>
      <NewLabelDialog
        createdCallBack={() => {}}
        selectedUserTeam={selectedUserTeam}
        setShowNewDialog={setShowNewDialog}
        showNewDialog={showNewDialog}
      />

      <Dialog fullWidth maxWidth="xs" onClose={closeDialog} open={open}>
        <Box>
          <Box p={2} width="100%">
            <Typography variant="subtitle2">Label</Typography>
          </Box>

          <AppBar
            className={classes.searchBar}
            color="default"
            elevation={0}
            position="static"
          >
            <Toolbar className={classes.labelToolbar}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
              >
                <InputBase
                  className={classes.input}
                  onChange={(e) => {
                    if (waitForQuery === false) {
                      setWaitForQuery(true);
                    }
                    setQuery(e.target.value);
                  }}
                  placeholder="Filter labels"
                  type="search"
                  value={query}
                />
                <IconButton
                  aria-label="search"
                  className={classes.iconButton}
                  size="large"
                >
                  {waitForQuery === true ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Search />
                  )}
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>

        <Box>
          <div className={classes.tableWrapper} ref={scrollParentRef}>
            <InfiniteScroll
              hasMore={waitForQuery === false ? nextToken !== null : false}
              loadMore={loadItems}
              loader={loader}
              pageStart={0}
              useWindow={false}
            >
              <List>
                {labels.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => {
                      setLabel(item);
                      closeDialog();

                      //
                    }}
                  >
                    <Box width="14px">
                      <>
                        {label && label.id === item.id && (
                          <Check fontSize="inherit" />
                        )}
                      </>
                    </Box>

                    <ListItemText
                      disableTypography
                      primary={
                        <Typography fontSize="" noWrap type="body2">
                          <Box
                            component="span"
                            display="flex"
                            flexDirection="row"
                            fontSize={14}
                            m={1}
                          >
                            <Box component="span" mr={1}>
                              <Icon color="secondary" fontSize="inherit">
                                language
                              </Icon>
                            </Box>

                            {item.name}
                          </Box>
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </InfiniteScroll>
          </div>

          <Divider />
          {false && (
            <Box p={1}>
              <Button
                fullWidth
                onClick={async () => {
                  setShowNewDialog(true);
                }}
              >
                Create new{' '}
              </Button>
            </Box>
          )}
        </Box>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddLabelDialog;
