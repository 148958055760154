import { useAuthenticator } from '@aws-amplify/ui-react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dataHubTextDev from '../../data-hub-text-dev.svg';
import dataHubTextProd from '../../data-hub-text-prod.svg';

const ConfirmResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [succes, setSuccess] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const params = useParams();
  const { route } = useAuthenticator();
  const sub = params?.code;
  const token = params?.token;

  async function forgotPasswordSubmit(username, code, newPass) {
    if (!error) {
      setLoading(true);
      try {
        const data = await Auth.forgotPasswordSubmit(username, code, newPass);
        if (data.toLowerCase() === 'success') {
          setSuccess(
            `Password changed successfully. You'll be redirected to the login screen`
          );
          setError('');
          setLoading(false);
          setTimeout(() => {
            navigate('/auth');
          }, 2000);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (newPassword !== '' && confirmPassword !== '') {
      if (newPassword === confirmPassword && newPassword.length >= 8) {
        setCanSubmit(true);
        setError('');
      } else {
        setCanSubmit(false);
        if (newPassword !== confirmPassword) {
          setError("Passwords don't match.");
        }
        if (newPassword.length < 8) {
          setError('Passwords must have a minimum length of 8 characters.');
        }
      }
    } else {
      setError('');
    }
  }, [newPassword, confirmPassword]);

  const handleSetNewPassword = () => {
    if (newPassword === confirmPassword && newPassword.length >= 8) {
      setError('');
      forgotPasswordSubmit(sub, token, newPassword);
    }
  };

  return (
    <>
      {sub && token && (
        <>
          <Box
            sx={{
              height: '100vh',
              width: '100%',
            }}
          >
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              >
                {route !== 'authenticated' && (
                  <>
                    <Box sx={{ width: '396px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          alt="Data Hub Logo"
                          src={
                            process.env.REACT_APP_USER_BRANCH === 'prod'
                              ? dataHubTextProd
                              : dataHubTextDev
                          }
                          style={{ height: '75px' }}
                        />
                      </Box>
                    </Box>
                    <Box
                      mt={3}
                      p={4}
                      sx={{
                        backgroundColor: 'white',
                        border: '1px solid rgb(137, 148, 159)',
                        boxShadow: 'rgba(13, 26, 38, 0.15) 0px 2px 6px 0px',
                      }}
                    >
                      {/* New password */}
                      <Typography
                        color="initial"
                        mb={4}
                        sx={{
                          fontSize: '32px',
                          fontWeight: 500,
                        }}
                        variant="body1"
                      >
                        Reset Password
                      </Typography>
                      <Box my={2}>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <InputLabel htmlFor="password">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            id="password"
                            label="New Password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            value={newPassword}
                          />
                        </FormControl>
                      </Box>
                      <Box my={2}>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <InputLabel htmlFor="password_confirmation">
                            Password Confirmation
                          </InputLabel>
                          <OutlinedInput
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            id="password_confirmation"
                            label="Password Confirmation"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                          />
                          <FormHelperText>
                            Passwords must have a minimum length of 8 characters
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      {error && <Alert severity="error">{error}</Alert>}
                      {succes && <Alert severity="success">{succes}</Alert>}
                      <LoadingButton
                        color="secondary"
                        disabled={!canSubmit}
                        loading={loading}
                        onClick={handleSetNewPassword}
                        sx={{
                          width: '100%',
                          mt: 2,
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Set new password
                      </LoadingButton>
                    </Box>

                    <Box
                      sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',

                        width: '396px',
                      }}
                    >
                      <Typography
                        align="center"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                        variant="caption"
                      >
                        By signing up for Data Hub, you agree to the{' '}
                        <Link
                          color="secondary"
                          href="https://datahub.gladior.com/terms-of-service/"
                        >
                          Terms of Service
                        </Link>
                        . View our{' '}
                        <Link
                          color="secondary"
                          href="https://datahub.gladior.com/privacy-policy/"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </Typography>

                      <Typography
                        align="center"
                        color="text.secondary"
                        variant="caption"
                      >
                        {'Copyright © '}
                        Data Hub powered by Gladior {new Date().getFullYear()}.
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default ConfirmResetPassword;
