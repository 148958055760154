export const GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS';
export const GOOGLE_ADS = 'GOOGLE_ADS';
export const GOOGLE_SEARCH_CONSOLE = 'GOOGLE_SEARCH_CONSOLE';
export const GOOGLE_MY_BUSINESS = 'GOOGLE_MY_BUSINESS';
export const YOUTUBE = 'YOUTUBE';
export const GOOGLE_SHEETS = 'GOOGLE_SHEETS';
export const MARKTPLAATS = 'MARKTPLAATS';

export const FACEBOOK_INSIGHTS = 'FACEBOOK_INSIGHTS';
export const FACEBOOK_ADS = 'FACEBOOK_ADS';
export const MICROSOFT_ADVERTISING = 'MICROSOFT_ADVERTISING';
export const MAILCHIMP = 'MAILCHIMP';
export const LINKEDIN = 'LINKEDIN';
export const LINKEDIN_ADS = 'LINKEDIN_ADS';
export const GOOGLE_ANALYTICS_4 = 'GOOGLE_ANALYTICS_4';
export const AFAS_PROFIT = 'AFAS_PROFIT';
export const WINCHER = 'WINCHER';
export const MULTI_DATA_PROVIDER = 'MULTI_DATA_PROVIDER';
export const CHANNABLE = 'CHANNABLE';
export const INDEED_JOBS = 'INDEED_JOBS';
export const MAJESTIC = 'MAJESTIC';
export const LIGHTHOUSE_SERVER = 'LIGHTHOUSE_SERVER';
export const GOOGLE_MERCHANT_CENTER = 'GOOGLE_MERCHANT_CENTER';
export const INSTAGRAM = 'INSTAGRAM';

// not yet implented
export const LIGHTSPEED = 'LIGHTSPEED';
export const CCV_SHOP = 'CCV_SHOP';
export const MAGENTO_2 = 'MAGENTO_2';
