import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

async function deleteCredentialObject(selectedCredential) {
  await API.graphql(
    graphqlOperation(mutations.removeCredential, {
      credentialId: selectedCredential.id,
    })
  );

  return selectedCredential;
}

export default deleteCredentialObject;
