import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import getUserObject from './getUserObject';

async function createTeamObject(name, makeUserDefaultTeam) {
  // Create Team Object
  const teamMutationsObject = await API.graphql(
    graphqlOperation(mutations.createTeam, {
      input: { name },
    })
  );

  const teamObject = teamMutationsObject.data.createTeam;
  const { userObject } = await getUserObject();

  // Create add the currect user to the teamMembers (Many to Many relationship)
  // const teamMembershipMutationsObject =
  // let themeMembership =
  await API.graphql(
    graphqlOperation(mutations.createTeamMembership, {
      input: {
        teamMembershipTeamId: teamObject.id,
        teamMembershipUserId: userObject.id,
      },
    })
  );

  if (makeUserDefaultTeam === true) {
    await API.graphql(
      graphqlOperation(mutations.updateUser, {
        input: {
          id: userObject.id,
          defaultTeamId: teamObject.id,
        },
      })
    );
  }

  return teamObject;
}

export default createTeamObject;
