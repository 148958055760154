/* eslint-disable no-nested-ternary */
import {
  AccessTime,
  ArrowDropDown,
  Check,
  CheckCircle,
  InfoOutlined,
  MoreVert,
  NotInterested,
  PersonAdd,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import _find from 'lodash/find';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import * as types from '../../constants/ActionTypes';
import * as customMutations from '../../graphql-custom/mutations';
import * as customQueries from '../../graphql-custom/queries';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import UserAvatar from '../user/UserAvatar';
import AddTeamMemberDialog from './AddTeamMemberDialog';
import RemoveAsAdminDialog from './RemoveAsAdminDialog';

const ITEM_HEIGHT = 48;

const ADMIN = 'ADMIN';
const TEAM_MEMBER = 'TEAM_MEMBER';
const EDITOR = 'EDITOR';
const BLOCKED = 'BLOCKED';

const useStyles = makeStyles((theme) => ({
  avatarButtons: {
    marginBottom: '10px',
  },
  tableWrapper: {
    maxHeight: 400,
    overflow: 'auto',
  },

  adminButton: {
    textTransform: 'none',
    background: theme.palette.background.default,
    width: '100%',
  },
}));

function ManageTeamMembersDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // redux selectors
  const userObject = useSelector((state) => state.userObject);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const userTeams = useSelector((state) => state.userTeams);

  // local state
  const [anchorEl, setAnchorEl] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAddTeamMemberDialog, setShowAddTeamMemberDialog] = useState(false);
  const [openChapterMenuId, setOpenChapterMenuId] = useState(-1);
  const [showRemoveAsAdminDialog, setShowRemoveAsAdminDialog] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);

  const [selectedTeam, setSelectedTeam] = useState(null);

  function onClose() {
    const queryObj = querystring.parse(location.search.slice(1));
    if (queryObj.workspaceId) {
      delete queryObj.workspaceId;
    }

    navigate({
      pathname: `/workspace/${workspaceId}/data-sources`,
      search: querystring.stringify(queryObj),
    });
  }

  function closeDialog() {
    setTeamMembers([]);
    dispatch({
      type: types.SHOW_MANAGE_TEAM_MEMBERS_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function getAllThemeMembers() {
    const getAllTeamMembersResponse = await API.graphql(
      graphqlOperation(queries.getAllTeamMembers, { teamId: selectedTeam.id })
    );

    const teamMembersR = getAllTeamMembersResponse.data.getAllTeamMembers;
    setTeamMembers(teamMembersR);
  }

  useEffect(() => {
    if (userTeams.length > 0) {
      let selectedUserTeamId = selectedUserTeam;
      const queryObj = querystring.parse(location.search.slice(1));
      if (queryObj.workspaceId) {
        if (
          _find(userTeams, {
            id: queryObj.workspaceId,
          })
        ) {
          selectedUserTeamId = queryObj.workspaceId;
        }
      }
      const selectedUserTeamNew = _find(userTeams, {
        id: selectedUserTeamId,
      });
      setSelectedTeam(selectedUserTeamNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTeams]);

  useEffect(() => {
    if (selectedTeam) {
      getAllThemeMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddTeamMemberDialog, selectedTeam]);

  async function switchRole(newRole, userId, force = false) {
    // get the latest version of the team object
    const teamQueryObject = await API.graphql(
      graphqlOperation(customQueries.getTeam, { id: selectedTeam.id })
    );
    const teamObject = teamQueryObject.data.getTeam;

    let newAdmins = teamObject.admins ? [...teamObject.admins] : [];
    let newMembers = teamObject.members ? [...teamObject.members] : [];
    let newEditors = teamObject.editors ? [...teamObject.editors] : [];

    // check if team member is admin or not
    const memberHasAdminRole = teamObject.admins.includes(userId);

    // only switch into new roles
    if (newRole === ADMIN) {
      // move the username name from the members array into the admins array
      newMembers = newMembers.filter((e) => e !== userId);
      newEditors = newEditors.filter((e) => e !== userId);
      newAdmins.push(userId);
    } else if (newRole === TEAM_MEMBER) {
      // move the username name from the admins array into the members array
      newAdmins = newAdmins.filter((e) => e !== userId);
      newEditors = newEditors.filter((e) => e !== userId);
      newMembers.push(userId);
    } else if (newRole === EDITOR) {
      // move the username name from the admins array into the members array
      newAdmins = newAdmins.filter((e) => e !== userId);
      newMembers = newMembers.filter((e) => e !== userId);
      newEditors.push(userId);
    } else if (newRole === BLOCKED) {
      newEditors = newEditors.filter((e) => e !== userId);
      newAdmins = newAdmins.filter((e) => e !== userId);
      newMembers = newMembers.filter((e) => e !== userId);
    }

    // // don't allow admin-less teams
    // if (newAdmins.length > 0 ) {
    // the user is removing itself as admin, show an are-you-really-sure alert
    if (
      userId === userObject.id &&
      (newRole === TEAM_MEMBER || newRole === EDITOR) &&
      memberHasAdminRole === true &&
      force === false
    ) {
      setSelectedUsername(userId);
      setShowRemoveAsAdminDialog(true);
    } else {
      // update team with the new roles
      const updateTeamObject = await API.graphql(
        graphqlOperation(customMutations.updateTeam, {
          input: {
            id: selectedTeam.id,
            members: newMembers,
            admins: newAdmins,
            editors: newEditors,
          },
        })
      );
      // update redux with the updated team
      dispatch({
        type: types.UPDATE_USER_TEAM,
        team: updateTeamObject.data.updateTeam,
      });

      // if user remove itself as admin, close dialog
      if (
        userId === userObject.id &&
        (newRole === TEAM_MEMBER || newRole === EDITOR) &&
        memberHasAdminRole === true
      ) {
        closeDialog();
      }
    }

    enqueueSnackbar(
      `It can take up to 10 minutes for the change to take effect`,
      {
        variant: 'info',
        key: 'switch_user',
        autoHideDuration: 5000,
        persist: false,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }
    );

    setAnchorEl(null);
    setOpenChapterMenuId(-1);
  }

  if (!selectedTeam) {
    return null;
  }

  if (userTeams.length === 0) {
    return null;
  }

  const userIsAdmin = selectedTeam.admins.includes(userObject.id);

  return (
    <div>
      {selectedUsername && showRemoveAsAdminDialog && (
        <RemoveAsAdminDialog
          closeDialog={() => {
            setSelectedUsername(null);
            setShowRemoveAsAdminDialog(false);
          }}
          open={showRemoveAsAdminDialog}
          selectedUsername={selectedUsername}
          switchRole={switchRole}
        />
      )}

      <AddTeamMemberDialog
        closeDialog={() => {
          setShowAddTeamMemberDialog(false);
        }}
        open={showAddTeamMemberDialog}
        selectedTeam={selectedTeam}
      />

      <Dialog
        aria-describedby="manage-team-members-dialog-description"
        aria-labelledby="manage-team-members-dialog-title"
        maxWidth="md"
        onClose={closeDialog}
        open
      >
        <DialogTitle id="manage-team-members-dialog-title">
          Manage members
        </DialogTitle>

        {!userIsAdmin && (
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Chip
              color="primary"
              icon={<InfoOutlined />}
              label="You are not an Admin"
              variant="outlined"
            />
          </Box>
        )}

        <DialogContent>
          {userIsAdmin && (
            <DialogContentText
              id="manage-team-members-dialog-description"
              sx={{ mb: 2 }}
            >
              Manage members from <b>{ellipsis(selectedTeam.name, 45)}</b>
            </DialogContentText>
          )}

          {teamMembers.length === 0 && (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <CircularProgress />
            </Box>
          )}

          <Box className={classes.tableWrapper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Member</TableCell>
                  <TableCell>User status</TableCell>
                  <TableCell>Rol</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {teamMembers.map((member, i) => (
                  <TableRow key={member.id}>
                    <TableCell className={classes.tableCellNoBorder}>
                      <Box display="flex" flexDirection="row">
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          marginRight="12px"
                        >
                          <UserAvatar
                            avatar={member.avatar}
                            id={member.id}
                            name={member.name}
                          />
                        </Box>

                        <Box display="flex" flexDirection="column">
                          <Typography
                            className={classes.inline}
                            color="textPrimary"
                            component="span"
                            variant="body1"
                          >
                            {ellipsis(
                              `${member.name}${
                                userObject.id === member.id ? ' (you)' : ''
                              }`,
                              45
                            )}
                          </Typography>
                          <Typography
                            className={classes.inline}
                            color="textSecondary"
                            component="span"
                            variant="body2"
                          >
                            {member.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell
                      align="left"
                      className={classes.tableCellNoBorder}
                    >
                      <Typography noWrap variant="inherit">
                        {member.memberStatus === 'HAS_ACCESS' && (
                          <>
                            <Box color={green[500]} component="span">
                              <CheckCircle fontSize="inherit" />{' '}
                            </Box>
                          </>
                        )}

                        {member.memberStatus === 'INVITED' && (
                          <>
                            <Box color={orange[500]} component="span">
                              <AccessTime fontSize="inherit" />{' '}
                            </Box>
                          </>
                        )}

                        {member.memberStatus === 'NO_ACCESS' && (
                          <>
                            <Box color={red[500]} component="span">
                              <NotInterested fontSize="inherit" />{' '}
                            </Box>
                          </>
                        )}

                        {member.memberStatus === 'HAS_ACCESS' && 'Has access'}
                        {member.memberStatus === 'NO_ACCESS' && 'Has no access'}
                        {member.memberStatus === 'INVITED' &&
                          'Pending invitation'}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      className={classes.tableCellNoBorder}
                    >
                      <Button
                        className={classes.adminButton}
                        disabled={
                          !userIsAdmin ||
                          member.owner === true ||
                          member.memberStatus === 'INVITED'
                        }
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setOpenChapterMenuId(i);
                        }}
                        variant="outlined"
                      >
                        <Typography
                          color={
                            member.memberStatus === 'NO_ACCESS'
                              ? 'error'
                              : 'inherit'
                          }
                          variant="inherit"
                        >
                          {member.owner === true
                            ? 'Owner'
                            : member.memberStatus === 'NO_ACCESS'
                              ? 'Blocked'
                              : member.permission === 'ADMIN'
                                ? 'Admin'
                                : member.permission === 'TEAM_MEMBER'
                                  ? 'Member'
                                  : member.permission === 'EDITOR'
                                    ? 'Editor'
                                    : 'Blocked'}
                        </Typography>

                        <ArrowDropDown fontSize="small" />
                      </Button>

                      <Menu
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 250,
                          },
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        onClose={() => {
                          setAnchorEl(null);
                          setOpenChapterMenuId(-1);
                        }}
                        open={openChapterMenuId === i}
                      >
                        <MenuItem
                          onClick={() => {
                            switchRole(ADMIN, member.id);
                          }}
                        >
                          {member.permission === 'ADMIN' && (
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          )}
                          Admin
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            switchRole(EDITOR, member.id);
                          }}
                        >
                          {member.permission === 'EDITOR' &&
                            member.memberStatus !== 'NO_ACCESS' && (
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                            )}
                          Editor
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            switchRole(TEAM_MEMBER, member.id);
                          }}
                        >
                          {member.permission === 'TEAM_MEMBER' &&
                            member.memberStatus !== 'NO_ACCESS' && (
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                            )}
                          Member
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            switchRole(BLOCKED, member.id);
                          }}
                        >
                          {member.memberStatus === 'NO_ACCESS' && (
                            <ListItemIcon>
                              <Check color="error" />
                            </ListItemIcon>
                          )}
                          <Typography color="error" variant="inherit">
                            Block user{' '}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </TableCell>

                    <TableCell padding="none">
                      <PopupState variant="popover">
                        {(popupState) => (
                          <>
                            <IconButton
                              color="primary"
                              disabled={userIsAdmin === false}
                              variant="contained"
                              {...bindTrigger(popupState)}
                              size="large"
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                disabled={
                                  member.owner === true ||
                                  member.id === userObject.id ||
                                  userIsAdmin === false
                                }
                                onClick={async () => {
                                  try {
                                    await API.graphql(
                                      graphqlOperation(
                                        mutations.revokeAccessWorkspace,
                                        {
                                          teamId: selectedTeam.id,
                                          memberId: member.id,
                                        }
                                      )
                                    );
                                  } catch (e) {
                                    if (e.errors) {
                                      if (
                                        e.errors[0].errorType ===
                                        'Lambda:Unhandled'
                                      ) {
                                        const errorMessage =
                                          e.errors[0].message;
                                        enqueueSnackbar(errorMessage, {
                                          variant: 'error',
                                        });
                                      }
                                    }
                                  }

                                  await getAllThemeMembers();

                                  popupState.close();
                                }}
                              >
                                Revoke access
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          <Box display="flex" flexDirection="column" sx={{ mt: 4 }}>
            <Button
              className={classes.avatarButtons}
              disabled={!userIsAdmin}
              onClick={() => {
                setShowAddTeamMemberDialog(true);
              }}
              startIcon={<PersonAdd />}
              variant="contained"
            >
              Add member
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageTeamMembersDialog;
