import { useAuthenticator } from '@aws-amplify/ui-react';
import { CompareArrows } from '@mui/icons-material';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Link,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import signInCallback from '../../auth/signInCallback';
import * as types from '../../constants/ActionTypes';
import logoDev from '../../data-hub-icon-dev.svg';
import * as queries from '../../graphql/queries';
import logo from '../../logo-icon.svg';
import UserAvatar from '../user/UserAvatar';
import WorkspaceSelectRow from './WorkspaceSelectRow';

function SelectWorkspace() {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userObject = useSelector((state) => state.userObject);
  const { dataProvider } = useParams();
  const dataProviderId = dataProvider.toUpperCase().replaceAll('-', '_');
  const [anchorEl, setAnchorEl] = useState(null);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const dataProviderInfo =
    dataProviderSettings.allDataProviders[dataProviderId];

  const [selected, setSelected] = useState(0); // index of selected workspace

  const { items: teamInvites, isFetching } = useSelector(
    (state) => state.teamInvites
  );
  const [loading, setLoading] = useState(false);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const groups =
    currentAuthenticatedUser?.signInUserSession?.accessToken?.payload[
      'cognito:groups'
    ];

  async function setOauthForWorkspace(inviteId) {
    const urlParams = new URLSearchParams(window.location.search);
    setLoading(true);
    const res = await signInCallback(
      {
        code: urlParams.get('oauth_consumer_key'),
      },
      ['MAGENTO'],
      'magento_integration',
      inviteId,
      { route: 'magento' }
    );
    navigate(`/success`);
    return res;
  }
  async function createSmallBusinessPlusCredential(inviteId) {
    const urlParams = new URLSearchParams(window.location.search);
    const completeUrl = urlParams.get('afasBaseUrl');
    navigate(
      `/workspace/${inviteId}/data-sources/create/data-source?dataProvider=AFAS_SMALL_BUSINESS_PLUS&ENVIRONMENT=${completeUrl}`
    );
  }
  async function addInfoToWorkspace(inviteId) {
    switch (dataProviderId) {
      case 'MAGENTO':
        setOauthForWorkspace(inviteId);
        break;
      case 'AFAS_SMALL_BUSINESS_PLUS':
        createSmallBusinessPlusCredential(inviteId);
        break;
      default:
        break;
    }
  }
  async function getTeamInvites() {
    dispatch({
      type: types.REQUEST_TEAM_INVITES,
    });
    const getAllTeamInvitesRe = await API.graphql(
      graphqlOperation(queries.getAllTeamInvites, {})
    );
    const newTeamInvites = getAllTeamInvitesRe.data.getAllTeamInvites;
    dispatch({
      type: types.SET_TEAM_INVITES,
      invites: newTeamInvites,
    });
    return teamInvites;
  }

  useEffect(() => {
    async function check() {
      if (teamInvites.length === 0) {
        await getTeamInvites();
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      teamInvites.filter((invite) => invite.status === 'ACCEPTED').length ===
        1 &&
      !isFetching &&
      !loading
    ) {
      addInfoToWorkspace(
        teamInvites.filter((invite) => invite.status === 'ACCEPTED')[0].teamId
      );
    }
  }, [teamInvites, isFetching, loading]);

  return (
    <>
      {(isFetching === true || loading === true) && (
        <Backdrop
          open
          sx={(theme2) => ({
            zIndex: theme2.zIndex.drawer + 1,
            color: '#fff',
          })}
        >
          <CircularProgress color="inherit" />{' '}
        </Backdrop>
      )}
      {!isFetching && !loading && (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box alignItems="center" display="flex" flexDirection="column">
              <Box
                mb={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Avatar
                  src={
                    process.env.REACT_APP_USER_BRANCH === 'prod'
                      ? logo
                      : logoDev
                  }
                  variant="rounded"
                />

                <CompareArrows color="action" sx={{ m: 1 }} />

                <Avatar src={dataProviderInfo?.image} variant="rounded" />
              </Box>

              <Typography align="center" variant="h6">
                Connect to {dataProviderInfo?.name}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 3,
            }}
          >
            <Typography align="center" color="text.secondary" variant="caption">
              Select the workspace you want to connect to.
            </Typography>
          </Box>
          <Box>
            {teamInvites.filter((invite) => invite.status === 'ACCEPTED')
              .length > 0 && (
              <Box mt={3} style={{ opacity: isFetching === true ? 0.3 : 1 }}>
                {groups.includes('gladior') && (
                  <Card
                    square={true}
                    style={{ borderBottom: 0 }}
                    variant="outlined"
                  >
                    <CardContent style={{ padding: '12px' }}>
                      <Typography>
                        Workspaces for{' '}
                        <b>{currentAuthenticatedUser?.attributes?.email}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                <Card square={true} variant="outlined">
                  {teamInvites
                    .filter((invite) => invite.status === 'ACCEPTED')
                    .map((invite, index) => (
                      <div
                        key={`workspace-select-${invite.id}`}
                        onClick={() => {
                          setSelected(index);
                        }}
                      >
                        <WorkspaceSelectRow
                          checked={index === selected}
                          invite={invite}
                          key={invite.id}
                        />
                      </div>
                    ))}
                </Card>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center" mt={6}>
            <Button
              color="secondary"
              disableElevation
              onClick={() => {
                addInfoToWorkspace(
                  teamInvites.filter((invite) => invite.status === 'ACCEPTED')[
                    selected
                  ]?.teamId
                );
              }}
              size="medium"
              variant="contained"
            >
              Select Workspace
            </Button>
          </Box>

          <Box alignItems="center" display="flex" flexDirection="column" mt={6}>
            <Box sx={{ mb: 2 }}>
              <Chip
                avatar={
                  <UserAvatar
                    avatar={userObject.avatar}
                    name={user.attributes?.name}
                    size="small"
                  />
                }
                label={
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span>{ellipsis(user.attributes?.name, 45)}</span>

                    <SvgIcon>
                      <path d="M7 10l5 5 5-5z" />
                    </SvgIcon>
                  </span>
                }
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  textTransform: 'none',
                  border: `1px solid ${
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[600]
                      : theme.palette.grey[300]
                  }`,

                  color:
                    theme.palette.mode === 'dark'
                      ? '#FFF'
                      : theme.palette.primary[500],
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : '#FFF',
                }}
                variant="outlined"
              />
              <Menu
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                keepMounted
                onClose={() => {
                  setAnchorEl(null);
                }}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <MenuItem
                  onClick={() => {
                    signOut()
                      .then(() => navigate('/auth'))
                      .catch(() => navigate('/auth'));
                  }}
                >
                  Sign out
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Typography
              align="center"
              color="text.secondary"
              sx={{ mb: 2 }}
              variant="caption"
            >
              By using the Data Hub, you agree to the{' '}
              <Link
                color="secondary"
                href="https://datahub.gladior.com/terms-of-service"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </Link>
              . <br />
              View our{' '}
              <Link
                color="secondary"
                href="https://datahub.gladior.com/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            <Typography align="center" color="text.secondary" variant="caption">
              {'Copyright © '}
              Data Hub powered by Gladior {new Date().getFullYear()}.
            </Typography>
          </Box>
        </Container>
      )}
    </>
  );
}

export default SelectWorkspace;
