import isValidDomain from 'is-valid-domain';
import isValidUrl from './isValidUrl';

function retrieveUrlFromTheConfigParams(config, configParams) {
  const urlConfig = config.data_source.url;

  let url = '';
  if (urlConfig) {
    const configParamsItem = configParams[urlConfig.input_id];
    if (configParamsItem) {
      const urlValue = configParamsItem[urlConfig.key];
      if (urlValue) {
        url = urlValue;
      }
    }
  }

  if (url && !url.startsWith('http') && isValidDomain(url)) {
    url = `https://${url}`;
  }

  if (isValidUrl(url)) {
    return url;
  }
  return '';
}

export default retrieveUrlFromTheConfigParams;
