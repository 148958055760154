import { Box, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';

export default function DraggableDrawer({ children, open, close }) {
  const [startX, setStartX] = useState(null);
  const [width, setWidth] = useState(300);

  function onMouseMove(event) {
    if (!startX) return;
    event.preventDefault();
    event.stopPropagation();
    const newWidth = document.body.clientWidth - event.clientX - 45;
    setWidth(Math.max(newWidth, 300));
  }

  function onMouseUp() {
    setStartX(null);
  }

  useEffect(() => {
    if (open) {
      setWidth(300);
    }
  }, [open]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          flexDirection: 'row',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          overflowX: 'hidden',
          pr: '30px',
        },
      }}
      anchor="right"
      onClose={close}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'row',

        zIndex: 1300, // same z-index as modal to not overlap eachother
      }}
    >
      <Box
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setStartX(e.clientX);
        }}
        sx={() => ({
          width: '15px',
          height: '100%',
          minWidth: '15px',

          cursor: 'ew-resize',
          display: 'flex',
          alignItems: 'center',
        })}
      ></Box>
      <Box
        sx={{
          height: '100%',
          width: `${width}px`,
          maxWidth: '90vw',
          minWidth: 'fit-content',
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
}
