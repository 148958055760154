/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  alpha,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import avatarCredentialColors from '../../../utilities/avatarCredentialColors';
import getInitials from '../../../utilities/getInitials';
import numberFromText from '../../../utilities/numberFromText';
import AddCredentialButton from '../AddCredentialButton';
import DataProviderChip from '../DataProviderChip';

const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      credentials(limit: 300) {
        items {
          id
          name
          email
          dataProvider
          editors
          owners
          status
          picture
          dataSources {
            items {
              name
              displayName
              tags
              id
            }
          }
        }
      }
    }
  }
`;

function CredentialSelector({
  id,
  stepIndex,
  config,
  dependsOn,
  credential,
  updateConfig,
  configParams,
  dataProvider,
}) {
  const [credentials, setCredentials] = useState([]);

  const { option_config, label, helper_text } = config;

  const { filter = [] } = option_config || {};

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);

  useEffect(() => {
    async function getAllCredentials() {
      const credentialsQueryObject = await API.graphql(
        graphqlOperation(getTeam, {
          id: selectedUserTeam,
          limit: 300,
          nextToken: undefined,
        }),
      );

      const credentials2 =
        credentialsQueryObject.data.getTeam.credentials.items;

      if (filter.length === 0) {
        setCredentials(credentials2);
      }

      const filtered = credentials2.filter((credentialObj) =>
        credentialObj.dataProvider.some((r) => filter.includes(r)),
      );

      setCredentials(filtered);
    }
    getAllCredentials();
  }, [credentialsByTeam, filter]);

  return (
    <Box sx={{ my: 2 }}>
      <Autocomplete
        disablePortal
        fullWidth
        getOptionLabel={(option) => option.name}
        id="combo-box-demo"
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={(event, value, reason) => {
          updateConfig(stepIndex, id, value);
        }}
        options={credentials}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helper_text}
            label={label}
            variant="filled"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <ListItem
            {...props}
            key={option.id}
            sx={(theme) => ({
              background:
                option.status === 'INVALID'
                  ? alpha(theme.palette.error.light, 0.2)
                  : 'inherit',
            })}
          >
            <ListItemAvatar>
              <Avatar
                src={option.picture}
                sx={(theme) => ({
                  color: theme.palette.getContrastText(
                    avatarCredentialColors[
                      numberFromText(getInitials(option.name)) %
                        avatarCredentialColors.length
                    ],
                  ),
                  bgcolor:
                    avatarCredentialColors[
                      numberFromText(getInitials(option.name)) %
                        avatarCredentialColors.length
                    ],
                })}
              >
                {getInitials(option.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={option.name}
              secondary={`${option.email}`}
              sx={(theme) => ({
                color:
                  option.status === 'INVALID'
                    ? theme.palette.error.main
                    : 'inherit',
              })}
            />

            <Stack direction="row" spacing={1}>
              {option.dataProvider.map((dp) => (
                <DataProviderChip dataProvider={dp} key={dp} />
              ))}
            </Stack>
          </ListItem>
        )}
      />
      {filter && (
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          {filter.map((selectedDataProvider) => (
            <Box key={selectedDataProvider}>
              <AddCredentialButton
                forceShowIcon={true}
                selectedDataProvider={selectedDataProvider}
                variant="credentialPicker"
              />
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default CredentialSelector;
