import { combineReducers } from 'redux';
import * as types from '../constants/ActionTypes';

const errorMessage = (
  state = { open: false, title: '', description: '' },
  action
) => {
  switch (action.type) {
    case types.SHOW_ERROR_MESSAGE:
      return { ...state, open: true, ...action.error };
    case types.CLOSE_ERROR_MESSAGE:
      return { open: false, title: '', description: '' };
    default:
      return state;
  }
};

const selectedUserTeam = (state = '', action) => {
  switch (action.type) {
    case types.SELECT_USER_TEAM:
      return action.teamId;
    default:
      return state;
  }
};

const reports = (
  state = {
    isFetching: false,
    didInvalidate: false,
    nextToken: undefined,
    items: [],
    movedReportCount: 0,
    q: '',
  },
  action
) => {
  switch (action.type) {
    case types.SET_TEAM_REPORTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        nextToken: action.nextToken,
        items: action.reports,
        q: action.q,
      };

    case types.REQUEST_TEAM_REPORTS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case types.ADD_TEAM_REPORT:
      return {
        ...state,
        movedReportCount: action.movedReportCount
          ? state.movedReportCount + action.movedReportCount
          : state.movedReportCount,
        items: [action.report, ...state.items],
      };

    case types.DELETE_TEAM_REPORT:
      return {
        ...state,
        items: state.items.filter(
          (reportItem) => reportItem.id !== action.report.id
        ),
      };

    case types.UPDATE_TEAM_REPORT:
      return {
        ...state,
        movedReportCount: action.movedReportCount
          ? state.movedReportCount + action.movedReportCount
          : state.movedReportCount,
        items: state.items.map((report) =>
          report.id === action.report.id
            ? { ...report, ...action.report }
            : report
        ),
      };

    case types.ADD_MORE_TEAM_REPORTS:
      return {
        ...state,
        nextToken: action.nextToken,
        isFetching: false,
        didInvalidate: false,
        items: [...state.items, ...action.reports],
      };

    default:
      return state;
  }
};

const credentials = (
  state = {
    isFetching: false,
    didInvalidate: false,
    nextToken: undefined,
    items: [],
    movedCount: 0,
  },
  action
) => {
  switch (action.type) {
    case types.SET_TEAM_CREDENTIALS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        nextToken: action.nextToken,
        items: action.credentials,
      };

    case types.REQUEST_TEAM_CREDENTIALS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case types.ADD_TEAM_CREDENTIAL:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: [action.credential, ...state.items],
      };

    case types.DELETE_TEAM_CREDENTIAL:
      return {
        ...state,
        items: state.items.filter(
          (credentialItem) => credentialItem.id !== action.credential.id
        ),
      };

    case types.UPDATE_TEAM_CREDENTIAL:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: state.items.map((credential) =>
          credential.id === action.credential.id
            ? { ...credential, ...action.credential }
            : credential
        ),
      };

    case types.ADD_MORE_TEAM_CREDENTIALS:
      return {
        ...state,
        nextToken: action.nextToken,
        items: [...state.items, ...action.credentials],
      };

    default:
      return state;
  }
};

const dataSources = (
  state = {
    isFetching: false,
    didInvalidate: false,
    nextToken: undefined,
    items: [],
    movedCount: 0,
    q: '',
    dataProvider: '',
  },
  action
) => {
  switch (action.type) {
    case types.SET_TEAM_DATA_SOURCES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        nextToken: action.nextToken,
        items: action.dataSources,
        q: action.q,
        dataProvider: action.dataProvider,
      };

    case types.REQUEST_TEAM_DATA_SOURCES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case types.ADD_TEAM_DATA_SOURCE:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: [action.dataSource, ...state.items],
      };

    case types.DELETE_TEAM_DATA_SOURCE:
      return {
        ...state,
        items: state.items.filter(
          (dataSourceItem) => dataSourceItem.id !== action.dataSource.id
        ),
      };

    case types.UPDATE_TEAM_DATA_SOURCE:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: state.items.map((dataSource) =>
          dataSource.id === action.dataSource.id
            ? { ...dataSource, ...action.dataSource }
            : dataSource
        ),
      };

    case types.ADD_MORE_TEAM_DATA_SOURCES:
      return {
        ...state,
        nextToken: action.nextToken,
        items: [...state.items, ...action.dataSources],
      };

    default:
      return state;
  }
};

const widgets = (
  state = {
    isFetching: false,
    didInvalidate: false,
    nextToken: undefined,
    items: [],
    movedCount: 0,
    q: '',
  },
  action
) => {
  switch (action.type) {
    case types.SET_TEAM_WIDGETS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        nextToken: action.nextToken,
        items: action.widgets,
        q: action.q,
      };

    case types.REQUEST_TEAM_WIDGETS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case types.ADD_TEAM_WIDGET:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: [action.widget, ...state.items],
      };

    case types.DELETE_TEAM_WIDGET:
      return {
        ...state,
        items: state.items.filter(
          (widgetItem) => widgetItem.id !== action.widget.id
        ),
      };

    case types.UPDATE_TEAM_WIDGET:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: state.items.map((widget) =>
          widget.id === action.widget.id
            ? { ...widget, ...action.widget }
            : widget
        ),
      };

    case types.ADD_MORE_TEAM_WIDGETS:
      return {
        ...state,
        nextToken: action.nextToken,
        items: [...state.items, ...action.widgets],
      };

    default:
      return state;
  }
};

const templates = (
  state = {
    isFetching: false,
    didInvalidate: false,
    nextToken: undefined,
    items: [],
    movedCount: 0,
    q: '',
  },
  action
) => {
  switch (action.type) {
    case types.SET_TEAM_TEMPLATES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        nextToken: action.nextToken,
        items: action.templates,
        q: action.q,
      };

    case types.REQUEST_TEAM_TEMPLATES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case types.ADD_TEAM_TEMPLATE:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: [action.template, ...state.items],
      };

    case types.DELETE_TEAM_TEMPLATE:
      return {
        ...state,
        items: state.items.filter(
          (templateItem) => templateItem.id !== action.template.id
        ),
      };

    case types.UPDATE_TEAM_TEMPLATE:
      return {
        ...state,
        movedCount: action.movedCount
          ? state.movedCount + action.movedCount
          : state.movedCount,
        items: state.items.map((template) =>
          template.id === action.template.id
            ? { ...template, ...action.template }
            : template
        ),
      };

    case types.ADD_MORE_TEAM_TEMPLATES:
      return {
        ...state,
        nextToken: action.nextToken,
        items: [...state.items, ...action.templates],
      };

    default:
      return state;
  }
};

const reportsByTeam = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TEAM_REPORTS:
    case types.ADD_TEAM_REPORT:
    case types.UPDATE_TEAM_REPORT:
    case types.DELETE_TEAM_REPORT:
    case types.ADD_MORE_TEAM_REPORTS:
    case types.REQUEST_TEAM_REPORTS:
      return {
        ...state,
        [`${action.teamId}`]: reports(state[`${action.teamId}`], action),
      };
    default:
      return state;
  }
};

const credentialsByTeam = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TEAM_CREDENTIALS:
    case types.ADD_TEAM_CREDENTIAL:
    case types.UPDATE_TEAM_CREDENTIAL:
    case types.DELETE_TEAM_CREDENTIAL:
    case types.ADD_MORE_TEAM_CREDENTIALS:
    case types.REQUEST_TEAM_CREDENTIALS:
      return {
        ...state,
        [action.teamId]: credentials(state[action.teamId], action),
      };
    default:
      return state;
  }
};

const dataSourcesByTeam = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TEAM_DATA_SOURCES:
    case types.ADD_TEAM_DATA_SOURCE:
    case types.UPDATE_TEAM_DATA_SOURCE:
    case types.DELETE_TEAM_DATA_SOURCE:
    case types.ADD_MORE_TEAM_DATA_SOURCES:
    case types.REQUEST_TEAM_DATA_SOURCES:
      return {
        ...state,
        [action.teamId]: dataSources(state[action.teamId], action),
      };
    default:
      return state;
  }
};

const widgetsByTeam = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TEAM_WIDGETS:
    case types.ADD_TEAM_WIDGET:
    case types.UPDATE_TEAM_WIDGET:
    case types.DELETE_TEAM_WIDGET:
    case types.ADD_MORE_TEAM_WIDGETS:
    case types.REQUEST_TEAM_WIDGETS:
      return {
        ...state,
        [action.teamId]: widgets(state[action.teamId], action),
      };
    default:
      return state;
  }
};

const templatesByTeam = (state = {}, action) => {
  switch (action.type) {
    case types.SET_TEAM_TEMPLATES:
    case types.ADD_TEAM_TEMPLATE:
    case types.UPDATE_TEAM_TEMPLATE:
    case types.DELETE_TEAM_TEMPLATE:
    case types.ADD_MORE_TEAM_TEMPLATES:
    case types.REQUEST_TEAM_TEMPLATES:
      return {
        ...state,
        [action.teamId]: templates(state[action.teamId], action),
      };
    default:
      return state;
  }
};

const userObject = (state = {}, action) => {
  switch (action.type) {
    case types.SET_USER_OBJECT:
      return action.userObject;
    default:
      return state;
  }
};

const currentAuthenticatedUser = (state = {}, action) => {
  switch (action.type) {
    case types.SET_CURRENT_AUTHENTICATED_USER:
      return action.currentAuthenticatedUser;
    default:
      return state;
  }
};

const userTeams = (state = [], action) => {
  switch (action.type) {
    case types.SET_USER_TEAMS:
      return action.userTeams;
    case types.ADD_USER_TEAM:
      return [...state, action.team];
    case types.DELETE_USER_TEAM:
      return state.filter((team) => team.id !== action.teamId);
    case types.ADD_TEAM_THEME:
      return state.map((team) =>
        team.id === action.teamId
          ? {
              ...team,
              themes: {
                ...team.themes,
                items: [...team.themes.items, action.theme],
              },
            }
          : team
      );
    case types.UPDATE_TEAM_THEME:
      return state.map((team) =>
        team.id === action.teamId
          ? {
              ...team,
              themes: {
                ...team.themes,
                items: team.themes.items.map((o) =>
                  o.id === action.theme.id ? action.theme : o
                ),
              },
            }
          : team
      );
    case types.UPDATE_USER_TEAM:
      return state.map((team) =>
        team.id === action.team.id ? { ...team, ...action.team } : team
      );
    default:
      return state;
  }
};

const templateSettings = (state = null, action) => {
  switch (action.type) {
    case types.SET_TEMPLATE_SETTINGS:
      return action.templateSettings;
    default:
      return state;
  }
};

const selectedReport = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_REPORT:
      return action.report;
    default:
      return state;
  }
};

const selectedChapter = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_CHAPTER:
      return action.chapter;
    default:
      return state;
  }
};

const selectedDataSource = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_DATA_SOURCE:
      return action.dataSource;
    default:
      return state;
  }
};

const selectedDataProvider = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_DATA_PROVIDER:
      return action.dataProvider;
    default:
      return state;
  }
};

const selectedTemplate = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TEMPLATE:
      return action.template;
    default:
      return state;
  }
};

const selectedTeam = (state = null, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TEAM:
      return action.team;
    default:
      return state;
  }
};

const teamInvites = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_TEAM_INVITES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.SET_TEAM_INVITES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.invites,
      };
    default:
      return state;
  }
};

const connectedDataSources = (state = [], action) => {
  switch (action.type) {
    case types.SET_CONNECTED_DATA_SOURCES:
      return action.connectedDataSources;
    default:
      return state;
  }
};

const connectedReports = (state = [], action) => {
  switch (action.type) {
    case types.SET_CONNECTED_REPORTS:
      return action.connectedReports;
    default:
      return state;
  }
};

const dialogs = (
  state = {
    showDeleteReportDialog: false,
    showUpdateReportDialog: false,
    showEditingRightsReportDialog: false,
    showEditReportDialog: false,
    showCreateReportDataSourceConnectionDialog: false,
    showTemplateSettingsDialog: false,
    showChangeCredentialForDataSourceDialog: false,
    showDeleteDataSourceDialog: false,
    showEditingRightsDataSourceDialog: false,
    showReConnectDataSourceDialog: false,
    showEditDataSourceDialog: false,
    showCreateDataSourceConnectionDialog: false,
    showCreateTeamDialog: false,
    showDeleteTeamDialog: false,
    showUpdateTeamDialog: false,
    showManageTeamMembersDialog: false,
    showDeleteTemplateDialog: false,
    showEditTemplateDialog: false,
    showUserTokenDialog: false,
    showUpdateDataSourceDisplayNameDialog: false,
    showGoogleDataStudioDialog: false,
    showDataApiDialog: false,
    showQueryComposerDialog: false,
    showPreCacheDialog: false,
    showMicrosoftTeamsDialog: false,
    showDataExplorerDialog: false,
    showAskDataHubDialog: false,
    showUsageDialog: false,
    showUpdateMaxRequestDialog: false,
    showWebsiteIntegrationDialog: false,
  },
  action
) => {
  switch (action.type) {
    case types.SHOW_DELETE_REPORT_DIALOG:
      return {
        ...state,
        showDeleteReportDialog: action.isVisible,
      };
    case types.SHOW_UPDATE_REPORT_DIALOG:
      return {
        ...state,
        showUpdateReportDialog: action.isVisible,
      };
    case types.SHOW_EDITING_RIGHTS_REPORT_DIALOG:
      return {
        ...state,
        showEditingRightsReportDialog: action.isVisible,
      };
    case types.SHOW_EDIT_REPORT_DIALOG:
      return {
        ...state,
        showEditReportDialog: action.isVisible,
      };
    case types.SHOW_CREATE_REPORT_DATA_SOURCE_CONNECTION_DIALOG:
      return {
        ...state,
        showCreateReportDataSourceConnectionDialog: action.isVisible,
      };
    case types.SHOW_TEMPLATE_SETTINGS_DIALOG:
      return {
        ...state,
        showTemplateSettingsDialog: action.isVisible,
      };

    case types.SHOW_DELETE_DATA_SOURCE_DIALOG:
      return {
        ...state,
        showDeleteDataSourceDialog: action.isVisible,
      };

    case types.SHOW_DELETE_TEMPLATE_DIALOG:
      return {
        ...state,
        showDeleteTemplateDialog: action.isVisible,
      };

    case types.SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG:
      return {
        ...state,
        showEditingRightsDataSourceDialog: action.isVisible,
      };

    case types.SHOW_GOOGLE_DATA_STUDIO_DIALOG:
      return {
        ...state,
        showGoogleDataStudioDialog: action.isVisible,
      };
    case types.SHOW_POWER_BI_DIALOG:
      return {
        ...state,
        showPowerBiDialog: action.isVisible,
      };
    case types.SHOW_DATA_API_DIALOG:
      return {
        ...state,
        showDataApiDialog: action.isVisible,
      };
    case types.SHOW_QUERY_COMPOSER_DIALOG:
      return {
        ...state,
        showQueryComposerDialog: action.isVisible,
      };
    case types.SHOW_PRE_CACHE_DIALOG:
      return {
        ...state,
        showPreCacheDialog: action.isVisible,
      };

    case types.SHOW_WEBSITE_INTEGRATION_DIALOG:
      return {
        ...state,
        showWebsiteIntegrationDialog: action.isVisible,
      };

    case types.SHOW_MICROSOFT_TEAMS_DIALOG:
      return {
        ...state,
        showMicrosoftTeamsDialog: action.isVisible,
      };
    case types.SHOW_DATA_EXPLORER_DIALOG:
      return {
        ...state,
        showDataExplorerDialog: action.isVisible,
      };

    case types.SHOW_ASK_DATA_HUB_DIALOG:
      return {
        ...state,
        showAskDataHubDialog: action.isVisible,
      };

    case types.SHOW_RE_CONNECT_DATA_SOURCE_DIALOG:
      return {
        ...state,
        showReConnectDataSourceDialog: action.isVisible,
        selectedDataSource: action.dataSource,
      };

    case types.SHOW_EDIT_DATA_SOURCE_DIALOG:
      return {
        ...state,
        showEditDataSourceDialog: action.isVisible,
      };

    case types.SHOW_EDIT_TEMPLATE_DIALOG:
      return {
        ...state,
        showEditTemplateDialog: action.isVisible,
      };

    case types.SHOW_CREATE_DATA_SOURCE_CONNECTION_DIALOG:
      return {
        ...state,
        showCreateDataSourceConnectionDialog: action.isVisible,
      };

    case types.SHOW_CREATE_TEAM_DIALOG:
      return {
        ...state,
        showCreateTeamDialog: action.isVisible,
      };

    case types.SHOW_DELETE_TEAM_DIALOG:
      return {
        ...state,
        showDeleteTeamDialog: action.isVisible,
      };

    case types.SHOW_UPDATE_TEAM_DIALOG:
      return {
        ...state,
        showUpdateTeamDialog: action.isVisible,
      };

    case types.SHOW_UPDATE_DATA_SOURCE_DISPLAY_NAME:
      return {
        ...state,
        showUpdateDataSourceDisplayNameDialog: action.isVisible,
      };

    case types.SHOW_UPDATE_MAX_REQUEST:
      return {
        ...state,
        showUpdateMaxRequestDialog: action.isVisible,
      };

    case types.SHOW_USAGE:
      return {
        ...state,
        showUsageDialog: action.isVisible,
      };

    case types.SHOW_UPDATE_COLOR_THEME_DIALOG:
      return {
        ...state,
        showUpdateColorThemeDialog: action.isVisible,
      };

    case types.SHOW_MANAGE_TEAM_MEMBERS_DIALOG:
      return {
        ...state,
        showManageTeamMembersDialog: action.isVisible,
      };

    case types.SHOW_CHANGE_CREDENTIAL_FOR_DATA_SOURCE_DIALOG:
      return {
        ...state,
        showChangeCredentialForDataSourceDialog: action.isVisible,
      };
    case types.SHOW_USER_TOKEN_DIALOG:
      return {
        ...state,
        showUserTokenDialog: action.isVisible,
      };
    default:
      return state;
  }
};

const config = (
  state = {
    isFetching: false,
    didInvalidate: false,
    config: {},
  },
  action
) => {
  switch (action.type) {
    case types.SET_DATA_PROVIDER_CONFIG:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,

        config: action.config,
      };

    case types.REQUEST_DATA_PROVIDER_CONFIG:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    default:
      return state;
  }
};

const configByDataProvider = (state = {}, action) => {
  switch (action.type) {
    case types.SET_DATA_PROVIDER_CONFIG:
    case types.REQUEST_DATA_PROVIDER_CONFIG:
      return {
        ...state,
        [`${action.dataProvider}`]: config(
          state[`${action.dataProvider}`],
          action
        ),
      };
    default:
      return state;
  }
};

const dataProviderSettings = (
  state = {
    availableDataProviders: [],
    allDataProviders: {},
    config: {},
  },
  action
) => {
  switch (action.type) {
    case types.SET_DATA_PROVIDER_SETTINGS:
      return action.settings;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  dataProviderSettings,
  configByDataProvider,
  dialogs,
  teamInvites,
  connectedDataSources,
  connectedReports,
  selectedTemplate,
  selectedReport,
  selectedChapter,
  selectedDataSource,
  selectedDataProvider,
  selectedTeam,
  templateSettings,
  errorMessage,
  currentAuthenticatedUser,
  selectedUserTeam,
  userObject,
  userTeams,
  reportsByTeam,
  credentialsByTeam,
  dataSourcesByTeam,
  widgetsByTeam,
  templatesByTeam,
});

const rootReducer = (state, action) => {
  if (action.type === types.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
