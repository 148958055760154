/* eslint-disable no-unused-vars */
import { Link } from '@mui/material';
import React from 'react';
import Linkify from 'react-linkify';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';

function LinkText({
  id,
  config,
  dependsOn,
  configParams,
  selectedCredential,
  updateConfig,
  stepIndex,
}) {
  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  const nameTemplate = config.template;

  let name = '';
  if (nameTemplate) {
    name = nameTemplate.replace(
      /\{\{(([#]{1}?)\w+([.]{1}?)(\w+?))\}\}/g,
      (all, one) => {
        let cId = one.split('.')[0];
        if (cId.startsWith('#')) {
          cId = cId.substring(1);
        }
        let key;
        if (one.split('.').length === 2) {
          // eslint-disable-next-line prefer-destructuring
          key = one.split('.')[1];
        }
        if (cId === 'CREDENTIAL') {
          return selectedCredential[key];
        }
        if (configParams?.[cId]?.[key]) {
          return configParams?.[cId]?.[key];
        }
        return all;
      }
    );
  }

  return (
    <>
      <Linkify
        // eslint-disable-next-line react/no-unstable-nested-components
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <Link
            color="secondary"
            href={decoratedHref}
            key={key}
            onClick={() => {
              updateConfig(stepIndex, id, selectedCredential);
            }}
            target="_blank"
          >
            {decoratedText}
          </Link>
        )}
      >
        {name}
      </Linkify>
    </>
  );
}

export default LinkText;
