import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql-custom/queries';

async function getAllLabels(
  teamId,
  nextToken = undefined,
  limit = 1000,
  filter
) {
  const inputQuery = {
    id: teamId,
    limit,
    nextToken,
  };

  if (filter) {
    inputQuery.filter = filter;
  }

  const labelsQueryObject = await API.graphql(
    graphqlOperation(queries.getTeamLabels, inputQuery)
  );

  const dataSourceLabels =
    labelsQueryObject.data.getTeam.dataSourceLabels.items;
  const nextTokenBack =
    labelsQueryObject.data.getTeam.dataSourceLabels.nextToken;

  return { dataSourceLabels, nextToken: nextTokenBack };
}

export default getAllLabels;
