import { useAuthenticator } from '@aws-amplify/ui-react';
import NoteAdd from '@mui/icons-material/Add';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Fade,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import { darken, styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import dataSetIcon from '../data-set-icon.svg';
import dataSourceIcon from '../data-source-icon.svg';
import RecentSwitcher from './RecentSwitcher';

const CreateButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#e0257b'),
    textTransform: 'none',
    backgroundColor: '#e0257b',
    '&:hover': {
      backgroundColor: darken('#e0257b', 0.5),
    },
  },
}))(Button);

// eslint-disable-next-line no-unused-vars
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    // border: `1px solid ${
    //   theme.palette.mode === 'dark'
    //     ? theme.palette.grey[600]
    //     : theme.palette.grey[300]
    // }`,
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',

    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      // borderRadius: theme.shape.borderRadius,
    },
  },
}));

function NavigationToggleButtonGroup() {
  const { user } = useAuthenticator((context) => [context.user]);
  // const { search, pathname } = useLocation();
  const navigate = useNavigate();
  // const { path } = useRouteMatch();
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const match1 = useMatch('/workspace/:workspaceId/:part/t/:tId');
  const match2 = useMatch('/workspace/:workspaceId/:part');

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];
  const isAfasUser =
    currentAuthenticatedUser.attributes['custom:type'] === 'AFAS';

  let valueToggle = 'data-sources';

  if (match1?.params?.part) {
    valueToggle = match1?.params?.part;
  }

  if (match2?.params?.part) {
    valueToggle = match2.params?.part;
  }

  // eslint-disable-next-line no-unused-vars
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      navigate(`/workspace/${selectedUserTeam.split('-')[0]}/${newAlignment}`);
    }
  };

  const [recentAnchorEl, setRecentAnchorEl] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const userType =
    user?.signInUserSession?.idToken?.payload?.user_type || 'general';

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        margin="13px 0px"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" spacing={2}>
            <Box>
              {groups.includes('gladior') || groups.includes('Template') ? (
                <Paper elevation={0}>
                  <StyledToggleButtonGroup
                    aria-label="text alignment"
                    exclusive
                    onChange={handleAlignment}
                    size="small"
                    value={valueToggle}
                  >
                    <ToggleButton value="data-sources">
                      Data Sources
                    </ToggleButton>
                    <ToggleButton value="templates">Templates</ToggleButton>
                  </StyledToggleButtonGroup>
                </Paper>
              ) : (
                <Box></Box>
              )}
            </Box>
            <Box>
              <Popper
                anchorEl={recentAnchorEl}
                id={recentAnchorEl ? 'recent-popper' : undefined}
                open={Boolean(recentAnchorEl)}
                placement="bottom-start"
                transition
              >
                {({ TransitionProps }) => (
                  <ClickAwayListener
                    onClickAway={() => {
                      setRecentAnchorEl(null);
                    }}
                  >
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper elevation={5}>
                        <RecentSwitcher />
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </Popper>
            </Box>
          </Stack>

          <Box display="flex" flexDirection="row">
            {groups.includes('gladior') ||
            groups.includes('Template') ||
            isAfasUser ? (
              <PopupState popupId="demo-popup-menu" variant="popover">
                {(popupState) => (
                  <>
                    <CreateButton
                      color="secondary"
                      disableElevation
                      size="medium"
                      startIcon={<NoteAdd />}
                      variant="contained"
                      {...bindTrigger(popupState)}
                    >
                      Create
                    </CreateButton>

                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          navigate(`data-sources/create/data-source`);
                          popupState.close();
                        }}
                      >
                        <Box mr={2}>
                          <Avatar
                            alt=""
                            src={dataSourceIcon}
                            sx={(theme) => ({
                              width: theme.spacing(3),
                              height: theme.spacing(3),
                            })}
                            variant="square"
                          />
                        </Box>
                        Data Source
                      </MenuItem>
                      {/* {(isAfasUser || groups.includes('gladior')) && (
                        <MenuItem
                          onClick={() => {
                            navigate(`data-sources/create/integration`);
                            popupState.close();
                          }}
                        >
                          <Box mr={2}>
                            <Avatar
                              alt=""
                              src={integrationIcon}
                              sx={(theme) => ({
                                width: theme.spacing(3),
                                height: theme.spacing(3),
                              })}
                              variant="square"
                            />
                          </Box>
                          Integration
                        </MenuItem>
                      )} */}
                      {(groups.includes('gladior') ||
                        groups.includes('Template')) && (
                        <MenuItem
                          onClick={() => {
                            navigate(`templates/create/template`);
                            popupState.close();
                          }}
                        >
                          <Box mr={2}>
                            <Avatar
                              alt=""
                              src={dataSetIcon}
                              sx={(theme) => ({
                                width: theme.spacing(3),
                                height: theme.spacing(3),
                              })}
                              variant="square"
                            />
                          </Box>
                          Template
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </PopupState>
            ) : (
              <CreateButton
                color="secondary"
                disableElevation
                onClick={() => {
                  navigate(`data-sources/create/data-source`);
                }}
                size="medium"
                startIcon={<NoteAdd />}
                variant="contained"
              >
                Create
              </CreateButton>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NavigationToggleButtonGroup;
