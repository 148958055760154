/* eslint-disable camelcase */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import _find from 'lodash/find';
import React from 'react';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';

function StaticDropdown({
  id,
  stepIndex,
  dependsOn,
  config,
  configParams,
  updateConfig,
}) {
  const { label, options } = config;

  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  return (
    <>
      <FormControl fullWidth variant="filled">
        <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
        <Select
          id={id}
          label={label}
          labelId={`${id}-select-label`}
          onChange={(event) => {
            let name = '';

            const selectedDP = _find(options, {
              value: event.target.value,
            });

            if (selectedDP) {
              name = selectedDP.title;
            }
            updateConfig(stepIndex, id, {
              id: event.target.value,
              name,
              value: event.target.value,
            });
          }}
          value={configParams[id] ? configParams[id].value : ''}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default StaticDropdown;
