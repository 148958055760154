import { Hidden } from '@mui/material';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import MFADialog from './MFADialog';
import UserAvatar from './UserAvatar';
import UserAvatarDialog from './UserAvatarDialog';

const useStyles = makeStyles((theme) => ({
  adminButton: {
    textTransform: 'none',
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[300]
    }`,

    color: theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
    background:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#FFF',
  },
}));

function UserProfileMenu() {
  const classes = useStyles();
  const navigate = useNavigate();

  // redux selectors
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const userObject = useSelector((state) => state.userObject);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMFADialog, setOpenMFADialog] = useState(false);

  if (!currentAuthenticatedUser.attributes) {
    return null;
  }

  return (
    <>
      <UserAvatarDialog
        handleClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
      />
      <MFADialog
        open={openMFADialog}
        handleClose={() => {
          setOpenMFADialog(false);
        }}
      ></MFADialog>
      <Chip
        avatar={
          <UserAvatar
            avatar={userObject.avatar}
            name={currentAuthenticatedUser.attributes.name}
            size="small"
          />
        }
        className={classes.adminButton}
        label={
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Hidden lgDown>
              <span>
                {ellipsis(currentAuthenticatedUser.attributes.name, 45)}
              </span>
            </Hidden>
            <SvgIcon>
              <path d="M7 10l5 5 5-5z" />
            </SvgIcon>
          </span>
        }
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        variant="outlined"
      />
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        keepMounted
        onClose={() => {
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Edit profile
        </MenuItem>
        {currentAuthenticatedUser?.attributes?.sub ===
          currentAuthenticatedUser?.username && (
          <MenuItem
            onClick={() => {
              setOpenMFADialog(true);
            }}
          >
            Configure MFA
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            Auth.signOut()
              .then(() => navigate('/auth'))
              .catch(() => navigate('/auth'));
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserProfileMenu;
