import querystring from 'querystring';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function UsercallbackRoute() {
  const location = useLocation();

  const queryStringObject = querystring.parse(location.search.slice(1));

  let { state } = queryStringObject;

  state = decodeURI(state);
  if (state.startsWith('"')) {
    state = state.slice(1);
  }
  if (state.endsWith('"')) {
    state = state.slice(0, -1);
  }
  state = JSON.parse(state);
  const { dataProvider, workspaceId, mode, redirectUri } = state;

  let baseUrl = 'https://datahubapp.gladior.com';

  if (mode && mode === 'local') {
    baseUrl = 'http://localhost:3000';
  } else if (mode && mode === 'staging') {
    baseUrl = 'https://data-hub-staging.gladior.com';
  }

  const urlR = `${baseUrl}/workspace/${
    workspaceId.split('-')[0]
  }/auth/${dataProvider.toLowerCase()}?${querystring.stringify({
    ...queryStringObject,
    redirectUri: redirectUri || '',
  })}`;

  useEffect(() => {
    window.location.href = urlR;
  }, [urlR]);

  return <></>;
}

export default UsercallbackRoute;
