import { API, Auth } from 'aws-amplify';

export default async function signInCallback(
  authResult,
  dataProviders,
  clientId,
  selectedUserTeam,
  dpConf,
  queryStringObject = {}
) {
  if (authResult.code) {
    const { route } = dpConf;
    const tokens = await Auth.currentSession();
    let state = {};
    if (queryStringObject.state) {
      state = queryStringObject.state;

      state = decodeURIComponent(state);
      if (state.startsWith('"')) {
        state = state.slice(1);
      }
      if (state.endsWith('"')) {
        state = state.slice(0, -1);
      }
      state = JSON.parse(state);
    }
    const response = await API.post(
      'DataProviderApi',
      `/${route}/credential/`,
      {
        body: {
          ...queryStringObject,
          workspaceId: selectedUserTeam,
          state,
          code: authResult.code,
          dataProviders,
          clientId,
        },
        headers: {
          Authorization: `${tokens.getIdToken().getJwtToken()}`,
        },
        response: true,
        queryStringParameters: {},
      }
    );

    return response.data;
  }
  throw new Error('node code found');
}
