function shouldFetchDataSources(
  dataSourcesByTeam,
  selectedUserTeam,
  q,
  dataProvider
) {
  if (!dataSourcesByTeam) {
    return true;
  }

  if (!selectedUserTeam) {
    return false;
  }

  if (!dataSourcesByTeam[selectedUserTeam]) {
    return true;
  }

  if (dataSourcesByTeam[selectedUserTeam].isFetching === true) {
    return false;
  }

  if (dataSourcesByTeam[selectedUserTeam].q !== q) {
    return true;
  }

  if (dataSourcesByTeam[selectedUserTeam].dataProvider !== dataProvider) {
    return true;
  }

  if (dataSourcesByTeam[selectedUserTeam].movedCount > 0) {
    if (
      dataSourcesByTeam[selectedUserTeam].items.length -
        dataSourcesByTeam[selectedUserTeam].movedCount <
      1
    ) {
      return true;
    }
  }

  if (dataSourcesByTeam[selectedUserTeam].items.length > 0) {
    return false;
  }

  return true;
}

export default shouldFetchDataSources;
