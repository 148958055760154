import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql-custom/mutations';

async function updateTeamObject(id, name) {
  const input = {};

  if (id) {
    input.id = id;
  }

  if (name) {
    input.name = name;
  }

  // Create Team Object
  const updateTeamObjectObj = await API.graphql(
    graphqlOperation(mutations.updateTeam, {
      input,
    })
  );

  return updateTeamObjectObj.data.updateTeam;
}

export default updateTeamObject;
