import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import React from 'react';

function SelectButton({ invite, checked }) {
  return (
    <Tooltip title={invite.name}>
      <Box p={1}>
        {checked && <RadioButtonChecked color="secondary" />}
        {!checked && <RadioButtonUnchecked color="action" />}
      </Box>
    </Tooltip>
  );
}

export default SelectButton;
