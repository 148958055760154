import { MarkEmailRead } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';

import { useState } from 'react';

const backendEndpoint =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data-hub-api.dh.gladior.com'
    : 'https://data-hub-api-dev.dh.gladior.com';

async function sendMagicLink(email) {
  return fetch(`${backendEndpoint}/magic-link`, {
    method: 'POST',
    body: JSON.stringify({ email, location: window.location.href }),
  });
}

export default function MagicLink({ open, handleclose }) {
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState('mail');

  return (
    <Dialog onClose={handleclose} open={open}>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '400px',
        }}
      >
        {tab === 'mail' && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                }}
                variant="h6"
              >
                Sign in with magic link
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  mb: 1,
                  display: 'block',
                }}
                variant="caption"
              >
                Enter your email to receive a link for access.
              </Typography>

              <TextField
                autoComplete="username"
                fullWidth
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                sx={{
                  mt: 1,
                }}
                type="email"
                value={email}
              ></TextField>
            </Box>
            <Button
              color="secondary"
              disabled={!email}
              fullWidth
              onClick={() => {
                setTab('sent');
                sendMagicLink(email);
                setEmail('');
              }}
              sx={{
                mt: 3,
              }}
              variant="contained"
            >
              Send magic link
            </Button>
          </Box>
        )}
        {tab === 'sent' && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1,
                width: '100%',
              }}
            >
              <MarkEmailRead color="success"></MarkEmailRead>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  display: 'inline',
                }}
                variant="h6"
              >
                Magic Link Sent
              </Typography>
            </Box>
            <Typography>
              We've sent a magic link to your email address. Please check your
              inbox and click the link to sign in.
            </Typography>
            <Box
              sx={{
                textAlign: 'center',
                my: 4,
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  opacity: 0.8,
                }}
                variant="caption"
              >
                Didn't receive the email? Check your spam folder.
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setTab('mail');
                }}
                variant="contained"
              >
                Send another link
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
