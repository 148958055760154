/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { ArrowBack, InfoOutlined, Language } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { API, Auth } from 'aws-amplify';
import clsx from 'clsx';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import Linkify from 'react-linkify';

import _find from 'lodash/find';

import { batch, useDispatch, useSelector } from 'react-redux';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import getAllCredentials from '../../api/getAllCredentials';
import updateDataSourceObject from '../../api/updateDataSourceObject';

import * as types from '../../constants/ActionTypes';
import usePrefetchDataProviderData from '../../hooks/usePrefetchDataProviderData';
import getHostName from '../../utilities/getHostName';
import shouldFetchCredentials from '../../utilities/shouldFetchCredentials';
import CredentialPicker from './CredentialPicker';
import DataProviderPicker from './DataProviderPicker';
import DataProviderSelectors from './DataProviderSelectors';

const useStyles = makeStyles(() => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  dialogPaper: {},
}));

function CreateNewDataSourceDialog({ integration = false }) {
  const { workspaceId } = useParams();
  const createButtonRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const dataProvider = searchParams.get('dataProvider');
  const dataSourceToConnect = searchParams.get('dataSourceToConnect');
  const credentialId = searchParams.get('credentialId');

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const classes = useStyles();

  let dataProviderToConnectParam = dataProvider
    ? dataProvider.toUpperCase()
    : '';

  if (dataSourceToConnect && !dataProvider) {
    dataProviderToConnectParam = dataSourceToConnect.toUpperCase();
  }

  // use state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [createStatus, setCreateStatus] = useState(false);

  const [configParams, setConfigParams] = useState({});

  const { prefetch } = usePrefetchDataProviderData();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedDP, setSelectedDP] = useState(dataProviderToConnectParam);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [potentialLabels, setPotentialLabels] = useState([]);
  const [shouldCreateLabels, setShouldCreateLabels] = useState(true);

  // redux

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);
  const { items: credentials2 } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };
  const { items: dataSources } = dataSourcesByTeam[selectedUserTeam] || {
    items: [],
  };

  function closeDialog() {
    searchParams.delete('dataProvider');
    searchParams.delete('dataSourceToConnect');
    searchParams.delete('credentialId');

    navigate({
      pathname: `/workspace/${workspaceId}/data-sources`,
      search: searchParams.toString(),
    });
  }

  useEffect(() => {
    if (dataProviderToConnectParam && !credentialId) {
      setActiveStep(1);
    }

    if (dataProviderToConnectParam && credentialId) {
      const foundCredential = _find(credentials2, { id: credentialId });

      if (
        foundCredential &&
        !foundCredential.dataProvider.includes(selectedDP)
      ) {
        searchParams.delete('credentialId');
        setSearchParams(searchParams);
      } else if (credentials2.length > 0 && foundCredential) {
        setSelectedCredential(foundCredential);
        setActiveStep(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProviderToConnectParam, credentialId, credentials2]);

  useEffect(() => {
    if (activeStep < 3) {
      setShouldCreateLabels(true);
      setPotentialLabels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    async function fetchALlCredentials() {
      if (shouldFetchCredentials(credentialsByTeam, selectedUserTeam)) {
        dispatch({
          type: types.REQUEST_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
        });
        const { credentials } = await getAllCredentials(
          selectedUserTeam,
          undefined
        );
        dispatch({
          type: types.SET_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
          credentials,
          nextToken: undefined,
        });
      }
    }

    fetchALlCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam]);

  useEffect(() => {
    const configSettings = dataProviderSettings.config[selectedDP];

    if (configSettings) {
      prefetch(`/${configSettings.route}/config/`, {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDP, dataProviderSettings]);

  function setDynamicStatus(status, configParamsIds, url) {
    if (configParamsIds) {
      setConfigParams(configParamsIds);
    }

    if (url) {
      setPotentialLabels([{ value: getHostName(url) }]);
    } else {
      setPotentialLabels([]);
    }

    setCreateStatus(status);
  }

  async function createNewDataSource() {
    // const newDataSourceItems = [];

    setIsSubmitting(true);

    const { route } = dataProviderSettings.config[selectedDP];

    const tokens = await Auth.currentSession();
    try {
      const response = await API.post(
        'DataProviderApi',
        `/${route}/data-source/`,
        {
          body: {
            configParams,
          },
          headers: {
            Authorization: `${tokens.getIdToken().getJwtToken()}`,
          },
          response: true,
          queryStringParameters: {
            credentialId: selectedCredential.id,
          },
        }
      );

      const dataSourceId = response.data.id;

      const foundDataSource = _find(dataSources, { id: dataSourceId });

      if (foundDataSource) {
        enqueueSnackbar(`Data Source already exists`, {
          variant: 'info',
          key: 'aleady_exists_data_source',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } else {
        // get the latest version with also the correct label
        const updatedDataSource = await updateDataSourceObject({
          id: dataSourceId,
        });

        batch(() => {
          dispatch({
            type: types.ADD_TEAM_DATA_SOURCE,
            dataSource: { ...updatedDataSource, status: 'new' },
            movedCount: 1,
            teamId: updatedDataSource.team.id,
          });
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsSubmitting(false);
    setSuccess(true);
    setTimeout(() => {
      setActiveStep(0);
      setSelectedDP('');
      setSelectedCredential(null);
      searchParams.delete('dataProvider');
      searchParams.delete('dataSourceToConnect');
      searchParams.delete('credentialId');
      setSearchParams(searchParams);

      closeDialog();
    }, 500);
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Dialog
      PaperProps={{ classes: { root: classes.dialogPaper } }}
      fullWidth
      maxWidth={activeStep < 2 ? 'sm' : 'md'}
      onClose={closeDialog}
      open
    >
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              {activeStep > 0 && (
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    if (activeStep === 2) {
                      searchParams.delete('credentialId');
                      setSearchParams(searchParams);
                    }

                    if (activeStep === 1) {
                      searchParams.delete('dataProvider');
                      searchParams.delete('dataSourceToConnect');
                      setSearchParams(searchParams);
                    }

                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
              )}
            </Box>

            <Typography align="center" color="inherit" variant="h6">
              {activeStep === 0 && 'Create a new Data Source'}
              {(activeStep === 1 || activeStep === 2) && (
                <Box
                  component={'span'}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={dataProviderSettings.config[selectedDP].image}
                    sx={{ width: 25, height: 25, mr: 1 }}
                    title={dataProviderSettings.config[selectedDP].name}
                    variant="rounded"
                  />
                  {dataProviderSettings.config[selectedDP].name}{' '}
                </Box>
              )}
            </Typography>
            {activeStep === 1 &&
            dataProviderSettings.config[selectedDP].howToConnect ? (
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <IconButton
                      {...bindTrigger(popupState)}
                      color="info"
                      size="large"
                    >
                      <InfoOutlined />
                    </IconButton>

                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box maxWidth="400px" p={2}>
                        <Typography variant="caption">
                          <Linkify>
                            {' '}
                            {
                              dataProviderSettings.config[selectedDP]
                                .howToConnect
                            }
                          </Linkify>
                        </Typography>
                      </Box>
                    </Popover>
                  </>
                )}
              </PopupState>
            ) : (
              <Box />
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {activeStep === 0 && (
        <DataProviderPicker
          setActiveConnectStep={setActiveStep}
          setSelectedDataProvider={(selectDataPr) => {
            searchParams.set('dataProvider', selectDataPr);
            setSearchParams(searchParams);
            setSelectedCredential(null);
            setSelectedDP(selectDataPr);
          }}
          integration={integration}
        />
      )}

      <>
        {activeStep === 1 && (
          <CredentialPicker
            credentialSelected={() => {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }}
            selectedDataProvider={selectedDP}
            setSelectedCredential={(selecCrede) => {
              searchParams.set('credentialId', selecCrede.id);
              setSearchParams(searchParams);
              setSelectedCredential(selecCrede);
            }}
          />
        )}

        {activeStep === 2 && (
          <DataProviderSelectors
            dataProvider={selectedDP}
            selectedCredential={selectedCredential}
            setDynamicStatus={setDynamicStatus}
          />
        )}
      </>

      {potentialLabels.length > 0 && (
        <Box sx={{ m: 0, mt: 2, py: 2, width: '100%' }}>
          <FormGroup row style={{ justifyContent: 'flex-end' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldCreateLabels}
                  onChange={(event) => {
                    setShouldCreateLabels(event.target.checked);
                  }}
                  value="shouldCreateLabels"
                />
              }
              label={
                <Box component="span" display="flex" flexDirection="row">
                  <Box component="span" lineHeight="32px">
                    Add the label:
                  </Box>
                  <Chip
                    icon={
                      <Avatar
                        sx={(theme) => ({
                          width: theme.spacing(3),
                          height: theme.spacing(3),
                          backgroundColor: '#fff',
                          border: `1px solid ${theme.palette.action.disabled}`,
                        })}
                      >
                        <Language color="secondary" fontSize="small" />
                      </Avatar>
                    }
                    label={potentialLabels[0].value}
                    sx={{ ml: 1 }}
                    variant="outlined"
                  />
                </Box>
              }
              // sx={{ mr: 0 }}
            />
          </FormGroup>
        </Box>
      )}

      <DialogActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          py: 2,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            className={buttonClassname}
            color="info"
            disabled={!createStatus || activeStep !== 2}
            onClick={createNewDataSource}
            ref={createButtonRef}
            variant="contained"
          >
            {success && <Icon>check</Icon>}
            {isSubmitting === true ? (
              <CircularProgress size={24} sx={{ color: green[500] }} />
            ) : (
              'Create'
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewDataSourceDialog;
