import { Box, Drawer, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import MessageEditor from '../data/queryComposer/textEditor/messageEditor';

export default function EditorPanel({ open, setOpen, node, setNode }) {
  const [name, setName] = useState(node?.name || '');
  useEffect(() => {
    setName(node?.name || '');
  }, [node]);
  return (
    <Drawer
      anchor="right"
      onClose={() => {
        setNode({ ...node, name });
        setOpen(false);
      }}
      open={open}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          onChange={(val) => {
            setName(val.target.value);
          }}
          value={name}
        >
          Name
        </TextField>
        {/* <Setting></Setting> */}
        <MessageEditor
          onChange={() => {}}
          small={true}
          fields={[
            {
              id: 'deal_id',
              name: 'Deal id',
              description: '',
              type: 'TEXT',
              conceptType: 'DIMENSION',
              group: 'Deals',
            },
            {
              id: 'deal_title',
              name: 'Title',
              description: '',
              type: 'TEXT',
              conceptType: 'DIMENSION',
              group: 'Deals',
            },
            {
              id: 'deal_summary',
              name: 'Summary',
              description: '',
              type: 'TEXT',
              conceptType: 'DIMENSION',
              group: 'Deals',
            },
          ]}
        ></MessageEditor>
      </Box>
    </Drawer>
  );
}
