/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import { MobileOff, ViewListTwoTone } from '@mui/icons-material';
import {
  Dialog,
  Paper,
  TablePagination,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import _filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Outlet } from 'react-router-dom';
import { createSelector } from 'reselect';

import * as types from '../../constants/ActionTypes';
import useQuery from '../../hooks/useQuery';
import fetchAllDataSources from '../../utilities/fetchAllDataSources';
import fetchAllTemplates from '../../utilities/fetchAllTemplates';
import shouldFetchDataSources from '../../utilities/shouldFetchDataSources';
import shouldFetchTemplates from '../../utilities/shouldFetchTemplates';
import DataSourceTableRow from './DataSourceTableRow';
import DataTableHead from './DataTableHead';
import NewInDataHubDialog from './NewInDataHubDialog';

const selectDataSources = createSelector(
  (state) => state,
  (_, settings) => settings,
  (state, settings) => {
    const { labels, selectedUserTeam, credentials } = settings;
    const {
      isFetching,
      items: dataSources,
      nextToken: initialNextToken,
    } = state.dataSourcesByTeam[selectedUserTeam] || {
      isFetching: true,
      items: [],
      nextToken: undefined,
    };

    let filteredDataSource = dataSources;

    if (labels) {
      filteredDataSource = _filter(filteredDataSource, (o) => {
        if (o?.labels?.items && o.labels.items.length > 0) {
          return o?.labels?.items?.[0]?.label.id === labels;
        }
        return false;
      });
    }

    if (credentials) {
      filteredDataSource = _filter(filteredDataSource, (o) => {
        if (o?.credential?.id) {
          return o?.credential?.id === credentials;
        }
        return false;
      });
    }

    return {
      isFetching,
      dataSources: filteredDataSource,
      initialNextToken,
    };
  }
);

function DataSourceOverviewList() {
  const dispatch = useDispatch();

  const query = useQuery();
  const q = query.get('q') ? query.get('q') : '';
  const labels = query.get('labels') ? query.get('labels') : '';
  const credentials = query.get('credentials') ? query.get('credentials') : '';
  const connectors = query.get('connectors') ? query.get('connectors') : '';

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  // redux selectors
  const userObject = useSelector((state) => state.userObject);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);
  const templatesByTeam = useSelector((state) => state.templatesByTeam);
  const userTeams = useSelector((state) => state.userTeams);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  // local state
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openChapterMenuId, setOpenChapterMenuId] = useState(-1);

  const [renderPromise, setRenderPromsie] = useState({
    resolve: () => {},
    reject: () => {},
  });

  const { isFetching, dataSources } = useSelector((state) =>
    selectDataSources(state, { labels, credentials, selectedUserTeam })
  );

  useEffect(() => {
    async function fetchTeamDataSources() {
      const dataProvider =
        connectors && dataProviderSettings.config[connectors] ? connectors : '';
      if (
        shouldFetchDataSources(
          dataSourcesByTeam,
          selectedUserTeam,
          q,
          dataProvider
        )
      ) {
        batch(() => {
          dispatch({
            type: types.REQUEST_TEAM_DATA_SOURCES,
            teamId: selectedUserTeam,
          });
        });
        let allDatasources = [];
        let nextToken;

        const rPromise = new Promise((resolve, reject) => {
          setRenderPromsie({ resolve, reject });
        });
        renderPromise.reject();
        while (nextToken !== null) {
          const { newDataSources, newNextToken, useSources } =
            //  { credentials }
            await Promise.race([
              fetchAllDataSources(
                selectedUserTeam,
                nextToken,
                q,
                dataProvider,
                100
              ),
              rPromise.catch(() => ({
                useSources: false,
                newNextToken: null,
                newDataSources: [],
              })),
              // getAllCredentials(selectedUserTeam, undefined),
            ]);
          allDatasources = [...allDatasources, ...newDataSources];
          nextToken = newNextToken;
          if (useSources !== false) {
            batch(() => {
              dispatch({
                type: types.SET_TEAM_DATA_SOURCES,
                teamId: selectedUserTeam,
                q,
                dataProvider,
                dataSources: allDatasources,
                nextToken: newNextToken,
              });
            });
          }
        }
      }
    }

    async function fetchTeamTemplates() {
      if (shouldFetchTemplates(templatesByTeam, selectedUserTeam, q)) {
        batch(() => {
          dispatch({
            type: types.REQUEST_TEAM_TEMPLATES,
            teamId: selectedUserTeam,
          });
        });

        const { newTemplates, newNextToken } = await fetchAllTemplates();

        const filteredTemplates = _filter(
          newTemplates,
          (o) =>
            o.groups.includes(selectedUserTeam) || o.groups.includes('public')
        );

        batch(() => {
          dispatch({
            type: types.SET_TEAM_TEMPLATES,
            teamId: selectedUserTeam,
            q,
            templates: filteredTemplates,
            nextToken: newNextToken,
          });
        });
      }
    }

    if (selectedUserTeam) {
      fetchTeamDataSources();
      fetchTeamTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam, q, connectors]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = React.useMemo(
    () =>
      dataSources.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, dataSources]
  );

  useEffect(() => {
    setPage(0);
  }, [dataSources]);

  if (isFetching) {
    return null;
  }

  if (process.env.REACT_APP_USER_BRANCH === 'prod') {
    if (matches === true) {
      return (
        <Dialog fullScreen open>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="216px"
            justifyContent="center"
          >
            <Box display="flex" justifyContent="center">
              <MobileOff color="disabled" fontSize="large" />
            </Box>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              No Mobile Version yet
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              display="block"
              gutterBottom
              variant="caption"
            >
              Mobile Version will be available at later date
            </Typography>
          </Box>
        </Dialog>
      );
    }
  }

  if (dataSources.length === 0) {
    return (
      <>
        <Outlet />
        <NewInDataHubDialog />
        <Box
          alignContent="center"
          display="flex"
          flexDirection="column"
          height="100vh"
          justifyContent="center"
          width="100%"
        >
          <Box>
            <Box display="flex" justifyContent="center">
              <ViewListTwoTone color="disabled" fontSize="large" />
            </Box>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              No data sources found
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              display="block"
              gutterBottom
              variant="caption"
            >
              Create a new data source
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Outlet />
      <NewInDataHubDialog />
      <Paper>
        <Table key="table-key" size="small">
          <DataTableHead />
          <TableBody>
            {visibleRows.map((item) => (
              <React.Fragment key={item.id}>
                <DataSourceTableRow
                  hover
                  item={item}
                  menuAnchorEl={menuAnchorEl}
                  openChapterMenuId={openChapterMenuId}
                  setMenuAnchorEl={setMenuAnchorEl}
                  setOpenChapterMenuId={setOpenChapterMenuId}
                  userObject={userObject}
                  userTeams={userTeams}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={dataSources.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
          showFirstButton={true}
          showLastButton={true}
        />
      </Paper>
    </>
  );
}

export default DataSourceOverviewList;
