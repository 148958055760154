/* eslint-disable consistent-return */
// eslint-disable-next-line import/no-extraneous-dependencies
import { parse, print, visit } from 'graphql';

const filterGraphQLFields = (definition, filter) => {
  const ast = parse(definition);
  const names = [];
  const editedAST = visit(ast, {
    Field: {
      enter(node) {
        names.push(node.name.value);
        const joinedNames = names.join('.');
        if (!filter(joinedNames)) {
          names.pop();
          return null;
        }
      },
      leave() {
        names.pop();
      },
    },
  });
  return print(editedAST);
};

export default filterGraphQLFields;
