/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Language } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';
import getHostName from '../../../utilities/getHostName';
import isValidUrl from '../../../utilities/isValidUrl';

function UrlTextField({
  id,
  dependsOn,
  credentialId,
  config,
  configParams,
  stepIndex,
  updateConfig,
}) {
  const [urlLabel, setUrlLabel] = useState('');
  const [label, setLabel] = useState('');
  const [urlIsValid, setUrlIsValid] = useState(true);

  const debouncedUrlLabel = useDebounce(urlLabel, 1000);

  useEffect(() => {
    if (
      !debouncedUrlLabel.startsWith('http://') &&
      !debouncedUrlLabel.startsWith('https://')
    ) {
      if (isValidUrl(`https://${debouncedUrlLabel}`)) {
        setUrlLabel(`https://${debouncedUrlLabel}`);
      }
    }

    if (isValidUrl(debouncedUrlLabel)) {
      setUrlIsValid(true);
      setLabel(getHostName(debouncedUrlLabel));
      updateConfig(stepIndex, id, { id: debouncedUrlLabel });
    } else {
      setUrlIsValid(false);
    }
  }, [debouncedUrlLabel]);

  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  return (
    <>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Language />
            </InputAdornment>
          ),
        }}
        error={urlIsValid === false}
        fullWidth
        label={config.label}
        onChange={(event) => {
          setLabel('');
          setUrlIsValid(true);
          setUrlLabel(event.target.value);
        }}
        placeholder="https://www.example.com"
        value={urlLabel}
        variant="filled"
      />
    </>
  );
}

export default UrlTextField;
