import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';

// generic error message, controled by redux
// example:
//   dispatch({
//     type: types.SHOW_ERROR_MESSAGE,
//     error: {
//       open: true,
//       title: "Error",
//       description: "Geen teams zonder admin toegestaan"
//     }
//   });
function ErrorMessageDialog() {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage);

  const { open, title, description } = errorMessage;

  function closeDialog() {
    dispatch({
      type: types.CLOSE_ERROR_MESSAGE,
    });
  }

  return (
    <div>
      <Dialog
        aria-describedby="general-error-dialog-description"
        aria-labelledby="general-error-dialog-title"
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="general-error-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="general-error-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ErrorMessageDialog;
