const dataSourceLabel = /* GraphQL */ `
     id
    name
    nameLowercase
    value
    type
    createdAt
    dataProviders
    updatedAt
`;

const teamCredential = /* GraphQL */ `
  id
  owners
  editors
  name
  email
  picture
  status
  authType
  dataProvider
  team {
    id
  }
  `;

const teamDataSource = /* GraphQL */ `
id
owner
editors
groups
createdAt
updatedAt
dataProvider
name
nameLowercase
view {
  viewId
              viewName
              propertyId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              dc
              connectorId
}
url
hostname
domain
tags

labels {
          items {
            label {
              ${dataSourceLabel}
            }
          }
        }

filter {
  isActive
  dimensionsFilters {
    fieldName
    values
    type
    operator
  }
  segment {
    id
    name
  }
}
credential {
  ${teamCredential}
}

team {
  id
}
`;

export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        admins
        editors
        members
        teamMembers {
          nextToken
        }
        avatar {
          visibility
          file {
            bucket
            key
            region
            fileName
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserTeams = /* GraphQL */ `
  query GetUserTeams($id: ID!) {
    getUser(id: $id) {
      id
      username
      defaultTeamId
      teams {
        items {
          team {
            id
            name
            avatar {
              visibility
              file {
                bucket
                key
                region
                fileName
              }
            }
          }
        }
      }
    }
  }
`;

export const getTeamLabels = /* GraphQL */ `
  query GetTeamLabels(
    $id: ID!
    $limit: Int
    $filter: ModelDataSourceLabelFilterInput
    $nextToken: String
  ) {
    getTeam(id: $id) {
      id
      dataSourceLabels(
        sortDirection: DESC
        limit: $limit
        nextToken: $nextToken
        filter: $filter
      ) {
        items {
          ${dataSourceLabel}
        }
        nextToken
      }
    }
  }
`;

export const getTeamLabel = /* GraphQL */ `
  query ListTemplates(
    $id: ID!
    $filter: ModelDataSourceLabelFilterInput
  ) {
    getTeam(id: $id) {
      dataSourceLabels(
        filter: $filter
      ) {
        items {
          ${dataSourceLabel}
        }
      }
    }
  }
`;

export const getTeamDataSourceLabels = /* GraphQL */ `
  query GetTeamDataSourceLabels(
    $id: ID!
    $limit: Int
    $filter: ModelDataSourceLabelFilterInput
    $nextToken: String
  ) {
    getTeam(id: $id) {
      id
      dataSourceLabels(
        sortDirection: DESC
        limit: $limit
        nextToken: $nextToken
        filter: $filter
      ) {
        items {
          ${dataSourceLabel}
        }
        nextToken
      }
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      defaultTeamId
      avatar {
        visibility
        file {
          bucket
          key
          region
          fileName
        }
      }
    }
  }
`;

export const getCredential = /* GraphQL */ `
query GetCredential($id: ID!) {
    getCredential(id: $id) {
      ${teamCredential} 
    }
  }
  `;

export const listCredentials = /* GraphQL */ `
  query ListCredentials(
    $filter: ModelCredentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredentials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${teamCredential} 
      }
      nextToken
    }
  }
`;

export const getTeamDataSources = /* GraphQL */ `
query GetTeamDataSources($id: ID! 
    $limit: Int
    $filter: ModelDataSourceFilterInput
    $nextToken: String) {
    getTeam(id: $id) {
      id
      dataSources(sortDirection: DESC, limit: $limit, nextToken: $nextToken, filter: $filter,  ) {
        items {
        ${teamDataSource}
        }
        nextToken
      }
    }
  }
    `;

export const getDataSource = /* GraphQL */ `
query GetDataSource($id: ID!) {
      getDataSource(id: $id) {
        ${teamDataSource}
      }
    }
    `;

export const getTeamCredentials = /* GraphQL */ `
query GetTeamCredentials($id: ID! 
        $limit: Int
        $filter: ModelCredentialFilterInput
        $nextToken: String) {
        getTeam(id: $id) {
          id
          credentials(sortDirection: DESC, limit: $limit, nextToken: $nextToken, filter: $filter,  ) {
            items {
            ${teamCredential}
            }
            nextToken
          }
        }
      }
        `;

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      owner
      members
      admins
      editors
      teamMembers {
        items {
          id
          teamMembershipUserId
          teamMembershipTeamId
          owner
        }
        nextToken
      }
      avatar {
        visibility
        file {
          bucket
          key
          region
          fileName
        }
      }
      dataSources {
        items {
          id
          owner
          editors
          createdAt
          updatedAt
          dataProvider
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            dc
            connectorId
          }
        }
        nextToken
      }
      credentials {
        items {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          authType
          dataProvider
        }
        nextToken
      }
    }
  }
`;

export const listWidgets = /* GraphQL */ `
  query ListWidgets(
    $filter: ModelWidgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        subTitle
        description
        mainDataElementType
        dataSection
        dataElements {
          id
          dataProvider
          type
          variant
          dateRange
          comparisonPeriod
          resource
          metrics
          dimensions
          sortField
          sortDirection
          settings {
            key
            value
          }
          filters {
            fieldName
            value
            type
            operator
          }
        }
      }
      nextToken
    }
  }
`;
