import { Avatar, Paper, Typography } from '@mui/material';

export default function IntegrationTile({ integration, selected, select }) {
  return (
    <Paper
      onClick={() => select()}
      sx={(theme) => ({
        aspectRatio: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        border: selected
          ? `2px solid ${theme.palette.info.main}`
          : '2px solid transparent',
        ':hover': {
          boxShadow: 5,
        },
      })}
    >
      <Avatar
        alt={integration.name}
        src={integration.image}
        sx={{
          width: 'unset',
          height: '75%',
          padding: 2,
        }}
        variant="square"
      ></Avatar>

      <Typography
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.action.disabled}`,
          width: '100%',

          textAlign: 'center',
          padding: 1,
          boxSizing: 'border-box',
        })}
      >
        {integration.name}
      </Typography>
    </Paper>
  );
}
