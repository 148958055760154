import { Box } from '@mui/material';
import { Auth, Storage } from 'aws-amplify';
import React from 'react';

function TemplateImage({
  components = [],
  image,
  height = '',
  width = '',
  reportId = '',
  pageId = '',
}) {
  const [imageSrc, setImageSrc] = React.useState('');

  // get the image from S3
  React.useEffect(() => {
    let active = true;
    async function getS3AvatarLink() {
      const creds = await Auth.currentCredentials();
      const result = await Storage.get(image.key, {
        level: 'public',
        identityId: creds.identityId,
      });
      if (active) {
        setImageSrc(result);
      }
    }
    if (image) {
      getS3AvatarLink();
    } else if (active) {
      setImageSrc('');
    }
    return () => {
      active = false;
    };
  }, [image]);

  return (
    <>
      {components.length > 0 && (
        <Box
          sx={() => ({
            position: 'absolute',
            left: '0px',
            right: '0px',
            bottom: '0px',
            top: '0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <svg
            height={height}
            id={`${reportId}_${pageId}`}
            version="1.1"
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs></defs>

            <mask id="myMask">
              <rect fill="white" height="100%" width="100%" x="0" y="0" />
              {components.map((component) => (
                <rect
                  className="bb-box"
                  fill="black"
                  height={component.height}
                  id={component.componentId}
                  key={component.componentId}
                  width={component.width}
                  x={component.left}
                  y={component.top}
                />
              ))}
            </mask>

            <rect
              fill="white"
              fillOpacity={'0.9'}
              height="100%"
              mask="url(#myMask)"
              width="100%"
              x="0"
              y="0"
            />
          </svg>
        </Box>
      )}
      <img
        alt={'template'}
        src={imageSrc}
        style={{
          width: '100%',
        }}
      />
    </>
  );
}

export default TemplateImage;
