/* eslint-disable no-console */
import { ArrowForward } from '@mui/icons-material';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as mutations from '../../graphql/mutations';

function TeamActionButton({ invite, getTeamInvites }) {
  const navigate = useNavigate();

  const [joinButtonType, setJoinButtonType] = useState('');

  useEffect(() => {
    if (invite.status === 'ACCEPTED') {
      setJoinButtonType('LAUNCH');
    } else if (
      invite.status === 'OPEN' &&
      invite.inviteType === 'AUTO_JOIN_INVITE'
    ) {
      setJoinButtonType('LOADING');
    } else {
      setJoinButtonType('JOIN_BUTTON');
    }
  }, [invite.inviteType, invite.status]);

  useEffect(() => {
    async function check() {
      if (
        invite.inviteType === 'AUTO_JOIN_INVITE' &&
        invite.status === 'OPEN'
      ) {
        await API.graphql(
          graphqlOperation(mutations.joinTeam, {
            teamId: invite.teamId,
            action: 'ACCEPT',
            inviteId: invite.id,
          })
        );

        const cognitoUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const refreshToken = session.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, (error) => {
          if (error) {
            console.warn(error);
          }
        });

        getTeamInvites();
        window.location.reload();
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite.inviteType, invite.status]);

  async function joinTeam() {
    await API.graphql(
      graphqlOperation(mutations.joinTeam, {
        teamId: invite.teamId,
        action: 'ACCEPT',
        inviteId: invite.id,
      })
    );

    navigate(`/workspace/${invite.teamId.split('-')[0]}/data-sources`);

    getTeamInvites();

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken();
    cognitoUser.refreshSession(refreshToken, (error) => {
      if (error) {
        console.warn(error);
      }
    });

    window.location.reload();
  }

  async function declineTeam() {
    await API.graphql(
      graphqlOperation(mutations.joinTeam, {
        teamId: invite.teamId,
        action: 'DECLINE',
        inviteId: invite.id,
      })
    );

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken();
    cognitoUser.refreshSession(refreshToken, (error) => {
      if (error) {
        console.warn(error);
      }
    });

    getTeamInvites();
  }

  if (!invite && !invite.status) {
    return null;
  }

  return (
    <>
      {joinButtonType === 'JOIN_BUTTON' && (
        <>
          <Button
            onClick={() => {
              setJoinButtonType('LOADING');
              declineTeam();
            }}
            size="small"
            variant="outlined"
          >
            Decline
          </Button>

          <Button
            color="primary"
            onClick={() => {
              setJoinButtonType('LOADING');
              joinTeam();
            }}
            size="small"
            style={{ marginLeft: '5px' }}
            variant="outlined"
          >
            Accept
          </Button>
        </>
      )}

      {joinButtonType === 'LOADING' && <CircularProgress size={30} />}

      {joinButtonType === 'LAUNCH' && (
        <Tooltip title="Launch Workspace">
          <Box p={1}>
            <ArrowForward color="action" />
          </Box>
        </Tooltip>
      )}
    </>
  );
}

export default TeamActionButton;
