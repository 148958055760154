/* eslint-disable no-nested-ternary */
import { ArrowDropDown, Check } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import * as queries from '../../graphql/queries';
import UserAvatar from '../user/UserAvatar';

const EDIT_RIGHTS = 'EDIT_RIGHTS';
const READ_ONLY_RIGHTS = 'READ_ONLY_RIGHTS';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  adminButton: {
    textTransform: 'none',
    background: theme.palette.background.default,
    width: '100%',
  },
}));

function EditingRightsDataSourceDialog({ onClose = () => {} }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const userObject = useSelector((state) => state.userObject);
  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const dialogs = useSelector((state) => state.dialogs);

  const open = dialogs.showEditingRightsDataSourceDialog;

  const [teamMembers, setTeamMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChapterMenuId, setOpenChapterMenuId] = useState(-1);

  async function switchRole(newRole, userId) {
    const editors = selectedDataSource.editors
      ? selectedDataSource.editors
      : [];
    const memberIsEditor = editors.includes(userId);

    let newEditors = [...editors];

    if (newRole === EDIT_RIGHTS && memberIsEditor === false) {
      newEditors.push(userId);
    } else if (newRole === READ_ONLY_RIGHTS && memberIsEditor === true) {
      newEditors = newEditors.filter((e) => e !== userId);
    }

    const updatedDataSourceObject = await updateDataSourceObject({
      id: selectedDataSource.id,
      editors: newEditors,
    });

    dispatch({
      type: types.UPDATE_TEAM_DATA_SOURCE,
      dataSource: updatedDataSourceObject,
      teamId: selectedDataSource.team.id,
    });

    setAnchorEl(null);
    setOpenChapterMenuId(-1);
  }

  function closeDialog() {
    dispatch({
      type: types.SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  useEffect(() => {
    async function getAllThemeMembers() {
      const getAllTeamMembersResponse = await API.graphql(
        graphqlOperation(queries.getAllTeamMembers, {
          teamId: selectedDataSource.team.id,
        })
      );
      const teamMembersR = getAllTeamMembersResponse.data.getAllTeamMembers;
      setTeamMembers(teamMembersR);
    }
    if (selectedDataSource) {
      getAllThemeMembers();
    }
  }, [selectedDataSource]);

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <div>
      <Dialog
        aria-describedby="editing-rights-dialog-description"
        aria-labelledby="editing-rights-dialog-title"
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="editing-rights-dialog-title">
          Data Source Permissions and Roles
        </DialogTitle>
        <DialogContent>
          {teamMembers.length === 0 ? (
            <Box
              alignContent="center"
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Team member</TableCell>
                  <TableCell>Permission</TableCell>
                  <TableCell>Rol</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {teamMembers.map((member, i) => {
                  const editors = selectedDataSource.editors
                    ? selectedDataSource.editors
                    : [];

                  const isEditor =
                    editors.includes(member.id) &&
                    member.memberStatus === 'HAS_ACCESS';

                  let disableSwitch = selectedDataSource.owner === member.id;

                  let permission = 'No access';
                  let rol = 'Blocked';

                  if (member.memberStatus === 'HAS_ACCESS') {
                    rol = 'Viewer';
                  }

                  if (selectedDataSource.owner === member.id) {
                    rol = 'Owner';
                  } else if (isEditor === true) {
                    rol = 'Editor';
                  }

                  if (member.memberStatus === 'HAS_ACCESS') {
                    permission = 'View rights';
                  }

                  if (isEditor) {
                    permission = 'Edit rights';
                  }

                  if (member.permission === 'ADMIN') {
                    disableSwitch = true;
                    permission = 'Edit rights';
                    rol = 'Workspace Admin';
                  }

                  if (member.permission === 'EDITOR') {
                    disableSwitch = true;
                    permission = 'Edit rights';
                    rol = 'Workspace Editor';
                  }

                  if (member.memberStatus === 'NO_ACCESS') {
                    permission = 'No Access';
                    rol = 'Blocked';
                  }

                  if (member.memberStatus === 'INVITED') {
                    disableSwitch = true;
                    permission = 'No Access';
                    rol = 'Invited';
                  }

                  return (
                    <TableRow key={member.id}>
                      <TableCell className={classes.tableCellNoBorder}>
                        <Box display="flex" flexDirection="row">
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            marginRight="12px"
                          >
                            <UserAvatar
                              avatar={member.avatar}
                              id={member.id}
                              name={member.name}
                            />
                          </Box>

                          <Box display="flex" flexDirection="column">
                            <Typography
                              className={classes.inline}
                              color="textPrimary"
                              component="span"
                              variant="body1"
                            >
                              {`${member.name}${
                                userObject.id === member.id ? ' (you)' : ''
                              }`}
                            </Typography>
                            <Typography
                              className={classes.inline}
                              color="textSecondary"
                              component="span"
                              variant="body2"
                            >
                              {member.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell className={classes.tableCellNoBorder}>
                        <Typography color="inherit" noWrap variant="inherit">
                          {permission}
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.tableCellNoBorder}
                      >
                        <Button
                          className={classes.adminButton}
                          disabled={disableSwitch}
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setOpenChapterMenuId(i);
                          }}
                          variant="outlined"
                        >
                          <Typography color="inherit" noWrap variant="inherit">
                            {rol}
                          </Typography>

                          <ArrowDropDown fontSize="small" />
                        </Button>

                        <Menu
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: 250,
                            },
                          }}
                          anchorEl={anchorEl}
                          keepMounted
                          onClose={() => {
                            setAnchorEl(null);
                            setOpenChapterMenuId(-1);
                          }}
                          open={openChapterMenuId === i}
                        >
                          <MenuItem
                            onClick={() => {
                              switchRole(EDIT_RIGHTS, member.id);
                            }}
                          >
                            {isEditor === true && (
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                            )}
                            Editor
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              switchRole(READ_ONLY_RIGHTS, member.id);
                            }}
                          >
                            {isEditor === false && (
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                            )}
                            Viewer
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditingRightsDataSourceDialog;
