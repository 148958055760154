import React from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import * as types from '../../constants/ActionTypes';
import useQuery from '../../hooks/useQuery';
import fetchAllTemplates from '../../utilities/fetchAllTemplates';
import shouldFetchTemplates from '../../utilities/shouldFetchTemplates';
import DataProviderChip from '../data/DataProviderChip';
import TemplateImage from './TemplateImage';

const topRankedTemplates = ['GOOGLE_ANALYTICS4', 'MULTIPLE'];

// calculate the number of blocks that we need to fill the grid
function calculateBlocks(numItems) {
  const remainder = numItems % 3;
  if (remainder === 0) {
    return numItems;
  }
  return numItems + (3 - remainder);
}

const selectTemplates = createSelector(
  (state) => state,
  (_, settings) => settings,
  (state, settings) => {
    const { selectedUserTeam } = settings;
    const {
      isFetching,
      items: templates,
      nextToken: initialNextToken,
    } = state.templatesByTeam[selectedUserTeam] || {
      isFetching: true,
      items: [],
      nextToken: undefined,
    };
    const filteredTemplates = templates;
    return {
      isFetching,
      templates: filteredTemplates,
      initialNextToken,
    };
  }
);

function TemplatesOverviewList() {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const q = query.get('q') ? query.get('q') : '';

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const templatesByTeam = useSelector((state) => state.templatesByTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  // eslint-disable-next-line no-unused-vars
  const { isFetching, templates, initialNextToken } = useSelector((state) =>
    selectTemplates(state, { selectedUserTeam })
  );

  React.useEffect(() => {
    async function fetchTeamTemplates() {
      if (shouldFetchTemplates(templatesByTeam, selectedUserTeam, q)) {
        batch(() => {
          dispatch({
            type: types.REQUEST_TEAM_TEMPLATES,
            teamId: selectedUserTeam,
          });
        });

        const { newTemplates, newNextToken } = await fetchAllTemplates();

        const filteredTemplates = _filter(
          newTemplates,
          (o) =>
            o.groups.includes(selectedUserTeam) || o.groups.includes('public')
        );

        batch(() => {
          dispatch({
            type: types.SET_TEAM_TEMPLATES,
            teamId: selectedUserTeam,
            q,
            templates: filteredTemplates,
            nextToken: newNextToken,
          });
        });
      }
    }

    if (selectedUserTeam) {
      fetchTeamTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam, q]);

  // group the templates by data provider
  const groupedAndSortedTemplates = React.useMemo(() => {
    const grouped = _groupBy(templates, (template) => {
      if (template.dataProviders.length === 1) {
        return template.dataProviders[0];
      }
      if (template.dataProviders.length > 1) {
        return 'MULTIPLE';
      }
      return 'UNKNOWN';
    });

    const groupedArray = [];

    Object.keys(grouped).forEach((key) => {
      if (key) {
        groupedArray.push({
          groupKey: key,
          items: grouped[key],
        });
      }
    });

    const sorted = _sortBy(groupedArray, (item) => {
      const topRankedTemplatesIndex = topRankedTemplates.indexOf(item.groupKey);
      if (item.groupKey === 'UNKNOWN') {
        return -1000000;
      }
      // order based on top ranked index
      // order rank on amounth of templates
      if (topRankedTemplatesIndex === -1) {
        const alphaRank = item.groupKey.charCodeAt(0);
        // make sure that this is always lower than the top ranked templates
        return item.items.length * 100 + Number(`-${alphaRank}`);
      }
      return (topRankedTemplatesIndex + 1) * 1000000;
    });

    return sorted.reverse();
  }, [templates]);

  // check if we need to show the templates in full width mode
  // if there are multiple templates in a group, we need to show them in full width mode
  const fullWidthMode = React.useMemo(
    () =>
      Object.keys(groupedAndSortedTemplates).some(
        (key) => groupedAndSortedTemplates[key].items.length > 1
      ),
    [groupedAndSortedTemplates]
  );

  // calculate the number of missing blocks
  // to make sure that the grid is always filled
  const missingBlocks = React.useMemo(
    () =>
      Array(
        calculateBlocks(groupedAndSortedTemplates.length) -
          groupedAndSortedTemplates.length
      ).fill(1),
    [groupedAndSortedTemplates]
  );

  // wait for the templates to be fetched
  if (isFetching) {
    return null;
  }

  return (
    <>
      <Outlet />
      {!groups.includes('gladior') && !groups.includes('Template') && (
        <Navigate replace to="/workspace" />
      )}
      <Box sx={{ m: 2 }}>
        <Grid container spacing={2}>
          {groupedAndSortedTemplates.map((groupObj) => (
            <Grid
              container
              item
              key={groupObj.groupKey}
              spacing={2}
              sx={{ flexGrow: 1 }}
              xs={fullWidthMode === false ? 4 : 12}
            >
              <Grid xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography variant="h6">
                    {groupObj.groupKey === 'UNKNOWN' && 'Unknown'}
                    {groupObj.groupKey === 'MULTIPLE' && 'Multiple Connectors'}
                    {
                      dataProviderSettings.allDataProviders[groupObj.groupKey]
                        ?.name
                    }
                  </Typography>
                </Box>
              </Grid>

              {groupObj.items.map((template) => (
                <Grid key={template.id} xs={fullWidthMode === false ? 12 : 4}>
                  <Card sx={{ height: '100%' }} variant="outlined">
                    <Tooltip title={template.name}>
                      <CardActionArea
                        onClick={() => {
                          if (template?.pages === null) {
                            navigate({
                              pathname: `/workspace/${
                                selectedUserTeam.split('-')[0]
                              }/templates/t/${template.id}`,
                              search: '',
                            });
                          } else {
                            navigate({
                              pathname: `/workspace/${
                                selectedUserTeam.split('-')[0]
                              }/templates/create/dashboard`,
                              search: `templateId=${template.id}`,
                            });
                          }
                        }}
                      >
                        <Box
                          sx={(theme) => ({
                            bgcolor: theme.palette.background.paper,
                            height: '165px',
                            maxHeight: '165px',
                            overflow: 'hidden',
                            '&::before':
                              template.groups !== 'public'
                                ? {
                                    position: 'absolute',
                                    display: 'block',
                                    fontFamily: "'Material Icons'",
                                    content: "'lock'",
                                    textAlign: 'center',
                                    width: '100px',
                                    height: '20px',
                                    lineHeight: '20px',
                                    fontSize: '15px',
                                    right: '-10px',
                                    top: '-10px',
                                    transformOrigin: '50% 50%',
                                    transform:
                                      'translate(20px, 20px)  rotate(45deg)',
                                    backgroundColor:
                                      theme.palette.background.default,
                                    borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
                                    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                                    color: theme.palette.text.primary,
                                  }
                                : {},
                          })}
                        >
                          {template?.pages?.length > 0 && (
                            <TemplateImage image={template.pages[0].image} />
                          )}

                          {template?.pages === null && (
                            <Box
                              sx={(theme) => ({
                                bgcolor: theme.palette.background.paper,
                                height: '165px',
                                maxHeight: '165px',
                              })}
                            >
                              <Box
                                sx={() => ({
                                  position: 'absolute',
                                  left: '0px',
                                  right: '8px',
                                  bottom: '0px',
                                  top: '0px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                })}
                              >
                                <Box>
                                  <Typography variant="body2">
                                    Processing...
                                  </Typography>
                                </Box>
                              </Box>

                              <Box sx={{ opacity: 0.2 }}>
                                <TemplateImage image={template.image} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <CardContent
                          sx={(theme) => ({
                            borderTop: `1px solid ${theme.palette.action.disabled}`,
                          })}
                        >
                          <Typography noWrap variant="subtitle2">
                            {template.name}
                          </Typography>
                          <Typography
                            color={'textSecondary'}
                            gutterBottom
                            noWrap
                            variant="body2"
                          >
                            {template.type === 'LOOKER_STUDIO' &&
                              'Looker Studio template'}
                          </Typography>

                          <Typography color="text.secondary" variant="body2">
                            {template.description}
                          </Typography>

                          <Box
                            sx={{
                              my: 1,
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {template.dataProviders.map((dataProvider) => (
                              <DataProviderChip
                                dataProvider={dataProvider}
                                key={dataProvider}
                                variant="avatar"
                              />
                            ))}
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Tooltip>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ))}

          {fullWidthMode === false && (
            <>
              {missingBlocks.map(() => (
                <Grid xs={4}></Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default TemplatesOverviewList;
