import { Box } from '@mui/material';
import React from 'react';
import TextTextField from './TextTextField';
import UrlTextField from './UrlTextField';

function ConfigTextField({
  id,
  stepIndex,
  config,
  dependsOn,
  credentialId,
  updateConfig,
  configParams,
}) {
  let textField;

  if (config.variant === 'URL') {
    textField = (
      <UrlTextField
        config={config}
        configParams={configParams}
        credentialId={credentialId}
        dependsOn={dependsOn}
        id={id}
        key={id}
        stepIndex={stepIndex}
        updateConfig={updateConfig}
      />
    );
  } else if (config.variant === 'TEXT') {
    textField = (
      <TextTextField
        config={config}
        configParams={configParams}
        credentialId={credentialId}
        dependsOn={dependsOn}
        id={id}
        key={id}
        stepIndex={stepIndex}
        updateConfig={updateConfig}
      />
    );
  }

  return <Box sx={{ my: 2 }}>{textField}</Box>;
}

export default ConfigTextField;
