import { Box } from '@mui/material';
import React from 'react';
import DataProviderSelector from './data-provider/DataProviderSelector';

function DataProviderSelectors({
  setDynamicStatus,
  dataProvider,
  selectedCredential,
}) {
  return (
    <Box sx={{ m: 2 }}>
      <DataProviderSelector
        dataProvider={dataProvider}
        selectedCredential={selectedCredential}
        setCreateStatus={setDynamicStatus}
      />
    </Box>
  );
}

export default DataProviderSelectors;
