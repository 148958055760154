export const CUSTOM = 'CUSTOM';
export const LAST_YEAR = 'LAST_YEAR';
export const LAST_QUARTER = 'LAST_QUARTER';
export const LAST_MONTH = 'LAST_MONTH';
export const THIS_MONTH_TO_DATE = 'THIS_MONTH_TO_DATE';
export const LAST_WEEK_STARTS_MONDAY = 'LAST_WEEK_STARTS_MONDAY';
export const LAST_WEEK_STARTS_SUNDAY = 'LAST_WEEK_STARTS_SUNDAY';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_14_DAYS = 'LAST_14_DAYS';
export const LAST_28_DAYS = 'LAST_28_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const YESTERDAY = 'YESTERDAY';
export const THIS_WEEK_STARTS_SUNDAY_TO_DATE =
  'THIS_WEEK_STARTS_SUNDAY_TO_DATE';
export const THIS_WEEK_STARTS_MONDAY_TO_DATE =
  'THIS_WEEK_STARTS_MONDAY_TO_DATE';
export const THIS_QUARTER_TO_DATE = 'THIS_QUARTER_TO_DATE';
export const THIS_YEAR_TO_DATE = 'THIS_YEAR_TO_DATE';
export const THIS_WEEK_STARTS_SUNDAY = 'THIS_WEEK_STARTS_SUNDAY';
export const THIS_WEEK_STARTS_MONDAY = 'THIS_WEEK_STARTS_MONDAY';
export const THIS_MONTH = 'THIS_MONTH';
export const THIS_QUARTER = 'THIS_QUARTER';
export const THIS_YEAR = 'THIS_YEAR';
export const TODAY = 'TODAY';

