import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDataSource } from '../../api/getAllQueryObjectsForDataSource';
import getQueryObject from '../../api/getQueryObject';
import AddNewNode from './AddNewNode';
import EditorNode from './EditorNode';
import EditorPanel from './EditorPanel';

export default function FeedTemplate() {
  const params = useParams();
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dataSource, setDataSource] = useState(null);
  const [open, setOpen] = useState(false);
  const [openNode, setOpenNode] = useState(null);
  const [nodes, setNodes] = useState([
    {
      name: 'webhook',
    },
    {
      name: 'end',
    },
  ]);
  useEffect(() => {
    (async () => {
      const { queryId } = params;
      const q = await getQueryObject(queryId);
      setQuery(q);
      const d = await getDataSource(q.dataSourceQueriesId);
      setDataSource(d);
    })();
  }, []);
  return (
    <Box>
      <Typography variant="h6">Create template</Typography>
      <Box
        sx={{
          m: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {nodes.map((node, index) => (
          <Box
            key={node.name}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <EditorNode
              isFixed={index === 0 || index === nodes.length - 1}
              name={node.name}
              onClick={() => {
                setOpen(true);
                setOpenNode(node);
              }}
              removeNode={() => {
                setNodes([...nodes.slice(0, index), ...nodes.slice(index + 1)]);
              }}
            ></EditorNode>

            {index !== nodes.length - 1 && (
              <AddNewNode
                addNode={() => {
                  const newNode = {
                    name: `new node ${nodes.length}`,
                  };
                  setNodes([
                    ...nodes.slice(0, index + 1),
                    newNode,
                    ...nodes.slice(index + 1),
                  ]);
                  setOpen(true);
                  setOpenNode(newNode);
                }}
              ></AddNewNode>
            )}
          </Box>
        ))}
      </Box>
      <EditorPanel
        node={openNode}
        open={open}
        setNode={(n) => {
          Object.keys(n).forEach((key) => {
            openNode[key] = n[key];
          });
        }}
        setOpen={setOpen}
      ></EditorPanel>
    </Box>
  );
}
