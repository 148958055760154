import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../constants/ActionTypes';
import * as mutations from '../graphql/mutations';

function AdminPanel({ getTeamInvites }) {
  const dispatch = useDispatch();
  const { items: teamInvites } = useSelector((state) => state.teamInvites);

  return (
    <>
      <Card
        elevation={20}
        style={{
          position: 'fixed',
          right: 10,
          bottom: 10,
          width: '350px',
          maxHeight: '200px',
          overflow: 'auto',
        }}
      >
        <CardContent>
          <Typography align="center" gutterBottom variant="h6">
            Admin Panel
          </Typography>
          <Grid alignContent="stretch" container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={async () => {
                  await API.graphql(
                    graphqlOperation(mutations.adminPanel, {
                      action: 'ADMIN_INVITE',
                    })
                  );
                  getTeamInvites();
                }}
                variant="contained"
              >
                Create Admin Invite Invite
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={async () => {
                  await API.graphql(
                    graphqlOperation(mutations.adminPanel, {
                      action: 'EMAIL_DOMAIN_INVITE',
                    })
                  );
                  getTeamInvites();
                }}
                variant="contained"
              >
                Create email domain Invite
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={async () => {
                  await API.graphql(
                    graphqlOperation(mutations.adminPanel, {
                      action: 'AUTO_JOIN_INVITE',
                    })
                  );
                  getTeamInvites();
                }}
                variant="contained"
              >
                Create auto join Invite
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={async () => {
                  await API.graphql(
                    graphqlOperation(mutations.adminPanel, {
                      action: 'DELETE_ALL_INVITES',
                    })
                  );
                  getTeamInvites();
                }}
                variant="contained"
              >
                Delete all invites
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={async () => {
                  await API.graphql(
                    graphqlOperation(mutations.adminPanel, {
                      action: 'UNLINK_ALL_TEST_TEAMS',
                    })
                  );
                  getTeamInvites();
                }}
                variant="contained"
              >
                Unlink Test Team
              </Button>
            </Grid>

            <Grid item xs={12} />

            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                onClick={async () => {
                  dispatch({
                    type: types.SET_TEAM_INVITES,
                    invites: teamInvites.filter(
                      (invite) => invite.status !== 'ACCEPTED'
                    ),
                  });
                }}
                variant="contained"
              >
                No accepted teams
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default AdminPanel;
