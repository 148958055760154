/* eslint-disable consistent-return */
// eslint-disable-next-line import/no-extraneous-dependencies
import { parse, print, visit } from 'graphql';

const addExtrasGraphQL = (definition, extraVariables, extraArguments) => {
  const ast = parse(definition);

  const editedAST = visit(ast, {
    OperationDefinition: {
      leave(node) {
        const extraVars = extraVariables[node.name.value];
        if (extraVars) {
          node.variableDefinitions.push(...extraVars);
        }
      },
    },
    Field: {
      leave(node) {
        const extraArgs = extraArguments[node.name.value];
        if (extraArgs) {
          node.arguments.push(...extraArgs);
        }
      },
    },
  });

  return print(editedAST);
};

export default addExtrasGraphQL;
