import React from 'react';
import useDataProviderData from './useDataProviderData';

function usePrefetchDataProviderData() {
  const [path, setPath] = React.useState(null);
  const [queryStringObj, setQueryStringObj] = React.useState(null);

  useDataProviderData(path, queryStringObj);

  const prefetch = async (pathI, queryStringObjI) => {
    setPath(pathI);
    setQueryStringObj(queryStringObjI);
  };

  return {
    prefetch,
  };
}

export default usePrefetchDataProviderData;
