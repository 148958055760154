import {
  Box,
  CircularProgress,
  Container,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _find from 'lodash/find';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AskDataHubDialog from '../components/ai/AskDataHubDialog';
import ChangeCredentialForDataSource from '../components/data/ChangeCredentialForDataSource';
import DataApiDialog from '../components/data/DataApiDialog';
import DataExplorerDialog from '../components/data/DataExplorerDialog';
import DeleteDataSourceDialog from '../components/data/DeleteDataSourceDialog';
import EditingRightsDataSourceDialog from '../components/data/EditingRightsDataSourceDialog';
import GoogleDataStudioDialog from '../components/data/GoogleDataStudioDialog';
import MicrosoftTeamsDialog from '../components/data/MicrosoftTeamsDialog';
import PowerBIDialog from '../components/data/PowerBIDialog';
import PreCacheDialog from '../components/data/PreCacheDialog';
import QueryComposerDialog from '../components/data/queryComposer/QueryComposerDialog';
import ReConnectDataSourceDialog from '../components/data/ReConnectDataSourceDialog';
import UpdateDisplayNameDialog from '../components/data/UpdateDisplayNameDialog';
import UserTokenDialog from '../components/data/UserTokenDialog';
import WebsiteIntegrationDialog from '../components/data/WebsiteIntegrationDialog';
import ErrorMessageDialog from '../components/general/ErrorMessageDialog';
import NavigationBar from '../components/NavigationBar';
import NavigationToggleButtonGroup from '../components/NavigationToggleButtonGroup';
import UpdateMaxRequestDialog from '../components/rate-limiter/UpdateMaxRequestDialog';
import UsageDialog from '../components/rate-limiter/UsageDialog';
import CreateTeamDialog from '../components/teams/CreateTeamDialog';
import DeleteTeamDialog from '../components/teams/DeleteTeamDialog';
import UpdateTeamDialog from '../components/teams/UpdateTeamDialog';
import DeleteTemplateDialog from '../components/templates/DeleteTemplateDialog';
import * as types from '../constants/ActionTypes';
import useQuery from '../hooks/useQuery';

// const logger = new Logger('report-dashboard');

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '20px',
  },
  filterActive: {
    borderTop: `3px solid ${blue[500]}`,
  },
}));

function Workspace() {
  const classes = useStyles();

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const query = useQuery();

  // const match = useRouteMatch('/workspace/:workspaceId/:part');

  const labels = query.get('labels') ? query.get('labels') : '';

  // redux selectors
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);
  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const selectedReport = useSelector((state) => state.selectedReport);
  const reportsByTeam = useSelector((state) => state.reportsByTeam);
  const dialogs = useSelector((state) => state.dialogs);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const { items: reports } = reportsByTeam[selectedUserTeam] || {
    items: [],
  };

  const { items: dataSources } = dataSourcesByTeam[selectedUserTeam] || {
    items: [],
  };

  // if report object is changed in redux store, updated the selected report
  useEffect(() => {
    if (selectedReport && reports.length > 0) {
      const updatedReport = _find(reports, (o) => o.id === selectedReport.id);

      if (updatedReport) {
        dispatch({
          type: types.SET_SELECTED_REPORT,
          report: updatedReport,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  // if data source object is changed in redux store, updated the selected data source
  useEffect(() => {
    if (selectedDataSource) {
      const updatedDataSource = _find(
        dataSources,
        (o) => o.id === selectedDataSource.id
      );

      if (updatedDataSource) {
        dispatch({
          type: types.SET_SELECTED_DATA_SOURCE,
          dataSource: updatedDataSource,
        });
      } else {
        dispatch({
          type: types.SET_SELECTED_DATA_SOURCE,
          dataSource: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources]);

  if (!currentAuthenticatedUser?.attributes) {
    return (
      <Box style={{ position: 'fixed', top: '50%', left: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  function removeSelectedDataSource() {
    if (dialogs.showEditDataSourceDialog === false) {
      dispatch({
        type: types.SET_SELECTED_DATA_SOURCE,
        dataSource: null,
      });
    }
  }

  function removeSelectedTemplate() {
    if (dialogs.showEditTemplateDialog === false) {
      dispatch({
        type: types.SET_SELECTED_TEMPLATE,
        template: null,
      });
    }
  }

  function removeSelectedTeam() {
    dispatch({
      type: types.SET_SELECTED_TEAM,
      team: null,
    });
  }

  return (
    <>
      <Helmet>
        <title>Data Hub powered by Gladior</title>
      </Helmet>

      <NavigationBar />
      <Container className={classes.container} maxWidth="md" sx={{ mb: 5 }}>
        <NavigationToggleButtonGroup />
        <Paper
          className={clsx({ [classes.filterActive]: labels })}
          variant="outlined"
        >
          <Outlet />
        </Paper>
      </Container>
      <ErrorMessageDialog />
      <ChangeCredentialForDataSource onClose={removeSelectedDataSource} />
      <DeleteDataSourceDialog onClose={removeSelectedDataSource} />

      <DeleteTemplateDialog onClose={removeSelectedTemplate} />
      <QueryComposerDialog onClose={removeSelectedDataSource} />
      <EditingRightsDataSourceDialog onClose={removeSelectedDataSource} />
      <GoogleDataStudioDialog onClose={removeSelectedDataSource} />
      <PowerBIDialog onClose={removeSelectedDataSource} />
      <DataApiDialog onClose={removeSelectedDataSource} />
      <PreCacheDialog onClose={removeSelectedDataSource} />
      <WebsiteIntegrationDialog onClose={removeSelectedDataSource} />
      <MicrosoftTeamsDialog onClose={removeSelectedDataSource} />
      <DataExplorerDialog onClose={removeSelectedDataSource} />
      <AskDataHubDialog onClose={removeSelectedDataSource} />
      <ReConnectDataSourceDialog onClose={removeSelectedDataSource} />
      <UpdateDisplayNameDialog onClose={removeSelectedDataSource} />
      <UpdateMaxRequestDialog onClose={removeSelectedDataSource} />
      <UsageDialog onClose={removeSelectedDataSource} />
      <CreateTeamDialog />
      <DeleteTeamDialog onClose={removeSelectedTeam} />
      <UpdateTeamDialog onClose={removeSelectedTeam} />
      <UserTokenDialog onClose={() => {}} />

      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          background: '#F8F9FA',
        }}
      >
        <Typography align="center" color="text.secondary" variant="caption">
          <Link
            href="https://datahub.gladior.com/terms-of-service"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </Link>
          {' · '}
          <Link
            href="https://datahub.gladior.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </>
  );
}

export default Workspace;
