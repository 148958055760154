import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import addExtrasGraphQL from '../utilities/addExtrasGraphQL';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function createLabelIfNotExist(
  labelName,
  labelValue,
  labelType,
  dataProviders,
  teamId
) {
  const excludeArray = [
    'avatar',
    'id',
    'name',
    'owner',
    'members',
    'admins',
    'editors',
    'emailDomains',
    'autoInviteDomains',
    'teamMembers',
    'credentials',
    'dataSourceLabels.items.dataProviders',
    'dataSourceLabels.items.dataSources',
    'dataSourceLabels.items.team',
    'defaultTemplateId',
    'themes',
    'createdAt',
    'updatedAt',
    'dataSources',
  ];
  const getTeamFilter = (path) => {
    const re = new RegExp(`getTeam.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };

  let query = filterGraphQLFields(queries.getTeam, getTeamFilter);
  const variableDefinition = (name, type) => ({
    kind: 'VariableDefinition',
    variable: { kind: 'Variable', name: { kind: 'Name', value: name } },
    type: { kind: 'NamedType', name: { kind: 'Name', value: type } },
  });

  const enumArgument = (name, enumValue) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'EnumValue', value: enumValue },
  });
  const variableArgument = (name, variableName) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'Variable', name: { kind: 'Name', value: variableName } },
  });

  const extraVariables = {
    GetTeam: [
      variableDefinition('limit', 'Int'),
      variableDefinition('filter', 'ModelDataSourceLabelFilterInput'),
      variableDefinition('nextToken', 'String'),
    ],
  };
  const extraArguments = {
    dataSourceLabels: [
      enumArgument('sortDirection', 'DESC'),
      variableArgument('limit', 'limit'),
      variableArgument('nextToken', 'nextToken'),
      variableArgument('filter', 'filter'),
    ],
  };

  query = addExtrasGraphQL(query, extraVariables, extraArguments);

  const labelQueryObject = await API.graphql(
    graphqlOperation(query, {
      id: teamId,
      filter: { value: { eq: labelValue } },
    })
  );

  const label = labelQueryObject?.data?.getTeam?.dataSourceLabels?.items?.[0];

  if (label) {
    return label;
  }
  const newLabelObject = await API.graphql(
    graphqlOperation(
      filterGraphQLFields(mutations.createDataSourceLabel, (path) => {
        const fitlerExcludeArray = ['dataProviders', 'dataSources', 'team'];
        const re = new RegExp(
          `createDataSourceLabel.(${fitlerExcludeArray.join('|')})$`
        );
        return !re.test(path);
      }),
      {
        input: {
          name: labelName,
          nameLowercase: labelName.toLowerCase(),
          dataSourceLabelTeamId: teamId,
          type: labelType,
          dataProviders,
          value: labelValue,
        },
      }
    )
  );

  return newLabelObject.data.createDataSourceLabel;
}

export default createLabelIfNotExist;
