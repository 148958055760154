import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useState } from 'react';

export default function MFADialog({ open, handleClose }) {
  const [secretCode, setSecretCode] = useState(null);
  const [user, setUser] = useState(null);
  const [totp, setTotp] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(-1); // -1 no 0 success 1 loading
  async function verifyTotp() {
    setError(null);
    setLoading(1);
    try {
      await Auth.verifyTotpToken(user, totp.replace(' ', ''));
      setLoading(0);
      setTimeout(handleClose, 1000);
    } catch (err) {
      console.log(err);
      setError('incorrect token');
      setLoading(-1);
    }
  }
  useEffect(() => {
    (async () => {
      const u = await Auth.currentUserPoolUser();
      const sc = await Auth.setupTOTP(u);
      setSecretCode(sc);
      setUser(u);
    })();
  }, []);

  useEffect(() => {
    setTotp(null);
  }, [open]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Edit MFA settings
        <Typography
          sx={{
            fontSize: '0.9rem',
          }}
        >
          Scan this QR code with your authenticator app, and enter the TOTP to
          setup MFA
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: '600px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <QRCodeCanvas
            size={200}
            value={`otpauth://totp/Data Hub by Gladior:${user?.attributes?.email}?secret=${secretCode}&issuer=${'Data Hub by Gladior'}`}
          ></QRCodeCanvas>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100%',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography>{' '}</Typography>
              <TextField
                label="TOTP"
                onChange={(event) => {
                  setError(null);
                  setTotp(event.target.value);
                }}
                value={totp}
                variant="filled"
              ></TextField>

              <Typography
                sx={(theme) => ({
                  fontSize: '0.75rem',
                  color: theme.palette.error.main,
                })}
              >
                {error ?? ' '}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LoadingButton
                color={loading !== 0 ? 'secondary' : 'success'}
                disabled={loading === 0}
                loading={loading === 1}
                onClick={verifyTotp}
                variant="contained"
              >
                {loading !== 0 ? 'Verify TOTP' : 'TOTP Verified'}
              </LoadingButton>
              <Typography
                variant="caption"
                sx={{
                  width: '100%',
                  textAlign: 'right',
                  display: 'block',
                }}
              >
                This will invalidate all other configured TOTP providers
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
