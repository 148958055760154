import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

async function deleteDataSourceObject(selectedDataSource) {
  await API.graphql(
    graphqlOperation(mutations.removeDataSource, {
      dataSourceId: selectedDataSource.id,
    })
  );

  return selectedDataSource;
}

export default deleteDataSourceObject;
