/* eslint-disable no-unused-vars */
import { ExpandMore, Info } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import React from 'react';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';

function InfoBox({ config, dependsOn, configParams }) {
  if (dependsOn) {
    const shouldShow = depensOnCheckMatches(dependsOn, configParams);
    if (shouldShow === false) {
      return null;
    }
  }

  return (
    <Accordion sx={{ my: 2 }} variant="outlined">
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMore />}
        id="panel1a-header"
      >
        <Typography>
          <Info sx={{ fontSize: 'inherit' }} /> {config.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {config.text && (
          <Typography align="center" gutterBottom variant="body2">
            {config.text}
          </Typography>
        )}

        {config.image && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
          >
            <img alt="" height="400px" src={config.image} />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default InfoBox;
