import { ContentCopy } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

export default function IntegrationInfo({ step }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',

        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        m: 1,
        my: 2,
        backgroundColor: 'background.default',
        borderRadius: 1,
        outline: '1px solid #e0e0e0',
      }}
    >
      <Typography
        sx={{
          mr: 1,
        }}
      >
        {step.title}
      </Typography>
      {step?.copy && (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(step.title);

            enqueueSnackbar(`Copied to clipboard`, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          }}
        >
          <ContentCopy></ContentCopy>
        </IconButton>
      )}
    </Box>
  );
}
