/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
import { InfoOutlined, PersonAddTwoTone } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import { API, Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { batch, useDispatch, useSelector } from 'react-redux';

import * as types from '../../constants/ActionTypes';
import AddLabelDialog from '../labels/AddLabelDialog';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  buttonProgress: {
    color: green[500],
  },
}));

function UserTokenDialog({ onClose = () => {} }) {
  function generateState(service, workspaceId, href) {
    let mode = '';
    if (window.location.hostname === 'localhost') {
      mode = 'local';
    } else if (window.location.hostname.indexOf('staging') > -1) {
      mode = 'staging';
    } else {
      mode = 'production';
    }

    return {
      dataProvider: service,
      workspaceId,
      mode,
      redirectUri: window.location.href,
      href: encodeURIComponent(href),
    };
  }

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dialogs = useSelector((state) => state.dialogs);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const { enqueueSnackbar } = useSnackbar();
  const selectedDataProvider = useSelector(
    (state) => state.selectedDataProvider
  );
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const open = dialogs.showUserTokenDialog;

  // local state
  const [success, setSuccess] = useState(false);
  const [label, setLabel] = useState(null);
  const [openNewLabelDialog, setOpenNewLabelDialog] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  function closeDialog() {
    setSuccess(false);
    dispatch({
      type: types.SHOW_USER_TOKEN_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  if (
    !selectedDataProvider ||
    !dataProviderSettings.config[selectedDataProvider] ||
    !dataProviderSettings.config[selectedDataProvider].fields
  ) {
    return null;
  }

  function CredentialPickerButton(props) {
    const { Label, href, ...other } = props;
    return (
      <Button
        color="primary"
        href={`${href}&state="${encodeURIComponent(
          JSON.stringify(
            generateState(selectedDataProvider, selectedUserTeam, href)
          )
        )}"`}
        startIcon={<PersonAddTwoTone />}
        variant="contained"
        {...other}
      >
        {Label}
      </Button>
    );
  }

  const initialValues = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );

  dataProviderSettings.config[selectedDataProvider].fields.forEach((field) => {
    if (field.fieldType === 'TEXTFIELD' && !initialValues[field.type]) {
      initialValues[field.type] = '';
    } else if (field.fieldType === 'SELECT' && !initialValues[field.type]) {
      initialValues[field.type] = field.options[0].value;
    } else if (field.fieldType === 'PASSWORD' && !initialValues[field.type]) {
      initialValues[field.type] = field.value;
    }
  });

  return (
    <>
      <AddLabelDialog
        closeDialog={() => {
          setOpenNewLabelDialog(false);
        }}
        label={label}
        open={openNewLabelDialog}
        setLabel={setLabel}
      />

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle>
          {' '}
          {dataProviderSettings.config[selectedDataProvider].name}
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            if (
              dataProviderSettings.config[selectedDataProvider]?.addButton
                ?.type === 'OAUTH'
            ) {
              return;
            }
            try {
              // let updatedTeam = await updateTeamObject(selectedTeam.id, values.name);

              const credentials = [];

              for (const key in values) {
                // eslint-disable-next-line no-prototype-builtins
                if (values.hasOwnProperty(key)) {
                  credentials.push({ type: key, value: values[key] });
                }
              }

              const inputG = {
                service:
                  dataProviderSettings.config[selectedDataProvider].service,
                dataProviders: [selectedDataProvider],
                teamId: selectedUserTeam,
                credentials,
              };

              if (label && label.id) {
                inputG.labelId = label.id;
              }

              const dpConf = dataProviderSettings.config[selectedDataProvider];

              const { route } = dpConf;
              const tokens = await Auth.currentSession();
              const response = await API.post(
                'DataProviderApi',
                `/${route}/credential/`,
                {
                  body: {
                    credentials,
                    workspaceId: selectedUserTeam,
                    dataProviders: [selectedDataProvider],
                  },
                  headers: {
                    Authorization: `${tokens.getIdToken().getJwtToken()}`,
                  },
                  response: true,
                  queryStringParameters: {},
                }
              );

              const newCredential = response.data;

              // response.data;

              if (newCredential.status === 'NEW') {
                await batch(() => {
                  dispatch({
                    type: types.ADD_TEAM_CREDENTIAL,
                    credential: { ...newCredential },
                    movedCount: 1,
                    teamId: selectedUserTeam,
                  });
                });
              } else if (newCredential.status === 'UPDATED') {
                // show message updated
                await batch(() => {
                  dispatch({
                    type: types.UPDATE_TEAM_CREDENTIAL,
                    credential: { ...newCredential },
                    teamId: selectedUserTeam,
                  });
                });
                enqueueSnackbar(
                  `Successfully gained access to ${newCredential.email}`,
                  {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }
                );
              } else if (newCredential.status === 'UNCHANGED') {
                // show message already exist
                await batch(() => {
                  dispatch({
                    type: types.UPDATE_TEAM_CREDENTIAL,
                    credential: { ...newCredential },
                    teamId: selectedUserTeam,
                  });
                });
                enqueueSnackbar(`${newCredential.email} was already linked`, {
                  variant: 'info',

                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                });
              }
              setSubmitting(false);
              setSuccess(true);
              setTimeout(() => {
                closeDialog();
              }, 500);
            } catch (e) {
              console.log(e);

              enqueueSnackbar(`Token is not valid`, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              });
            }
          }}
          validate={(values) => {
            const errors = {};
            dataProviderSettings.config[selectedDataProvider].fields.forEach(
              (field) => {
                if (
                  !values[field.type] &&
                  (field.fieldType === 'SELECT' ||
                    field.fieldType === 'TEXTFIELD' ||
                    field.fieldType === 'PASSWORD')
                ) {
                  errors[field.type] = 'Required field';
                }
                if (field?.match) {
                  if (
                    values[field.type]?.match(
                      new RegExp(field.match.source, field.match.flags)
                    )?.length !== 1 ||
                    values[field.type]?.match(
                      new RegExp(field.match.source, field.match.flags)
                    )?.[0] !== values[field.type]
                  ) {
                    errors[field.type] = 'Invalid value';
                  }
                }
              }
            );

            return errors;
          }}
        >
          {(props) => (
            <Form>
              <DialogContent className={classes.container}>
                {dataProviderSettings.config[selectedDataProvider].fields.map(
                  (field) => {
                    if (
                      field.fieldType === 'TEXTFIELD' ||
                      field.fieldType === 'PASSWORD'
                    ) {
                      return (
                        <TextField
                          type={
                            field.fieldType === 'PASSWORD' ? 'password' : 'text'
                          }
                          error={props.errors[field.type] !== undefined}
                          helperText={props.errors[field.type]}
                          key={field.type}
                          label={field.label}
                          margin="dense"
                          name={field.type}
                          onBlur={props.handleBlur}
                          onChange={(event) => {
                            const ev = event;
                            if (
                              field.match &&
                              event.target.value?.match(
                                new RegExp(
                                  field.match.source,
                                  field.match.flags
                                )
                              )?.length === 1
                            ) {
                              // eslint-disable-next-line prefer-destructuring
                              ev.target.value = event.target.value.match(
                                new RegExp(
                                  field.match.source,
                                  field.match.flags
                                )
                              )[0];
                            }
                            props.handleChange(ev);
                          }}
                          placeholder={
                            field.placeholder ? field.placeholder : ''
                          }
                          value={props.values[field.type]}
                          variant="filled"
                        />
                      );
                    }

                    if (field.fieldType === 'DESCRIPTION_TEXT') {
                      return (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="caption">
                            <Linkify
                              // eslint-disable-next-line react/no-unstable-nested-components
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <Link
                                  color="secondary"
                                  href={decoratedHref}
                                  key={key}
                                  target="blank"
                                >
                                  {decoratedText}
                                </Link>
                              )}
                            >
                              {field.value}
                            </Linkify>
                          </Typography>
                        </Box>
                      );
                    }

                    if (field.fieldType === 'STEPS') {
                      return (
                        <Box sx={{ mt: 2 }}>
                          <Typography sx={{ mb: 1 }} variant="subtitle2">
                            {field.label}
                          </Typography>
                          {field.value.map((item, index) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mb: 1,
                              }}
                            >
                              <Chip
                                color="default"
                                label={`${index + 1}`}
                                size="small"
                                sx={{ mr: 1 }}
                              />
                              <Typography key={item} variant="body2">
                                {item}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      );
                    }

                    if (field.fieldType === 'HELPER_TEXT') {
                      return (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-end"
                          key={field.type}
                          my={2}
                        >
                          <PopupState variant="popover">
                            {(popupState) => (
                              <>
                                <IconButton
                                  {...bindTrigger(popupState)}
                                  size="large"
                                >
                                  <InfoOutlined />
                                </IconButton>

                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <Box maxWidth="400px" p={2}>
                                    <FormHelperText>
                                      <Linkify>{field.value}</Linkify>
                                    </FormHelperText>
                                  </Box>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        </Box>
                      );
                    }

                    if (field.fieldType === 'POPUP') {
                      return (
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            key={field.type}
                            my={2}
                          >
                            <Button
                              color="info"
                              onClick={() => {
                                window.open(
                                  field.value,
                                  'popUpWindow',
                                  'height=241,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                                );
                              }}
                              variant="outlined"
                            >
                              {field.label}
                            </Button>
                          </Box>
                        </>
                      );
                    }

                    if (field.fieldType === 'SELECT') {
                      return (
                        <FormControl
                          className={classes.formControl}
                          fullWidth
                          key={field.type}
                          variant="filled"
                        >
                          <InputLabel>{field.label}</InputLabel>
                          <Select
                            onChange={(event) => {
                              props.setFieldValue(
                                field.type,
                                event.target.value
                              );
                            }}
                            value={props.values[field.type]}
                          >
                            {field.options.map((dataSe) => (
                              <MenuItem key={dataSe.value} value={dataSe.value}>
                                {dataSe.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }
                  }
                )}
                {props.isSubmitting && <LinearProgress />}
              </DialogContent>
              <DialogActions className={classes.actionsContainer}>
                <Button color="primary" onClick={closeDialog}>
                  Cancel
                </Button>
                {dataProviderSettings.config[selectedDataProvider]?.addButton
                  ?.type === 'OAUTH' ? (
                  <>
                    {(() => {
                      const value =
                        dataProviderSettings.config[selectedDataProvider]
                          ?.addButton?.value;
                      // replaces for instance https://{{ENVIRONMENT}}.com with the actual value
                      const matchRegex = /{{.*?}}/gm;

                      const matches = (value.match(matchRegex) ?? []).map((m) =>
                        m.slice(2, m.length - 2)
                      );
                      const isDisabled =
                        matches.some((m) => !props.values[m]) ||
                        Object.values(props.errors).length > 0;
                      const finalUrl = matches.reduce(
                        (acc, m) => acc.replace(`{{${m}}}`, props.values[m]),
                        value
                      );
                      return (
                        <CredentialPickerButton
                          Label={'Add credential'}
                          disabled={isDisabled}
                          href={finalUrl}
                        />
                      );
                    })()}
                  </>
                ) : (
                  <Button
                    // onClick={props.submitForm}
                    className={buttonClassname}
                    color="primary"
                    disabled={
                      props.isSubmitting ||
                      Object.values(props.errors).length > 0
                    }
                    type="submit"
                    variant="contained"
                  >
                    {success && <Icon>check</Icon>}
                    {props.isSubmitting === true ? (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={24}
                      />
                    ) : (
                      'Add'
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default UserTokenDialog;
