import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import querystring from 'querystring';

import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import signInCallback from '../auth/signInCallback';

import * as types from '../constants/ActionTypes';
import * as cs from '../constants/CredentialStatus';

const snackbarAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

function AuthDataProvider() {
  const dispatch = useDispatch();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const [showRedirect, setShowRedirect] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const { dataProvider } = useParams();

  const queryStringObject = querystring.parse(location.search.slice(1));

  const dpConf = dataProviderSettings.config[dataProvider.toUpperCase()];

  useEffect(() => {
    async function fetchGraphQL() {
      const newCredential = await signInCallback(
        { code: queryStringObject.code },
        [dpConf.dataProvider],
        dpConf.clientId,
        selectedUserTeam,
        dpConf,
        queryStringObject
      );

      if (newCredential) {
        const { status } = newCredential;

        batch(() => {
          dispatch({
            type:
              status === cs.NEW
                ? types.ADD_TEAM_CREDENTIAL
                : types.UPDATE_TEAM_CREDENTIAL,
            credential: newCredential,
            movedCount: status === cs.NEW ? 1 : 0,
            teamId: newCredential?.credentialTeamId
              ? newCredential.credentialTeamId
              : newCredential.team.id,
          });
        });

        if (status === cs.UPDATED) {
          enqueueSnackbar(
            `Succesvol toegang gekregen tot ${newCredential.email}`,
            {
              variant: 'success',
              anchorOrigin: snackbarAnchorOrigin,
            }
          );
        } else if (status === cs.UNCHANGED) {
          enqueueSnackbar(`${newCredential.email} was already linked`, {
            variant: 'info',
            anchorOrigin: snackbarAnchorOrigin,
          });
        }

        setShowRedirect(true);
      }
    }
    if (selectedUserTeam) {
      fetchGraphQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam]);

  if (showRedirect === true) {
    if (queryStringObject.redirectUri) {
      window.location.href = queryStringObject.redirectUri;
    } else {
      return (
        <Navigate
          replace
          to={{
            pathname: `/workspace/${
              selectedUserTeam.split('-')[0]
            }/data-sources/create/data-source`,
            search: `?dataProvider=${dataProvider}`,
            state: { dataProvider: dataProvider.toUpperCase() },
          }}
        />
      );
    }
  }
  return (
    <Backdrop
      open
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      })}
    >
      <CircularProgress color="inherit" />{' '}
    </Backdrop>
  );
}

export default AuthDataProvider;
