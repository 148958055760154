import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function UpdateMaxRequestDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogs = useSelector((state) => state.dialogs);

  const selectedDataSource = useSelector((state) => state.selectedDataSource);

  const open = dialogs.showUpdateMaxRequestDialog;

  const [success, setSuccess] = useState(false);

  function closeDialog() {
    dispatch({
      type: types.SHOW_UPDATE_MAX_REQUEST,
      isVisible: false,
    });
    onClose();
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xs" onClose={closeDialog} open={open}>
        <Formik
          initialValues={{
            max: selectedDataSource?.rateLimit?.max ?? 0,
            interval: selectedDataSource?.rateLimit?.interval ?? 'MONTH',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const updatedDataSource = await updateDataSourceObject({
              id: selectedDataSource.id,
              rateLimit: {
                max: values.max,
                interval: values.interval,
              },
            });

            dispatch({
              type: types.UPDATE_TEAM_DATA_SOURCE,
              dataSource: updatedDataSource,
              teamId: selectedDataSource.team.id,
            });

            setSubmitting(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              closeDialog();
            }, 3000);
          }}
          validate={() => {
            const errors = {};

            return errors;
          }}
        >
          {(props) => (
            <Form>
              <DialogContent className={classes.container}>
                <TextField
                  error={props.errors.max !== undefined}
                  helperText={props.errors.max}
                  label="Max amount of requests"
                  margin="dense"
                  name="max"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  type="number"
                  value={props.values.max}
                  variant="filled"
                />

                <FormControl
                  className={classes.formControl}
                  fullWidth
                  variant="filled"
                >
                  <InputLabel>Interval</InputLabel>
                  <Select
                    onChange={(event) => {
                      props.setFieldValue('interval', event.target.value);
                    }}
                    value={props.values.interval}
                  >
                    <MenuItem value={'MONTH'}>Month</MenuItem>
                  </Select>
                </FormControl>

                {props.isSubmitting && <LinearProgress />}
              </DialogContent>
              <DialogActions className={classes.actionsContainer}>
                <Button
                  color="primary"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Close
                </Button>
                <Button
                  className={buttonClassname}
                  color="info"
                  disabled={
                    props.isSubmitting ||
                    props.values.displayName === selectedDataSource.displayName
                  }
                  onClick={props.submitForm}
                  type="submit"
                  variant="contained"
                >
                  {success && <Icon>check</Icon>}
                  {props.isSubmitting === true ? (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                    />
                  ) : (
                    'Update'
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default UpdateMaxRequestDialog;
