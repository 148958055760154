/* eslint-disable no-nested-ternary */
import { AccountCircleTwoTone, ArrowForwardIos } from '@mui/icons-material';
import { AppBar, Avatar, Box, Toolbar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import _filter from 'lodash/filter';
import React from 'react';
import { useSelector } from 'react-redux';
import avatarCredentialColors from '../../utilities/avatarCredentialColors';
import getInitials from '../../utilities/getInitials';
import numberFromText from '../../utilities/numberFromText';

import AddCredentialButton from './AddCredentialButton';

function CredentialPicker({
  setSelectedCredential,
  credentialSelected = () => {},
  selectedDataProvider,
  allowCreateCredential = true,
}) {
  const theme = useTheme();

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const userObject = useSelector((state) => state.userObject);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const { items: credentials } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };

  const filteredCredentials = _filter(credentials, (o) =>
    o.dataProvider.includes(selectedDataProvider)
  );

  const userId = userObject.id;

  return (
    <>
      <Box sx={{ m: 2 }}>
        {filteredCredentials.length === 0 && (
          <Box sx={{ mt: 4 }}>
            <Box display="flex" justifyContent="center">
              <AccountCircleTwoTone color="disabled" fontSize="large" />
            </Box>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              No credentials found for{' '}
              {dataProviderSettings?.config?.[selectedDataProvider]?.name}
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              display="block"
              gutterBottom
              variant="caption"
            >
              Add an credential below
            </Typography>
          </Box>
        )}

        <List>
          {filteredCredentials.map((credential) => {
            const {
              email,
              owners,
              editors,
              name,
              picture,
              id: credentialId,
            } = credential;

            let hasCredentialAccess =
              owners.includes(userId) || editors.includes(userId);

            if (credential.groups) {
              const groups =
                currentAuthenticatedUser.signInUserSession.accessToken.payload[
                  'cognito:groups'
                ];

              if (groups) {
                if (groups.includes(credential.groups)) {
                  hasCredentialAccess = true;
                }
              }
            }

            return (
              <ListItem
                button
                disabled={hasCredentialAccess === false}
                key={credentialId}
                onClick={() => {
                  setSelectedCredential(credential);
                  credentialSelected();
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={picture}
                    sx={() => ({
                      color: theme.palette.getContrastText(
                        avatarCredentialColors[
                          numberFromText(getInitials(name)) %
                            avatarCredentialColors.length
                        ]
                      ),
                      bgcolor:
                        avatarCredentialColors[
                          numberFromText(getInitials(name)) %
                            avatarCredentialColors.length
                        ],
                    })}
                  >
                    {getInitials(name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={
                    <>
                      <Typography
                        color="textSecondary"
                        component="span"
                        sx={() => ({
                          display: 'inline',
                        })}
                        variant="body2"
                      >
                        {email}
                      </Typography>
                      {hasCredentialAccess === false && (
                        <>
                          <br />
                          <Typography
                            color="textSecondary"
                            component="span"
                            sx={() => ({
                              display: 'inline',
                            })}
                            variant="caption"
                          >
                            No access, request access or add account again
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />

                <ArrowForwardIos
                  fontSize="small"
                  htmlColor={theme.palette.text.secondary}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      {allowCreateCredential === true && (
        <Box display="flex" flexDirection="column" marginTop="20px">
          <AppBar
            color="default"
            elevation={0}
            position="static"
            sx={() => ({
              borderTop: `1px solid ${theme.palette.action.disabled}`,
            })}
          >
            <Toolbar variant="dense">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
              >
                <AddCredentialButton
                  selectedDataProvider={selectedDataProvider}
                  variant="credentialPicker"
                />
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </>
  );
}

export default CredentialPicker;
