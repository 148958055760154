import isValidDomain from 'is-valid-domain';
import isValidUrl from './isValidUrl';

function depensOnCheckMatches(dependsOn, configParams) {
  if (dependsOn) {
    let shouldShow = true;
    dependsOn.forEach((dependsOnCheck) => {
      if (configParams[dependsOnCheck.input_id]) {
        if (dependsOnCheck.condition === 'EQUAL_TO') {
          if (
            configParams[dependsOnCheck.input_id].id !== dependsOnCheck.value
          ) {
            shouldShow = false;
          }
        } else if (dependsOnCheck.condition === 'NOT_EMPTY') {
          if (!configParams[dependsOnCheck.input_id].id) {
            shouldShow = false;
          }
        } else if (dependsOnCheck.condition === 'VALID_URL') {
          if (!isValidUrl(configParams[dependsOnCheck.input_id].id)) {
            shouldShow = false;
          }
        } else if (dependsOnCheck.condition === 'VALID_DOMAIN') {
          if (!isValidDomain(configParams[dependsOnCheck.input_id].id)) {
            shouldShow = false;
          }
        } else if (dependsOnCheck.condition === 'IS_MULTIPLE') {
          if (
            !configParams[dependsOnCheck.input_id]?.options ||
            configParams[dependsOnCheck.input_id]?.options?.length < 2
          ) {
            shouldShow = false;
          }
        }
      } else {
        shouldShow = false;
      }
    });

    return shouldShow;
  }
  return true;
}

export default depensOnCheckMatches;
