/* eslint-disable no-nested-ternary */
import { Label } from '@mui/icons-material';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Badge, Box, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { useSelector } from 'react-redux';

import * as dp from '../constants/DataProviders';
import dataSetIcon from '../data-set-icon.svg';
import dataSourceIcon from '../data-source-icon.svg';
import ListItemLink from './ListItemLink';

const SUGGESTION = 'SUGGESTION';
const REPORT = 'REPORT';
const DATA_SOURCE = 'DATA_SOURCE';
const LABEL = 'LABEL';
const CREDENTIAL = 'CREDENTIAL';
const DATA_PROVIDER = 'DATA_PROVIDER';

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

function SearchSuggestion({ suggestion, query, isHighlighted }) {
  const theme = useTheme();

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  if (suggestion.type === SUGGESTION) {
    const nameMatches = match(suggestion.name, query);
    const nameParts = parse(suggestion.name, nameMatches);

    let urlParts = [];

    if (suggestion.url) {
      let urlMatches = match(suggestion.url, query);
      urlParts = parse(suggestion.url, urlMatches);

      if (suggestion.variant === CREDENTIAL) {
        urlMatches = match(suggestion.email, query);
        urlParts = parse(suggestion.email, urlMatches);
      }
    }

    const primaryColor = theme.palette.primary.main;

    let dataProviderImage = '';
    let dataProviderLabel = '';

    if (suggestion.variant === DATA_SOURCE) {
      dataProviderImage =
        dataProviderSettings.config[suggestion.dataProvider].image;
      dataProviderLabel =
        dataProviderSettings.config[suggestion.dataProvider].name;
    }

    if (suggestion.variant === DATA_PROVIDER) {
      dataProviderImage = dataProviderSettings.config[suggestion.id].image;
      dataProviderLabel = dataProviderSettings.config[suggestion.id].name;
    }

    if (suggestion.variant === CREDENTIAL) {
      dataProviderImage =
        dataProviderSettings?.config[suggestion.dataProvider[0]]?.image;
      dataProviderLabel =
        dataProviderSettings?.config[suggestion.dataProvider[0]]?.name;
    }

    return (
      <ListItemLink
        component="div"
        href="#"
        key={suggestion.id}
        selected={isHighlighted}
      >
        {suggestion.variant === REPORT ? (
          <ListItemIcon>
            <InsertChartIcon htmlColor={primaryColor} />
          </ListItemIcon>
        ) : suggestion.variant === LABEL ? (
          <ListItemIcon>
            <Label color="secondary" fontSize="large" />
          </ListItemIcon>
        ) : suggestion.variant === CREDENTIAL ? (
          <ListItemIcon>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <SmallAvatar
                  src={dataProviderImage}
                  title={dataProviderLabel}
                />
              }
              overlap="circular"
            >
              <Avatar src={suggestion.picture} title={suggestion.name} />
            </Badge>
          </ListItemIcon>
        ) : suggestion.variant === DATA_PROVIDER ? (
          <ListItemIcon>
            <Avatar src={dataProviderImage} title={dataProviderLabel} />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <SmallAvatar
                  src={
                    suggestion.dataProvider === dp.MULTI_DATA_PROVIDER
                      ? dataSetIcon
                      : dataSourceIcon
                  }
                />
              }
              overlap="circular"
            >
              <Avatar src={dataProviderImage} title={dataProviderLabel} />
            </Badge>
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Box component="span">
              {nameParts.map((part) => (
                <span
                  key={suggestion.id + part.text}
                  style={{
                    fontWeight: part.highlight ? 500 : 300,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Box>
          }
          secondary={
            <Typography color="textSecondary" component="span" variant="body2">
              {urlParts.map((part) => (
                <span
                  key={part.text}
                  style={{
                    fontWeight: part.highlight ? 500 : 300,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Typography>
          }
        />
      </ListItemLink>
    );
  }
  return (
    <>
      <ListItemLink
        component="div"
        href="#"
        key={suggestion.name}
        selected={isHighlighted}
      >
        <ListItemIcon>
          <SearchIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={query} />
      </ListItemLink>
      <Divider />
    </>
  );
}

export default SearchSuggestion;
