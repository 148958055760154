function getInitials(name) {
  if (name) {
    const [firstName, lastName] = name
      .replace('-', '')
      .replace('(', '')
      .split(' ');

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }

    return firstName.charAt(0).toUpperCase();
  }
  return 'E';
}

export default getInitials;
