import { Delete, Webhook } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography } from '@mui/material';

export default function EditorNode({ name, onClick, removeNode, isFixed }) {
  return (
    <Paper
      onClick={(event) => {
        onClick(event);
      }}
      sx={(theme) => ({
        display: 'flex',
        width: '300px',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        border: `2px solid ${theme.palette.info.main}`,
        margin: 1,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Webhook
          sx={{
            fontSize: '3rem',
            margin: 1,
          }}
        ></Webhook>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body1">
            test name
            {
              // query?.name}
            }
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        <IconButton
          disabled={isFixed}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            removeNode();
          }}
        >
          <Delete color={isFixed ? 'disabled' : 'error'}></Delete>
        </IconButton>
      </Box>
    </Paper>
  );
}
