import { Check, Search } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import _orderBy from 'lodash/orderBy';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import getAllLabels from '../../api/getAllLabels';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql/mutations';
import isValidUrl from '../../utilities/isValidUrl';
import useDebounce from '../../utilities/useDebounce';
import ListItemLink from '../ListItemLink';
import NewLabelDialog from './NewLabelDialog';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  labelToolbar: {
    height: '35px',
    minHeight: '35px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableWrapper: {
    maxHeight: 150,
    minHeight: 150,
    overflow: 'auto',
  },
}));

const loader = (
  <ListItemLink key={0}>
    <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress size={20} />
    </Box>
  </ListItemLink>
);

const SelectLabel = React.forwardRef(
  ({ labelIds, dataSourceId, teamId, closePopper }, ref) => {
    const classes = useStyles();

    const scrollParentRef = useRef();
    const dispatch = useDispatch();

    const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

    const [nextToken, setNextToken] = useState(null);
    const [labels, setLabels] = useState([]);
    const [query, setQuery] = useState('');

    const debouncedSearchTerm = useDebounce(query, 1000);
    const [waitForQuery, setWaitForQuery] = useState(false);
    const [showLoaderForLabel, setShowLoaderForLabel] = useState(-1);
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [urlLabel, setUrlLabel] = useState('');

    const debouncedUrlLabel = useDebounce(urlLabel, 1000);

    async function createdCallBack(labelObj) {
      if (!labelIds.includes(labelObj.id)) {
        const results = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const lid of labelIds) {
          results.push(
            API.graphql(
              graphqlOperation(mutations.removeLabelFromDataSource, {
                labelId: lid,
                dataSourceId,
                teamId,
              })
            )
          );
        }

        await Promise.all(results);
        await API.graphql(
          graphqlOperation(mutations.addLabelToDataSource, {
            labelId: labelObj.id,
            dataSourceId,
            teamId,
          })
        );

        const updatedDataSource = await updateDataSourceObject({
          id: dataSourceId,
        });

        dispatch({
          type: types.UPDATE_TEAM_DATA_SOURCE,
          dataSource: updatedDataSource,
          teamId: updatedDataSource.team.id,
        });
      }
    }

    async function fetchLabels() {
      let filter;
      if (debouncedSearchTerm) {
        filter = {
          or: [
            {
              nameLowercase: {
                contains: debouncedSearchTerm.trim().toLowerCase(),
              },
            },
          ],
        };
      }

      let labelsToAdd = [];
      const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
        await getAllLabels(selectedUserTeam, undefined, 10, filter);

      labelsToAdd = [...labelsToAdd, ...newDataSourceLabels];

      if (debouncedSearchTerm === '') {
        const { dataSourceLabels: suggestedDataSourceLabels } =
          await getAllLabels(selectedUserTeam, undefined, 10, undefined);

        const suggestedDataSourceLabelsToAddIds = suggestedDataSourceLabels.map(
          (o) => o.id
        );

        const suggestedDataSourceLabelsToAdd = suggestedDataSourceLabels.map(
          (o) => ({ ...o, isSuggestion: true })
        );

        const filteredDataSourceLabelsToAdd = labelsToAdd.filter(
          (o) => !suggestedDataSourceLabelsToAddIds.includes(o.id)
        );

        labelsToAdd = [
          ...suggestedDataSourceLabelsToAdd,
          ...filteredDataSourceLabelsToAdd,
        ];
      }

      labelsToAdd = labelsToAdd.map((lta) => ({
        ...lta,
        isConnected: labelIds.includes(lta.id),
      }));

      labelsToAdd = _orderBy(
        labelsToAdd,
        ['isConnected', 'name'],
        ['desc', 'asc']
      );

      setLabels(labelsToAdd);

      setNextToken(newNextToken);
      setWaitForQuery(false);
    }

    useEffect(() => {
      fetchLabels();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    useEffect(() => {
      if (
        !debouncedUrlLabel.startsWith('http://') &&
        !debouncedUrlLabel.startsWith('https://')
      ) {
        if (isValidUrl(`https://${debouncedUrlLabel}`)) {
          setUrlLabel(`https://${debouncedUrlLabel}`);
        }
      }
    }, [debouncedUrlLabel]);

    async function loadItems() {
      let filter;
      if (debouncedSearchTerm) {
        filter = {
          or: [
            {
              nameLowercase: {
                contains: debouncedSearchTerm.trim().toLowerCase(),
              },
            },
          ],
        };
      }
      const { dataSourceLabels: newDataSourceLabels, nextToken: newNextToken } =
        await getAllLabels(selectedUserTeam, nextToken, 10, filter);

      let labelsToAdd = [...labels, ...newDataSourceLabels];

      labelsToAdd = labelsToAdd.map((lta) => ({
        ...lta,
        isConnected: labelIds.includes(lta.id),
      }));

      labelsToAdd = _orderBy(
        labelsToAdd,
        ['isConnected', 'name'],
        ['desc', 'asc']
      );

      setLabels(labelsToAdd);
      setNextToken(newNextToken);
    }

    return (
      <div className={classes.paper} ref={ref}>
        <NewLabelDialog
          closePopper={closePopper}
          createdCallBack={createdCallBack}
          selectedUserTeam={selectedUserTeam}
          setShowNewDialog={setShowNewDialog}
          showNewDialog={showNewDialog}
        />

        <Paper elevation={5}>
          <Box width="300px">
            <Box>
              <Box p={2} width="100%">
                <Typography variant="subtitle2">
                  Apply label to this Data Source
                </Typography>
              </Box>

              <AppBar
                className={classes.searchBar}
                color="default"
                elevation={0}
                position="static"
              >
                <Toolbar className={classes.labelToolbar} variant="dense">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                  >
                    <InputBase
                      className={classes.input}
                      margin="dense"
                      onChange={(e) => {
                        if (waitForQuery === false) {
                          setWaitForQuery(true);
                        }
                        setQuery(e.target.value);
                      }}
                      placeholder="Filter labels"
                      type="search"
                      value={query}
                    />
                    <IconButton
                      aria-label="search"
                      className={classes.iconButton}
                      size="large"
                    >
                      {waitForQuery === true ? (
                        <CircularProgress size={15} />
                      ) : (
                        <Search />
                      )}
                    </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
            </Box>

            <Box>
              <div className={classes.tableWrapper} ref={scrollParentRef}>
                <InfiniteScroll
                  hasMore={waitForQuery === false ? nextToken !== null : false}
                  loadMore={loadItems}
                  loader={loader}
                  pageStart={0}
                  useWindow={false}
                >
                  <List dense>
                    {labels.map((item, i) => (
                      <ListItem
                        button
                        key={item.id}
                        onClick={async () => {
                          if (!labelIds.includes(item.id)) {
                            setShowLoaderForLabel(i);
                            const results = [];

                            // eslint-disable-next-line no-restricted-syntax
                            for (const lid of labelIds) {
                              results.push(
                                API.graphql(
                                  graphqlOperation(
                                    mutations.removeLabelFromDataSource,
                                    {
                                      labelId: lid,
                                      dataSourceId,
                                      teamId,
                                    }
                                  )
                                )
                              );
                            }
                            await Promise.all(results);

                            await API.graphql(
                              graphqlOperation(mutations.addLabelToDataSource, {
                                labelId: item.id,
                                dataSourceId,
                                teamId,
                              })
                            );

                            const updatedDataSource =
                              await updateDataSourceObject({
                                id: dataSourceId,
                              });

                            dispatch({
                              type: types.UPDATE_TEAM_DATA_SOURCE,
                              dataSource: updatedDataSource,
                              teamId: updatedDataSource.team.id,
                            });

                            setShowLoaderForLabel(-1);
                            closePopper();
                          }
                        }}
                      >
                        <Box width="14px">
                          {showLoaderForLabel === i ? (
                            <CircularProgress size={10} />
                          ) : (
                            <>
                              {labelIds.includes(item.id) && (
                                <Check fontSize="inherit" />
                              )}
                            </>
                          )}
                        </Box>

                        <ListItemText
                          disableTypography
                          primary={
                            <Typography fontSize="" noWrap type="body2">
                              <Box
                                component="span"
                                display="flex"
                                flexDirection="row"
                                fontSize={14}
                                m={1}
                              >
                                <Box component="span" mr={1}>
                                  <Icon color="secondary" fontSize="inherit">
                                    language
                                  </Icon>
                                </Box>

                                {item.name}
                              </Box>
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </InfiniteScroll>
              </div>

              <Divider />
              <Box p={1}>
                <Button
                  fullWidth
                  onClick={async () => {
                    setShowNewDialog(true);
                  }}
                >
                  Create new{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    );
  }
);

export default SelectLabel;
