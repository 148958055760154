import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import IntegrationStepList from './integrationStepList';

export default function SubSteps({
  step,
  setConfig,
  configObj,
  queryObj,
  messageType,
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!configObj) {
      setConfig([{}]);
    }
    if (typeof configObj === 'string' || configObj instanceof String) {
      setConfig(JSON.parse(configObj));
    }
  }, []);

  return (
    <Box
      sx={(theme) => ({
        borderRadius: 1,
        m: 1,
        outline: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
      })}
    >
      <Box
        component={Button}
        onClick={() => {
          setOpen(!open);
        }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'background.default',
          p: 1,
          borderRadius: 0,
          width: '100%',
          fontWeight: 'normal',
        }}
      >
        {step.title}{' '}
        <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </Box>
      <Collapse in={open}>
        <Box
          sx={(theme) => ({
            p: 1,
            borderTop: `1px solid  ${theme.palette.divider}`,
          })}
        >
          <IntegrationStepList
            config={step}
            configObj={configObj?.[0]}
            messageType={messageType}
            queryObj={queryObj}
            setConfig={(value) => {
              setConfig((prev) => [value(prev[0])]);
            }}
          ></IntegrationStepList>
        </Box>
      </Collapse>
    </Box>
  );
}
