import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Auth, Storage } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createAvatarObject from '../../api/createAvatarObject';
import * as types from '../../constants/ActionTypes';
import UserAvatar from './UserAvatar';
import UserUploadAvatarDialog from './UserUploadAvatarDialog';

const useStyles = makeStyles(() => ({
  avatarButtons: {
    marginBottom: '10px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function UserAvatarDialog({ handleClose, open }) {
  const userObject = useSelector((state) => state.userObject);

  const classes = useStyles();
  const dispatch = useDispatch();

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const [openDialog, setOpenDialog] = useState(false);

  const [avatarImageSrc, setAvatarImageSrc] = useState('');

  const [success, setSuccess] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {
    async function getS3AvatarLink() {
      const result = await Storage.get(userObject.avatar.file.key);
      setAvatarImageSrc(result);
    }

    if (userObject.avatar) {
      getS3AvatarLink();
    }

    if (!userObject.avatar) {
      setAvatarImageSrc('');
    }
  }, [userObject.avatar]); // Onl

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // reason behind:  {openDialog === true && (
  return (
    <>
      {openDialog === true && (
        <UserUploadAvatarDialog
          closeDialog={handleDialogClose}
          handleClose={handleDialogClose}
          open={openDialog}
        />
      )}

      <Dialog
        aria-labelledby="user-avatar-settings-dialog-title"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="user-avatar-settings-dialog-title">
          Edit Profile
        </DialogTitle>
        <DialogContent>
          <Box width="100%">
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              Public avatar
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box p={2}>
              {avatarImageSrc && (
                <UserAvatar
                  avatar={userObject.avatar}
                  id={userObject.id}
                  name={currentAuthenticatedUser.attributes.name}
                  size="big"
                />
              )}
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box>
                <Button
                  className={classes.avatarButtons}
                  fullWidth
                  onClick={handleDialogOpen}
                  variant="outlined"
                >
                  Upload new avatar
                </Button>
              </Box>
              {avatarImageSrc && (
                <Box>
                  <Button
                    className={classes.avatarButtons}
                    fullWidth
                    onClick={async () => {
                      const updatedUser = await createAvatarObject(
                        userObject.id,
                        null
                      );
                      dispatch({
                        type: types.SET_USER_OBJECT,
                        userObject: updatedUser,
                      });
                    }}
                    variant="outlined"
                  >
                    Delete Avatar
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box width="100%">
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              Profile
            </Typography>
          </Box>
          <Box width="100%">
            <Formik
              enableReinitialize // because of currentAuthenticatedUser
              initialValues={{
                name: currentAuthenticatedUser.attributes.name,
              }}
              onSubmit={async (values, submitObject) => {
                const { setSubmitting } = submitObject;

                try {
                  const { name } = values;
                  const user = await Auth.currentAuthenticatedUser();
                  await Auth.updateUserAttributes(user, {
                    name,
                  });

                  const currentAuthUser = await Auth.currentAuthenticatedUser();
                  dispatch({
                    type: types.SET_CURRENT_AUTHENTICATED_USER,
                    currentAuthenticatedUser: currentAuthUser,
                  });
                  setSubmitting(false);
                  setSuccess(true);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 500);
                } catch (e) {
                  setSubmitting(false);
                  setSuccess(false);
                }
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Required field';
                }

                return errors;
              }}
            >
              {(props) => (
                <Form>
                  <Box display="flex" flexDirection="column">
                    <TextField
                      error={props.errors.name !== undefined}
                      helperText={props.errors.name}
                      label="Name"
                      margin="dense"
                      name="name"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.name}
                      variant="filled"
                    />

                    {props.status && props.status.error && (
                      <p>{props.status.error}</p>
                    )}
                    {props.isSubmitting && <LinearProgress />}

                    <Box mt={2}>
                      <Button
                        className={buttonClassname}
                        color="primary"
                        disabled={
                          props.isSubmitting ||
                          props.values.name ===
                            currentAuthenticatedUser.attributes.name
                        }
                        onClick={props.submitForm}
                        type="submit"
                        variant="contained"
                      >
                        {success && <Icon>check</Icon>}
                        {props.isSubmitting === true ? (
                          <CircularProgress
                            className={classes.buttonProgress}
                            size={24}
                          />
                        ) : (
                          'Update profile'
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>

          <Box mt={2} width="100%">
            <Typography color="" gutterBottom variant="body2">
              Do you want to delete this account,{' '}
              <Link
                href="https://datahub.gladior.com/support"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact support
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
            width="100%"
          >
            <Typography color="textSecondary" gutterBottom variant="caption">
              {userObject?.username}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserAvatarDialog;
