/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-boolean-value */
import {
  Email,
  ManageAccounts,
  SupervisedUserCircle,
} from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  ButtonBase,
  CardHeader,
  Divider,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';
import ellipsis from 'text-ellipsis';

import React from 'react';
import { Link } from 'react-router-dom';
import UserAvatar from '../user/UserAvatar';
import TeamActionButton from './TeamActionButton';
import TeamAvatar from './TeamAvatar';

function getInitials(name) {
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
}

const useStyles = makeStyles((theme) => ({
  cardHeaderAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    width: '100%',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '10px',
  },
}));

function WorkspaceRow({ invite, getTeamInvites }) {
  const classes = useStyles();
  const theme = useTheme();

  let permission = '';

  switch (invite.permission) {
    case 'TEAM_MEMBER':
      permission = 'member';
      break;
    case 'EDITOR':
      permission = 'editor';
      break;
    case 'ADMIN':
      if (invite.inviteType === 'OWNER') {
        permission = 'owner';
      } else {
        permission = 'admin';
      }
      break;

    default:
      break;
  }

  const cardHeader = (
    <>
      <Box mt={2}>
        {invite.inviteType === 'ADMIN_INVITE' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={2}
          >
            <Typography color="textSecondary" variant="caption">
              <SupervisedUserCircle sx={{ fontSize: 'inherit' }} />{' '}
              <b>{invite?.admin?.email}</b> has invited you to join this
              workspace
            </Typography>
          </Box>
        )}

        {invite.inviteType === 'EMAIL_DOMAIN_INVITE' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={2}
          >
            <Typography color="textSecondary" variant="caption">
              <Email sx={{ fontSize: 'inherit' }} /> Anyone with{' '}
              <b>{invite.team.emailDomain}</b> can join
            </Typography>
          </Box>
        )}

        {invite.inviteType === 'MEMBER' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={2}
          >
            <Typography color="textSecondary" variant="caption">
              <Email sx={{ fontSize: 'inherit' }} /> You are part of this
              workspace
            </Typography>
          </Box>
        )}

        {invite.inviteType === 'OWNER' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={2}
          >
            <Typography color="textSecondary" variant="caption">
              <ManageAccounts sx={{ fontSize: 'inherit' }} /> You are owner of
              this workspace
            </Typography>
          </Box>
        )}

        {invite.inviteType === 'AUTO_JOIN_INVITE' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mx={2}
          >
            <Typography color="textSecondary" variant="caption">
              <Email sx={{ fontSize: 'inherit' }} /> Anyone with{' '}
              <b>{invite.team.emailDomain}</b> can join
            </Typography>

            <Typography color="textSecondary" variant="caption">
              <Box
                color={green[500]}
                component="span"
                ml={2}
                style={{
                  borderColor: green[500],
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderRadius: '16px',
                  paddingRight: '5px',
                  paddingLeft: '5px',
                  paddingTop: '1px',
                  paddingBottom: '1px',
                }}
              >
                <Box color={green[500]} component="span">
                  <Box component="span" mr={1}>
                    <Icon
                      color="inherit"
                      fontSize="inherit"
                      style={{
                        position: 'relative',
                        top: '1px',
                      }}
                    >
                      check_circle
                    </Icon>
                  </Box>
                  Auto accepted
                </Box>
              </Box>
            </Typography>
          </Box>
        )}
      </Box>

      <CardHeader
        action={
          <Tooltip title="Launch Workspace">
            <>
              <TeamActionButton
                getTeamInvites={getTeamInvites}
                invite={invite}
              />
            </>
          </Tooltip>
        }
        avatar={
          <>
            {invite.team.avatar ? (
              <TeamAvatar
                avatar={invite.team.avatar}
                id={invite.team.id}
                name={invite.team.name}
              />
            ) : (
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                {getInitials(invite.team.name)}
              </Avatar>
            )}
          </>
        }
        classes={{
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Box>
            <Box alignContent="center" display="flex" flexDirection="row">
              <AvatarGroup
                classes={{
                  avatar: classes.small,
                }}
                max={4}
              >
                {invite.team.members.map((member) => (
                  <UserAvatar
                    avatar={member.avatar}
                    id={member.id}
                    key={member.id}
                    name="member"
                    size="small"
                  />
                ))}
              </AvatarGroup>
              <span style={{ marginLeft: '5px', lineHeight: '26px' }}>
                {invite.team.membersCount}{' '}
                {invite.team.membersCount < 2 ? 'member' : 'members'}
              </span>
            </Box>
          </Box>
        }
        title={
          <Typography variant="body1">
            {ellipsis(invite.team.name, 45)}{' '}
            <Typography
              color="textSecondary"
              component="span"
              variant="caption"
            >
              ({permission})
            </Typography>
          </Typography>
        }
      />
    </>
  );

  return (
    <Box key={invite.id} style={{ width: '100%' }} variant="outlined">
      {invite.status === 'ACCEPTED' ? (
        <ButtonBase
          // eslint-disable-next-line react/no-unstable-nested-components
          className={classes.cardAction}
          component={React.forwardRef((props, ref) => (
            <Link
              ref={ref}
              to={`/workspace/${invite.teamId.split('-')[0]}/data-sources`}
              {...props}
            />
          ))}
        >
          {cardHeader}
        </ButtonBase>
      ) : (
        <>{cardHeader}</>
      )}
      <Divider />
    </Box>
  );
}

export default WorkspaceRow;
