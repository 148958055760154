import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

async function deleteIntegration(integrationId) {
  await API.graphql(
    graphqlOperation(mutations.removeIntegration, {
      integrationId,
    })
  );

  return integrationId;
}

export default deleteIntegration;
