import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

async function fetchAllTemplates() {
  const teamsQueryObject = await API.graphql(
    graphqlOperation(queries.listTemplates, {
      input: {},
      limit: 1000,
    })
  );
  const templateItems = teamsQueryObject.data.listTemplates.items;
  const nextTokenBack = teamsQueryObject.data.listTemplates.nextToken;

  return { newTemplates: templateItems, newNextToken: nextTokenBack };
}

export default fetchAllTemplates;
