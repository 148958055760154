/* eslint-disable no-nested-ternary */
import { CopyAll, Sync } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';

async function sendRequest(url, method) {
  const tokens = await Auth.currentSession();

  return API[method]('BackendApi', url, {
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
  });
}

function PowerBIDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const dialogs = useSelector((state) => state.dialogs);
  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const open = dialogs.showPowerBiDialog;
  const [accessToken, setAccessToken] = useState('');

  function closeDialog() {
    dispatch({
      type: types.SHOW_POWER_BI_DIALOG,
      isVisible: false,
    });
    onClose();
  }
  useEffect(() => {
    if (selectedDataSource) {
      sendRequest(`/accessToken/${selectedDataSource.id}`, 'get').then(
        (response) => {
          setAccessToken(response.data);
        }
      );
    }
  }, [selectedDataSource]);
  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={open}>
        <DialogTitle sx={{ textAlign: 'center' }}>Power BI</DialogTitle>

        <DialogContent>
          <Box>
            <Typography
              color="textSecondary"
              gutterBottom
              sx={{
                mb: 0,
                mt: 1,
              }}
              variant="subtitle2"
            >
              Access Token
            </Typography>
            <TextField
              InputProps={{
                style: {
                  background: 'transparent',
                  padding: 0,
                },
                endAdornment: (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        enqueueSnackbar('Generating Acess Token', {
                          variant: 'info',
                        });
                        sendRequest(
                          `/accessToken/${selectedDataSource.id}`,
                          'post'
                        ).then((response) => {
                          setAccessToken(response.data);
                          enqueueSnackbar('Acess Token Generated', {
                            variant: 'success',
                          });
                        });
                      }}
                    >
                      <Sync></Sync>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(accessToken);
                        enqueueSnackbar('Copied to clipboard', {
                          variant: 'success',
                        });
                      }}
                    >
                      <CopyAll></CopyAll>
                    </IconButton>
                  </Box>
                ),
              }}
              disabled
              fullWidth
              sx={{
                background: 'transparent',
              }}
              value={accessToken ?? ''}
              variant="filled"
            ></TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PowerBIDialog;
