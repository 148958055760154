import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add, Delete, Lock, Public, Refresh } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { API, Auth } from 'aws-amplify';
import _find from 'lodash/find';
import React, { useEffect } from 'react';
import 'react-json-pretty/themes/monikai.css';
import Carousel from 'react-multi-carousel';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import * as types from '../../constants/ActionTypes';
import dataStudioLogo from '../../data_studio_logo.svg';
import DataProviderChip from '../data/DataProviderChip';
import TemplateImage from './TemplateImage';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();

  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

function EditTemplateDialog() {
  const { user } = useAuthenticator((context) => [context.user]);
  const { templateId, workspaceId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/templates/refresh',
    sendRequest
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // redux selectors
  const selectedTemplate = useSelector((state) => state.selectedTemplate);

  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showEditTemplateDialog;
  const templatesByTeam = useSelector((state) => state.templatesByTeam);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  let mayDeleteTemplate = false;
  const groups = user.getSignInUserSession().getIdToken().payload[
    'cognito:groups'
  ];

  if (
    groups.includes(`${selectedUserTeam}-edit-rights`) &&
    selectedTemplate?.editGroups ===
      groups.includes(`${selectedUserTeam}-edit-rights`)
  ) {
    mayDeleteTemplate = true;
  }

  function onClose() {
    if (dialogs.showEditTemplateDialog === false) {
      dispatch({
        type: types.SET_SELECTED_TEMPLATE,
        template: null,
      });
    }
  }

  const { items: templates } = templatesByTeam[selectedUserTeam] || {
    items: [],
  };

  useEffect(() => {
    if (templateId && templates.length > 0) {
      const updatedTemplate = _find(templates, (o) => o.id === templateId);

      if (updatedTemplate) {
        batch(() => {
          dispatch({
            type: types.SET_SELECTED_TEMPLATE,
            template: updatedTemplate,
          });

          dispatch({
            type: types.SHOW_EDIT_TEMPLATE_DIALOG,
            isVisible: true,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templates]);

  // Local state

  function closeDialog() {
    dispatch({
      type: types.SET_SELECTED_TEMPLATE,
      template: null,
    });

    dispatch({
      type: types.SHOW_EDIT_TEMPLATE_DIALOG,
      isVisible: false,
    });

    navigate({
      pathname: `/workspace/${workspaceId}/templates`,
      search: location.search,
    });

    onClose();
  }

  if (!selectedTemplate) {
    return <></>;
  }

  return (
    <>
      <Dialog
        aria-describedby="edit-template-dialog-description"
        aria-labelledby="edit-template-dialog-title"
        disableEnforceFocus
        fullWidth
        maxWidth="xs"
        onClose={closeDialog}
        open={open}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            {selectedTemplate.groups === 'public' ? (
              <Chip
                color="info"
                icon={<Public fontSize="inherit" />}
                label="Community template"
                variant="outlined"
              />
            ) : (
              <Chip
                color="info"
                icon={<Lock fontSize="inherit" />}
                label="Personal template"
                variant="outlined"
              />
            )}
          </Box>
          <Typography align="center" noWrap variant="h6">
            {selectedTemplate.name}
          </Typography>
          <Typography
            align="center"
            color={'textSecondary'}
            gutterBottom
            noWrap
            variant="subtitle1"
          >
            {selectedTemplate.type === 'LOOKER_STUDIO' &&
              'Looker Studio template'}
          </Typography>
          <Typography
            align="center"
            color={'textSecondary'}
            component={'p'}
            sx={{ width: '100%', mt: 2 }}
            variant="caption"
          >
            Contains:
          </Typography>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{ mt: 1 }}
          >
            {selectedTemplate.dataProviders.map((dataProvider) => (
              <DataProviderChip
                dataProvider={dataProvider}
                key={dataProvider}
                variant="avatar"
              />
            ))}
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 2,
              mb: 2,
            }}
          >
            <Box sx={{ width: '100%' }}>
              {selectedTemplate?.pages?.length > 0 && (
                <Carousel
                  responsive={{
                    superLargeDesktop: {
                      breakpoint: { max: 4000, min: 3000 },
                      items: 1,
                    },
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                    },
                  }}
                >
                  {selectedTemplate.pages.map((pageObj) => (
                    <ImageListItem sx={{ maxHeight: '300px' }} key={pageObj.id}>
                      <TemplateImage image={pageObj.image} />
                      <ImageListItemBar
                        subtitle={
                          <>
                            <Box
                              sx={{
                                my: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {pageObj.datasourceAliases.map((al) => (
                                <DataProviderChip
                                  dataProvider={al.dataProvider}
                                  key={al.dataSourceId}
                                  variant="avatar"
                                />
                              ))}
                            </Box>
                          </>
                        }
                        sx={{
                          background:
                            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        title={pageObj.name}
                      />
                    </ImageListItem>
                  ))}
                </Carousel>
              )}

              {!selectedTemplate.pages && (
                <ImageListItem>
                  <TemplateImage image={selectedTemplate.image} />
                  <ImageListItemBar
                    sx={{
                      background:
                        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                  />
                </ImageListItem>
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}>
            <Button
              sx={(theme) => ({
                textTransform: 'none',
                border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
                textAlign: 'left',
              })}
              href={`https://lookerstudio.google.com/reporting/${selectedTemplate.view.lookerStudioId}/}`}
              startIcon={
                <Avatar
                  src={dataStudioLogo}
                  sx={{ width: 12, height: 20 }}
                  variant="square"
                />
              }
              variant="outlined"
            >
              View Template in Looker Studio
            </Button>
          </Box>

          {selectedTemplate?.pages?.length > 0 && (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}
            >
              <Button
                onClick={async () => {
                  searchParams.set('templateId', selectedTemplate.id);
                  setSearchParams(searchParams);

                  navigate({
                    pathname: `/workspace/${
                      selectedUserTeam.split('-')[0]
                    }/data-sources/create/dashboard`,
                    search: searchParams.toString(),
                  });
                }}
                startIcon={<Add color={'secondary'} />}
                sx={(theme) => ({
                  textTransform: 'none',
                  border: `1px solid ${alpha(
                    theme.palette.common.black,
                    0.23
                  )}`,
                  textAlign: 'left',
                })}
                variant="outlined"
              >
                Create new dashboard
              </Button>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}>
            <Button
              disabled={selectedTemplate.groups === 'public'}
              onClick={async () => {
                await trigger({
                  templateId: selectedTemplate.id,
                  workspaceId: selectedUserTeam,
                });
              }}
              startIcon={
                <Refresh
                  color={
                    selectedTemplate.groups === 'public'
                      ? 'disabled'
                      : 'secondary'
                  }
                />
              }
              sx={(theme) => ({
                textTransform: 'none',
                border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
                textAlign: 'left',
              })}
              variant="outlined"
            >
              Update screenshots
            </Button>
          </Box>

          {/*  Check if user can delete template, is workspace admin */}
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}>
            <Button
              disabled={!mayDeleteTemplate}
              onClick={() => {
                batch(() => {
                  dispatch({
                    type: types.SET_SELECTED_TEMPLATE,
                    template: selectedTemplate,
                  });

                  dispatch({
                    type: types.SHOW_DELETE_TEMPLATE_DIALOG,
                    isVisible: true,
                  });
                });
              }}
              startIcon={
                <Delete color={!mayDeleteTemplate ? 'disabled' : 'secondary'} />
              }
              sx={(theme) => ({
                textTransform: 'none',
                border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
                textAlign: 'left',
              })}
              variant="outlined"
            >
              Delete Template
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditTemplateDialog;
