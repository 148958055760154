/* eslint-disable react/jsx-boolean-value */
import {
  InfoOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  MobileStepper,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../constants/ActionTypes';
import dataHubTextDev from '../data-hub-text-dev.svg';
import dataHubTextProd from '../data-hub-text-prod.svg';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import integrityIcon from '../Icon-Data-Integrity.svg';
import dataSourceIcon from '../Icon-Data-Source.svg';
import freeAndIcon from '../Icon-Free-easy-to-use.svg';
import templateIcon from '../Icon-Template-for-every-data-source.svg';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

const useStyles = makeStyles((theme) => ({
  welcomeDialog: {
    color: theme.palette.common.black,
    background: 'rgba(254, 225, 2, 0.102)',
  },

  stepButton: {
    color: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.grey[600],
    },
  },

  stepper: {
    background: '#3D4147',
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
  },
}));

const stepCount = 6;

function WelcomeScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const [showScreen, setShowScreen] = useState(false);

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    async function check() {
      const excludeArray = ['teams'];

      const updateDataSourceFilter = (path) => {
        const re = new RegExp(`getUser.(${excludeArray.join('|')})$`);
        return !re.test(path);
      };
      const user = await API.graphql(
        graphqlOperation(
          filterGraphQLFields(queries.getUser, updateDataSourceFilter),
          { id: currentAuthenticatedUser?.attributes?.sub }
        )
      );

      const { getUser } = user.data;

      if (
        !getUser?.userSettings ||
        getUser?.userSettings?.onboardingCompleted === false
      ) {
        // setShowScreen(true);
        setShowScreen(false);
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only re-run the effect if count changes

  async function closeScreen() {
    const excludeArray = ['teams'];

    const updateUserFilter = (path) => {
      const re = new RegExp(`updateUser.(${excludeArray.join('|')})$`);
      return !re.test(path);
    };

    const userUpdatedResponse = await API.graphql(
      graphqlOperation(
        filterGraphQLFields(mutations.updateUser, updateUserFilter),
        {
          input: {
            id: currentAuthenticatedUser?.attributes?.sub,
            userSettings: {
              onboardingCompleted: true,
            },
          },
        }
      )
    );

    const updatedUser = userUpdatedResponse.data.updateUser;
    dispatch({
      type: types.SET_USER_OBJECT,
      userObject: updatedUser,
    });

    setShowScreen(false);
  }

  return (
    <>
      {showScreen === true && (
        <Confetti
          colors={['#0090CB', '#62AF7C', '#F0DC0B']}
          numberOfPieces={activeStep === 0 ? 200 : 0}
        />
      )}
      <Dialog maxWidth="sm" onClose={() => {}} open={showScreen}>
        <Box className={classes.welcomeDialog}>
          <Box minHeight="400px" width="600px">
            {activeStep === 0 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  m={2}
                  mt={4}
                >
                  <img
                    alt="Data Hub Logo"
                    src={
                      process.env.REACT_APP_USER_BRANCH === 'prod'
                        ? dataHubTextProd
                        : dataHubTextDev
                    }
                    style={{ height: '75px' }}
                  />
                </Box>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Typography align="center" gutterBottom variant="h5">
                    <strong>
                      Hi {currentAuthenticatedUser.attributes.name}
                    </strong>
                  </Typography>

                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    mt={1}
                  >
                    <Box maxWidth="400px">
                      <Typography align="center" variant="body2">
                        Welcome to the Data Hub. Really good & simple tool for
                        connecting all your data to your favourite Dashboarding
                        tool.
                        <br />
                        <br />
                        We believe that data is everything! With data we can
                        make data driven decisions.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    m={2}
                    mt={4}
                  >
                    <img alt="" src={freeAndIcon} style={{ height: '70px' }} />
                  </Box>
                  <Typography align="center" gutterBottom variant="h5">
                    <strong>Free and easy to use</strong>
                  </Typography>

                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    mt={1}
                  >
                    <Box maxWidth="400px">
                      <Typography align="center" variant="body2">
                        It remains free your personal workspace. <br />
                        <br />
                        The Data Hub is a tool that was initially developed for
                        our own use. We couldn’t keep it to ourselves, because
                        we think it will solve the problems for many other data
                        enthusiasts. It is our pleasure to share the Data Hub
                        with you!
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                  >
                    <Chip
                      clickable
                      color="secondary"
                      component="a"
                      href="https://datahub.gladior.com"
                      icon={<InfoOutlined />}
                      label="Learn more"
                      size="small"
                      target="_blank"
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 2 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    m={2}
                    mt={4}
                  >
                    <img
                      alt=""
                      src={dataSourceIcon}
                      style={{ height: '70px' }}
                    />
                  </Box>
                  <Typography align="center" gutterBottom variant="h5">
                    <strong>All your data sources in one place</strong>
                  </Typography>

                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    mt={1}
                  >
                    <Box maxWidth="400px">
                      <Typography align="center" variant="body2">
                        Don’t waste time looking in 5 different places for all
                        your data.
                        <br />
                        <br />
                        See opportunities faster when the right data sources are
                        combined. With the Data Hub, you have all your data
                        sources in one place. You can easily connect to
                        different data sources.
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                  >
                    <Chip
                      clickable
                      color="secondary"
                      component="a"
                      href="https://datahub.gladior.com/data-sources/"
                      icon={<InfoOutlined />}
                      label="Learn more"
                      size="small"
                      target="_blank"
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 3 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    m={2}
                    mt={4}
                  >
                    <img alt="" src={templateIcon} style={{ height: '70px' }} />
                  </Box>
                  <Typography align="center" gutterBottom variant="h5">
                    <strong>Template for every data source</strong>
                  </Typography>

                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    mt={1}
                  >
                    <Box maxWidth="400px">
                      <Typography align="center" variant="body2">
                        We believe it is easier to start with a basic template
                        than a blank sheet.
                        <br />
                        <br />
                        Each data source has a default template. This template
                        is free to use and the template can be used immediately,
                        but it is also possible to customize the template.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 4 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    m={2}
                    mt={4}
                  >
                    <img
                      alt=""
                      src={integrityIcon}
                      style={{ height: '70px' }}
                    />
                  </Box>
                  <Typography align="center" gutterBottom variant="h5">
                    <strong>Data Integrity</strong>
                  </Typography>

                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    mt={1}
                  >
                    <Box maxWidth="400px">
                      <Typography align="center" variant="body2">
                        The data you’ll connect to the Data Hub will only be
                        available in your own Personal workspace. Others do not
                        have access and can’t access the data. Unless you share
                        them yourself.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 5 && (
              <>
                <Box
                  alignContent="center"
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  m={3}
                >
                  <Box
                    alignContent="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    m={2}
                    mt={4}
                  >
                    <img
                      alt="Data Hub Logo"
                      src={
                        process.env.REACT_APP_USER_BRANCH === 'prod'
                          ? dataHubTextProd
                          : dataHubTextDev
                      }
                      style={{ height: '75px' }}
                    />
                  </Box>

                  <Box mt={4}>
                    <Typography align="center" gutterBottom variant="h5">
                      Start using the Data Hub
                    </Typography>
                  </Box>

                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                    mt={6}
                  >
                    <Button
                      color="secondary"
                      onClick={() => {
                        closeScreen();
                      }}
                      size="large"
                      variant="outlined"
                    >
                      Get started
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          <MobileStepper
            activeStep={activeStep}
            backButton={
              <Button
                className={classes.stepButton}
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                size="small"
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
            className={classes.stepper}
            nextButton={
              <>
                {activeStep === stepCount - 1 ? (
                  <Button
                    color="secondary"
                    onClick={() => {
                      closeScreen();
                    }}
                    size="small"
                  >
                    Get started
                  </Button>
                ) : (
                  <Button
                    className={classes.stepButton}
                    color="inherit"
                    disabled={activeStep === stepCount - 1}
                    onClick={handleNext}
                    size="small"
                  >
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                )}
              </>
            }
            position="static"
            steps={stepCount}
            variant="text"
          />
        </Box>
      </Dialog>
    </>
  );
}

export default WelcomeScreen;
