/* eslint-disable consistent-return */
import { API, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import addExtrasGraphQL from '../utilities/addExtrasGraphQL';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function getAllDataSources(
  teamId,
  nextToken = undefined,
  limit = 10000,
  filter
) {
  const inputQuery = {
    id: teamId,
    limit,
    nextToken,
    filter,
  };

  const excludeArray = [
    'avatar',
    'id',
    'name',
    'owner',
    'members',
    'admins',
    'editors',
    'emailDomains',
    'autoInviteDomains',
    'teamMembers',
    'credentials',
    'dataSourceLabels',
    'defaultTemplateId',
    'themes',
    'createdAt',
    'updatedAt',
    'dataSources.items.credential.status',
    'dataSources.items.credential.owners',
    'dataSources.items.credential.editors',
    'dataSources.items.credential.createdAt',
    'dataSources.items.credential.updatedAt',
    'dataSources.items.credential.authType',
    'dataSources.items.credential.team',
    'dataSources.items.credential.dataSources',
    'dataSources.items.credential.dataProvider',
    'dataSources.items.credential.authDataId',
    'dataSources.items.credential.clientId',
    'dataSources.items.credential.authData',
    'dataSources.items.credential.label.team',
    'dataSources.items.credential.label.dataSources',
    'dataSources.items.credential.label.dataProviders',
    'dataSources.items.sets',
    'dataSources.items.labels.items.dataSource',
    'dataSources.items.labels.items.label.dataSources',
    'dataSources.items.labels.items.label.team',
    'dataSources.items.team.avatar',
    'dataSources.items.team.name',
    'dataSources.items.team.owner',
    'dataSources.items.team.members',
    'dataSources.items.team.dataSources',
    'dataSources.items.team.teamMembers',
    'dataSources.items.team.credentials',
    'dataSources.items.team.dataSourceLabels',
    'dataSources.items.dataUrls.items.dataSource',
    'dataSources.items.queries.items.dataSource',
    'dataSources.items.notificationActions.items.dataSource',
    'dataSources.items.preCacheRequests.items.dataSource',
    'dataSources.items.queries',
    'dataSources.items.integrations.items.dataSource',
    'dataSources.items.integrations.items.credential',
    'dataSources.items.integrations.items.type',
    'dataSources.items.integrations.items.query',
    'dataSources.items.integrations.items.additionalSettings',
    'dataSources.items.integrations.items.dataProvider',
    // 'dataSources.items.team.themes',
    // 'dataSources.items.view.viewName',
    // 'dataSources.items.view.propertyId',
    // 'dataSources.items.view.loginCustomerId',
    // 'dataSources.items.view.customerId',
    // 'dataSources.items.view.site',
    // 'dataSources.items.view.locationId',
    // 'dataSources.items.view.channelId',
    // 'dataSources.items.view.spreadsheetId',
    // 'dataSources.items.view.accountId',
    // 'dataSources.items.view.dc',
    // 'dataSources.items.view.connectorId',
  ];

  const getTeamFilter = (path) => {
    const re = new RegExp(`getTeam.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };

  let query = filterGraphQLFields(queries.getTeam, getTeamFilter);

  const variableDefinition = (name, type) => ({
    kind: 'VariableDefinition',
    variable: { kind: 'Variable', name: { kind: 'Name', value: name } },
    type: { kind: 'NamedType', name: { kind: 'Name', value: type } },
  });

  const enumArgument = (name, enumValue) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'EnumValue', value: enumValue },
  });
  const variableArgument = (name, variableName) => ({
    kind: 'Argument',
    name: { kind: 'Name', value: name },
    value: { kind: 'Variable', name: { kind: 'Name', value: variableName } },
  });

  const extraVariables = {
    GetTeam: [
      variableDefinition('limit', 'Int'),
      variableDefinition('filter', 'ModelDataSourceFilterInput'),
      variableDefinition('nextToken', 'String'),
    ],
  };
  const extraArguments = {
    dataSources: [
      enumArgument('sortDirection', 'DESC'),
      variableArgument('limit', 'limit'),
      variableArgument('nextToken', 'nextToken'),
      variableArgument('filter', 'filter'),
    ],
  };

  query = addExtrasGraphQL(query, extraVariables, extraArguments);

  const session = await Auth.currentSession();
  // const accessToken = session.getAccessToken();

  const jwtToken = session.getIdToken().getJwtToken();

  const dataSourcesQueryObject = await API.graphql({
    query,
    variables: inputQuery,
    authMode: 'AWS_LAMBDA',
    authToken: jwtToken,
  });

  // const dataSourcesQueryObject = await API.graphql(
  //   graphqlOperation(query, inputQuery)
  // );

  const dataSources =
    dataSourcesQueryObject.data.getTeam.dataSources.items.filter(
      (ds) => ds.integrations.items.length === 0
    );
  const nextTokenBack =
    dataSourcesQueryObject.data.getTeam.dataSources.nextToken;

  return { dataSources, nextToken: nextTokenBack };
}

export default getAllDataSources;
