import isValidDomain from 'is-valid-domain';
import isValidUrl from './isValidUrl';

function checkIfHasValidRequiredPartsCombination(configParams, requiredParts) {
  const conditionArr = [];

  Object.keys(configParams).forEach((configParamKey) => {
    conditionArr.push({ id: configParamKey });
  });

  // eslint-disable-next-line prefer-const
  let hasValidRequiredPartsCombination = false;

  requiredParts.forEach((resultItem) => {
    let requiredPartCombinationIsValid = true;
    resultItem.forEach((resultItemPart) => {
      if (configParams[resultItemPart.input_id]) {
        if (resultItemPart.condition === 'EQUAL_TO') {
          if (
            configParams[resultItemPart.input_id].id !== resultItemPart.value
          ) {
            requiredPartCombinationIsValid = false;
          }
        } else if (resultItemPart.condition === 'NOT_EMPTY') {
          if (!configParams[resultItemPart.input_id].id) {
            requiredPartCombinationIsValid = false;
          }
        } else if (resultItemPart.condition === 'VALID_URL') {
          if (!isValidUrl(configParams[resultItemPart.input_id].id)) {
            requiredPartCombinationIsValid = false;
          }
        } else if (resultItemPart.condition === 'VALID_DOMAIN') {
          if (!isValidDomain(configParams[resultItemPart.input_id].id)) {
            requiredPartCombinationIsValid = false;
          }
        }
      } else {
        requiredPartCombinationIsValid = false;
      }
    });
    if (requiredPartCombinationIsValid === true) {
      hasValidRequiredPartsCombination = true;
    }
  });

  return hasValidRequiredPartsCombination;
}

export default checkIfHasValidRequiredPartsCombination;
