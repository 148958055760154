import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import logoDev from '../../data-hub-icon-dev.svg';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import logo from '../../logo-icon.svg';
import filterGraphQLFields from '../../utilities/filterGraphQLFields';

const CURRENT_RELEASE = 1.5;

function NewInDataHubDialog() {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [showScreen, setShowScreen] = useState(false);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  async function closeScreen() {
    const excludeArray = ['teams'];

    const updateUserFilter = (path) => {
      const re = new RegExp(`updateUser.(${excludeArray.join('|')})$`);
      return !re.test(path);
    };

    const updateDataSourceFilter = (path) => {
      const re = new RegExp(`getUser.(${excludeArray.join('|')})$`);
      return !re.test(path);
    };

    const user = await API.graphql(
      graphqlOperation(
        filterGraphQLFields(queries.getUser, updateDataSourceFilter),
        { id: currentAuthenticatedUser?.userattributes?.sub }
      )
    );

    const { getUser } = user.data;

    const userUpdatedResponse = await API.graphql(
      graphqlOperation(
        filterGraphQLFields(mutations.updateUser, updateUserFilter),
        {
          input: {
            id: currentAuthenticatedUser?.attributes?.sub,
            userSettings: {
              ...getUser.userSettings,
              releaseNotes: Number(CURRENT_RELEASE),
            },
          },
        }
      )
    );

    const updatedUser = userUpdatedResponse.data.updateUser;
    dispatch({
      type: types.SET_USER_OBJECT,
      userObject: updatedUser,
    });

    setShowScreen(false);
  }

  useEffect(() => {
    async function check() {
      const excludeArray = ['teams'];

      const updateDataSourceFilter = (path) => {
        const re = new RegExp(`getUser.(${excludeArray.join('|')})$`);
        return !re.test(path);
      };

      const user = await API.graphql(
        graphqlOperation(
          filterGraphQLFields(queries.getUser, updateDataSourceFilter),
          { id: currentAuthenticatedUser?.attributes?.sub }
        )
      );

      const { getUser } = user.data;

      if (getUser?.userSettings?.releaseNotes) {
        const releaseNotesNumber = Number(getUser?.userSettings?.releaseNotes);

        const beforeDate = dayjs('2023-01-17T15:33:52.227Z');

        if (
          dayjs(getUser.createdAt).isBefore(beforeDate) &&
          CURRENT_RELEASE > releaseNotesNumber
        ) {
          setShowScreen(false);
        }
      }

      if (getUser?.userSettings?.releaseNotes === null) {
        setShowScreen(false);
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only re-run the effect if count changes

  return (
    <>
      <Dialog fullWidth maxWidth="sm" onClose={() => {}} open={showScreen}>
        <DialogContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box mb={2} mt={1}>
              <Avatar
                src={
                  process.env.REACT_APP_USER_BRANCH === 'prod' ? logo : logoDev
                }
                variant="rounded"
              />
            </Box>

            <Typography align="center" sx={{ mb: 4 }} variant="h5">
              Technical issues in the Data Hub
            </Typography>

            <Box sx={{ width: '100%', mb: 3 }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body2"
                  >
                    Due to a technical issue, beyond our control, the Data Hub
                    and associated Looker Studio connectors were unavailable
                    from yesterday (16 jan) 17:00 CET until 16:00 CET (17 jan).
                    <br />
                    <br />
                    The issue has been resolved, but unfortunately the issue
                    resulted in the data credentials becoming unusable for a
                    part of our free users.
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box sx={{ width: '100%', mb: 3 }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Typography
                    align="left"
                    color="textPrimary"
                    sx={{ fontWeight: '700' }}
                    variant="body1"
                  >
                    Yes, I am experiencing issues with my Looker Studio
                    Dashboard
                  </Typography>
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body2"
                  >
                    - Update the data sources used in the Looker Studio
                    dashboards and create new credentials
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ width: '100%', mb: 3 }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Typography
                    align="left"
                    color="textPrimary"
                    sx={{ fontWeight: '700' }}
                    variant="body1"
                  >
                    No, I don't experience any issues
                  </Typography>
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body2"
                  >
                    - In that case you can ignore this message
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ width: '100%', mb: 3 }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body2"
                  >
                    Data Hub Team
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeScreen();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewInDataHubDialog;
