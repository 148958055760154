import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import { green } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import updateTeamObject from '../../api/updateTeamObject';
import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql/mutations';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function AddTeamMemberDialog({ selectedTeam, open, closeDialog }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Dialog
      aria-describedby="add-team-member-dialog-description"
      aria-labelledby="add-team-member-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle id="add-team-member-dialog-title">
        Add member to a workspace
      </DialogTitle>

      <Formik
        initialValues={{
          email: '',
          message: '',
          permission: 'TEAM_MEMBER',
          notifyPeople: true,
        }}
        onSubmit={async (values, submitObject) => {
          const { setSubmitting } = submitObject;

          const { email, message, permission, notifyPeople } = values;

          try {
            await API.graphql(
              graphqlOperation(mutations.inviteTeamMember, {
                email,
                message,
                permission,
                notifyPeople,
                teamId: selectedTeam.id,
              })
            );
            const updatedTeam = await updateTeamObject(selectedTeam.id);
            dispatch({
              type: types.UPDATE_USER_TEAM,
              team: updatedTeam,
            });
            setSubmitting(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              closeDialog();
            }, 500);
          } catch (e) {
            if (e.errors) {
              if (e.errors[0].errorType === 'Lambda:Unhandled') {
                submitObject.setStatus({ error: e.errors[0].message });
              }
            }

            setSubmitting(false);
            setSuccess(false);
          }
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required field';
          } else if (!isEmail(values.email)) {
            errors.email = 'No valid email adress';
          }
          return errors;
        }}
      >
        {(props) => (
          <Form>
            <DialogContent className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    error={props.errors.email !== undefined}
                    fullWidth
                    label="E-mail"
                    name="email"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder="example@example.com"
                    type="text"
                    value={props.values.email}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={4}>
                  <Box ml={1}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel>Permission</InputLabel>
                      <Select
                        fullWidth
                        inputProps={{
                          id: 'permission',
                        }}
                        name="permission"
                        onChange={(event) => {
                          props.setFieldValue('permission', event.target.value);
                        }}
                        value={props.values.permission}
                      >
                        <MenuItem value="TEAM_MEMBER">Member</MenuItem>
                        <MenuItem value="EDITOR">Editor</MenuItem>
                        <MenuItem value="ADMIN">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {props.errors.email && (
                  <Grid item xs={12}>
                    <FormHelperText error={props.errors.email}>
                      {props.errors.email}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.values.notifyPeople}
                          color="secondary"
                          onChange={(event) => {
                            props.setFieldValue(
                              'notifyPeople',
                              event.target.checked
                            );
                          }}
                        />
                      }
                      label="Notify people"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Collapse in={props.values.notifyPeople}>
                    <TextField
                      error={props.errors.message !== undefined}
                      fullWidth
                      helperText={props.errors.message}
                      maxRows={8}
                      multiline
                      name="message"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      placeholder="Message (optional)"
                      rows={4}
                      value={props.values.message}
                      variant="filled"
                    />
                  </Collapse>
                </Grid>
              </Grid>

              {props.status && props.status.error && (
                <Box mt={2}>
                  <Alert severity="warning">{props.status.error}</Alert>
                </Box>
              )}
              {props.isSubmitting && <LinearProgress />}
            </DialogContent>
            <DialogActions className={classes.actionsContainer}>
              <Button
                onClick={() => {
                  closeDialog();
                }}
              >
                Cancel
              </Button>

              <Button
                className={buttonClassname}
                color="primary"
                disabled={props.isSubmitting}
                onClick={props.submitForm}
                type="submit"
                variant="contained"
              >
                {success && <Icon>check</Icon>}
                {props.isSubmitting === true ? (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                ) : (
                  'Invite'
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddTeamMemberDialog;
