import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';
import useDataProviderData from '../../hooks/useDataProviderData';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
}));

function UsageDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogs = useSelector((state) => state.dialogs);

  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const { data = {}, isLoading } = useDataProviderData(
    `/data-providers/usage`,
    {
      dataSourceId: selectedDataSource?.id,
    }
  );

  const open = dialogs.showUsageDialog;
  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  function closeDialog() {
    dispatch({
      type: types.SHOW_USAGE,
      isVisible: false,
    });
    onClose();
  }

  if (!groups.includes('gladior')) {
    return null;
  }

  if (!selectedDataSource || isLoading) {
    return <></>;
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xs" onClose={closeDialog} open={open}>
        <DialogContent className={classes.container}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: 'Usage',
              },
              chart: {
                type: 'column',
              },
              xAxis: {
                type: 'datetime',
              },
              yAxis: {
                title: {
                  text: 'Requests',
                },
              },
              credits: {
                enabled: false,
              },
              series: [
                {
                  name: 'Requests',
                  data: data?.usage?.map((usageItem) => [
                    Date.parse(usageItem.date),
                    usageItem.requests,
                  ]),
                },
              ],
            }}
          />

          {data?.rateLimit?.max > -1 && (
            <Typography align="center" variant="body2">
              <b>{data.rateLimit.max - data.rateLimit.requests}</b> requests
              remaining this month
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            color="primary"
            onClick={() => {
              closeDialog();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UsageDialog;
