import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import IntegrationStepList, {
  dependsOnMatches,
} from './integrationSteps/integrationStepList';

const getDataProviderData = async (input) => {
  const [path, params] = input;

  const tokens = await Auth.currentSession();
  const token = tokens.getIdToken().getJwtToken();
  return API.post('DataProviderApi', path, {
    headers: {
      Authorization: token,
    },
    response: true,
    body: {
      ...params,
    },
    queryStringParameters: {
      outputMode: 'datahub_dataproviderdata',
    },
  }).then((res) => res.data);
};
function useDataProviderData(path, queryStringObj = {}) {
  // eslint-disable-next-line no-unused-vars
  const { data, error } = useSWR(
    path ? [path, queryStringObj] : null,
    getDataProviderData,
    {
      errorRetryCount: 3,
    }
  );

  let errorMessage;

  let errorType = '';

  let statusCode = 200;

  if (error) {
    errorMessage =
      'Unknown Error - There was an error communicating with the data provider.';

    if (error.response) {
      const errorData = error.response.data;

      errorType = 'UnexpectedError';

      if (errorData?.type) {
        errorType = errorData.type;
      }

      if (errorData?.type === 'DataProviderRequestError') {
        errorMessage = errorData.message;
        statusCode = errorData.statusCode;
      } else if (errorData?.type === 'InvalidCredentialError') {
        errorMessage = errorData.message;
        statusCode = errorData.statusCode;
      } else if (errorData?.type === 'AuthenicationError') {
        errorMessage = 'Authenication error';
        statusCode = 403;
      }
    }
  }

  return {
    data,
    isLoading: !error && !data,
    errorMessage,
    statusCode,
    errorType,
  };
}

export default function IntegrationSettings({
  queryObj,
  dataProvider,
  dataSourceId,
  configObj,
  setConfig,
  setValid,
  dataSourceConfig,
  credentialId,
}) {
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const configSettings = dataProviderSettings.config[dataProvider];
  const { route } = configSettings ?? {};

  const [messageType, setMessageType] = useState(
    configObj?.messageType ? [configObj?.messageType] : ['individual']
  );
  const { data: config = {}, isLoading } = useDataProviderData(
    route && (dataSourceId || dataSourceConfig)
      ? `/${route}/integration-config?credentialId=${credentialId}&dataSourceId=${dataSourceId}`
      : null,
    {
      queryObj: JSON.stringify(queryObj),
      dataSourceConfig: JSON.stringify(dataSourceConfig),
    }
  );
  useEffect(() => {
    if (messageType[0] !== configObj.messageType) {
      setConfig({ messageType: messageType[0] });
    }
  }, [messageType]);

  useEffect(() => {
    setValid(dependsOnMatches(config, configObj));
  }, [config, configObj]);
  if (isLoading === true) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        mb: 2,
        minWidth: 'min(900px, 90vw)',
        outline: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'background.default',
          p: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Typography>{'Settings'}</Typography>
        <ToggleButtonGroup
          exclusive
          onChange={(event) => {
            setMessageType([event.target.value]);
          }}
          size="small"
          value={messageType}
        >
          <ToggleButton key="individual" value="individual">
            Individual
          </ToggleButton>
          <ToggleButton key="aggregated" value="aggregated">
            Aggregated
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        <IntegrationStepList
          config={config}
          configObj={configObj}
          messageType={messageType}
          queryObj={queryObj}
          setConfig={setConfig}
        />
      </Box>
    </Box>
  );
}
