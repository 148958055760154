/* eslint-disable no-nested-ternary */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ellipsis from 'text-ellipsis';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as types from '../../constants/ActionTypes';
import logoDev from '../../data-hub-icon-dev.svg';
import logo from '../../logo-icon.svg';
import TeamAvatar from './TeamAvatar';

const dataSourcesOnlyMode = process.env.REACT_APP_DATA_SOURCE_ONLY === 'true';

function getInitials(name) {
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
}

const useStyles = makeStyles((theme) => ({
  searchBar: {
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  editToolbarButton: {
    background: theme.palette.background.paper,
    textTransform: 'none',
  },

  bold: {
    fontWeight: 500,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    background: theme.palette.primary.main,
    fontSize: '10px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  workspaceButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? theme.palette.grey[600]
        : theme.palette.grey[300]
    }`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
    background:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#FFF',
  },
  smallAvatar: {
    width: '18px',
    height: '18px',
  },
}));

function TeamsMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  // redux selectors
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const userObject = useSelector((state) => state.userObject);
  const userTeams = useSelector((state) => state.userTeams);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const [workspaceAnchorEl, setWorkspaceAnchorEl] = useState(null);
  const [openChapterMenuId, setOpenChapterMenuId] = useState(-1);

  const open = Boolean(anchorElPopover);

  function closeMenu() {
    setWorkspaceAnchorEl(null);
    setOpenChapterMenuId(-1);
  }

  if (userTeams.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        className={classes.workspaceButton}
        onClick={(e) => {
          setAnchorElPopover(e.currentTarget);
        }}
        variant="outlined"
      >
        <Box display="flex" marginRight="10px">
          <Box color="secondary.main" display="flex" justifyContent="center">
            {userTeams.filter((o) => o.id === selectedUserTeam)[0]?.avatar ? (
              <TeamAvatar
                avatar={
                  userTeams.filter((o) => o.id === selectedUserTeam)[0]?.avatar
                }
                id={userTeams.filter((o) => o.id === selectedUserTeam)[0].id}
                name={
                  userTeams.filter((o) => o.id === selectedUserTeam)[0].name
                }
                size="small"
              />
            ) : (
              <Avatar className={classes.avatar}>
                {getInitials(
                  userTeams.filter((o) => o.id === selectedUserTeam)[0].name
                )}
              </Avatar>
            )}
          </Box>
        </Box>
        <Hidden xlDown>
          {ellipsis(
            userTeams.filter((o) => o.id === selectedUserTeam)[0]
              ? userTeams.filter((o) => o.id === selectedUserTeam)[0].name
              : '',
            45
          )}
        </Hidden>
        <ArrowDropDownIcon fontSize="small" />
      </Button>
      <Popover
        anchorEl={anchorElPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setWorkspaceAnchorEl(null);
          setAnchorElPopover(null);
          setOpenChapterMenuId(-1);
        }}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <>
          <Table size="small">
            <TableBody>
              {userTeams.map((teamItem, i) => {
                const userIsAdmin = teamItem.admins.includes(userObject.id);
                const userIsEditor = teamItem.editors
                  ? teamItem.editors.includes(userObject.id)
                  : false;

                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={teamItem.id}
                    selected={teamItem.id === selectedUserTeam}
                  >
                    <TableCell
                      onClick={() => {
                        dispatch({
                          type: types.SELECT_USER_TEAM,
                          teamId: teamItem.id,
                        });

                        navigate(
                          `/workspace/${teamItem.id.split('-')[0]}/data-sources`
                        );

                        setWorkspaceAnchorEl(null);
                        setAnchorElPopover(null);
                        setOpenChapterMenuId(-1);
                      }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          marginRight="12px"
                        >
                          <ListItemIcon>
                            {teamItem.avatar ? (
                              <TeamAvatar
                                avatar={teamItem.avatar}
                                id={teamItem.id}
                                name={teamItem.name}
                                size="small"
                              />
                            ) : (
                              <Avatar className={classes.avatar}>
                                {getInitials(teamItem.name)}
                              </Avatar>
                            )}
                          </ListItemIcon>
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Typography
                            className={clsx({
                              [classes.bold]: teamItem.id === selectedUserTeam,
                            })}
                            color={
                              teamItem.id === selectedUserTeam
                                ? 'secondary'
                                : 'inherit'
                            }
                            component="span"
                            noWrap
                            variant="inherit"
                          >
                            {ellipsis(teamItem.name, 45)}
                          </Typography>
                          {userIsAdmin === true ? (
                            <Typography
                              color="textSecondary"
                              noWrap
                              variant="caption"
                            >
                              Admin
                            </Typography>
                          ) : userIsEditor === true ? (
                            <Typography
                              color="textSecondary"
                              noWrap
                              variant="caption"
                            >
                              Editor
                            </Typography>
                          ) : (
                            <Typography
                              color="textSecondary"
                              noWrap
                              variant="caption"
                            >
                              Member
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={(event) => {
                          setWorkspaceAnchorEl(event.currentTarget);
                          setOpenChapterMenuId(i);
                        }}
                        size="large"
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        PaperProps={{
                          style: {
                            maxHeight: 48 * 4.5,
                            width: 250,
                          },
                        }}
                        anchorEl={workspaceAnchorEl}
                        id="long-menu"
                        keepMounted
                        onClose={() => {
                          setWorkspaceAnchorEl(null);
                          setAnchorElPopover(null);
                          setOpenChapterMenuId(-1);
                        }}
                        open={open && openChapterMenuId === i}
                      >
                        <MenuItem
                          disabled={!userIsAdmin}
                          onClick={() => {
                            batch(() => {
                              dispatch({
                                type: types.SET_SELECTED_TEAM,
                                team: teamItem,
                              });
                              dispatch({
                                type: types.SHOW_UPDATE_TEAM_DIALOG,
                                isVisible: true,
                              });
                            });
                            closeMenu();
                          }}
                        >
                          Edit workspace
                        </MenuItem>

                        {groups.includes('gladior') && (
                          <MenuItem
                            disabled={!groups.includes('gladior')}
                            onClick={() => {
                              searchParams.set('workspaceId', teamItem.id);
                              navigate({
                                pathname: `data-sources/members`,
                                search: searchParams.toString(),
                              });

                              closeMenu();
                            }}
                          >
                            {userIsAdmin ? 'Manage' : 'View'} members
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            searchParams.set('workspaceId', teamItem.id);
                            navigate({
                              pathname: `data-sources/credentials`,
                              search: searchParams.toString(),
                            });

                            closeMenu();
                          }}
                        >
                          {userIsAdmin ? 'Manage' : 'View'} credentials
                        </MenuItem>

                        {dataSourcesOnlyMode === false && (
                          <MenuItem
                            onClick={() => {
                              batch(() => {
                                dispatch({
                                  type: types.SET_SELECTED_TEAM,
                                  team: teamItem,
                                });
                                dispatch({
                                  type: types.SHOW_UPDATE_COLOR_THEME_DIALOG,
                                  isVisible: true,
                                });
                              });
                              closeMenu();
                            }}
                          >
                            Thema
                          </MenuItem>
                        )}

                        {groups.includes('gladior') && (
                          <MenuItem
                            disabled={
                              !userIsAdmin || !groups.includes('gladior')
                            }
                            onClick={() => {
                              batch(() => {
                                dispatch({
                                  type: types.SET_SELECTED_TEAM,
                                  team: teamItem,
                                });
                                dispatch({
                                  type: types.SHOW_DELETE_TEAM_DIALOG,
                                  isVisible: true,
                                });
                              });
                              closeMenu();
                            }}
                          >
                            Delete workspace
                          </MenuItem>
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <AppBar
            className={classes.searchBar}
            color="default"
            elevation={0}
            position="static"
          >
            <Toolbar variant="dense">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
              >
                <Button
                  className={classes.editToolbarButton}
                  onClick={() => {
                    navigate(`/workspace`);
                  }}
                  size="small"
                  startIcon={
                    <Avatar
                      className={classes.smallAvatar}
                      src={
                        process.env.REACT_APP_USER_BRANCH === 'prod'
                          ? logo
                          : logoDev
                      }
                      variant="rounded"
                    />
                  }
                  variant="outlined"
                >
                  Workspace Overview
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </>
      </Popover>
    </>
  );
}

export default TeamsMenu;
