/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Chip,
  StepContent,
  StepIcon,
  StepLabel,
  Typography,
} from '@mui/material';
import React from 'react';
import checkIfMeetsMandatoryParts from '../../../utilities/checkIfMeetsMandatoryParts';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';
import ConfigDropdown from '../config/ConfigDropdown';
import ConfigTextField from '../config/ConfigTextField';
import CredentialSelector from '../config/CredentialSelector';
import DataSourceSelector from '../config/DataSourceSelector';
import InfoBox from '../config/InfoBox';
import LinkText from '../config/LinkText';

export function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <StepIcon
      active={active}
      className={className}
      completed={completed}
      icon={
        <Chip
          color={active ? 'secondary' : 'default'}
          label={String(props.icon)}
          size="small"
          sx={{
            fontWeight: 'bold',
          }}
          variant={'filled'}
        />
      }
    />
  );
}

function SelectorStep({
  step,
  configParams,
  selectedCredential,
  stepIndex,
  updateConfig,
  lastActiveStepIndex,
  setActiveStep,
  activeStep,
  dataProvider,
}) {
  const { input_items, title, summary, required_parts, depends_on } = step;

  const meetsMandatoryPart = checkIfMeetsMandatoryParts(
    required_parts,
    configParams
  );

  // determine if the step needs to be visible in the interface
  const shouldShowStep = depensOnCheckMatches(depends_on, configParams);
  if (shouldShowStep === false) {
    return null;
  }

  // determine the (optinal) summary text
  // shown under the step title is the step is completed
  let summaryText = '';
  if (summary) {
    if (configParams[summary.input_id]) {
      summaryText = configParams[summary.input_id][summary.key];
    }
  }

  return (
    <>
      <StepLabel StepIconComponent={ColorlibStepIcon}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="inherit">{title}</Typography>
          {summaryText && (
            <Typography color="textSecondary" variant="caption">
              {summaryText}
            </Typography>
          )}
        </Box>
      </StepLabel>
      <StepContent>
        {input_items.map((inputItem) => {
          if (inputItem.type === 'DROPDOWN') {
            return (
              <ConfigDropdown
                config={inputItem.config}
                configParams={configParams}
                credential={selectedCredential}
                dataProvider={dataProvider}
                dependsOn={inputItem.depends_on}
                id={inputItem.id}
                key={inputItem.id}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            );
          }

          if (inputItem.type === 'DATA_SOURCE_SELECTOR') {
            return (
              <DataSourceSelector
                config={inputItem.config}
                configParams={configParams}
                credential={selectedCredential}
                dataProvider={dataProvider}
                dependsOn={inputItem.depends_on}
                id={inputItem.id}
                key={inputItem.id}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            );
          }

          if (inputItem.type === 'CREDENTIAL_SELECTOR') {
            return (
              <CredentialSelector
                config={inputItem.config}
                configParams={configParams}
                credential={selectedCredential}
                dataProvider={dataProvider}
                dependsOn={inputItem.depends_on}
                id={inputItem.id}
                key={inputItem.id}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            );
          }

          if (inputItem.type === 'TEXTFIELD') {
            return (
              <ConfigTextField
                config={inputItem.config}
                configParams={configParams}
                credentialId={selectedCredential.id}
                dependsOn={inputItem.depends_on}
                id={inputItem.id}
                key={inputItem.id}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            );
          }

          if (inputItem.type === 'INFO') {
            return (
              <InfoBox
                config={inputItem.config}
                configParams={configParams}
                dependsOn={inputItem.depends_on}
                key={inputItem.id}
              />
            );
          }

          if (inputItem.type === 'LINK_TEXT') {
            return (
              <LinkText
                config={inputItem.config}
                configParams={configParams}
                dependsOn={inputItem.depends_on}
                id={inputItem.id}
                key={inputItem.id}
                selectedCredential={selectedCredential}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            );
          }

          return null;
        })}
        {stepIndex === lastActiveStepIndex && meetsMandatoryPart && (
          <Typography
            color="textSecondary"
            sx={{ ml: 2 }}
            variant="caption"
          >{`Click "CREATE" to continue.`}</Typography>
        )}
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}>
          <Button
            color="primary"
            disabled={!meetsMandatoryPart || stepIndex === lastActiveStepIndex}
            onClick={() => {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }}
            sx={{ mt: 1, mr: 1 }}
            variant="contained"
          >
            Continue
          </Button>
          <Button
            color="primary"
            disabled={activeStep < 1}
            onClick={() => {
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
        </Box>
      </StepContent>
    </>
  );
}

export default SelectorStep;
