import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Logger } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import deleteDataSourceObject from '../../api/deleteDataSourceObject';
import * as types from '../../constants/ActionTypes';

function DeleteDataSourceDialog({ onClose = () => {} }) {
  const logger = new Logger('DeleteDataSourceDialog');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showDeleteDataSourceDialog;

  const userObject = useSelector((state) => state.userObject);

  function closeDialog() {
    dispatch({
      type: types.SHOW_DELETE_DATA_SOURCE_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  async function deleteSelectedDataSource() {
    // check if the user is the owner
    if (userObject.id === selectedDataSource.owner) {
      logger.info('user is owner');
    }
    const deletedDataSource = await deleteDataSourceObject(selectedDataSource);
    batch(() => {
      dispatch({
        type: types.DELETE_TEAM_DATA_SOURCE,
        dataSource: deletedDataSource,
        teamId: deletedDataSource.team.id,
      });
    });

    enqueueSnackbar(`Data source deleted successfully`, {
      variant: 'info',
    });

    closeDialog(true);
  }

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <div>
      <Dialog
        aria-describedby="delete-data-source-dialog-description"
        aria-labelledby="delete-data-source-dialog-title"
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="delete-data-source-dialog-title">
          Delete data source
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-data-source-dialog-description">
            Are you sure you want to delete <b>{selectedDataSource.name}</b>?
            This cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={deleteSelectedDataSource}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteDataSourceDialog;
