function shouldFetchTemplates(templatesByTeam, selectedUserTeam, q) {
  if (!templatesByTeam) {
    return true;
  }

  if (!selectedUserTeam) {
    return false;
  }

  if (!templatesByTeam[selectedUserTeam]) {
    return true;
  }

  if (templatesByTeam[selectedUserTeam].isFetching === true) {
    return false;
  }

  if (templatesByTeam[selectedUserTeam].q !== q) {
    return true;
  }

  if (templatesByTeam[selectedUserTeam].movedCount > 0) {
    if (
      templatesByTeam[selectedUserTeam].items.length -
        templatesByTeam[selectedUserTeam].movedCount <
      1
    ) {
      return true;
    }
  }

  if (templatesByTeam[selectedUserTeam].items.length > 0) {
    return false;
  }

  return true;
}

export default shouldFetchTemplates;
