/* eslint-disable no-nested-ternary */

import {
  Add,
  DataObject,
  DataObjectTwoTone,
  MoreVert,
  OpenInNew,
} from '@mui/icons-material';
import ellipsis from 'text-ellipsis';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import CreateWebUrlDialog from './CreateWebUrlDialog';

const dataApiBase =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data.dh.gladior.com'
    : 'https://data-dev.dh.gladior.com';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();

  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

const orState = {
  id: '',
  name: '',
  status: 'ACTIVE',
  dateRange: '',
  selectedMetrics: [],
  selectedDimensions: [],
  startDate: '',
  endDate: '',
  dateRangeError: '',
  dimensionsFilters: [],
  sortField: null,
  limit: '',
};

function DataApiDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [initialState, setInitialState] = React.useState(orState);

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  const openDialog = dialogs.showDataApiDialog;

  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/data-url/delete',
    sendRequest
  );

  function closeDialog() {
    reduxDispatch({
      type: types.SHOW_DATA_API_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  function createInitialState(dataLink) {
    const inState = {
      id: dataLink.id,
      name: dataLink.name,
      status: dataLink.status,
      dateRange: dataLink.dateRangeType,
      fieldIds: dataLink.fields,
      sort: dataLink.sort,
      dimensionsFiltersObjects: dataLink.dimensionsFilters,
      selectedMetrics: [],
      selectedDimensions: [],
      startDate: dataLink.dateRange.startDate,
      endDate: dataLink.dateRange.endDate,
      dateRangeError: '',
      dimensionsFilters: [],
      sortField: null,
      limit: dataLink.limit,
    };

    setInitialState(inState);

    setOpen(true);
  }

  React.useEffect(() => {
    setInitialState(orState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSource]);

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      {open && (
        <CreateWebUrlDialog
          initialState={initialState}
          open={open}
          setOpen={setOpen}
        />
      )}

      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>JSON/CSV/XML</DialogTitle>
        <DialogContent>
          {selectedDataSource?.dataUrls?.items?.length > 0 && (
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Web Url</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedDataSource?.dataUrls?.items.map((dataLink) => (
                    <TableRow key={dataLink.id}>
                      <TableCell>
                        <Box display="flex" flexDirection="row">
                          <Box sx={{ mr: 1 }}>
                            <Avatar
                              sx={{ bgcolor: 'white' }}
                              color="info"
                              variant="circular"
                            >
                              <DataObject color="info" />
                            </Avatar>
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography
                              color="textPrimary"
                              component="span"
                              variant="body1"
                            >
                              {dataLink.name}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              component="span"
                              variant="body2"
                            >
                              {ellipsis(dataLink.fields.join(','), 40)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <Button
                          color="info"
                          href={`${dataApiBase}/d/${dataLink.id}?outputFormat=json`}
                          startIcon={<OpenInNew />}
                          sx={(theme) => ({
                            textTransform: 'none',
                            background: theme.palette.background.default,
                            width: '100%',
                          })}
                          target="_blank"
                          variant="outlined"
                        >
                          <Typography variant="inherit">Open</Typography>
                        </Button>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <PopupState variant="popover">
                          {(popupState) => (
                            <>
                              <IconButton
                                color="primary"
                                variant="contained"
                                {...bindTrigger(popupState)}
                                size="large"
                              >
                                <MoreVert />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={async () => {
                                    popupState.close();
                                    createInitialState(dataLink);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    trigger({
                                      dataUrlId: dataLink.id,
                                    });

                                    popupState.close();

                                    setTimeout(async () => {
                                      const updatedDataSource =
                                        await updateDataSourceObject({
                                          id: selectedDataSource.id,
                                        });

                                      batch(() => {
                                        reduxDispatch({
                                          type: types.UPDATE_TEAM_DATA_SOURCE,
                                          dataSource: updatedDataSource,
                                          teamId: updatedDataSource.team.id,
                                        });
                                      });
                                    }, 500);
                                  }}
                                >
                                  {isMutating ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <>Delete</>
                                  )}
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {selectedDataSource?.dataUrls?.items.length === 0 && (
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="center">
                <DataObjectTwoTone color="disabled" fontSize="large" />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
              >
                No Web URL's found
              </Typography>
              <Typography
                align="center"
                color="textSecondary"
                display="block"
                gutterBottom
                variant="caption"
              >
                Add a Web URL below
              </Typography>
            </Box>
          )}
        </DialogContent>

        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            mt: 2,
            borderTop: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              pt: 2,
            }}
          >
            <Button
              color="info"
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<Add />}
              variant="outlined"
            >
              Create Web URL
            </Button>
          </Box>
        </Box>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DataApiDialog;
