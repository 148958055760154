import { Box, Typography } from '@mui/material';
import { animated, useTransition } from '@react-spring/web';

// eslint-disable-next-line no-unused-vars
function ChatMessages({ messages = [], side = 'left', isTyping = false }) {
  const transitions = useTransition(
    // eslint-disable-next-line no-return-assign
    messages,
    {
      key: (item) => item.name,
      from: {
        opacity: 0,
        transform:
          side === 'left'
            ? 'translate3d(-40px,0px,0px)'
            : 'translate3d(40px,0px,0px)',
      },
      trail: 700,
      leave: {
        opacity: 0,
        transform:
          side === 'left'
            ? 'translate3d(-40px,0px,0px)'
            : 'translate3d(40px,0px,0px)',
      },
      enter: () => ({ opacity: 1, transform: 'translate3d(0px,0px,0px)' }),
    }
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {transitions((style, msg, t, index) => (
          <animated.div style={{ ...style }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: side === 'left' ? 'flex-start' : 'flex-end',
              }}
            >
              <Typography
                align={'left'}
                color={'inherit'}
                gutterBottom={false}
                key={msg}
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  marginBottom: 0.5,
                  display: 'inline-block',
                  wordBreak: 'break-words',
                  borderRadius: 0,
                  ...(side === 'left' && {
                    borderTopRightRadius: theme.spacing(2.5),
                    borderBottomRightRadius: theme.spacing(2.5),
                    backgroundColor: theme.palette.grey[200],
                    textAlign: 'left',
                  }),

                  ...(side === 'right' && {
                    borderTopLeftRadius: theme.spacing(2.5),
                    borderBottomLeftRadius: theme.spacing(2.5),
                    background:
                      'linear-gradient(to right, #f06844 0%, #ee4c54 25%, #d45e95 50%, #9c6ca6 75%, #6583c1 100%)',
                    color: theme.palette.common.white,
                    textAlign: 'right',
                  }),
                  ...(index === 0 &&
                    side === 'left' && {
                      borderTopLeftRadius: theme.spacing(2.5),
                    }),

                  ...(index === messages.length - 1 &&
                    side === 'left' && {
                      borderBottomLeftRadius: theme.spacing(2.5),
                    }),
                  ...(index === 0 &&
                    side === 'right' && {
                      borderTopRightRadius: theme.spacing(2.5),
                    }),
                  ...(index === messages.length - 1 &&
                    side === 'right' && {
                      borderBottomRightRadius: theme.spacing(2.5),
                    }),
                })}
                variant={'body1'}
              >
                {msg === 'IS_TYPING' ? (
                  <div className="chat-bubble">
                    <div className="loading">
                      <div className="dot one" />
                      <div className="dot two" />
                      <div className="dot three" />
                    </div>
                  </div>
                ) : (
                  <>{msg}</>
                )}
              </Typography>
            </Box>
          </animated.div>
        ))}
      </Box>
    </>
  );
}

export default ChatMessages;
