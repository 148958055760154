/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import { BugReport } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDataProviderData from '../../../hooks/useDataProviderData';
import checkIfHasValidRequiredPartsCombination from '../../../utilities/checkIfHasValidRequiredPartsCombination';
import depensOnCheckMatches from '../../../utilities/depensOnCheckMatches';
import retrieveUrlFromTheConfigParams from '../../../utilities/retrieveUrlFromTheConfigParams';
import SelectorStep from './SelectorStep';

function DataProviderSelector({
  dataProvider,
  selectedCredential,
  setCreateStatus,
}) {
  // local state
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStepIndex, setLastActiveStepIndex] = useState(-1);
  const [stepInputOrder, setStepInputOrder] = useState([]);
  const [configParams, setConfigParams] = useState({});

  // redux state
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const configSettings = dataProviderSettings.config[dataProvider];
  const { route } = configSettings ?? {};

  const { data: config = {}, isLoading } = useDataProviderData(
    `/${route}/config/`,
    {}
  );

  React.useEffect(() => {
    if (config?.steps) {
      // calculate global ordering of every input item, in every step
      // the ordering is used to reset the state for all subsequent input items.
      const stepInputOrderTemp = [];
      config.steps.forEach((step) => {
        step.input_items.forEach((inputItem) => {
          stepInputOrderTemp.push(inputItem.id);
        });
      });
      setStepInputOrder(stepInputOrderTemp);
    }
  }, [config]);

  React.useEffect(() => {
    if (config?.required_parts) {
      const hasValidRequiredPartsCombination =
        checkIfHasValidRequiredPartsCombination(
          configParams,
          config.required_parts
        );

      const configParamsIds = {};

      Object.keys(configParams).forEach((inputItemId) => {
        for (let i = 0; i < config.steps.length; i++) {
          const step = config.steps[i];

          const correspondingInputItem = _find(step.input_items, {
            id: inputItemId,
          });

          // check if the corresponding input item has an value set as a option_config
          if (correspondingInputItem?.config?.option_config?.value) {
            // check if the configParams (with the full objects), has the data for the inputItemId
            if (configParams[inputItemId]) {
              // set the correct value from the input item result to the configParamsIds
              configParamsIds[inputItemId] =
                configParams[inputItemId][
                  correspondingInputItem.config.option_config.value
                ];
            }
            break;
          } else if (configParams[inputItemId]) {
            configParamsIds[inputItemId] = configParams[inputItemId]?.id;
          }
        }
      });
      const url = retrieveUrlFromTheConfigParams(config, configParams);
      setCreateStatus(hasValidRequiredPartsCombination, configParamsIds, url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, configParams]);

  useEffect(() => {
    // calculate the last active step index
    // used to determine if the "continue" button should be active
    let activeStepIndex = -1;
    config?.steps?.forEach((step, stepIndex) => {
      const { depends_on } = step;
      const shouldShow = depensOnCheckMatches(depends_on, configParams);
      if (shouldShow && !step.dataStudioOnly) {
        activeStepIndex = stepIndex;
      }
    });
    setLastActiveStepIndex(activeStepIndex);
  }, [configParams, config]);

  function updateConfig(stepIndex, id, value) {
    // let value = inputValue;
    const selectedDP = _find(config.steps[stepIndex].input_items, {
      id,
    });

    // check if there is a selection handler for the input item
    if (
      value &&
      selectedDP?.config?.selection_handler?.action === 'GO_TO_NEXT_STEP'
    ) {
      // automatically go to the next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // add the new input item value to the config param
    const newConfigParams = { ...configParams, [id]: value };

    // determine all subsequent input items that should be reset
    const configParamsIdsToReset = stepInputOrder.slice(
      stepInputOrder.indexOf(id) + 1
    );
    // reset all subsequent input items
    configParamsIdsToReset.forEach((configParamId) => {
      const selectedDPa = _find(config.steps[stepIndex].input_items, {
        id: configParamId,
      });

      if (
        !_isEqual(
          selectedDPa?.config?.defaultValue,
          newConfigParams[configParamId]
        )
      ) {
        delete newConfigParams[configParamId];
      }
    });

    setConfigParams(newConfigParams);
  }

  if (isLoading === true) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {(config?.steps ?? []).map((step, stepIndex) => {
          // determine if the step needs to be visible in the interface
          const shouldShowStep = depensOnCheckMatches(
            step.depends_on,
            configParams
          );
          if (shouldShowStep === false) {
            return null;
          }

          if (step?.dataStudioOnly === true) {
            return null;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Step key={stepIndex}>
              <SelectorStep
                // eslint-disable-next-line react/no-array-index-key
                activeStep={activeStep}
                configParams={configParams}
                dataProvider={dataProvider}
                key={stepIndex}
                lastActiveStepIndex={lastActiveStepIndex}
                selectedCredential={selectedCredential}
                setActiveStep={setActiveStep}
                step={step}
                stepIndex={stepIndex}
                updateConfig={updateConfig}
              />
            </Step>
          );
        })}
      </Stepper>

      {process.env.REACT_APP_USER_BRANCH !== 'prod' && (
        <>
          <PopupState popupId="demo-popup-popover" variant="popover">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton color="info" {...bindTrigger(popupState)}>
                    <BugReport />
                  </IconButton>
                </Box>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box sx={{ maxHeight: 300, maxWidth: 400 }}>
                    <Typography variant="caption">
                      <pre>{JSON.stringify(configParams, null, 2)}</pre>
                    </Typography>
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>
        </>
      )}
    </>
  );
}

export default DataProviderSelector;
