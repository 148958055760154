import { FilterList } from '@mui/icons-material';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';
import RecentSwitcher from '../RecentSwitcher';

function DataTableHead() {
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const [recentAnchorEl, setRecentAnchorEl] = React.useState(null);

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              border: 'none',
              padding: '8px 24px 8px 16px',
            }}
          >
            Data Source
          </TableCell>
          <TableCell
            sx={{
              border: 'none',
              padding: '8px 24px 8px 16px',
            }}
          >
            Connector
          </TableCell>
          {groups.includes('gladior') && (
            <TableCell
              sx={{
                border: 'none',
                padding: '8px 24px 8px 16px',
              }}
            >
              Access level
            </TableCell>
          )}
          <TableCell
            align="center"
            sx={{
              border: 'none',
              padding: '8px 24px 8px 16px',
            }}
          >
            <IconButton
              color="info"
              onClick={(event) => {
                setRecentAnchorEl(recentAnchorEl ? null : event.currentTarget);
              }}
              size="small"
              sx={(theme) => ({
                textTransform: 'none',
                border: `1px solid ${
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[600]
                    : theme.palette.grey[300]
                }`,

                color:
                  theme.palette.mode === 'dark'
                    ? '#FFF'
                    : theme.palette.primary[500],
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[800]
                    : '#FFF',
              })}
              variant="outlined"
            >
              <FilterList fontSize="inherit" />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>

      <Popper
        anchorEl={recentAnchorEl}
        id={recentAnchorEl ? 'recent-popper' : undefined}
        open={Boolean(recentAnchorEl)}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setRecentAnchorEl(null);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={5}>
                <RecentSwitcher />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export default DataTableHead;
