import React, { useEffect } from 'react';

function LocalhostRoute() {
  useEffect(() => {
    if (
      window.location.hostname === 'datahubapp.gladior.com' &&
      window.location.pathname.startsWith('/local/') === true
    ) {
      window.location.href = window.location.href
        .replace('/local', '')
        .replace('https://', 'http://')
        .replace('datahubapp.gladior.com', 'localhost:3000');
    }
  }, []);

  return <></>;
}

export default LocalhostRoute;
