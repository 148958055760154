/* eslint-disable react/jsx-boolean-value */
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Close, ExpandMore, People } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { API, graphqlOperation } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import AdminPanel from '../components/AdminPanel';
import WelcomeScreen from '../components/WelcomeScreen';
import WorkspaceRow from '../components/teams/WorkspaceRow';
import UserAvatar from '../components/user/UserAvatar';
import * as types from '../constants/ActionTypes';
import logoDev from '../data-hub-icon-dev.svg';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import logo from '../logo-icon.svg';

const useStyles = makeStyles((theme) => ({
  adminButton: {
    textTransform: 'none',
    background: theme.palette.background.paper,
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(8),
  },
}));

function WorkspacesOverview() {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const currentAUser = useSelector((state) => state.currentAuthenticatedUser);
  const userObject = useSelector((state) => state.userObject);
  // local state

  const [showInvitations, setShowInvitations] = useState(false);
  const [hasFreeDomain, setHasFreeDomain] = useState(true);
  const [success, setSuccess] = useState(false);
  const [hideCreateNewTeam, setHideCreateNewTeam] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  // redux
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const { items: teamInvites, isFetching } = useSelector(
    (state) => state.teamInvites
  );

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {
    if (!user || !user.attributes) {
      window.location.reload();
    }
  }, [user]);

  async function getTeamInvites() {
    dispatch({
      type: types.REQUEST_TEAM_INVITES,
    });

    const getAllTeamInvitesRe = await API.graphql(
      graphqlOperation(queries.getAllTeamInvites, {})
    );
    const newTeamInvites = getAllTeamInvitesRe.data.getAllTeamInvites;

    dispatch({
      type: types.SET_TEAM_INVITES,
      invites: newTeamInvites,
    });

    return teamInvites;
  }

  useEffect(() => {
    async function getFreeDomainStatus() {
      const hasFreeDomainRe = await API.graphql(
        graphqlOperation(queries.userHasFreeEmailDomain, {})
      );
      setHasFreeDomain(hasFreeDomainRe.data.userHasFreeEmailDomain);
    }
    getFreeDomainStatus();
  }, []); // Only re-run the effect if count changes

  useEffect(() => {
    async function check() {
      if (teamInvites.length === 0) {
        await getTeamInvites();
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only re-run the effect if count changes

  useEffect(() => {
    if (isFetching === false) {
      if (
        teamInvites.filter((invite) => invite.status === 'ACCEPTED').length ===
        0
      ) {
        setShowInvitations(true);
        if (
          teamInvites.filter((invite) => invite.status === 'OPEN').length === 0
        ) {
          setHideCreateNewTeam(false);
        } else {
          setHideCreateNewTeam(true);
        }
        // setShowCreateNewTeam(true);
      } else {
        setShowInvitations(false);
        setHideCreateNewTeam(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInvites]);

  if (!currentAuthenticatedUser?.attributes) {
    return null;
  }

  if (!user) {
    return null;
  }

  if (isFetching && teamInvites.length === 0) {
    return (
      <Box style={{ position: 'fixed', top: '50%', left: '50%' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const today = new Date();
  const curHr = today.getHours();

  let greetingText = '';

  if (curHr < 12) {
    greetingText = 'Good morning';
  } else if (curHr < 18) {
    greetingText = 'Good afternoon';
  } else {
    greetingText = 'Good evening';
  }

  return (
    <>
      <WelcomeScreen />

      <Container maxWidth="sm">
        <Box className={classes.paper}>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box mb={2}>
              <Avatar
                src={
                  process.env.REACT_APP_USER_BRANCH === 'prod' ? logo : logoDev
                }
                variant="rounded"
              />
            </Box>

            <Typography align="center" variant="h5">
              {greetingText} {currentAuthenticatedUser.attributes.name.trim()}
              {process.env.REACT_APP_USER_BRANCH !== 'prod' && (
                <>
                  <br />
                  <Typography
                    align="center"
                    color="textSecondary"
                    component="span"
                    gutterBottom
                    variant="subtitle1"
                  >
                    {' '}
                    (dev environment)
                  </Typography>
                </>
              )}
            </Typography>
          </Box>

          <Collapse in={hideCreateNewTeam}>
            {teamInvites.filter((invite) => invite.status === 'OPEN').length >
              0 && (
              <Box mt={6} style={{ opacity: isFetching === true ? 0.3 : 1 }}>
                <Accordion
                  defaultExpanded={showInvitations}
                  expanded={showInvitations}
                  onChange={(event, isExpanded) => {
                    setShowInvitations(isExpanded);
                  }}
                  square={true}
                  variant="outlined"
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMore />}
                    id="panel1a-header"
                  >
                    <Box
                      alignContent="center"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography>
                        {' '}
                        <Icon fontSize="inherit">email</Icon> Workspaces
                        invitations
                      </Typography>
                      <Chip
                        color="secondary"
                        label={
                          teamInvites.filter(
                            (invite) => invite.status === 'OPEN'
                          ).length
                        }
                        size="small"
                      />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <Box display="flex" flexDirection="column" width="100%">
                      {teamInvites
                        .filter((invite) => invite.status === 'OPEN')
                        .map((invite) => (
                          <WorkspaceRow
                            getTeamInvites={getTeamInvites}
                            invite={invite}
                            key={invite.id}
                          />
                        ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {teamInvites.filter((invite) => invite.status === 'ACCEPTED')
              .length > 0 && (
              <Box mt={3} style={{ opacity: isFetching === true ? 0.3 : 1 }}>
                {groups.includes('gladior') && (
                  <Card
                    square={true}
                    style={{ borderBottom: 0 }}
                    variant="outlined"
                  >
                    <CardContent style={{ padding: '12px' }}>
                      <Typography>
                        Workspaces for{' '}
                        <b>{currentAuthenticatedUser.attributes.email}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                <Card square={true} variant="outlined">
                  {teamInvites
                    .filter((invite) => invite.status === 'ACCEPTED')
                    .map((invite) => (
                      <WorkspaceRow
                        getTeamInvites={getTeamInvites}
                        invite={invite}
                        key={invite.id}
                      />
                    ))}
                </Card>
              </Box>
            )}
          </Collapse>

          {groups.includes('gladior') && (
            <Box mt={6}>
              <Card
                square
                style={{ backgroundColor: theme.palette.grey[200] }}
                variant="outlined"
              >
                <Box p={2}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      mr={2}
                    >
                      <Box mr={1}>
                        <Avatar className={classes.avatar}>
                          <People />
                        </Avatar>
                      </Box>

                      <Typography variant="body2">
                        Want to use a different workspace?
                      </Typography>
                    </Box>
                    <Box>
                      {hideCreateNewTeam ? (
                        <Button
                          className={classes.adminButton}
                          color="primary"
                          onClick={() => {
                            setHideCreateNewTeam(!hideCreateNewTeam);
                          }}
                          variant="outlined"
                        >
                          Create another workspace
                        </Button>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setHideCreateNewTeam(!hideCreateNewTeam);
                          }}
                          size="large"
                          variant="outlined"
                        >
                          <Close />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Collapse in={!hideCreateNewTeam}>
                  <Formik
                    enableReinitialize // because of hasFreeDomain
                    initialValues={{
                      team_name:
                        hasFreeDomain === false
                          ? currentAuthenticatedUser.attributes.email
                              .split('@')[1]
                              .split('.')[0]
                          : '',
                      autoInviteDomains: hasFreeDomain === false,
                      makeDefault: true,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      const newTeam = await API.graphql(
                        graphqlOperation(mutations.createNewTeam, {
                          teamName: values.team_name,
                          autoInviteDomains: values.autoInviteDomains,
                          makeDefault: values.makeDefault,
                        })
                      );
                      await getTeamInvites();
                      const newTeamId = newTeam.data.createNewTeam;
                      navigate(`/workspace/${newTeamId}/data-sources`);
                      setSubmitting(false);
                      setSuccess(true);
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.team_name) {
                        errors.team_name = 'Required field';
                      }

                      return errors;
                    }}
                  >
                    {(props) => (
                      <Form>
                        <Box p={2}>
                          <Typography variant="h6">
                            What’s the name of your company or workspace?
                          </Typography>
                          <Box mt={2}>
                            <TextField
                              error={props.errors.team_name !== undefined}
                              fullWidth
                              helperText={props.errors.team_name}
                              label="Workspace name"
                              margin="dense"
                              name="team_name"
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                              type="text"
                              value={props.values.team_name}
                              variant="filled"
                            />
                            {hasFreeDomain === false && (
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={props.values.autoInviteDomains}
                                      color="secondary"
                                      onChange={(event) => {
                                        props.setFieldValue(
                                          'autoInviteDomains',
                                          event.target.checked
                                        );
                                      }}
                                    />
                                  }
                                  label={
                                    <>
                                      Let anyone with an{' '}
                                      <b>
                                        {`@${
                                          currentAuthenticatedUser.attributes.email.split(
                                            '@'
                                          )[1]
                                        }`}
                                      </b>{' '}
                                      email join this team.
                                    </>
                                  }
                                />
                              </FormGroup>
                            )}
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            mt={2}
                          >
                            <Button
                              className={buttonClassname}
                              color="primary"
                              disabled={props.isSubmitting}
                              onClick={props.submitForm}
                              variant="contained"
                            >
                              {success && <Icon>check</Icon>}
                              {props.isSubmitting === true ? (
                                <CircularProgress
                                  className={classes.buttonProgress}
                                  size={24}
                                />
                              ) : (
                                'Create & Launch Workspace'
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Collapse>
              </Card>
            </Box>
          )}

          <Box alignItems="center" display="flex" flexDirection="column" mt={6}>
            <Box sx={{ mb: 2 }}>
              <Chip
                avatar={
                  <UserAvatar
                    avatar={userObject.avatar}
                    name={user?.attributes?.name}
                    size="small"
                  />
                }
                label={
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span>{ellipsis(user?.attributes?.name, 45)}</span>

                    <SvgIcon>
                      <path d="M7 10l5 5 5-5z" />
                    </SvgIcon>
                  </span>
                }
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  textTransform: 'none',
                  border: `1px solid ${
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[600]
                      : theme.palette.grey[300]
                  }`,

                  color:
                    theme.palette.mode === 'dark'
                      ? '#FFF'
                      : theme.palette.primary[500],
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : '#FFF',
                }}
                variant="outlined"
              />
              <Menu
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                keepMounted
                onClose={() => {
                  setAnchorEl(null);
                }}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <MenuItem
                  onClick={() => {
                    signOut();

                    try {
                      // eslint-disable-next-line no-restricted-globals
                      self.close();

                      const popup = window.self;
                      popup.opener = window.self;
                      popup.close();
                      // eslint-disable-next-line no-empty
                    } catch (e) {}
                    navigate(`/auth`);
                  }}
                >
                  Sign out
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Typography
              align="center"
              color="text.secondary"
              sx={{ mb: 2 }}
              variant="caption"
            >
              By using the Data Hub, you agree to the{' '}
              <Link
                color="secondary"
                href="https://datahub.gladior.com/terms-of-service"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </Link>
              . <br />
              View our{' '}
              <Link
                color="secondary"
                href="https://datahub.gladior.com/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            <Typography align="center" color="text.secondary" variant="caption">
              {'Copyright © '}
              Data Hub powered by Gladior {new Date().getFullYear()}.
            </Typography>
          </Box>
        </Box>
      </Container>
      {currentAUser.username === '864c28a9-9c01-4113-8630-7f40a3a05dfe' &&
        false && <AdminPanel getTeamInvites={getTeamInvites} />}
    </>
  );
}

export default WorkspacesOverview;
