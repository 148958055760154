function numberFromText(text) {
  if (!text) {
    return 0;
  }
  // numberFromText("AA");
  const charCodes = text
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
}

export default numberFromText;
