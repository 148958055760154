import querystring from 'querystring';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function generateState(service, workspaceId, redirectUri = '') {
  let mode = '';
  if (window.location.hostname === 'localhost') {
    mode = 'local';
  } else if (window.location.hostname.indexOf('staging') > -1) {
    mode = 'staging';
  } else {
    mode = 'production';
  }

  return {
    dataProvider: service,
    workspaceId,
    mode,
    redirectUri: atob(redirectUri),
  };
}

function MajesticUsercallbackRoute() {
  const location = useLocation();

  const queryStringObject = querystring.parse(location.search.slice(1));

  const { code, service, workspaceId, redirectUri } = queryStringObject;
  const state = generateState(service, workspaceId, redirectUri);

  const urlR = `https://datahubapp.gladior.com/usercallback/?${querystring.stringify(
    { code, state: JSON.stringify(state) }
  )}`;

  useEffect(() => {
    window.location.href = urlR;
  }, [urlR]);

  return <></>;
}

export default MajesticUsercallbackRoute;
