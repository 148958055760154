import { Notifications, NotificationsNone } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import _filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as types from '../constants/ActionTypes';
import * as queries from '../graphql/queries';
import WorkspaceRow from './teams/WorkspaceRow';

function TeamInviteMenu() {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [inviteAnchorEl, setInviteAnchorEl] = useState(null);
  const [openInvitesDialog, setOpenInvitesDialog] = useState(false);

  const { items: teamInvites } = useSelector((state) => state.teamInvites);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const { items: credentials } = credentialsByTeam[selectedUserTeam] || {
    items: [],
  };

  async function getTeamInvites() {
    setOpenInvitesDialog(false);

    dispatch({
      type: types.REQUEST_TEAM_INVITES,
    });

    const getAllTeamInvitesRe = await API.graphql(
      graphqlOperation(queries.getAllTeamInvites, {})
    );
    const newTeamInvites = getAllTeamInvitesRe.data.getAllTeamInvites;

    dispatch({
      type: types.SET_TEAM_INVITES,
      invites: newTeamInvites,
    });

    return teamInvites;
  }

  useEffect(() => {
    async function check() {
      if (teamInvites.length === 0) {
        await getTeamInvites();
      }
    }
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only re-run the effect if count changes

  const showNotification =
    _filter(credentials, (o) => o.status === 'INVALID').length > 0;

  return (
    <>
      <Dialog
        onClose={() => {
          setOpenInvitesDialog(false);
        }}
        open={openInvitesDialog}
      >
        <DialogTitle>Workspace Invitations</DialogTitle>
        <DialogContent>
          {teamInvites.filter((invite) => invite.status === 'OPEN').length >
            0 && (
            <Box display="flex" flexDirection="column" width="100%">
              {teamInvites
                .filter((invite) => invite.status === 'OPEN')
                .map((invite) => (
                  <WorkspaceRow
                    getTeamInvites={getTeamInvites}
                    invite={invite}
                    key={invite.id}
                  />
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInvitesDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        disabled={!showNotification}
        onClick={(event) => {
          setInviteAnchorEl(inviteAnchorEl ? null : event.currentTarget);
        }}
        size="large"
      >
        <Badge
          badgeContent={
            _filter(credentials, (o) => o.status === 'INVALID').length
          }
          color="secondary"
        >
          {showNotification === false ? (
            <NotificationsNone />
          ) : (
            <Notifications />
          )}
        </Badge>
      </IconButton>

      <Popper
        anchorEl={inviteAnchorEl}
        disablePortal
        id={inviteAnchorEl ? 'invite-popper' : undefined}
        open={Boolean(inviteAnchorEl)}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setInviteAnchorEl(null);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={10}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      color="text.secondary"
                      gutterBottom
                      sx={{ fontSize: 14 }}
                    >
                      Credentials
                    </Typography>

                    <Typography variant="body2">
                      Issues detected with some of your credentials.
                      <br /> Re-authenticate the invalid credentials.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        searchParams.set('workspaceId', selectedUserTeam);
                        searchParams.set('invalidCredentials', true);
                        navigate({
                          pathname: `data-sources/credentials`,
                          search: searchParams.toString(),
                        });
                      }}
                      size="small"
                    >
                      Fix issue
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export default TeamInviteMenu;
