import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import DynamicDropdown from './DynamicDropdown';
import StaticDropdown from './StaticDropdown';

function ConfigDropdown({
  id,
  stepIndex,
  config,
  dependsOn,
  credential,
  updateConfig,
  configParams,
  dataProvider,
}) {
  let dropdown;

  if (config.variant === 'DYNAMIC') {
    dropdown = (
      <DynamicDropdown
        config={config}
        configParams={configParams}
        credential={credential}
        dataProvider={dataProvider}
        dependsOn={dependsOn}
        id={id}
        key={id}
        stepIndex={stepIndex}
        updateConfig={updateConfig}
      />
    );
  }
  if (config.variant === 'STATIC') {
    dropdown = (
      <StaticDropdown
        config={config}
        configParams={configParams}
        dependsOn={dependsOn}
        id={id}
        key={id}
        stepIndex={stepIndex}
        updateConfig={updateConfig}
      />
    );
  }

  if (config?.style === 'ADVANCED_INPUT') {
    return (
      <Box sx={{ my: 2 }}>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Advanced Configuration</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {dropdown}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return <Box sx={{ my: 2 }}>{dropdown}</Box>;
}

export default ConfigDropdown;
