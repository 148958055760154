/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

function filterDataSources(dataSources, filter) {
  if (!filter) {
    return dataSources;
  }

  const filtered = dataSources.filter((dataSource) =>
    filter.includes(dataSource.dataProvider)
  );

  return filtered;
}

function DataSourceSelector({
  id,
  stepIndex,
  config,
  dependsOn,
  credential,
  updateConfig,
  configParams,
  dataProvider,
}) {
  const { option_config, label, helper_text } = config;

  const { filter = [] } = option_config || {};

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);
  const { items: dataSources } = dataSourcesByTeam[selectedUserTeam] || {
    items: [],
  };

  console.log({ filter });

  const filteredDataSources = useMemo(
    () => filterDataSources(dataSources, filter),
    [dataSources, filter]
  );

  return (
    <Box sx={{ my: 2 }}>
      <Autocomplete
        disablePortal
        fullWidth
        getOptionLabel={(option) => option.name}
        id="combo-box-demo"
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={(event, value, reason) => {
          updateConfig(stepIndex, id, value);
        }}
        options={filteredDataSources}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helper_text}
            label={label}
            variant="filled"
          />
        )}
      />
    </Box>
  );
}

export default DataSourceSelector;
