import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import { API, Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import getHostName from '../../utilities/getHostName';
import DataProviderSelectors from './DataProviderSelectors';

const useStyles = makeStyles(() => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function ReConnectDataSourceDialog({ onClose = () => {} }) {
  const classes = useStyles();
  const createButtonRef = React.useRef();

  // eslint-disable-next-line no-unused-vars
  const [configParams, setConfigParams] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [potentialLabels, setPotentialLabels] = useState([]);
  const dispatch = useDispatch();

  const globalDataSource = useSelector((state) => state.selectedDataSource);
  const dialogs = useSelector((state) => state.dialogs);

  const selectedDataSource = dialogs.selectedDataSource ?? globalDataSource;
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );
  const open = dialogs.showReConnectDataSourceDialog;

  function closeDialog() {
    dispatch({
      type: types.SHOW_RE_CONNECT_DATA_SOURCE_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createStatus, setCreateStatus] = useState(false);

  function setDynamicStatus(status, configParamsIds, url) {
    if (configParamsIds) {
      setConfigParams(configParamsIds);
    }

    if (url) {
      setPotentialLabels([{ value: getHostName(url) }]);
    } else {
      setPotentialLabels([]);
    }

    setCreateStatus(status);

    if (status === true) {
      setTimeout(() => {
        if (createButtonRef.current) {
          createButtonRef.current.focus();
        }
      }, 0);
    }
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  async function submitView() {
    setIsSubmitting(true);

    const { route } =
      dataProviderSettings.config[selectedDataSource.dataProvider];

    const tokens = await Auth.currentSession();
    try {
      const response = await API.post(
        'DataProviderApi',
        `/${route}/data-source/`,
        {
          body: {
            dataSourceId: selectedDataSource.id,
            configParams,
          },
          headers: {
            Authorization: `${tokens.getIdToken().getJwtToken()}`,
          },
          response: true,
          queryStringParameters: {
            credentialId: selectedDataSource.credential.id,
          },
        }
      );

      // get the latest version with also the correct label
      const updatedDataSource = await updateDataSourceObject({
        id: response.data.id,
      });

      batch(() => {
        dispatch({
          type: types.UPDATE_TEAM_DATA_SOURCE,
          dataSource: updatedDataSource,
          teamId: updatedDataSource.team.id,
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    setIsSubmitting(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      closeDialog();
    }, 500);
  }

  if (!selectedDataSource) {
    return <> </>;
  }

  return (
    <div>
      <Dialog
        aria-describedby="reconnect-data-source-dialog-description"
        aria-labelledby="reconnect-data-source-dialog-title"
        fullWidth
        maxWidth="md"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="reconnect-data-source-dialog-title">
          Reconnect data source
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="reconnect-data-source-dialog-description">
            With this option you can switch credential, view or location within
            a data provider. The name of the data source is also changed
            automatically.
          </DialogContentText>

          <DataProviderSelectors
            dataProvider={selectedDataSource.dataProvider}
            selectedCredential={selectedDataSource.credential}
            setDynamicStatus={setDynamicStatus}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button
            autoFocus
            className={buttonClassname}
            color="primary"
            disabled={!createStatus}
            onClick={submitView}
            ref={createButtonRef}
            variant="contained"
          >
            {success && <Icon>check</Icon>}
            {isSubmitting === true ? (
              <CircularProgress className={classes.buttonProgress} size={24} />
            ) : (
              'Reconnect data source'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReConnectDataSourceDialog;
