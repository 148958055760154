export default {
  CUSTOM: 'Custom',
  LAST_YEAR: 'Last year',
  LAST_QUARTER: 'Last quarter',
  LAST_MONTH: 'Last month',
  THIS_MONTH_TO_DATE: 'This month to date',
  LAST_WEEK_STARTS_MONDAY: 'Last week (starts Monday)',
  LAST_WEEK_STARTS_SUNDAY: 'Last week (starts Sunday)',
  LAST_7_DAYS: 'Last 7 days',
  LAST_14_DAYS: 'Last 14 days',
  LAST_28_DAYS: 'Last 28 days',
  LAST_30_DAYS: 'Last 30 days',
  YESTERDAY: 'Yesterday',
  THIS_WEEK_STARTS_SUNDAY_TO_DATE: 'This week to date (starts Sunday)',
  THIS_WEEK_STARTS_MONDAY_TO_DATE: 'This week to date (starts Monday)',
  THIS_QUARTER_TO_DATE: 'This quarter to date',
  THIS_YEAR_TO_DATE: 'This year to date',
  THIS_WEEK_STARTS_SUNDAY: 'This week (starts Sunday)',
  THIS_WEEK_STARTS_MONDAY: 'This week (starts Monday)',
  THIS_MONTH: 'This month',
  THIS_QUARTER: 'This quarter',
  THIS_YEAR: 'This year',
  TODAY: 'Today',
};
