/* eslint-disable no-nested-ternary */

import { ArrowForwardIosSharp, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import React from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../constants/ActionTypes';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const dataApiBase =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data.dh.gladior.com'
    : 'https://data-dev.dh.gladior.com';

const s3Url =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data-provider-api-prod-data-provider-assets.s3.eu-west-1.amazonaws.com'
    : 'https://data-provider-api-dev-data-provider-assets.s3.eu-west-1.amazonaws.com';

const salesPortalUrl =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://sales-portal.dh.datalaag.nl'
    : 'https://sales-portal-dev.dh.datalaag.nl';

function WebsiteIntegrationDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openDialog = dialogs.showWebsiteIntegrationDialog;

  function closeDialog() {
    reduxDispatch({
      type: types.SHOW_WEBSITE_INTEGRATION_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Instructions</DialogTitle>
        <DialogContent>
          {selectedDataSource.dataProvider === 'AFAS_INTEGRATION_PAGE' && (
            <>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                variant="outlined"
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  expandIcon={<ExpandMore />}
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    URL
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {`${salesPortalUrl}/${selectedDataSource.id}/`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {selectedDataSource.dataProvider === 'OPEN_AI' && (
            <>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                variant="outlined"
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  expandIcon={<ExpandMore />}
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Generic
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <b>Endpoint:</b> {`${dataApiBase}/open-ai/`}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <b>x-api-key:</b> {`${selectedDataSource.id}`}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <b>body:</b>{' '}
                    {`{
  "prompt": "Hello"
}`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                variant="outlined"
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  expandIcon={<ExpandMore />}
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    AFAS InSite & OutSite
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CopyBlock
                    language={'html'}
                    text={`
      <script src="${s3Url}/afas.js"></script>
    
    <script>
      var afasGPTConfig = {
        endPoint: "${dataApiBase}/open-ai/",
        token: "${selectedDataSource.id}",
        gptButtonIcon:
          "${s3Url}/gpt-icon.png",
        loadingImage:
          "${s3Url}/loading.gif",
      };
    </script>
    
    <script>
      afasMain(function () {
        addGptButtonToTextfield("Toelichting", function (editorId) {
          var title = getAFASMainFormTitle();
          var fields = getAFASMainFormFields();
          setStateGPTButton(editorId, "loading");
          runGPT({
            prompt: ""
              .concat(title, " met het volgende onderwerp ")
              .concat(fields["Onderwerp"]),
          })
            .then(function (gptReponse) {
              setStateGPTButton(editorId, "idle");
              return typeWriter(editorId, gptReponse.message.content);
            })
            .catch(function (e) {
              setStateGPTButton(editorId, "error");
              console.error(e);
            });
        });
      });
    </script>
    
      `}
                    wrapLines
                    theme={atomOneDark}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WebsiteIntegrationDialog;
