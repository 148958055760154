import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';

export default function AddNewNode({ addNode }) {
  return (
    <Paper
      onClick={() => addNode()}
      sx={(theme) => ({
        display: 'flex',
        width: 'fit-content',
        border: `2px solid ${theme.palette.secondary.main}`,
        cursor: 'pointer',
      })}
    >
      <Add></Add>
    </Paper>
  );
}
