import { HelpOutline } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import MessageEditor from '../textEditor/messageEditor';
import IntegrationDropdown from './IntegrationDropdown';
import IntegrationInfo from './integrationInfo';
// eslint-disable-next-line import/no-cycle
import SubSteps from './substeps';

export function dependsOnMatches(step, configObj) {
  const { depends_on: dependsOn } = step;
  if (!dependsOn) {
    return true;
  }
  return dependsOn.every((requirement) => {
    if (!configObj[requirement.input_id]) {
      return false;
    }
    if (
      (requirement.condition === 'NOT_EMPTY' &&
        !configObj[requirement.input_id]) ||
      configObj[requirement.input_id] ===
        `[{"type":"paragraph","children":[{"text":""}]}]`
    ) {
      return false;
    }
    if (
      requirement.condition === 'EQUAL_TO' &&
      configObj[requirement.input_id] !== requirement.value
    ) {
      return false;
    }
    if (
      requirement.condition === 'INCLUDES' &&
      !requirement.values.includes(configObj[requirement.input_id])
    ) {
      return false;
    }
    if (
      requirement.condition === 'NOT_INCLUDES' &&
      requirement.values.includes(configObj[requirement.input_id])
    ) {
      return false;
    }
    if (
      requirement.condition === 'VALID_URL' &&
      !(
        configObj[requirement.input_id].startsWith('http://') ||
        configObj[requirement.input_id].startsWith('https://')
      )
    ) {
      return false;
    }
    if (
      requirement.condition === 'VALID_DOMAIN' &&
      !configObj[requirement.input_id].includes('.')
    ) {
      return false;
    }
    return true;
  });
}

function StepBase({ stepConfig, children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: 1,
          borderBottom: 'none',
          maxWidth: '33%',
          width: '33%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {stepConfig.title}
          </Typography>
          {stepConfig.info && (
            <Tooltip title={stepConfig.info}>
              <HelpOutline fontSize="small" />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          padding: 0,
          borderBottom: 'none',
          width: '66%',
          pr: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default function IntegrationStepList({
  config,
  setConfig,
  configObj,
  queryObj,
  messageType,
}) {
  if (!configObj) {
    return <></>;
  }

  return (
    <Box>
      {config?.steps?.map((step) => {
        if (step.depends_on && !dependsOnMatches(step, configObj)) {
          return null;
        }
        if (step.type === 'dropdown') {
          return (
            <StepBase key={step.id} stepConfig={step}>
              <IntegrationDropdown
                setValue={(val) => {
                  setConfig((prev) => ({ ...prev, [step.id]: val }));
                }}
                step={step}
                value={configObj[step.id]}
              />
            </StepBase>
          );
        }
        if (step.type === 'info') {
          return <IntegrationInfo key={step.id} step={step} />;
        }
        if (step.type === 'large-text' || step.type === 'text') {
          return (
            <StepBase key={step.id} stepConfig={step}>
              <MessageEditor
                aggregateFields={messageType[0] === 'aggregated'}
                fields={[
                  ...queryObj.selectedDimensions,
                  ...queryObj.selectedMetrics,
                ]}
                initialMessage={
                  configObj[step.id] ??
                  JSON.stringify([
                    {
                      type: 'paragraph',
                      children: [{ text: '' }],
                    },
                  ])
                }
                onChange={(value) => {
                  setConfig((prev) => ({
                    ...prev,
                    [step.id]: JSON.stringify(value),
                  }));
                }}
                small={step.type === 'text'}
              ></MessageEditor>
            </StepBase>
          );
        }
        if (step.type === 'substep') {
          return (
            <SubSteps
              configObj={configObj[step.id]}
              key={step.id}
              messageType={messageType}
              queryObj={queryObj}
              setConfig={(value) => {
                if (typeof value === 'function') {
                  setConfig((prev) => ({
                    ...prev,
                    [step.id]: value(prev[step.id]),
                  }));
                } else {
                  setConfig((prev) => ({
                    ...prev,
                    [step.id]: value,
                  }));
                }
              }}
              step={step}
            />
          );
        }
        return <Box key={step.id}>{step.title}</Box>;
      })}
    </Box>
  );
}
