/* eslint-disable no-nested-ternary */
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { CompareArrows, EmailOutlined, PauseCircle } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  Link,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import _filter from 'lodash/filter';
import { useSnackbar } from 'notistack';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import getAllCredentials from '../api/getAllCredentials';

import getUserObject from '../api/getUserObject';
import AddCredentialButton from '../components/data/AddCredentialButton';
import UserTokenDialog from '../components/data/UserTokenDialog';
import UserAvatar from '../components/user/UserAvatar';

import ellipsis from 'text-ellipsis';
import * as types from '../constants/ActionTypes';
import logoDev from '../data-hub-icon-dev.svg';
import dataHubTextDev from '../data-hub-text-dev.svg';
import dataHubTextProd from '../data-hub-text-prod.svg';
import { ReactComponent as GoogleIcon } from '../google/btn_google_light_normal_ios.svg';
import * as mutations from '../graphql/mutations';
import useBackendData from '../hooks/useBackendData';
import useDataProviderData from '../hooks/useDataProviderData';
import logo from '../logo-icon.svg';

function kebabToSnake(str) {
  // replace all dashes with
  const myString = str.replace(/-/g, '_');
  // return str
  return myString;
  // try with: "this - is -a - ---test";
}

function MiniConnect() {
  const { user, signOut, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const { data: workspaces = [] } = useBackendData(user && `/workspaces`, {});
  const { data: configData } = useDataProviderData(
    user && `/data-providers/config/`,
    {}
  );

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useTheme();

  const { dataProvider } = useParams();

  const [showLoader, setShowLoader] = useState(false);

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const userObject = useSelector((state) => state.userObject);

  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const { items: credentials, isFetching } = credentialsByTeam[
    selectedUserTeam
  ] || {
    items: [],
  };

  const selectedDataProvider = kebabToSnake(dataProvider.toUpperCase());

  const filteredCredentials = _filter(credentials, (o) =>
    o.dataProvider.includes(selectedDataProvider)
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(false);

  async function generateNexStep() {
    const queryObj = querystring.parse(location.search.slice(1));

    if (queryObj.redirect_uri) {
      setShowLoader(true);

      const codeObjectResponse = await API.graphql(
        graphqlOperation(mutations.generateLoginCode, {})
      );
      const codeObject = codeObjectResponse.data.generateLoginCode;

      const environment =
        process.env.REACT_APP_USER_BRANCH === 'prod'
          ? 'DATA_HUB_PROD'
          : 'DATA_HUB_DEV';

      const redirectUrl = `${queryObj.redirect_uri}?code=${codeObject.code}&state=${queryObj.state}&environment=${environment}`;
      setShowLoader(false);
      window.location.replace(redirectUrl);
    } else {
      enqueueSnackbar(`No redirect_uri`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  }

  async function fetchALlCredentials() {
    dispatch({
      type: types.REQUEST_TEAM_CREDENTIALS,
      teamId: selectedUserTeam,
    });
    const { credentials: credentials2 } = await getAllCredentials(
      selectedUserTeam,
      undefined
    );

    dispatch({
      type: types.SET_TEAM_CREDENTIALS,
      teamId: selectedUserTeam,
      credentials: credentials2,
      nextToken: undefined,
    });
  }

  useEffect(() => {
    if (route === 'authenticated' && configData) {
      dispatch({
        type: types.SET_DATA_PROVIDER_SETTINGS,
        settings: configData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, route]);

  useEffect(() => {
    if (route === 'authenticated') {
      // check if current authenticated user has any teams
      // if user has no teams, show the create mandatory team dialog
      if (workspaces.length > 0) {
        batch(() => {
          dispatch({
            type: types.SELECT_USER_TEAM,
            teamId: workspaces[0].id,
          });
          dispatch({
            type: types.SET_USER_TEAMS,
            userTeams: workspaces,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, workspaces]);

  useEffect(() => {
    async function fetchGraphQL() {
      const {
        userObject: userObject2,
        currentAuthenticatedUser: currentAuthUser,
      } = await getUserObject();

      if (userObject2) {
        batch(() => {
          dispatch({
            type: types.SET_CURRENT_AUTHENTICATED_USER,
            currentAuthenticatedUser: currentAuthUser,
          });
          dispatch({
            type: types.SET_USER_OBJECT,
            userObject: userObject2,
          });
        });
      }
    }

    if (route === 'authenticated') {
      fetchGraphQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  useEffect(() => {
    if (selectedUserTeam) {
      fetchALlCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam]);

  useEffect(() => {
    const env = process.env.REACT_APP_USER_BRANCH;

    if (
      filteredCredentials &&
      filteredCredentials.length > 0 &&
      env === 'prod'
    ) {
      generateNexStep();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCredentials.length]);

  return (
    <>
      <UserTokenDialog
        onClose={() => {
          fetchALlCredentials();
        }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {route !== 'authenticated' && (
            <>
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt="Data Hub Logo"
                    src={
                      process.env.REACT_APP_USER_BRANCH === 'prod'
                        ? dataHubTextProd
                        : dataHubTextDev
                    }
                    style={{ height: '75px' }}
                  />
                  <Typography
                    align="center"
                    color="text.secondary"
                    variant="subtitle1"
                  >
                    A really good and simple tool for connecting all your data
                    to your favorite Dashboarding platform.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    my: 2,
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      Auth.federatedSignIn({
                        provider: 'Google',
                        customState: JSON.stringify({
                          location,
                        }),
                      });
                    }}
                    startIcon={<GoogleIcon />}
                    sx={{ background: '#fff', textTransform: 'none' }}
                    variant="outlined"
                  >
                    Continue with Google
                  </Button>
                </Box>
                <Box
                  sx={{
                    my: 2,
                  }}
                >
                  <Divider>
                    <Typography color="text.secondary" variant="caption">
                      or
                    </Typography>
                  </Divider>
                </Box>

                <Box
                  sx={{
                    my: 2,

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      setChecked((prev) => !prev);
                    }}
                    startIcon={<EmailOutlined />}
                    sx={{ background: '#fff', textTransform: 'none' }}
                    variant="outlined"
                  >
                    Use my email
                  </Button>
                </Box>
              </Box>

              <Collapse in={checked}>
                <Box sx={{ mt: 2 }}>
                  <Authenticator
                    formFields={{
                      setupTOTP: {
                        QR: {
                          totpIssuer: 'Data Hub by Gladior',
                        },
                      },
                    }}
                    loginMechanisms={['email']}
                    signUpAttributes={['name', 'email']}
                    socialProviders={[]}
                  />
                </Box>
              </Collapse>

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography
                  align="center"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                  variant="caption"
                >
                  By signing up for Data Hub, you agree to the{' '}
                  <Link
                    color="secondary"
                    href="https://datahub.gladior.com/terms-of-service"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                  . View our{' '}
                  <Link
                    color="secondary"
                    href="https://datahub.gladior.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>

                <Typography
                  align="center"
                  color="text.secondary"
                  variant="caption"
                >
                  {'Copyright © '}
                  Data Hub powered by Gladior {new Date().getFullYear()}.
                </Typography>
              </Box>
            </>
          )}

          {route === 'authenticated' && isFetching === true && (
            <Box display="flex" justifyContent="center" width="100%">
              <CircularProgress size={20} />
            </Box>
          )}

          {route === 'authenticated' && user && isFetching === false && (
            <>
              {showLoader === true && (
                <Backdrop
                  open
                  sx={(theme2) => ({
                    zIndex: theme2.zIndex.drawer + 1,
                    color: '#fff',
                  })}
                >
                  <CircularProgress color="inherit" />{' '}
                </Backdrop>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box alignItems="center" display="flex" flexDirection="column">
                  <Box
                    mb={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Avatar
                      src={
                        process.env.REACT_APP_USER_BRANCH === 'prod'
                          ? logo
                          : logoDev
                      }
                      variant="rounded"
                    />

                    <CompareArrows color="action" sx={{ m: 1 }} />

                    <Avatar
                      src={
                        dataProviderSettings.config[selectedDataProvider]?.image
                      }
                      variant="rounded"
                    />
                  </Box>

                  <Typography align="center" variant="h6">
                    Connect to{' '}
                    {dataProviderSettings.config[selectedDataProvider]?.name}
                  </Typography>
                </Box>

                <Box sx={{ mt: 5 }}>
                  <AddCredentialButton
                    selectedDataProvider={selectedDataProvider}
                    variant="large"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mt: 3,
                }}
              >
                <Typography
                  align="center"
                  color="text.secondary"
                  variant="caption"
                >
                  Connect to the right{' '}
                  {dataProviderSettings.config[selectedDataProvider].name} user
                  account. <br />
                  You can always link more accounts in the Data Hub.
                </Typography>
              </Box>

              {process.env.REACT_APP_USER_BRANCH !== 'prod' &&
                filteredCredentials.length > 0 && (
                  <Box sx={{ mt: 4 }}>
                    <Alert
                      action={
                        <Button
                          color="info"
                          onClick={() => {
                            generateNexStep();
                          }}
                          size="small"
                          variant="outlined"
                        >
                          Continue
                        </Button>
                      }
                      iconMapping={{
                        info: <PauseCircle fontSize="inherit" />,
                      }}
                      severity="info"
                      variant="outlined"
                    >
                      <Typography noWrap variant="inherit">
                        Debug mode is on — redirection paused
                      </Typography>
                    </Alert>
                  </Box>
                )}

              <Box sx={{ mt: 4 }}>
                <Typography align="center" gutterBottom variant="h6">
                  <Box sx={{ mb: 2 }}>
                    <Chip
                      avatar={
                        <UserAvatar
                          avatar={userObject.avatar}
                          name={user.attributes.name}
                          size="small"
                        />
                      }
                      label={
                        <span
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <span>{ellipsis(user.attributes.name, 45)}</span>

                          <SvgIcon>
                            <path d="M7 10l5 5 5-5z" />
                          </SvgIcon>
                        </span>
                      }
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                      sx={{
                        textTransform: 'none',
                        border: `1px solid ${
                          theme.palette.mode === 'dark'
                            ? theme.palette.grey[600]
                            : theme.palette.grey[300]
                        }`,

                        color:
                          theme.palette.mode === 'dark'
                            ? '#FFF'
                            : theme.palette.primary[500],
                        background:
                          theme.palette.mode === 'dark'
                            ? theme.palette.grey[800]
                            : '#FFF',
                      }}
                      variant="outlined"
                    />
                    <Menu
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                      }}
                      keepMounted
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                      open={Boolean(anchorEl)}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          signOut();
                          // eslint-disable-next-line no-restricted-globals
                          self.close();

                          const popup = window.self;
                          popup.opener = window.self;
                          popup.close();
                        }}
                      >
                        Sign out
                      </MenuItem>
                    </Menu>
                  </Box>
                </Typography>

                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Typography
                    align="center"
                    color="text.secondary"
                    component="p"
                    gutterBottom
                    variant="subtitle1"
                  >
                    This Data Hub Connector is{' '}
                    <Link
                      color="secondary"
                      href="https://datahub.gladior.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      free
                    </Link>
                    . There’s no catch.
                  </Typography>
                  <Typography
                    align="center"
                    color="text.secondary"
                    variant="caption"
                  >
                    {'Copyright © '}
                    Data Hub powered by Gladior {new Date().getFullYear()}.
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
}

export default MiniConnect;
